import React, { useMemo, useState } from 'react';
import { centsToDollars } from '../../utils';
import { TTMenuItem } from '../../../interfaces/menuItem';
import store from '../../../store';
import { TTModifierWOptions } from '../../../interfaces/modifier';
import { animated, config, useTransition } from 'react-spring';

type Props = {
  menuItem: TTMenuItem;
  editable: boolean;
  title: string;
  onClick: () => void;
  modsChecked: boolean;
  selectedModifiers: TTModifierWOptions[];
};

const DishInfoCard: React.FC<Props> = ({
  menuItem,
  editable,
  title,
  onClick,
  modsChecked,
  selectedModifiers,
}) => {
  const [displayPrice, setDisplayPrice] = useState(menuItem?.price ?? '$0.00');
  const transitions = useTransition(
    menuItem?.image_url,
    _ => menuItem?.image_url,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      config: config.molasses,
    },
  );
  useMemo(() => {
    setDisplayPrice(
      centsToDollars(
        menuItem.price +
          selectedModifiers
            .map(m =>
              m.modifier_options.map(o => o.price).reduce((a, b) => a + b, 0),
            )
            .reduce((a, b) => a + b, 0),
      ),
    );
  }, [menuItem, selectedModifiers]);

  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  const renderText = txt =>
    txt.split(' ').map(part =>
      urlRegex.test(part) ? (
        <a href={part} target="_blank">
          {part}{' '}
        </a>
      ) : (
        part + ' '
      ),
    );

  return (
    <div className="pr-4 pl-4 py-4">
      <div className="rounded-md shadow-md bg-white divide-y divide-gray-200">
        <div className="flex lg:flex-row lg:p-2 lg:items-center lg:justify-start lg:space-x-8">
          {menuItem?.image_url !== undefined &&
            menuItem?.image_url !== null &&
            !RegExp(
              `^(https:\/\/d2b8t2l9x5xm7h.cloudfront.net\/public\/[0-9]*\/100.jpg).*`,
            ).test(menuItem?.image_url) &&
            transitions.map(({ item, props, key }) => {
              return (
                <animated.div
                  key={key}
                  style={props}
                  className="hidden lg:block"
                >
                  <img
                    className="rounded-md w-60 xl:w-72 2xl:w-80"
                    src={menuItem?.image_url}
                    alt=""
                  />
                </animated.div>
              );
            })}
          <div className={'flex-1 flex flex-col px-2 md:px-0'}>
            <div className="py-2 text-center  lg:text-left">
              <div className="font-bold text-xl mb-2 lg:mb-0">
                {menuItem.name}
              </div>
            </div>

            <div className=" py-2 items-center text-center lg:text-left">
              <span className="text-sm lg:text-base whitespace-pre-wrap">
                {menuItem.description !== undefined
                  ? renderText(menuItem.description)
                  : ''}
              </span>
            </div>
            {displayPrice !== '$0.00' && (
              <div className="pt-2 items-center text-center md:text-left text-left pb-4 lg:justify-start">
                <span className="text-left inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mt-2">
                  {displayPrice}
                </span>
              </div>
            )}
            {menuItem?.min_quantity !== 1 && (
              <div className="-mt-2 justify-center text-center md:text-left pb-4 lg:justify-start">
                <span className="md:text-left text-center inline-block bg-yellow-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mt-2">
                  Minimum quantity: {menuItem?.min_quantity}
                </span>
                {menuItem?.max_quantity !== 999 && (
                  <span className="md:text-left text-center inline-block bg-yellow-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mt-2">
                    Maximum quantity: {menuItem?.max_quantity}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        {store.getState().restaurant.profile !== null &&
          store.getState().restaurant.profile.is_payment_live && (
            <div
              className={`flex flex-1 w-full p-4 ${
                modsChecked
                  ? !editable
                    ? 'bg-gray-900 md:hidden'
                    : 'bg-primary-600'
                  : 'bg-gray-300 md:hidden'
              } items-center justify-center shadow-sm rounded-b-md`}
              onClick={onClick}
            >
              <div className={` `}>
                <span
                  className={`${
                    !editable
                      ? 'text-white text-center'
                      : 'text-white text-left'
                  } font-bold text-lg  mx-auto`}
                >
                  {title}
                </span>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default DishInfoCard;
