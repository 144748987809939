import axios from 'axios';
import { apiUrl } from '../env';
import {
  PhoneVerificationCodeRequest,
  PhoneVerificationCodeRequestResponse,
  PhoneVerificationRequest,
  PhoneVerificationRequestResponse,
} from '../interfaces/login';
/* eslint-disable */
export const authApi = {
  async logInGetToken(email: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', email);
    params.append('password', password);
    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async createPhoneVerificationCode(body: PhoneVerificationRequest) {
    return axios.post<PhoneVerificationRequestResponse>(
      `${apiUrl}/api/v1/login/phone-verification`,
      body,
    );
  },
  async verifyPhoneVerificationCode(body: PhoneVerificationCodeRequest) {
    return axios.post<PhoneVerificationCodeRequestResponse>(
      `${apiUrl}/api/v1/login/phone-verification/code`,
      body,
    );
  },
};
