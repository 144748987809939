import { Dialog, Transition } from '@headlessui/react';
import { Drawer } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { paymentsApi } from '../../api/payments';
import {
  setPaymentMethods,
  setSelectedPM,
  toggleCardsFetched,
} from '../../features/payment/payment';
import { TTRestaurantProfile } from '../../interfaces/restaurant';
import { RootState } from '../../rootReducer';
import { SelectPaymentMethodHeader } from '../Cart/components/SelectPaymentMethodHeader';
import SquareCardView from './SquareElements/SquareCardView';
import AddPaymentMethodButton from './StripeElements/AddPaymentMethodButton';
import PaymentMethodList from './StripeElements/PaymentMethodList';
import SelectedPMCard from './StripeElements/SelectedPMCard';

export function PaymentMethods({ showSwitch = false }) {
  const restaurantProfile = useSelector<RootState, TTRestaurantProfile | null>(
    state => state.restaurant.profile,
  );
  const cards = useSelector<RootState, any[]>(
    state => state.payment.payment_methods,
  );

  const { r_id, o_id } = useParams();

  const cardsFetched = useSelector<RootState, boolean>(
    state => state.payment.cards_fetched,
  );
  const [showPaymentMethodOptions, setShowPaymentMethodOptions] =
    useState(false);
  const selectedPMId = useSelector<RootState, string | null>(
    state => state.payment.selected_pm,
  );
  const dispatch = useDispatch();
  if (restaurantProfile === null) {
    return <></>;
  }

  const drawerProps = useSpring({
    opacity: showPaymentMethodOptions ? 1 : 0,
  });

  const fetchCards = async () => {
    try {
      const response = await paymentsApi.listPaymentMethods(
        restaurantProfile.processing_platform,
        restaurantProfile.company_id,
      );
      console.log(response.data);
      dispatch(setPaymentMethods(response.data.data));
      if (response.data.data.length !== 0) {
        dispatch(setSelectedPM(response.data.data[0]['id']));
      }
      dispatch(toggleCardsFetched());
    } catch (e) {}
  };

  useEffect(() => {
    if (restaurantProfile !== null && !cardsFetched) {
      fetchCards();
    } else if (cardsFetched && cards?.length !== 0) {
      dispatch(setSelectedPM(cards[0]['id']));
    }
  }, [restaurantProfile]);

  const navigate = useNavigate();

  return (
    <>
      {restaurantProfile.processing_platform === 'STRIPE' &&
      cards.filter((card, i) => card.id === selectedPMId).length !== 0 ? (
        <SelectedPMCard
          card={cards.filter((card, i) => card.id === selectedPMId)[0]}
          onClick={() => {
            setShowPaymentMethodOptions(true);
          }}
        />
      ) : restaurantProfile.processing_platform === 'SQUARE' &&
        cards.filter((card, i) => card.id === selectedPMId).length !== 0 ? (
        <SquareCardView
          card={cards.filter((card, i) => card.id === selectedPMId)[0]}
          onClick={() => {
            setShowPaymentMethodOptions(true);
          }}
        />
      ) : (
        <></>
      )}

      <animated.div
        style={drawerProps}
        className={
          !showPaymentMethodOptions ? 'transition-opacity duration-200' : ''
        }
      >
        <Drawer
          title={<SelectPaymentMethodHeader />}
          placement="bottom"
          closable={false}
          visible={showPaymentMethodOptions}
          onClose={() => {
            setShowPaymentMethodOptions(false);
          }}
          getContainer={false}
          maskClosable={true}
          bodyStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
          height="600"
          footer={
            <AddPaymentMethodButton
              onClick={() => {
                navigate(
                  `/feed/restaurants/${r_id}/plate/payments/attach/${
                    restaurantProfile?.processing_platform == 'STRIPE'
                      ? 'stripe'
                      : 'square'
                  }`,
                );
              }}
            />
          }
        >
          {
            <PaymentMethodList
              platform={restaurantProfile.processing_platform}
              callback={(id: string) => {
                // logEvent(analytics, 'payment_method_selected');
                dispatch(setSelectedPM(id));
                setShowPaymentMethodOptions(false);
              }}
            />
          }
        </Drawer>
      </animated.div>
    </>
  );
}
