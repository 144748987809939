import axios from 'axios';
import { paymentsUrl } from '../env';
import { TTCheckoutSession } from '../interfaces/payment';
import { getAuthHeaders } from './utils';
/* eslint-disable */
export const checkoutsApi = {
  async createSetupSession(payload) {
    return axios.post<TTCheckoutSession>(
      `${paymentsUrl}/payment/checkout/setup`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tabletabToken')}`,
        },
      },
    );
  },
};
