import React from 'react';

type Props = {
  onClick: () => void;
  label: string;
};

const SwitchPaymentStyle: React.FC<Props> = ({ onClick, label }) => {
  return (
    <>
      <div
        className="px-4 pb-4 pt-2 justify-center items-center cursor:pointer mb-10"
        onClick={onClick}
      >
        <p className="text-sm text-blue-500 text-center font-semibold">
          {label}
        </p>
      </div>
    </>
  );
};

export default SwitchPaymentStyle;
