import React from 'react';

type Props = {
  restaurant_name: string;
  profile_image_url?: string;
  bio?: string;
};

const RestaurantNameCard: React.FC<Props> = ({
  restaurant_name,
  profile_image_url,
  bio,
}) => {
  return (
    <div className="align-bottom bg-white rounded-lg text-left overflow-hidden shadow-lg transform transition-all sm:my-8 sm:align-middle mx-4 mt-4">
      <div className="md:flex-col bg-white p-4 md:justify-start">
        {profile_image_url && (
          <img
            className="mx-auto h-32 w-32 flex-shrink-0 rounded-md"
            src={profile_image_url}
            alt=""
          />
        )}
        <div className="">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center text-lg text-gray-600 font-bold">
            {restaurant_name}
          </div>
          {bio && bio?.length !== 0 && (
            <div className="mx-auto flex-shrink-0 flex items-center justify-center text-md text-gray-600 ">
              {bio}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RestaurantNameCard;
