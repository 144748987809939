import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import {
  calculateCartCheck,
  updateTipPercent,
} from '../../../features/cart/cart';
import CustomTipField from './CustomTipField';
import { setOrderTipPercentage } from '../../../features/order/order';
import { guestApi } from '../../../api/guest';
import store from '../../../store';
import { setUserProfile } from '../../../features/auth/auth';
import { TTRestaurantProfile } from '../../../interfaces/restaurant';

type Props = {
  preset?: boolean;
  tip?: number;
};

const TipCollection: React.FC<Props> = React.memo(({ preset = false, tip }) => {
  const [standard_tip_options, setStandard_tip_options] = useState([]);
  const profile = useSelector<RootState, TTRestaurantProfile | null>(
    state => state.restaurant.profile,
  );
  useEffect(() => {
    if (profile !== null) {
      let tips = profile.pre_set_tip_defaults ?? ['18', '20', '22', '25'];
      setStandard_tip_options(
        // @ts-ignore
        tips?.map(t => parseInt(t)).concat('Custom') ?? [],
      );
    }
  }, [profile]);

  const [showCustomTip, setShowCustomTip] = useState(false);
  const [customTip, setCustomTip] = useState<number | null>(null);
  const selectedTip = useSelector<RootState, number>(state => {
    return Math.round(state.cart.tip_percent * 100);
  });
  const dispatch = useDispatch();
  return (
    <>
      {preset && (
        <div className="py-2 pr-4 pl-4 sm:w-1/2 min-w-4/5 sm:h-1/5 max-h-1/5 sm:flex rounded">
          <div className="p-4 flex felx-row w-full rounded-lg shadow-lg bg-white">
            <label className="block tracking-wide text-gray-700 text-xs font-bold ">
              {`Automatic ${tip}% fixed gratuity applied`}
            </label>
          </div>
        </div>
      )}
      {!preset && (
        <>
          <label className="mx-4 mt-4 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Select Tip
          </label>
          <div
            className={`flex flex-no-wrap  flex-row overflow-x-scroll items-center justify-between rounded-lg pl-4 pr-4 scrolling-touch mb-4 mt-4 bg-gray-100`}
          >
            {!showCustomTip &&
              standard_tip_options.map((tip, i) => {
                return (
                  <div
                    key={i}
                    onClick={async () => {
                      if (i != 4) {
                        dispatch(updateTipPercent(parseInt(tip.toString())));
                        dispatch(calculateCartCheck());
                        const resp = await guestApi.updateGuest(
                          { tip_default: tip.toString() },
                          store.getState().auth.guest_id,
                        );
                        dispatch(setUserProfile(resp.data));
                        dispatch(
                          setOrderTipPercentage(parseInt(tip.toString())),
                        );
                      } else {
                        setShowCustomTip(true);
                      }
                    }}
                    className="px-2 pb-2 pt-2 min-w-1/5 max-w-2/5"
                  >
                    <button
                      className={` ${
                        selectedTip == tip ||
                        (selectedTip == customTip && i == 4)
                          ? 'bg-blue-500'
                          : 'bg-white'
                      }  ${
                        selectedTip == tip ||
                        (selectedTip == customTip && i == 4)
                          ? 'text-white'
                          : 'text-blue-500'
                      } text-center align-center text-sm font-bold py-1 px-4 rounded-full w-full h-12 shadow-md outline-none focus:outline-none`}
                    >
                      {i !== 4
                        ? `${tip}%`
                        : customTip !== null
                        ? `${customTip}%`
                        : 'Custom %'}
                    </button>
                  </div>
                );
              })}
            {showCustomTip && (
              <CustomTipField
                onClick={async customTip => {
                  setCustomTip(customTip);
                  setStandard_tip_options(
                    [...standard_tip_options].map((o, i) =>
                      i === 4 ? customTip : o,
                    ),
                  );
                  dispatch(updateTipPercent(parseInt(customTip.toString())));
                  dispatch(calculateCartCheck());
                  const resp = await guestApi.updateGuest(
                    { tip_default: customTip.toString() },
                    store.getState().auth.guest_id,
                  );
                  dispatch(setUserProfile(resp.data));
                  dispatch(
                    setOrderTipPercentage(parseInt(customTip.toString())),
                  );
                  setShowCustomTip(false);
                }}
              />
            )}
          </div>
        </>
      )}
    </>
  );
});

export default TipCollection;
