import React from 'react';

type Props = {
  onCancel: () => void;
};

export const WhyThisPopUp: React.FC<Props> = ({ onCancel }) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 text-center ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="bottom-0 fixed inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 -mb-20 pb-32 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <svg
            className={'text-gray-500 w-5 h-5 top-0 right-0 m-1 fixed'}
            onClick={onCancel}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div>
            <h2 className="text-xl leading-9 font-bold text-gray-900">
              Why do we ask for ...
            </h2>
          </div>
          <>
            <div className={'mt-8'}>
              <ol className="overflow-hidden">
                <li className="relative pb-10">
                  <span className="relative flex items-start group">
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                        Table Number
                      </span>
                      <span className="text-sm text-gray-500">
                        We need your table number so your server knows where to
                        serve your food.
                      </span>
                    </span>
                  </span>
                </li>

                <li className="relative pb-10">
                  <span className="relative flex items-start group">
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                        Email Address
                      </span>
                      <span className="text-sm text-gray-500">
                        We need your email address to send the final receipt. We
                        securely store this information and don't share it with
                        third parties.
                      </span>
                    </span>
                  </span>
                </li>
              </ol>

              <div className="mt-5 sm:mt-6">
                <button
                  onClick={onCancel}
                  id={'dismiss'}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-400 text-base font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                >
                  Dismiss
                </button>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
