import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleEditMode } from '../../../features/payment/payment';
import { RootState } from '../../../rootReducer';

type Props = {};

export const SelectPaymentMethodHeader: React.FC<Props> = ({}) => {
  const isEditMode = useSelector<RootState, boolean>(
    state => state.payment.edit_mode,
  );
  const dispatch = useDispatch();
  return (
    <div className="flex flex-row bg-white justify-between items-start">
      <div className="flex flex-col">
        <div className="text-lg text-gray-900">Select Payment Method</div>
        <div className="text-sm text-gray-600 mt-2">
          Click on one of your saved cards on file to use. Or, hit the Add a new
          card button to use a new card.
        </div>
      </div>
      <button
        type="button"
        className="px-2 py-1 bg-red-500 text-white rounded-full ring-0 border-0 focus:border-0 focus:ring-0"
        onClick={() => {
          dispatch(toggleEditMode());
        }}
      >
        {isEditMode ? 'Cancel' : 'Edit'}
      </button>
    </div>
  );
};
