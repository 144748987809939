import React, { useState, ChangeEvent } from 'react';
import PillLabel from '../../../components/PillLabel';
import Input from '../../components/Input';
import { setTableNumber } from '../../../features/cart/cart';
import store from '../../../store';
import { guestApi } from '../../../api/guest';
import { setUserProfile } from '../../../features/auth/auth';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { TTUserProfile } from '../../../interfaces/user';
import { message } from 'antd';

type Props = {
  onClick: (email: string) => void;
  callback: (email: string) => void;
};

const EmailField: React.FC<Props> = ({ onClick, callback }) => {
  const [email, setEmail] = useState<string | null>(
    store.getState().auth.userProfile.email ?? '',
  );
  const guest = useSelector<RootState, TTUserProfile>(
    state => state.auth.userProfile,
  );
  const dispatch = useDispatch();
  return (
    <>
      <div className="flex flex-row justify-left content-center items-center mb-2 mt-4">
        <label className="mx-4 block uppercase tracking-wide text-gray-700 text-xs font-bold">
          Email Address
        </label>
        <div className="text-gray-900 items-center text-sm text-right content-center -mt-1">
          <PillLabel color="red" label="Required" />
        </div>
      </div>
      <div className="py-2 pr-4 pl-4  sm:h-1/5 max-h-1/5 sm:flex rounded">
        <div className="p-4 flex flex-row w-full rounded-lg shadow-lg justify-between bg-white">
          <Input
            // @ts-ignore
            id="email"
            type="email"
            value={email}
            handleBlur={async e => {
              const re = /\S+@\S+\.\S+/;
              if (email === '' || re.test(email)) {
                setEmail(e.target.value);
                callback(e.target.value);
                const resp = await guestApi.updateGuest(
                  { email: email },
                  guest.id,
                );
                dispatch(setUserProfile(resp.data));
                message.success('Email saved!');
              } else {
                setEmail('');
                callback('');
              }
            }}
            handleChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>
    </>
  );
};

export default EmailField;
