import axios from 'axios';
import { menuUrl } from '../env';
import { getAuthHeaders, cleanParams } from './utils';
import { TTMenuItem } from '../interfaces/menuItem';
import { QueryParams } from '../interfaces/pagination';
import { TTModifierWOptions } from '../interfaces/modifier';
/* eslint-disable */
export const menuItemsApi = {
  async getMenuItems(params: QueryParams) {
    return axios.get(`${menuUrl}/api/v1/menu-items/`, {
      params: cleanParams(params),
      headers: await getAuthHeaders(),
    });
  },
  async getFeaturedItems(merchant_id: number) {
    return axios.get<TTMenuItem[]>(
      `${menuUrl}/api/v1/menu-items/featured?merchant_id=${merchant_id}`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },

  async getMenuItem(id: number) {
    return axios.get<TTMenuItem>(`${menuUrl}/api/v1/menu-items/${id}`, {
      headers: await getAuthHeaders(),
    });
  },

  async getModifiers(id: number) {
    return axios.get<TTModifierWOptions[]>(
      `${menuUrl}/api/v1/menu-items/${id}/modifiers`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
};
