import React from 'react';

type Props = {
  onClick: () => void;
};

const AddPaymentMethodButton: React.FC<Props> = ({ onClick }) => {
  return (
    <>
      <div
        className="px-4 pt-4 max-w-full justify-center items-center mb-4"
        onClick={onClick}
      >
        <button
          id={'add-new-card-btn'}
          className={` bg-red-500 text-white  align-middle justify-center shadow-lg rounded-full w-full h-10 outline-none focus:outline-none`}
        >
          Add a new card
        </button>
      </div>
    </>
  );
};

export default AddPaymentMethodButton;
