import axios, { AxiosResponse } from 'axios';
import { promotionsUrl } from '../env';
import { getAuthHeaders, cleanParams } from './utils';
import { QueryParams } from '../interfaces/pagination';
import {
  TTPromotion,
  TTPromotionEvent,
  TTPromotionRequest,
} from '../interfaces/promotion';
/* eslint-disable */
export const promotionsApi = {
  async validatePromo(obj: TTPromotionRequest) {
    return axios.get<TTPromotionEvent>(
      `${promotionsUrl}/api/v1/promotions/validate/?guest_id=${
        obj.guest_id
      }&restaurant_id=${parseInt(obj.restaurant_id)}&promo_id=${obj.promo_id}`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async createEvent(obj: TTPromotionEvent) {
    return axios.post<TTPromotionEvent>(
      `${promotionsUrl}/api/v1/promotion-events/`,
      obj,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
};
