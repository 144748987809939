import React from 'react';
import { TTModifierOptions } from '../../../interfaces/modifier_options';
import RowCell from '../../components/RowCell';
import { centsToDollars } from '../../utils';
import { useSpring, animated } from 'react-spring';
import { CheckCircleIcon } from '@heroicons/react/solid';

/* eslint-disable */
type Props = {
  modifierOption: TTModifierOptions;
  className?: string;
  show: boolean;
  selected: boolean;
  onClick: () => void;
  disabled: boolean;
};

const ModifierRow: React.FC<Props> = ({
  modifierOption,
  className,
  onClick,
  show,
  selected,
  disabled,
}: Props) => {
  const props = useSpring({ opacity: show ? 1 : 0 });
  return (
    <>
      {show && (
        <animated.tr
          style={props}
          className={`${
            selected ? 'border-2 border-green-400  text-black' : ''
          } bg-white disabled:bg-gray-100 font-normal flex flex-row w-full justify-between rounded-md shadow-xs align-middle`}
          key={modifierOption.id}
          aria-disabled={disabled}
          onClick={() => {
            if (!disabled) {
              onClick();
            }
          }}
        >
          <RowCell className="break-normal ">
            <div className="flex flex-row space-x-1 items-center">
              {selected && (
                <div className="text-green-600 w-4 h-4 flex">
                  <CheckCircleIcon color="green-600" />
                </div>
              )}
              <span className="break-normal whitespace-normal">
                {modifierOption.name}
              </span>
            </div>
          </RowCell>
          <RowCell className="justify-end">
            <span className="align-middle">
              {centsToDollars(modifierOption.price)}
            </span>
          </RowCell>
        </animated.tr>
      )}
    </>
  );
};

export default ModifierRow;
