import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { faPlus } from '../../../fontawesome';
/* eslint-disable */
export type HeaderCellProps = {
  title: string;
  isOrdering: boolean;
  children?: ReactNode;
  required: boolean;
  subHeader?: string;
  requiredPill?;
};

const HeaderCell: React.FC<HeaderCellProps> = props => {
  return (
    <th className="w-full flex px-4 py-3 text-xs leading-4 justify-between font-medium  tracking-wider">
      <div className={'flex flex-row items-start'}>
        <div className="text-left flex-col">
          <p className={'uppercase text-gray-800 '}>{props.title}</p>
          {props.subHeader !== undefined && (
            <div className={'mt-2 text-xs text-gray-500 '}>
              {props.subHeader}
            </div>
          )}
        </div>
        {props.required && props.requiredPill}
      </div>

      {props.isOrdering && (
        <div className="flex ">
          <FontAwesomeIcon icon={faMinus} />
        </div>
      )}
      {!props.isOrdering && (
        <div className="flex">
          <FontAwesomeIcon icon={faPlus} />
        </div>
      )}
    </th>
  );
};

export default HeaderCell;
export function RequiredPill() {
  return (
    <span
      className={`  bg-yellow-200 rounded-full text-xs text-black-900 uppercase px-2 ml-2`}
    >
      {'Required'}
    </span>
  );
}

export function RequiredMetPill() {
  return (
    <span
      className={`  bg-green-200 rounded-full text-xs text-black-900 uppercase px-2 ml-2`}
    >
      {'Selected'}
    </span>
  );
}
