import React from 'react';

import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

import Menu from './MenuComponents';
import Menus from './Menus';
import MenuDish from '../MenuDish/MenuDish';
import Cart from '../Cart/Cart';
import CardSetupForm from '../Payment/StripeElements/AddPaymentElement';
import Order from '../Order/Order';
import RestaurantFeed from '../Feed/RestaurantFeed/FeedList';
import 'firebase/auth';
import Login from '../Login/index';
import { SquarePaymentForm } from '../Payment/SquareElements/AddSquarePaymentCard';
import { OrderClosedThankYou } from '../Order/OrderClosedThankYou';
import { ClaimHybridOrder } from '../Hybrid/ClaimHybridOrder';
import { StartGuestClaim } from '../Hybrid/StartGuestClaim';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

const MenuRouter: React.FC = () => {
  const loggedIn = useSelector<RootState, boolean>(
    state => state.auth.isLoggedIn,
  );
  const location = useLocation();
  return (
    <Routes>
      <Route path={`/login`} element={<Login />} />
      <Route path={`/menus`} element={<Menus />} />
      <Route path={`/menus/:m_id`} element={<Menu />} />
      <Route path={`/menus/:m_id/items/:mi_id`} element={<MenuDish />} />
      <Route path={`/plate`} element={<Cart />} />
      <Route
        path={`/plate/payments/attach/stripe`}
        element={<CardSetupForm />}
      />
      <Route
        path={`/plate/payments/attach/square`}
        element={<SquarePaymentForm />}
      />
      <Route path={`/hybrid/claim-form`} element={<ClaimHybridOrder />} />
      <Route path={`/hybrid/claim-guest-form`} element={<StartGuestClaim />} />
      <Route
        path={`/order/:o_id`}
        element={
          loggedIn ? (
            <Order />
          ) : (
            <Navigate to={`/login?redirect=${location.pathname}`} />
          )
        }
      />
      <Route path={`/order/:o_id/thankyou`} element={<OrderClosedThankYou />} />
      <Route path={`/all`} element={<RestaurantFeed />} />
    </Routes>
  );
};

export default MenuRouter;
