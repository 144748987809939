import { message, Spin } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  createGuestCheck,
  fetchOrderRestaurants,
  setOrderPrimaryRestaurant,
  setOrderRestaurants,
} from '../../features/order/order';
import {
  setAllProfiles,
  setProfile,
} from '../../features/restaurant/restaurant';
import { TTOrderV2 } from '../../interfaces/order';
import { TTOrderItem } from '../../interfaces/order_item';
import { RootState } from '../../rootReducer';
import store from '../../store';
import CheckInfoCard from '../Cart/components/Check';
import DishCard from '../Cart/components/DishCard';
import ErrorIcon from '../components/ErrorIcon';
import ViewHeader from '../components/ViewHeader';
import 'firebase/analytics';
import 'firebase/auth';
import * as Sentry from '@sentry/react';
import { TTRestaurantProfile } from '../../interfaces/restaurant';
import { TTUserProfile } from '../../interfaces/user';
import { TTCheck } from '../../interfaces/check';
import {
  getCurrentGuestProfile,
  setViewingOrder,
} from '../../features/auth/auth';
import { centsToDollars } from '../utils';
import { getAnalytics, logEvent } from 'firebase/analytics';
import TableTabBanner from '../components/TableTabBanner';
import { Dialog, Transition } from '@headlessui/react';
import { PickUpOrderInfo } from '../Order/components/PickUpOrderInfo';
import { OrderCateringDetails } from '../Order/components/OrderCateringDetails';
import { InfoAlert } from '../Order/components/InfoAlert';
import { DineInInfo } from '../Order/components/DineInInfo';
import { OrderSTSDetails } from '../Order/components/OrderSTSDetails';
import { OrderDeliveryDetails } from '../Order/components/OrderDeliveryDetails';
import { OrderHotelDetails } from '../Order/components/OrderHotelDetails';
import { BookingConfirmationModal } from '../Order/components/BookingConfirmationModal';
import { restaurantApi } from '../../api/restaurant';
import NavBar from '../components/BackButton';
import { LoadingIcon } from '../components/Spinner';
import { ordersApi } from '../../api/order';

type Props = {};

const OrderHistoryDetailView: React.FC<Props> = ({}) => {
  const guest = useSelector<RootState, TTUserProfile>(
    state => state.auth.userProfile,
  );

  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const order = useSelector<RootState, TTOrderV2>(
    state => state.auth.viewing_order,
  );
  const guestCheck = useSelector<RootState, TTCheck>(
    state => state.order.guest_check,
  );

  const [tip, setTip] = useState('Fetching...');

  const [loading, setLoading] = useState(true);

  const restaurant = useSelector<RootState, TTRestaurantProfile>(
    state => state.order?.order_primary_restaurant,
  );

  const requestBookingConfirmation = useSelector<RootState, boolean>(
    state => state.order.requestBookingConfirmation,
  );

  const _fetchOrder = async () => {
    try {
      const response = await ordersApi.getOrder(parseInt(id));
      dispatch(setViewingOrder(response.data));
    } catch (e) {
      message.error(
        e?.response?.data?.detail[0]?.msg ?? e?.response?.data?.detail,
      );
    }
  };
  useEffect(() => {
    if (order === null) {
      _fetchOrder();
    }
  }, []);
  useEffect(() => {
    async function fetch() {
      try {
        dispatch(getCurrentGuestProfile(() => {}, false));
      } catch (e) {
        Sentry.captureException(e);
      }
    }
    if (guest === null) {
      fetch();
    }
  }, [guest]);

  useEffect(() => {
    let isMounted = true;
    if (order !== null && restaurant !== null && guest !== null) {
      dispatch(
        createGuestCheck(order, () => {
          if (isMounted) {
            setLoading(false);
          }
        }),
      );
    }
    return function cleanup() {
      isMounted = false;
    };
  }, [order, guest, restaurant]);

  const fetchRestaurants = async () => {
    try {
      dispatch(fetchOrderRestaurants(order));
      const response = await restaurantApi.getProfile(
        order?.primary_restaurant_id,
      );
      dispatch(setOrderPrimaryRestaurant(response.data));
    } catch (e) {}
  };

  useEffect(() => {
    let isMounted = true;
    async function fetchRestaurant() {
      try {
        fetchRestaurants();
      } catch (e) {
        if (isMounted) {
          setLoading(false);
          message.error(e.message);
        }
      }
    }

    if (order !== null) {
      fetchRestaurant();
    }

    return function cleanup() {
      isMounted = false;
    };
  }, [order]);

  useEffect(() => {
    logEvent(getAnalytics(), 'order_history_view');
    // ZendeskAPI('webWidget', 'show');
  }, []);

  return (
    <>
      <div className="min-h-screen bg-gray-100 ">
        <NavBar show_refresh={false} hide={true} />
        <div
          className={
            'pt-4 overflow-y-scroll scrolling-touch bg-gray-100 md:mx-8 lg:mx-16 xl:mx-32'
          }
        >
          <Spin spinning={loading} indicator={LoadingIcon} tip={tip}>
            <ViewHeader
              title={` ${
                order !== null
                  ? order?.order_type === 'catering'
                    ? 'Your Catering Tab'
                    : order?.order_type === 'dine_in'
                    ? 'Your Tab'
                    : order?.order_type === 'delivery'
                    ? 'Your Delivery Tab'
                    : order?.order_type === 'hotel'
                    ? 'Your Hotel Tab'
                    : order?.order_type === 'sts'
                    ? 'Your Order'
                    : 'Your Tab'
                  : 'Your Tab'
              }`}
              className={'text-left px-6'}
            />

            {loading && (
              <main
                className={`flex-1 relative z-0 overflow-y-auto ${
                  loading ? 'pt-20' : 'pt-0'
                }`}
              />
            )}
            {!loading &&
              order !== null &&
              (order.order_type === 'pick_up' ||
                order.order_type === 'take_out') &&
              restaurant !== null && (
                <PickUpOrderInfo
                  pickup_time={order.order_tabs[0].pick_up_time}
                  location={restaurant.coordinates}
                />
              )}
            {!loading &&
              order !== null &&
              order.order_type === 'catering' &&
              restaurant !== null && <OrderCateringDetails />}
            {!loading &&
              order !== null &&
              order.order_type === 'hotel' &&
              restaurant !== null && <OrderHotelDetails />}
            {!loading &&
              order !== null &&
              order.order_type === 'delivery' &&
              restaurant !== null && <OrderDeliveryDetails />}
            {!loading &&
              order !== null &&
              order.order_type === 'sts' &&
              restaurant !== null && <OrderSTSDetails />}
            {!loading && order !== null && order.order_type === 'dine_in' && (
              <DineInInfo
                order={order}
                orderMoreCallback={() => {
                  // logEvent(getAnalytics(), 'order_again_clicked');
                  // // dispatch(clearCart(r_id));
                  // navigate(`/feed/restaurants/${r_id}/menus`);
                }}
              />
            )}
            {!loading && order !== null && order.order_tabs !== null && (
              <>
                <table className="min-w-full mb-6 pt-10 bg-gray-100">
                  <th className="px-4 pt-4 text-lg text-gray-900 font-normal">
                    {/*Items*/}
                    <InfoAlert
                      text={
                        'These are all of your ordered items, including any previously ordered ones.'
                      }
                    />
                  </th>
                  <tbody>
                    {order.order_tabs
                      .map(a => a.order_items)
                      .reduce((a, b) => a.concat(b))
                      ?.filter(
                        oI =>
                          oI.paired_with === undefined ||
                          (!oI.paired_with?.is_pairing_option ?? false),
                      )
                      .map((item: TTOrderItem, i) => {
                        if (
                          item.guest_id == store.getState().auth.guest_id &&
                          !item.voided
                        ) {
                          return (
                            <DishCard
                              menuItem={item}
                              onClick={() => {
                                logEvent(getAnalytics(), 'order_item_clicked');
                              }}
                              key={item.id}
                            />
                          );
                        }
                      })}
                  </tbody>
                </table>
              </>
            )}
            {!loading &&
              order !== null &&
              restaurant !== null &&
              guestCheck !== null && (
                <>
                  <CheckInfoCard from_order={true} tip_callback={() => {}} />
                  <div className={'px-4'}>
                    <InfoAlert
                      text={`The updated charge on your payment method is ${centsToDollars(
                        guestCheck.total + guestCheck.service_fee,
                      )}. Any previous holds have been canceled.`}
                    />
                  </div>
                </>
              )}

            <div className="w-full my-4">
              <TableTabBanner />
            </div>

            <Transition.Root show={requestBookingConfirmation} as={Fragment}>
              <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                onClose={
                  () => {}
                  // dispatch(setRequestTableNumberVerification(false))
                }
              >
                <div className="flex items-end justify-center h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    {/* <ChangeTipModal /> */}
                    <div className="relative inline-block align-bottom rounded-md w-full">
                      <BookingConfirmationModal />
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default OrderHistoryDetailView;
