import React, { useState, ChangeEvent } from 'react';
import PillLabel from '../../../components/PillLabel';
import store from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { setTableNumber } from '../../../features/cart/cart';
import Input from '../../components/Input';
import { WhyThisPopUp } from './WhyThisPopUp';
import { message } from 'antd';

type Props = {
  onSwitch: () => void;
};

const TableNumberField: React.FC<Props> = ({ onSwitch }) => {
  const [showWhyThis, setShowWhyThis] = useState(false);

  const [local, setLocal] = useState(
    store.getState().cart.order_create.table_number ?? '',
  );
  const order_type = useSelector<RootState, string>(
    state => state.cart.order_create.order_type,
  );
  const dispatch = useDispatch();

  return (
    <>
      <div className={'flex flex-col'}>
        <div className={'flex flex-row justify-between items-center '}>
          <div className="flex flex-row justify-left content-center items-start mb-2 mt-4">
            <label className="pl-4 block uppercase tracking-wide text-gray-700 text-xs font-bold">
              Table Number
            </label>
            <div className="text-gray-900 items-start text-sm text-right -mt-1 ml-2">
              <PillLabel color="red" label="Required" />
            </div>
          </div>
          <div className="text-gray-900 items-start text-sm text-right mx-4">
            <span
              onClick={() => setShowWhyThis(true)}
              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-100 text-indigo-800`}
            >
              Why do we ask this?
            </span>
          </div>
        </div>

        {store.getState().restaurant.profile.is_pickup && (
          <div
            className="inline-flex space-x-2 truncate text-sm pl-4"
            onClick={onSwitch}
          >
            <span className="text-gray-500 truncate group-hover:text-gray-900">
              Switch to {order_type === 'dine_in' ? 'Pick Up' : 'Dine In'}
            </span>
          </div>
        )}
      </div>
      <div className="py-2 pr-4 pl-4  sm:h-1/5 max-h-1/5 sm:flex rounded">
        <div className="p-4 flex felx-row w-full rounded-lg shadow-lg justify-between bg-white">
          <Input
            // @ts-ignore
            handleChange={e => {
              setLocal(e.target.value);
            }}
            handleBlur={e => {
              const re = /^[A-Za-z0-9.-]+$/;
              if (local === '' || re.test(local)) {
                dispatch(setTableNumber(local));
                if (
                  store
                    .getState()
                    .restaurant.profile.table_numbers.includes(local)
                ) {
                  message.success('Table number saved');
                }
              } else {
                setLocal('');
                dispatch(setTableNumber(''));
              }
            }}
            name="tableNumber"
            value={local}
            className={
              'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5'
            }
            autoComplete="off"
            placeholder=""
          />
        </div>
      </div>
      {showWhyThis && (
        <WhyThisPopUp
          onCancel={() => {
            setShowWhyThis(false);
          }}
        />
      )}
    </>
  );
};

export default TableNumberField;
