import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { restaurantApi } from '../../../../api/restaurant';
import { TTMenu } from '../../../../interfaces/menus';
import {
  RestaurantAddress,
  TTRestaurantProfile,
} from '../../../../interfaces/restaurant';
import { RootState } from '../../../../rootReducer';

type Props = {};

export function RestaurantInfoCard() {
  const selectedMenu = useSelector<RootState, TTMenu | null>(
    state => state.restaurant.menu_in_view,
  );

  const primaryRestaurant = useSelector<RootState, TTRestaurantProfile | null>(
    state => state.restaurant.profile,
  );

  const [menuOwnerProfile, setMenuOwnProfile] =
    useState<TTRestaurantProfile | null>(null);
  const [primaryAddress, setPrimaryAddress] =
    useState<RestaurantAddress | null>(null);
  const [showDetails, setShowDetails] = useState(false);
  const fetch = async () => {
    try {
      const response = await restaurantApi.getProfile(
        selectedMenu?.restaurant_id,
      );
      const addResponse = await restaurantApi.getAddresses(
        selectedMenu?.restaurant_id,
      );
      setPrimaryAddress(
        addResponse?.data?.filter(a => a.is_primary)[0] ?? null,
      );
      setMenuOwnProfile(response.data);
    } catch (e) {}
  };

  useEffect(() => {
    if (selectedMenu !== null) {
      fetch();
    }
  }, [selectedMenu?.restaurant_id]);

  return (
    <>
      <div className="flex-col bg-white rounded-md py-2 px-4 mb-4 shadow-md mx-4 md:mx-8 lg:mx-16 xl:mx-32">
        <div className="flex items-center justify-between">
          <div className="flex bg-white items-center space-x-2">
            {menuOwnerProfile?.main_profile_image && (
              <img
                className="flex w-20 h-20 md:w-28 md:h-28 shrink-0 rounded-md object-cover"
                src={menuOwnerProfile?.main_profile_image}
                alt=""
              />
            )}
            <div className="flex-col">
              <div className="mx-auto flex-shrink-0 flex  text-md md:text-lg text-gray-600 font-bold">
                {menuOwnerProfile?.name}
              </div>
              {/* {menuOwnerProfile?.bio && menuOwnerProfile?.bio?.length !== 0 && (
                <div className="mx-auto flex-shrink-0 flex  text-sm md:text-lg text-gray-600">
                  {menuOwnerProfile?.bio}
                </div>
              )} */}
            </div>
          </div>
          <div>
            <button
              className="flex rounded-full px-2 py-1 bg-gray-100 text-sm text-gray-900"
              onClick={() => setShowDetails(!showDetails)}
            >
              {showDetails ? 'Hide' : 'Details'}
            </button>
          </div>
        </div>
        {showDetails && (
          <div className="border-t border-gray-200 sm:p-0 ">
            <dl className="divide-y divide-gray-200 space-y-4">
              <div className="pt-4 sm:grid sm:grid-cols-3 sm:gap-4 ">
                <dt className="text-sm font-medium text-gray-500">About</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {menuOwnerProfile?.bio &&
                    menuOwnerProfile?.bio?.length !== 0 && (
                      <div className="mx-auto flex-shrink-0 flex  text-sm  text-gray-900">
                        {menuOwnerProfile?.bio}
                      </div>
                    )}
                </dd>
              </div>
              {primaryAddress !== null && (
                <div className="pt-4 sm:grid sm:grid-cols-3 sm:gap-4 ">
                  <dt className="text-sm font-medium text-gray-500">Address</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {primaryAddress?.address_line_1}, {primaryAddress?.city},{' '}
                    {primaryAddress?.administrative_area_level_1},{' '}
                    {primaryAddress?.city}, {primaryAddress?.country}{' '}
                    {primaryAddress?.zip_postal}
                  </dd>
                </div>
              )}
            </dl>
          </div>
        )}
      </div>
    </>
  );
}
