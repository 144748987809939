import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import CardSection from './CardSection';
import { useNavigate } from 'react-router-dom';
import ElevatedButton from '../../components/ElevatedButton';
import NavBar from '../../components/BackButton';
import ViewHeader from '../../components/ViewHeader';
import { paymentsApi } from '../../../api/payments';
import { useDispatch } from 'react-redux';
import { Result, Button, Spin, message } from 'antd';
import { LoadingIcon } from '../../components/Spinner';
import { StripeError } from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';
import { setSelectedPM } from '../../../features/payment/payment';
import { InfoAlert } from '../../Order/components/InfoAlert';
import store from '../../../store';

function CardSetupForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [clientSecret, setClientSecret] = useState(
    localStorage.getItem('__tt_stripe_cs'),
  );
  const [si, setSI] = useState(localStorage.getItem('__tt_stripe_si'));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<StripeError | null>(null);
  const [added, setAdded] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getSetupIntent() {
      try {
        const response = await paymentsApi.createSetupIntent();
        setClientSecret(response.data.clientSecret);
        localStorage.setItem('__tt_stripe_cs', response.data.clientSecret);
        localStorage.setItem('__tt_stripe_si', response.data.si_id);
        setSI(response.data.si_id);
        setError(null);
        setLoading(false);
      } catch (err) {
        const stripe_error: StripeError = {
          type: 'api_error',
          message:
            err.response?.data?.detail ??
            'Unable to setup card at this moment. Please try Apple or Google Pay.',
        };
        setError(stripe_error);
        setLoading(false);
        Sentry.captureException(err);
      }
    }
    setLoading(true);
    getSetupIntent();
  }, []);

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoading(false);
      message.error(
        'Unable to load Stripe. Please try again by refreshing the page.',
      );
      return;
    }

    setLoading(true);

    try {
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });
      if (result.error) {
        setError(result.error);
        setLoading(false);
        setAdded(false);
        Sentry.captureException(result.error);
      } else {
        // The setup has succeeded. Display a success message and send
        // result.setupIntent.payment_method to your server to save the
        // card to a Customer
        setAdded(true);
        setError(null);
        console.log(result.setupIntent);
        dispatch(setSelectedPM(result.setupIntent.payment_method));
        localStorage.removeItem('__tt_stripe_cs');
        localStorage.removeItem('__tt_stripe_si');
        message.success('Card saved on file', 1, () => {
          navigate(
            `/feed/restaurants/${
              store.getState().restaurant?.profile?.restaurant_id
            }/plate`,
          );
        });
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      setAdded(false);
      message.error(
        'Unable to add your card. Please try again by refreshing the page',
      );
      Sentry.captureException(e);
    }
  };

  return (
    <>
      <div className="h-screen">
        <NavBar />
        <ViewHeader
          title="Confirm Payment Method"
          className={'text-center px-6'}
        />
        <Spin spinning={loading} indicator={LoadingIcon}>
          {(!added || error == null) && (
            <form onSubmit={handleSubmit} className="px-6 py-10">
              <CardSection />
              <ElevatedButton
                title="Confirm"
                disabled={!stripe}
                className="mt-4 shadow-md border-none focus:outline-none"
              />
              <div className="p-4">
                <InfoAlert text="Saving card does not send the order to the kitchen. Press the Start your Tab button after the card has been successfuly saved." />
              </div>
            </form>
          )}
        </Spin>
        {error !== null && (
          <Result
            status={added ? 'success' : 'error'}
            title={error != null ? error.message : 'Payment method confirmed'}
            extra={[
              <Button
                type="primary"
                key="confirm-btn"
                onClick={() => {
                  error != null ? setError(null) : navigate(-1);
                }}
                title={error != null ? 'Retry' : 'Continue'}
              >
                Ok
              </Button>,
            ]}
          />
        )}
      </div>
    </>
  );
}

export default CardSetupForm;
