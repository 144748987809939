import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestionCircle,
  faChevronLeft,
  faRedo,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { ZendeskAPI } from 'react-zendesk';
import { useNavigate } from 'react-router';

type Props = {
  onClick?: () => void;
  show_refresh?: boolean;
  show_help?: boolean;
  hide?: boolean;
  hide_back?: boolean;
  middle_option?;
  help_element?: React.ReactNode;
};

const NavBar: React.FC<Props> = ({
  onClick = null,
  show_refresh = false,
  show_help = true,
  hide = false,
  hide_back = false,
  middle_option,
  help_element,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="px-4 md:px-0 pb-2 pt-2 space-x-10 justify-between flex flex-row items-center md:mx-8 lg:mx-16 xl:mx-32">
        <button
          className={` bg-white  justify-left align-middle rounded-full w-1/5 h-8 outline-none focus:outline-none ${
            hide_back && 'hidden'
          }`}
          onClick={
            onClick !== null
              ? onClick
              : () => {
                  navigate(-1);
                }
          }
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        {show_refresh && (
          <button
            className={`bg-white  justify-right align-middle rounded-full w-1/5 h-8 outline-none focus:outline-none`}
            onClick={() => {
              window.location.reload();
            }}
          >
            <FontAwesomeIcon icon={faRedo} />
          </button>
        )}
        {middle_option && middle_option}

        {show_help && (
          <button
            className={`bg-white  justify-right align-middle rounded-full w-1/5 h-8 outline-none focus:outline-none`}
            onClick={() => {
              ZendeskAPI('webWidget', 'show');
              ZendeskAPI('webWidget', 'open');
              ZendeskAPI('webWidget:on', 'close', function () {
                if (hide) {
                  ZendeskAPI('webWidget', 'hide');
                }
              });
            }}
          >
            <FontAwesomeIcon icon={faQuestion} />
          </button>
        )}
        {!show_help && help_element && help_element}
      </div>
    </>
  );
};

export default NavBar;
