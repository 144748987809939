import React, { ChangeEvent } from 'react';

type Props = {
  setComment: (string) => void;
  comment: string;
};

const DishComment: React.FC<Props> = ({ setComment, comment }) => {
  return (
    <>
      {
        <div className="flex flex-row w-full p-4">
          <input
            id="comment"
            type="text"
            placeholder="Comment"
            value={comment}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setComment(e.target.value)
            }
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      }
    </>
  );
};

export default DishComment;
