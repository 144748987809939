import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { restaurantApi } from '../../../api/restaurant';
import { TTRestaurantProfile } from '../../../interfaces/restaurant';
import { LoadingIcon } from '../../components/Spinner';
import RestaurantProfileCard from '../components/RestaurantCard';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ViewHeader from '../../components/ViewHeader';
import { setMenus, setProfile } from '../../../features/restaurant/restaurant';
import store from '../../../store';
import { fetchOrder } from '../../../features/order/order';
import { ClearCart } from '../components/ClearCartPopup';
import { clearCart, removePromotion } from '../../../features/cart/cart';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { ZendeskAPI } from 'react-zendesk';
import { RootState } from '../../../rootReducer';
import { TTUserProfile } from '../../../interfaces/user';
import { removeSelectedPM } from '../../../features/payment/payment';

const RestaurantFeed: React.FC = () => {
  const [restaurantProfiles, setRestaurantProfiles] = useState<
    TTRestaurantProfile[] | null
  >(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [clickedProfile, setClickedProfile] =
    useState<TTRestaurantProfile | null>(null);
  const [previousRestaurant, setPreviousRestaurant] =
    useState<TTRestaurantProfile | null>(null);
  const guest = useSelector<RootState, TTUserProfile>(
    state => state.auth.userProfile,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const analytics = getAnalytics();

  useEffect(() => {
    async function fetchProfiles() {
      try {
        const response = await restaurantApi.getProfiles();
        setRestaurantProfiles(
          response.data
            .filter(profile => {
              return (
                profile.on_pwa || profile.name == 'TableTab' || profile.id == 60
              );
            })
            .sort((a, b) => {
              return a.rank - b.rank;
            }),
        );
        if (
          store.getState().order.order_id !== null &&
          !isNaN(store.getState().order.order_id)
        ) {
          dispatch(
            fetchOrder(store.getState().order.order_id, () => {
              setLoading(false);
            }),
          );
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        setRestaurantProfiles([]);
      }
    }

    if (restaurantProfiles == null) {
      setLoading(true);
      fetchProfiles();
    } else {
      setLoading(false);
    }
    logEvent(analytics, 'feed_page');
  }, [restaurantProfiles, loading]);

  useEffect(() => {
    if (guest !== null) {
      dispatch(
        fetchOrder(parseInt(localStorage.getItem('__tt_order_id')), () => {}),
      );
    }
  }, [guest]);

  useEffect(() => {
    ZendeskAPI('webWidget', 'show');
  }, []);

  return (
    <>
      <Spin spinning={loading} indicator={LoadingIcon}>
        {loading && (
          <main
            className={`flex-1 relative z-0 overflow-y-auto ${
              loading ? 'pt-20' : 'pt-0'
            }`}
          />
        )}
        <div className="overflow-y-scroll scrolling-touch h-full">
          <div className="mt-6">
            <ViewHeader title="Restaurants 🍽" className="text-left px-6" />
          </div>
          {!loading && restaurantProfiles != null && (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 items-center rounded-lg sm:gap-x-10 pl-4 pr-4  mt-6 gap-y-10 ">
              {restaurantProfiles.map((profile, i) => {
                return (
                  <RestaurantProfileCard
                    profile={profile}
                    key={i}
                    onClick={() => {
                      logEvent(analytics, 'restaurant_profile_clicked', {
                        restaurant_id: profile.id,
                        restaurant_name: profile.name,
                        rank: profile.rank,
                      });
                      if (store.getState().cart.order_create == null) {
                        dispatch(setProfile(profile));
                        dispatch(setMenus(null));
                        navigate(
                          `/feed/restaurants/${profile.restaurant_id}/menus/`,
                        );
                      } else {
                        if (
                          store.getState().cart.order_create
                            .primary_restaurant_id == profile.restaurant_id
                        ) {
                          dispatch(setProfile(profile));
                          dispatch(setMenus(null));
                          navigate(
                            `/feed/restaurants/${profile.restaurant_id}/menus/`,
                          );
                        } else {
                          const prev = restaurantProfiles.filter(prof => {
                            return (
                              prof.restaurant_id ==
                              store.getState().cart.order_create
                                .primary_restaurant_id
                            );
                          });
                          if (prev[0] !== undefined) {
                            setPreviousRestaurant(prev[0]);
                            setClickedProfile(profile);
                            setShowModal(true);
                          } else {
                            dispatch(removeSelectedPM());
                            dispatch(clearCart(profile.restaurant_id));
                            dispatch(removePromotion(profile.restaurant_id));
                            dispatch(setProfile(profile));
                            dispatch(setMenus(null));
                            navigate(
                              `/feed/restaurants/${profile.restaurant_id}/menus/`,
                            );
                          }
                        }
                      }
                    }}
                  />
                );
              })}
            </div>
          )}
          {!loading && showModal && (
            <ClearCart
              restaurant_name={previousRestaurant.name}
              confirm={() => {
                dispatch(removeSelectedPM());
                dispatch(clearCart(clickedProfile.restaurant_id));
                dispatch(removePromotion(clickedProfile.restaurant_id));
                dispatch(setMenus(null));
                dispatch(setProfile(clickedProfile));
                setShowModal(false);
                navigate(
                  `/feed/restaurants/${clickedProfile.restaurant_id}/menus/`,
                );
              }}
              cancel={() => {
                setShowModal(false);
              }}
            />
          )}
        </div>
      </Spin>
    </>
  );
};

export default RestaurantFeed;
