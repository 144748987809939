import { message, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { paymentsApi } from '../../../api/payments';
import { restaurantApi } from '../../../api/restaurant';
import { squareKey, squareLocation } from '../../../env';
import {
  addPaymentMethod,
  setSelectedPM,
} from '../../../features/payment/payment';
import store from '../../../store';
import NavBar from '../../components/BackButton';
import { LoadingIcon } from '../../components/Spinner';
import ViewHeader from '../../components/ViewHeader';
import { InfoAlert } from '../../Order/components/InfoAlert';

type Props = {};

export const SquarePaymentForm: React.FC<Props> = ({}) => {
  const [card, setCard] = useState();
  const [spinning, setSpinning] = useState(false);
  const card_container_ref = useRef(null);
  const submit_button_ref = useRef(null);
  const status_ref = useRef(null);
  const form_ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function initializeCard(payments) {
    const card = await payments.card();
    await card.attach('#card-container');
    return card;
  }

  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }

      throw new Error(errorMessage);
    }
  }

  function displayPaymentResults(status) {
    const statusContainer = status_ref.current;
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
    }

    statusContainer.style.visibility = 'visible';
  }

  async function handlePaymentMethodSubmission(event, paymentMethod) {
    event.preventDefault();
    setSpinning(true);

    try {
      // disable the submit button as we await tokenization and make a payment request.
      // @ts-ignore
      const card_button = submit_button_ref.current;
      card_button.disabled = true;
      const token = await tokenize(paymentMethod);
      const primary_restaurant_card_response =
        await paymentsApi.attachSquarePaymentMethod({
          payment_method_token: token,
          platform: 'SQUARE',
          restaurant_id: 1,
          company_id: 1,
          cloning: false,
        });

      displayPaymentResults('SUCCESS');
      dispatch(addPaymentMethod(primary_restaurant_card_response.data));
      dispatch(setSelectedPM(primary_restaurant_card_response.data?.id));
      message.success('Card successfully saved', 2, () => {
        navigate(
          `/feed/restaurants/${
            store.getState().restaurant.profile.restaurant_id
          }/plate`,
        );
      });
    } catch (e) {
      // @ts-ignore
      const card_button = submit_button_ref.current;
      card_button.disabled = false;
      displayPaymentResults('FAILURE');
      setSpinning(false);
      console.log(e);
      console.log(e.response);
      message.error(
        e.response?.data?.detail ?? 'Unable to save card on file',
        2,
        () => {
          history.go(0);
        },
      );
    }
  }

  useEffect(() => {
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }

    let payments;
    try {
      payments = window.Square.payments(squareKey, squareLocation);
    } catch {
      const statusContainer = status_ref.current;
      statusContainer.className = 'missing-credentials';
      statusContainer.style.visibility = 'visible';
      return;
    }

    async function asynInit() {
      try {
        let localCard = await initializeCard(payments);
        setCard(localCard);
      } catch (e) {
        console.error('Initializing Card failed', e);
        return;
      }
    }

    asynInit();
  }, []);

  return (
    <>
      <NavBar />
      <ViewHeader
        title="Confirm Payment Method"
        className={'text-center px-6'}
      />
      <Spin spinning={spinning} indicator={LoadingIcon}>
        <form
          id="payment-form"
          ref={form_ref}
          className={`p-10 `}
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div id="card-container" ref={card_container_ref}></div>
          <button
            id="card-button"
            ref={submit_button_ref}
            onClick={async event => {
              event.preventDefault();
              await handlePaymentMethodSubmission(event, card);
            }}
            className="rounded-md w-full shadow-md bg-white text-gray-900 py-2"
            type="button"
          >
            Save Card
          </button>
        </form>
        <div id="payment-status-container" ref={status_ref}></div>
        <div className="px-10 mt-4">
          <InfoAlert text="By entering your card information, TableTab will securely save your card information on file using Square. Saving card does not send the order to the kitchen. Press the Start your Tab button after the card has been successfuly saved." />
        </div>
      </Spin>
    </>
  );
};
