import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SquareGiftCard } from '../../interfaces/stripe_payment_method';

/* eslint-disable */
interface PaymentState {
  stripe_id: string | null;
  selected_pm: string | null;
  square_gift_cards: SquareGiftCard[] | null;
  edit_mode: boolean;
  payment_methods: any[];
  cards_fetched: boolean;
}

const initialState: PaymentState = {
  stripe_id: localStorage.getItem('__tt_stripe_id') || 'NONE',
  selected_pm: localStorage.getItem('__tt_stripe_pm') || null,
  square_gift_cards: null,
  edit_mode: false,
  payment_methods: [],
  cards_fetched: false,
};

const payment = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setStripeId(state, action: PayloadAction<string>) {
      state.stripe_id = action.payload;
      localStorage.setItem('__tt_stripe_id', state.stripe_id);
    },
    setSelectedPM(state, action: PayloadAction<string>) {
      state.selected_pm = action.payload;
      localStorage.setItem('__tt_stripe_pm', action.payload);
    },
    removeSelectedPM(state) {
      state.selected_pm = null;
      localStorage.removeItem('__tt_stripe_pm');
    },
    setSquareGiftCards(state, action: PayloadAction<SquareGiftCard[]>) {
      state.square_gift_cards = action.payload;
    },
    toggleEditMode(state) {
      state.edit_mode = !state.edit_mode;
    },
    setPaymentMethods(state, action) {
      state.payment_methods = action.payload;
    },
    removePaymentMethod(state, action: PayloadAction<string>) {
      state.payment_methods = state.payment_methods.filter(
        p => p.id !== action.payload,
      );
    },
    toggleCardsFetched(state) {
      state.cards_fetched = !state.cards_fetched;
    },
    addPaymentMethod(state, action) {
      state.payment_methods.push(action.payload);
    },
  },
});

export const {
  setStripeId,
  setSelectedPM,
  setSquareGiftCards,
  toggleEditMode,
  setPaymentMethods,
  removePaymentMethod,
  addPaymentMethod,
  toggleCardsFetched,
  removeSelectedPM,
} = payment.actions;

export default payment.reducer;
