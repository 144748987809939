import React, { ChangeEvent, useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  attemptLogin,
  setDuringLogin,
  setUserProfile,
} from '../../features/auth/auth';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingIcon } from '../components/Spinner';
import { getAuth } from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import PillLabel from '../../components/PillLabel';
import PrivacyInfoCard from './components/PrivacyInfoCard';
import * as Sentry from '@sentry/react';
import useQuery from '../../hooks/useQuery';
import { ZendeskAPI } from 'react-zendesk';
import { authApi } from '../../api/auth';
import { setStripeId } from '../../features/payment/payment';
import { osName, osVersion } from 'react-device-detect';
/* typescript-eslint-disable */
declare global {
  interface Window {
    MyNamespace: any;
    recaptchaVerifier;
    confirmationResult;
    recaptchaWidgetId;
    $zopim;
    Square;
  }
}
declare var grecaptcha: any;

window.MyNamespace = window.MyNamespace || {};

const Login: React.FC = props => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showCodeField, setShowCodeField] = useState(false);
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPrivacyPopUp, setShowPrivacyPopUp] = useState(false);
  // var recaptcha;
  const dispatch = useDispatch();
  const query = useQuery();
  const analytics = getAnalytics();

  const [path, setPath] = useState<string | null>(null);

  const [isRedirect, setIsRedirect] = useState(false);

  const navigate = useNavigate();

  // var recaptcha;

  useEffect(() => {
    logEvent(analytics, 'login_landed');
  }, []);
  const auth = getAuth();

  useEffect(() => {
    ZendeskAPI('webWidget', 'show');
  }, []);

  useEffect(() => {
    if (query.get('redirect') != null) {
      console.log(query.get('redirect'));
      setIsRedirect(true);
      setPath(query.get('redirect') ?? '/');
    } else {
      setIsRedirect(false);
    }
  }, [props]);

  async function confirmCode() {
    try {
      const response = await authApi.verifyPhoneVerificationCode({
        phone_number: '+1' + phoneNumber,
        code: code,
        os_type: osName + '_' + osVersion,
      });
      localStorage.setItem('tabletabToken', response.data.token?.access_token);
      localStorage.setItem('tabletabIsLoggedIn', 'true');
      dispatch(setStripeId(response.data.guest?.stripe_id));
      dispatch(setUserProfile(response.data?.guest));
      ZendeskAPI('webWidget', 'identify', {
        name: '+1' + phoneNumber,
      });
      isRedirect ? navigate(path) : navigate('/feed');
    } catch (e) {
      setLoading(false);
      setError('Error: \n' + e.response?.data?.detail ?? e.message);
      Sentry.captureException(error);
    }
  }

  async function signInViaPhoneNumber() {
    try {
      const response = await authApi.createPhoneVerificationCode({
        phone_number: '+1' + phoneNumber,
        channel: 'sms',
      });
      if (response.data.status == 'pending' || response.data.status == 'sent') {
        setError('');
        setLoading(false);
        setShowCodeField(true);
      }
    } catch (e) {
      setLoading(false);
      setError('Error: \n' + e.response?.data?.detail ?? e.message);
      Sentry.captureException(error);
    }
  }

  return (
    <>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 text-center">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            className="bottom-0 fixed inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 -mb-20 pb-32 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <h2 className="text-3xl leading-9 font-extrabold text-gray-900">
                Log-in to continue
              </h2>
            </div>
            {error !== '' && (
              <div className="rounded-md bg-red-50 p-4 my-6">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-5 w-5 text-red-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm leading-5 font-medium text-red-800">
                      {error}
                    </h3>
                  </div>
                </div>
              </div>
            )}
            {!showCodeField && (
              <Spin spinning={loading} indicator={LoadingIcon}>
                <form
                  className={'w-full'}
                  onSubmit={async e => {
                    e.preventDefault();
                    setLoading(true);
                    await signInViaPhoneNumber();
                  }}
                >
                  <div>
                    <div className="flex flex-row justify-between items-center">
                      <label
                        htmlFor="tel"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Enter Your Phone Number
                      </label>
                      {!showCodeField && (
                        <div className="justify-center content-center items-center">
                          <div
                            className=" rounded"
                            onClick={event => setShowPrivacyPopUp(true)}
                          >
                            <div className="flex flex-col  bg-white h-full items-center justify-center align-middle">
                              <div className="flex flex-row w-full items-center justify-center">
                                <div className="text-gray-900 flex flex-row items-center text-sm text-left justify-left content-center">
                                  <PillLabel
                                    color="gray"
                                    label={'Why do we ask this?'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        id="tel"
                        type="phone"
                        required
                        value={phoneNumber}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          e.preventDefault();
                          const value = e.target.value.replace(/\s/g, '');
                          setPhoneNumber(value);
                        }}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-primary focus:border-red-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>
                  <div className="mt-6 justify-center content-center items-center">
                    <div className="pr-4 min-w-4/5 sm:h-10 max-h-10 sm:flex rounded">
                      <div className="flex flex-col bg-white h-full items-center justify-center align-middle">
                        <div className="flex flex-row w-full items-center justify-center">
                          <div className="text-gray-900 flex flex-row items-center text-xs text-left justify-left content-center">
                            <p>
                              {`You will receive an SMS for verification. Please make sure you have cellular service or WiFi connectivity.`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      id={'phone-number-submit'}
                      type="submit"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-400 text-base font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </Spin>
            )}
            {showCodeField && (
              <Spin spinning={loading} indicator={LoadingIcon}>
                <form
                  className={'w-full'}
                  onSubmit={async e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setLoading(true);
                    await confirmCode();
                  }}
                >
                  <div>
                    <label
                      htmlFor="code"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Enter Verification Code
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        id="code"
                        type="ont-time-code"
                        autoComplete="one-time-code"
                        required
                        value={code}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          e.preventDefault();
                          const value = e.target.value.replace(/\s/g, '');
                          setCode(value);
                        }}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-primary focus:border-red-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>
                  <div className="mt-6 justify-center content-center items-center">
                    <div className="pr-4 min-w-4/5 sm:h-10 max-h-10 sm:flex rounded">
                      <div className="flex flex-col bg-white h-full items-center justify-center align-middle">
                        <div className="flex flex-row w-full items-center justify-center">
                          <div className="text-gray-900 flex flex-row items-center text-xs text-left justify-left content-center">
                            <p>
                              {`We have sent a code to +1${phoneNumber}. Press retry if you haven't received it in 60 secs.`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<div id={'recaptcha'} ref={ref => (recaptcha = ref)} />*/}
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-400 text-base font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                    >
                      Confirm
                    </button>
                  </div>
                  <div className="justify-center content-center items-center mt-6">
                    <div
                      className="py-2 pr-4 pl-4 w-full sm:h-10 max-h-10 rounded"
                      onClick={async () => {
                        setLoading(false);
                        setShowCodeField(false);
                        // await signInViaPhoneNumber();
                      }}
                    >
                      <div className="flex flex-col p-4 bg-white h-full items-center justify-center align-middle">
                        <div className="flex flex-row w-full items-center justify-center">
                          <div className="text-gray-900 flex flex-row items-center text-sm text-left justify-left content-center">
                            <PillLabel color="gray" label={'Retry'} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Spin>
            )}

            {showPrivacyPopUp && (
              <PrivacyInfoCard callback={() => setShowPrivacyPopUp(false)} />
            )}
            {
              <div className="justify-center content-center items-center mt-6">
                <div className="  w-full  rounded">
                  <div className="flex flex-col  bg-white h-full items-center justify-center align-middle">
                    <p className="py-4 text-gray-900  text-xs text-left">
                      By continuing, you agree to our
                      <a
                        href="https://tabletab.io/terms"
                        target={'_blank'}
                        className="px-1"
                      >
                        Terms of Service
                      </a>
                      &
                      <a
                        href={'https://tabletab.io/privacy'}
                        target={'_blank'}
                        className="pl-1"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {};

export default Login;
