import React from 'react';
import PillLabel from '../../../../components/PillLabel';

type Props = {
  message: string;
  restaurant_name: string;
};

const ServiceMessageCard: React.FC<Props> = ({ message, restaurant_name }) => {
  return (
    <div className="align-bottom mb-10 bg-white rounded-lg text-left overflow-hidden shadow-lg transform transition-all sm:my-8 sm:align-middle mx-4 mt-4">
      <div className="bg-white p-4">
        <div className="sm:flex-col sm:items-start sm:flex-row ">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center">
            <span
              className={`px-2 inline-flex text-sm leading-5  rounded-full bg-red-100 text-red-700`}
            >
              {`Message from ${restaurant_name}`}
            </span>
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <div className="mt-2">
              <p className="text-sm leading-5 text-gray-800">{message}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceMessageCard;
