import React, { useEffect, useState } from 'react';
import { TTMenuItem } from '../../../interfaces/menuItem';
import { Spin } from 'antd';
import { LoadingIcon } from '../../components/Spinner';
import { getFullImageUrl, centsToDollars } from '../../utils';
import { menuItemsApi } from '../../../api/menuItems';
import store from '../../../store';

type Props = {
  menu_item_id: number;
  inCart: boolean;
  onClick: () => void;
};

export const PairingCard: React.FC<Props> = ({
  menu_item_id,
  onClick,
  inCart,
}) => {
  const [menuItem, setMenuItem] = useState<TTMenuItem>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        const response = await menuItemsApi.getMenuItem(menu_item_id);
        setMenuItem(response.data);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
    if (menuItem == null) {
      setLoading(true);
      fetch();
    }
  }, []);

  return (
    <>
      <Spin spinning={loading} indicator={LoadingIcon}>
        {menuItem !== undefined && menuItem !== null && (
          <div
            className={`flex flex-row items-center shadow-sm rounded-l-md rounded-b-md rounded-t-md justify-between ${
              inCart &&
              'border-2 border-green-400 border-l-md border-t-md border-b-md'
            } bg-white rounded-l-md rounded-r-md `}
            onClick={onClick}
          >
            <div className="flex px-4 py-1.5 text-sm items-center">
              <p className="text-gray-900 font-medium hover:text-gray-600 truncate">
                {menuItem.name}
              </p>
            </div>
            {menuItem.image_url !== null && menuItem.image_url !== '100.jpg' && (
              <div className={'flex-shrink-0 flex w-20 h-20 rounded-r-md'}>
                <img
                  alt={'Menu Dish Image'}
                  src={getFullImageUrl(
                    store.getState().restaurant.restaurant_id,
                    menuItem.image_url,
                  )}
                  className=" rounded-r-md "
                />
              </div>
            )}
          </div>
        )}
      </Spin>
    </>
  );
};
