import axios from 'axios';
import { apiUrl, menuUrl } from '../env';
import { getAuthHeaders } from './utils';
import { TTMenu, TTMenuCategories } from '../interfaces/menus';
import { TTMenuItem } from '../interfaces/menuItem';
import store from '../store';
/* eslint-disable */
export const menuApi = {
  async getMenusByRestaurant(id: number) {
    return axios.get<TTMenu[]>(`${menuUrl}/api/v1/menu/restaurant/${id}`, {
      headers: await getAuthHeaders(),
    });
  },

  async getMenuCategories(id: number) {
    return axios.get<TTMenuCategories>(
      `${menuUrl}/api/v1/menu/${id}/categories`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },

  async getMenuItems(id: number) {
    return axios.get<TTMenuItem[]>(`${menuUrl}/api/v1/menu/${id}/menu-items`, {
      headers: await getAuthHeaders(),
    });
  },

  async getMenu(id: number) {
    return axios.get<TTMenu>(`${menuUrl}/api/v1/menu/${id}`, {
      headers: await getAuthHeaders(),
    });
  },

  async getSignedUrlForFile(id: number) {
    return axios.get(`${menuUrl}/api/v1/files/${id}/guest-signed-url`, {
      headers: await getAuthHeaders(),
    });
  },
};
