import React from 'react';
import { getFullImageUrl, centsToDollars } from '../../utils';
import store from '../../../store';
import { TTOrderItem, TTOrderItemCreate } from '../../../interfaces/order_item';
import moment from 'moment';

type Props = {
  menuItem: TTOrderItemCreate | TTOrderItem;
  onClick: () => void;
};

export function getPrice(menuItem, pairedItemTotal) {
  return (
    menuItem.base_price +
    menuItem.modifier_groups
      .map(mod => {
        return mod.modifier_options.reduce(
          (prev, curr) => prev + curr.price || 0,
          0,
        );
      })
      .reduce((prev, curr) => prev + curr, 0) +
    pairedItemTotal
  );
}

export function getModifierPairingTotal(
  menuItem: TTOrderItem | TTOrderItemCreate,
) {
  return (
    menuItem?.modifier_groups
      ?.map(
        m =>
          m.modifier_pairing_options?.reduce(
            (prev, curr) =>
              prev +
              ('id' in menuItem
                ? (store
                    .getState()
                    .order.order.order_tabs.map(a => a.order_items)
                    .reduce((a, b) => a.concat(b))
                    .filter(
                      o =>
                        o.as_pairing &&
                        o.paired_with !== null &&
                        o.paired_with.paired_menu_item_id ===
                          menuItem.menu_item_id &&
                        o.paired_with.pairing_option_id ===
                          curr.pairing_option_id &&
                        o.paired_with.cart_insert_ts ===
                          menuItem.cart_insert_ts,
                    )[0]?.base_price ??
                    0 +
                      getModifierPairingTotal(
                        store
                          .getState()
                          .order.order.order_tabs.map(a => a.order_items)
                          .reduce((a, b) => a.concat(b))
                          .filter(
                            o =>
                              o.as_pairing &&
                              o.paired_with !== null &&
                              o.paired_with.paired_menu_item_id ===
                                menuItem.menu_item_id &&
                              o.paired_with.pairing_option_id ===
                                curr.pairing_option_id &&
                              o.paired_with.cart_insert_ts ===
                                menuItem.cart_insert_ts,
                          )[0],
                      )) *
                    store
                      .getState()
                      .order.order.order_tabs.map(a => a.order_items)
                      .reduce((a, b) => a.concat(b))
                      .filter(
                        o =>
                          o.as_pairing &&
                          o.paired_with !== null &&
                          o.paired_with.paired_menu_item_id ===
                            menuItem.menu_item_id &&
                          o.paired_with.pairing_option_id ===
                            curr.pairing_option_id &&
                          o.paired_with.cart_insert_ts ===
                            menuItem.cart_insert_ts,
                      )[0]?.quantity ?? 1
                : (store
                    .getState()
                    .cart.order_create.order_items.filter(
                      o =>
                        o.paired_with.paired_menu_item_id ===
                          menuItem.menu_item_id &&
                        o.paired_with.cart_insert_ts ===
                          menuItem.cart_insert_ts &&
                        o.menu_item_id === curr.menu_item_id,
                    )[0]?.base_price ??
                    0 +
                      getModifierPairingTotal(
                        store
                          .getState()
                          .cart.order_create.order_items.filter(
                            o =>
                              o.paired_with.paired_menu_item_id ===
                                menuItem.menu_item_id &&
                              o.paired_with.cart_insert_ts ===
                                menuItem.cart_insert_ts &&
                              o.menu_item_id === curr.menu_item_id,
                          )[0],
                      )) *
                    store
                      .getState()
                      .cart.order_create.order_items.filter(
                        o =>
                          o.paired_with.paired_menu_item_id ===
                            menuItem.menu_item_id &&
                          o.paired_with.cart_insert_ts ===
                            menuItem.cart_insert_ts &&
                          o.menu_item_id === curr.menu_item_id,
                      )[0]?.quantity ?? 1),
            0,
          ) ?? 0,
      )
      ?.reduce((prev, curr) => prev + curr, 0) ?? 0
  );
}

const DishCard: React.FC<Props> = React.memo(({ menuItem, onClick }) => {
  const pairedItemTotal = getModifierPairingTotal(menuItem);
  const tz =
    Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Los_Angeles';
  return (
    <tr key={menuItem.name} onClick={onClick}>
      <td>
        <div className="py-2 pr-4 pl-4  rounded">
          <div className="flex  flex-row bg-white p-4 items-start rounded-lg shadow-lg justify-between">
            <div className="flex flex-col justify-between leading-normal mr-1">
              <div className="mb-2">
                <div className="text-gray-900 font-bold text-sm mb-2">
                  {menuItem.name}
                </div>
                {menuItem?.product_type === 'booking' && (
                  <div className="flex items-center flex-row space-x-2 mt-2">
                    <div className="text-sm">
                      <p className="text-gray-900 leading-none mb-1">
                        Booking slot:
                        {menuItem?.booking_info?.status === 'pending' ||
                        menuItem?.booking_info?.status ===
                          'pending_vendor_accept'
                          ? moment
                              .utc(menuItem.booking_info?.proposed_start_at)
                              .clone()
                              .tz(tz)
                              .format('MM/D/YY, h:mm a') +
                            '-' +
                            moment
                              .utc(menuItem.booking_info?.proposed_end_at)
                              .clone()
                              .tz(tz)
                              .format('h:mm a')
                          : moment
                              .utc(menuItem.booking_info?.booked_for)
                              .clone()
                              .tz(tz)
                              .format('MM/D/YY, h:mm a')}
                      </p>
                    </div>
                  </div>
                )}
                {menuItem.modifier_groups.length !== 0 &&
                  menuItem.modifier_groups.filter(m => !m.is_pairing_type)
                    .length !== 0 && (
                    <p className="text-gray-700 text-xs">
                      Option(s):{' '}
                      {menuItem.modifier_groups
                        .filter(m => !m.is_pairing_type)
                        .map(mod => {
                          return mod.modifier_options
                            .map(op => {
                              return op.name;
                            })
                            .join(', ');
                        })
                        .join(', ')}
                    </p>
                  )}
                {menuItem.modifier_groups.length !== 0 &&
                  menuItem.modifier_groups.filter(m => m.is_pairing_type)
                    .length !== 0 && (
                    <p className="text-gray-700 text-xs">
                      Pairing Option(s):{' '}
                      {'id' in menuItem
                        ? store
                            .getState()
                            .order.order.order_tabs.map(a => a.order_items)
                            .reduce((a, b) => a.concat(b))
                            .filter(
                              o =>
                                o.as_pairing &&
                                o.paired_with !== null &&
                                o.paired_with.paired_menu_item_id ===
                                  menuItem.menu_item_id &&
                                o.paired_with.cart_insert_ts ===
                                  menuItem.cart_insert_ts,
                            )
                            .map(modItem => modItem.name)
                            .join(', ')
                        : menuItem.modifier_groups
                            .filter(m => m.is_pairing_type)
                            .map(mod => {
                              return mod.modifier_pairing_options
                                .map(op => {
                                  return op.orderItem?.name;
                                })
                                .join(', ');
                            })
                            .join(', ')}
                    </p>
                  )}
              </div>
              <div className="flex items-center flex-row space-x-2">
                <div className="text-sm">
                  <p className="text-gray-900 leading-none">
                    x {menuItem.quantity}
                  </p>
                </div>
                <div className="text-sm">
                  <p className="text-gray-900 leading-none">
                    {centsToDollars(getPrice(menuItem, pairedItemTotal))}
                  </p>
                </div>
              </div>
            </div>
            {menuItem.image_url !== null && (
              <img
                className="w-24 h-24 rounded object-cover"
                src={getFullImageUrl(
                  store.getState().restaurant.profile.restaurant_id,
                  menuItem.image_url,
                )}
                alt="Menu Dish Photo"
              />
            )}
          </div>
        </div>
      </td>
    </tr>
  );
});

export default DishCard;
