import moment from 'moment';
export const DISPLAY_FORMAT = 'L';
export const ISO_FORMAT = 'YYYY-MM-DD';
export const ISO_MONTH_FORMAT = 'YYYY-MM'; // TODO delete this line of dead code on next breaking change

export const BUSINESS_TYPES = [
  {
    key: 'food_beverage',
    name: 'Food & Beverage',
    description:
      'Food & Beverage businesses such as restaurants, breweries, etc.',
  },
  { key: 'beauty_spa', name: 'Beauty & Spa' },
  { key: 'sport_rentals', name: 'Sport Rentals' },
  { key: 'other', name: 'Other' },
];

export const BUSINESS_TYPES_DICT = {
  food_beverage: 'Food & Beverage',
  beauty_spa: 'Beauty & Spa',
  sport_rentals: 'Sport Rentals',
  other: 'Other',
};

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export const HORIZONTAL_ORIENTATION = 'horizontal';
export const VERTICAL_ORIENTATION = 'vertical';
export const VERTICAL_SCROLLABLE = 'verticalScrollable';

export const NAV_POSITION_BOTTOM = 'navPositionBottom';
export const NAV_POSITION_TOP = 'navPositionTop';

export const ICON_BEFORE_POSITION = 'before';
export const ICON_AFTER_POSITION = 'after';

export const INFO_POSITION_TOP = 'top';
export const INFO_POSITION_BOTTOM = 'bottom';
export const INFO_POSITION_BEFORE = 'before';
export const INFO_POSITION_AFTER = 'after';

export const ANCHOR_LEFT = 'left';
export const ANCHOR_RIGHT = 'right';

export const OPEN_DOWN = 'down';
export const OPEN_UP = 'up';

export const DAY_SIZE = 39;
export const BLOCKED_MODIFIER = 'blocked';
export const WEEKDAYS = [0, 1, 2, 3, 4, 5, 6];

export const FANG_WIDTH_PX = 20;
export const FANG_HEIGHT_PX = 10;
export const DEFAULT_VERTICAL_SPACING = 22;

export const MODIFIER_KEY_NAMES = new Set(['Shift', 'Control', 'Alt', 'Meta']);
export function capitalize(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function toUnderscoreCase(str: string) {
  return str.toLowerCase().replace(' ', '_');
}

export function isBeforeDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
}

export function isInclusivelyBeforeDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isAfterDay(a, b);
}

export function isInclusivelyAfterDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isBeforeDay(a, b);
}

export function isSameDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return (
    a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
  );
}

export function isAfterDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isBeforeDay(a, b) && !isSameDay(a, b);
}

export function toMomentObject(dateString, customFormat) {
  const dateFormats = customFormat
    ? [customFormat, DISPLAY_FORMAT, ISO_FORMAT]
    : [DISPLAY_FORMAT, ISO_FORMAT];

  const date = moment(dateString, dateFormats, true);
  return date.isValid() ? date.hour(12) : null;
}

export function toISOMonthString(date, currentFormat) {
  const dateObj = moment.isMoment(date)
    ? date
    : toMomentObject(date, currentFormat);
  if (!dateObj) return null;

  // Template strings compiled in strict mode uses concat, which is slow. Since
  // this code is in a hot path and we want it to be as fast as possible, we
  // want to use old-fashioned +.
  // eslint-disable-next-line prefer-template
  return dateObj.year() + '-' + String(dateObj.month() + 1).padStart(2, '0');
}

const startCacheOutsideDays = new Map();
const endCacheOutsideDays = new Map();

const startCacheInsideDays = new Map();
const endCacheInsideDays = new Map();

export default function isDayVisible(
  day,
  month,
  numberOfMonths,
  enableOutsideDays,
) {
  if (!moment.isMoment(day)) return false;

  // Cloning is a little expensive, so we want to do it as little as possible.

  const startKey = toISOMonthString(month, ISO_MONTH_FORMAT);
  // eslint-disable-next-line prefer-template
  const endKey = startKey + '+' + numberOfMonths;

  if (enableOutsideDays) {
    if (!startCacheOutsideDays.has(startKey)) {
      startCacheOutsideDays.set(
        startKey,
        month.clone().startOf('month').startOf('week').hour(12),
      );
    }

    if (isBeforeDay(day, startCacheOutsideDays.get(startKey))) return false;

    if (!endCacheOutsideDays.has(endKey)) {
      endCacheOutsideDays.set(
        endKey,
        month
          .clone()
          .endOf('week')
          .add(numberOfMonths - 1, 'months')
          .endOf('month')
          .endOf('week')
          .hour(12),
      );
    }

    return !isAfterDay(day, endCacheOutsideDays.get(endKey));
  }

  // !enableOutsideDays

  if (!startCacheInsideDays.has(startKey)) {
    startCacheInsideDays.set(startKey, month.clone().startOf('month').hour(12));
  }

  if (isBeforeDay(day, startCacheInsideDays.get(startKey))) return false;

  if (!endCacheInsideDays.has(endKey)) {
    endCacheInsideDays.set(
      endKey,
      month
        .clone()
        .add(numberOfMonths - 1, 'months')
        .endOf('month')
        .hour(12),
    );
  }

  return !isAfterDay(day, endCacheInsideDays.get(endKey));
}
