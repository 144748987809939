import React, { useState, useRef, useEffect, Fragment, createRef } from 'react';
import { TTMenu } from '../../../interfaces/menus';
import { TTMenuItem } from '../../../interfaces/menuItem';
import { menuApi } from '../../../api/menu';
import { useNavigate, useParams } from 'react-router-dom';
import { TCategory } from '../../../interfaces/category';
import MenuItemCard from './components/MenuItemCard';
import { message, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import MenuCategoryPill from './components/MenuCategoryPill';
import CartBanner from '../../Cart/components/CartBanner';
import store from '../../../store';
import {
  getRestaurant,
  setCurrentMenuCats,
  setCurrentMenuItems,
  setMenu,
  setMenus,
} from '../../../features/restaurant/restaurant';
import { LoadingIcon } from '../../components/Spinner';
import NavBar from '../../components/BackButton';
import { getAnalytics, logEvent } from 'firebase/analytics';
import smoothscroll from 'smoothscroll-polyfill';
import { ZendeskAPI } from 'react-zendesk';
import MenuListSelection from '../Menus/components/MenuListSelection';
import { RootState } from '../../../rootReducer';
import { RestaurantInfoCard } from './components/RestaurantInfo';
import { TTRestaurantProfile } from '../../../interfaces/restaurant';
import { MenuFileView } from './components/MenuFileView';
import { DocumentTextIcon } from '@heroicons/react/outline';
import FileRequiredToView from './components/FileRequiredToView';

type Props = {};

const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const Menu: React.FC<Props> = () => {
  const [menuItems, setMenuItems] = useState<TTMenuItem[] | null>(null);
  const [menuCategories, setMenuCategories] = useState<TCategory[] | null>();
  const [selectedCategory, setSelectedCategory] = useState<TCategory | null>();
  const selectedMenu = useSelector<RootState, TTMenu>(
    state => state.restaurant.menu_in_view,
  );
  const restaurantProfile = useSelector<RootState, TTRestaurantProfile | null>(
    state => state.restaurant.profile,
  );
  const [refs, setElRefs] = React.useState([]);
  const [catRefs, setCatRfs] = React.useState([]);

  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isSelectedScroll, setIsSelectedScroll] = useState(false);
  // @ts-ignore
  const { r_id, m_id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const itemsTableRef = useRef(null);

  const analytics = getAnalytics();

  const scrollTo = ele => {
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = ele.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - 160;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
    setIsSelectedScroll(false);
  };

  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
    const scrollPosition = window.scrollY + 80;
    if (itemsTableRef?.current != null) {
      // console.log(catRefs);
      const selected = catRefs.find(ref => {
        if (ref?.current !== null && ref?.current !== undefined) {
          // console.log(ref);
          const { offsetBottom, offsetTop } = getDimensions(ref);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
        return false;
      });
      if (selected !== null) {
      }
    }
  };

  useEffect(() => {
    async function fetchMenus() {
      try {
        const response = await menuApi.getMenusByRestaurant(parseInt(r_id));
        dispatch(setMenus(response.data.sort((a, b) => a.rank - b.rank)));
        if (
          isNaN(parseInt(m_id)) ||
          response.data.filter(a => a.id == parseInt(m_id)).length == 0
        ) {
          message.error(
            'The restaurant does not have the menu you requested',
            2,
            () => {
              navigate(`/feed/restaurants/${r_id}/menus/`);
            },
          );
        }
        dispatch(
          setMenu(
            response.data.filter(a => a.id == parseInt(m_id)).length !== 0
              ? response.data.filter(a => a.id == parseInt(m_id))[0]
              : null,
          ),
        );
      } catch {}
    }
    async function fetchMenuItems() {
      try {
        const response = await menuApi.getMenuItems(selectedMenu?.id);
        const categoryResponse = await menuApi.getMenuCategories(
          selectedMenu?.id,
        );
        const nonEmptyCategories = categoryResponse.data.categories?.filter(
          c =>
            response.data?.filter(
              i => !i.out_of_stock && i.category?.id == c?.id,
            )?.length !== 0,
        );
        setMenuCategories(nonEmptyCategories.sort((a, b) => a.rank - b.rank));
        setSelectedCategory(nonEmptyCategories[0]);
        setMenuItems(response.data);
        dispatch(setCurrentMenuItems(response.data));
        dispatch(setCurrentMenuCats(nonEmptyCategories));
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }

    if (
      store.getState().restaurant.profile === null ||
      store.getState().restaurant.profile?.restaurant_id != parseInt(r_id)
    ) {
      setLoading(true);
      dispatch(
        getRestaurant(parseInt(r_id), () => {
          fetchMenus().then(() => {
            fetchMenuItems();
          });
        }),
      );
    } else if (
      selectedMenu?.restaurant_id != parseInt(r_id) &&
      !store.getState().restaurant.profile?.is_primary_vendor
    ) {
      navigate(`/feed/restaurants/${selectedMenu?.restaurant_id}/menus/`);
    } else if (store.getState().restaurant.current_menus === null) {
      fetchMenus().then(() => {
        fetchMenuItems();
      });
    } else if (selectedMenu === null) {
      dispatch(
        setMenu(
          store
            .getState()
            .restaurant.current_menus.filter(a => a.id == parseInt(m_id))[0],
        ),
      );
    } else {
      if (
        menuItems === null ||
        menuItems.length === 0 ||
        menuItems.filter(i => i.menu_id !== selectedMenu.id).length !== 0
      ) {
        setLoading(true);
        fetchMenuItems();
      }
    }
  }, [selectedMenu]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('touchmove', handleScroll);
    smoothscroll.polyfill();
    logEvent(analytics, 'menu_view', {
      menu_id: m_id,
      source_merchant_id: r_id,
    });
    ZendeskAPI('webWidget', 'show');

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
      document.removeEventListener('touchmove', () => handleScroll);
    };
  }, []);

  return (
    <>
      <div className="sticky top-0 w-full z-20 bg-gray-100 ">
        <NavBar
          onClick={() => navigate(`/feed/restaurants/${r_id}/menus/`)}
          hide={false}
          middle_option={<MenuListSelection />}
          show_help={selectedMenu?.file_id === null}
          help_element={
            selectedMenu?.file_id && (
              <button
                className={`bg-white   align-middle rounded-full flex items-center justify-center w-1/5 h-8 outline-none focus:outline-none`}
                onClick={async () => {
                  try {
                    const response = await menuApi.getSignedUrlForFile(
                      selectedMenu?.file_id,
                    );
                    window.open(response.data, '_blank');
                  } catch (e) {
                    message.error('Unable to download the file');
                  }
                }}
              >
                <DocumentTextIcon className="w-5 h-5 text-gray-600 " />
              </button>
            )
          }
        />
      </div>
      {selectedMenu !== null &&
        selectedMenu?.restaurant_id !== restaurantProfile?.restaurant_id && (
          <RestaurantInfoCard />
        )}
      {selectedMenu?.file_id && (
        <MenuFileView menu={selectedMenu} loading_menu={loading} />
      )}

      {!loading &&
        menuCategories !== null &&
        selectedCategory !== undefined &&
        menuCategories !== undefined &&
        selectedMenu !== null && (
          <div
            className={`flex flex-no-wrap  flex-row overflow-x-scroll items-center pl-4 pr-4 scrolling-touch mb-2 mt-2 sticky ${
              isSticky ? 'top-12' : 'top-12'
            } bg-gray-100 z-10 md:mx-8 lg:mx-16 xl:mx-32`}
            ref={ref}
            onScroll={() => {
              logEvent(analytics, 'menu_sections_scrolled');
            }}
          >
            {menuCategories.map((cat, i) => {
              return (
                <div
                  ref={_ref => (catRefs[i] = _ref)}
                  key={i.toString()}
                  className="px-2 py-2 opacity-100"
                >
                  <MenuCategoryPill
                    menuCategory={cat}
                    selected={cat.id == selectedCategory.id}
                    onClick={cat => {
                      logEvent(analytics, 'menu_section_clicked', {
                        menu_category_id: cat.id,
                        rank: cat.rank,
                        menu_category_name: cat.name,
                      });
                      setIsSelectedScroll(true);
                      setSelectedCategory(cat);
                      scrollTo(refs[i]);
                    }}
                    key={cat.id}
                  />
                </div>
              );
            })}
          </div>
        )}
      <div className="min-h-screen bg-gray-100 z-0 md:mx-8 lg:mx-16 xl:mx-32">
        <div className="transition duration-500 ease-in-out overflow-y-scroll scrolling-touch bg-gray-100">
          <Spin spinning={loading} indicator={LoadingIcon} className={'z-0'}>
            {loading && (
              <main
                className={`flex-1 relative z-0 overflow-y-auto ${
                  loading ? 'pt-20' : 'pt-0'
                }`}
              />
            )}

            {menuItems !== null && menuCategories !== null && !loading && (
              <div ref={itemsTableRef} onScroll={handleScroll} id="item_tables">
                {menuCategories.map((cat, i) => {
                  return (
                    <div key={cat.id.toString() + '-div'}>
                      <div
                        className="flex flex-col   h-full  mb-4 space-y-1"
                        key={cat.id.toString() + '-header'}
                      >
                        <div className="py-2 px-4 bg-gray-600 md:rounded-md flex flex-col w-full  shadow-lg">
                          <div className=" flex flex-col text-white text-sm text-left  space-y-1">
                            <span className="font-bold">{cat.name}</span>
                          </div>
                          {cat.description !== null && (
                            <span className="text-white text-left justify-center  whitespace-pre-line">
                              {cat.description}
                            </span>
                          )}
                        </div>
                      </div>

                      <ol
                        className=" mb-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-flow-row auto-rows-max items-center gap-y-4 gap-x-0 sm:gap-x-4 px-4"
                        id={cat.id.toString()}
                        key={cat.id.toString()}
                        ref={ref => (refs[i] = ref)}
                        onScroll={e => {
                          e.preventDefault();
                        }}
                      >
                        {menuItems
                          .filter((item: TTMenuItem) => {
                            return (
                              item.category.id == cat.id && !item.out_of_stock
                            );
                          })
                          .sort((a, b) => {
                            return a.rank - b.rank;
                          })
                          .map((item: TTMenuItem, i) => {
                            return (
                              <MenuItemCard
                                menuItem={item}
                                menu={selectedMenu}
                                onClick={() => {
                                  logEvent(analytics, 'menu_item_clicked', {
                                    menu_item_id: item.id,
                                    menu_item_name: item.name,
                                  });
                                  navigate(
                                    `/feed/restaurants/${r_id}/menus/${selectedMenu.id}/items/${item.id}`,
                                  );
                                }}
                                key={item.id.toString()}
                              />
                            );
                          })}
                      </ol>
                    </div>
                  );
                })}
                <div className="mb-20" />
              </div>
            )}
          </Spin>
        </div>
        {!loading && (
          // <div className="w-full">
          <CartBanner cta="Continue to checkout" />
          // </div>
        )}
      </div>
    </>
  );
};

export default Menu;
const CategoryPillRef = React.forwardRef((props, ref) => (
  <div
    // @ts-ignore
    ref={ref}
    // @ts-ignore
    key={props.i.toString()}
    className="px-2 pb-2 pt-2 min-w-1/3 max-w-1/2 opacity-100"
  >
    <MenuCategoryPill
      // @ts-ignore
      menuCategory={props.cat}
      // @ts-ignore
      selected={props.cat.id == props.selectedCategory.id}
      onClick={cat => {
        logEvent(getAnalytics(), 'menu_section_clicked', {
          menu_category_id: cat.id,
          rank: cat.rank,
          menu_category_name: cat.name,
        });
        // @ts-ignore
        props.setIsSelectedScroll(true);
        // @ts-ignore
        props.setSelectedCategory(cat);
        // @ts-ignore
        scrollTo(props.refs[props.i]);
      }}
      // @ts-ignore
      key={props.cat.id}
    />
  </div>
));
