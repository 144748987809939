import React from 'react';
/* eslint-disable */
type Props = {
  title: string;
  className?: string;
};

const ViewHeader: React.FC<Props> = ({ title, className = 'text-center' }) => {
  return (
    <div className={` sm:px-4  ${className}`}>
      <div className="text-2xl font-semibold text-gray-900">{title}</div>
    </div>
  );
};

export default ViewHeader;
