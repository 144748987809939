import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TTOrderV2 } from '../../interfaces/order';
import { SquareGiftCard } from '../../interfaces/stripe_payment_method';
import { TTUserProfile } from '../../interfaces/user';

/* eslint-disable */
interface HybridState {
  hybrid_draft_order_id: number | null;
  claiming_guest_id: number | null;
  claiming_order: TTOrderV2 | null;
  last_4: string;
  guest_profile: TTUserProfile | null;
  lastQRTableNumber: string | null;
}

const initialState: HybridState = {
  hybrid_draft_order_id: isNaN(
    parseInt(localStorage.getItem('__tt_hybrid_claim_id')),
  )
    ? null
    : parseInt(localStorage.getItem('__tt_hybrid_claim_id')),
  claiming_guest_id: isNaN(
    parseInt(localStorage.getItem('__tt_hybrid_claim_guest_id')),
  )
    ? null
    : parseInt(localStorage.getItem('__tt_hybrid_claim_guest_id')),
  claiming_order: null,
  last_4: localStorage.getItem('__tt_hybrid_last_4') ?? '',
  guest_profile: null,
  lastQRTableNumber: localStorage.getItem('__tt_table_number'),
};

const hybrid = createSlice({
  name: 'hybrid',
  initialState,
  reducers: {
    setHybridDraftOrderId(state, action: PayloadAction<number>) {
      state.hybrid_draft_order_id = action.payload;
      localStorage.setItem('__tt_hybrid_claim_id', action.payload.toString());
    },
    setClaimingGuestId(state, action: PayloadAction<number>) {
      state.claiming_guest_id = action.payload;
      localStorage.setItem(
        '__tt_hybrid_claim_guest_id',
        action.payload.toString(),
      );
    },
    setClaimingGuest(state, action: PayloadAction<TTUserProfile>) {
      state.guest_profile = action.payload;
    },
    setclaimingOrder(state, action: PayloadAction<TTOrderV2>) {
      state.claiming_order = action.payload;
    },
    setLast4(state, action: PayloadAction<string>) {
      state.last_4 = action.payload;
      localStorage.setItem('__tt_hybrid_last_4', action.payload.toString());
    },
    setLastTableNumber(state, action: PayloadAction<string>) {
      state.lastQRTableNumber = action.payload;
    },
  },
});

export const {
  setHybridDraftOrderId,
  setClaimingGuestId,
  setclaimingOrder,
  setLast4,
  setClaimingGuest,
  setLastTableNumber,
} = hybrid.actions;

export default hybrid.reducer;
