import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { TTMenuItem } from '../../interfaces/menuItem';
import { useParams } from 'react-router-dom';
import { Drawer, message, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { menuItemsApi } from '../../api/menuItems';
import DishImage from './components/DishImage';
import store from '../../store';
import { getRestaurant } from '../../features/restaurant/restaurant';
import DishInfoCard from './components/DishInfoCard';
import { TTModifierWOptions } from '../../interfaces/modifier';
import ModifierTable from '../DishModifiers/components/ModifiersTable';
import AddToCartBanner from '../Cart/components/AddToCartBanner';
import { LoadingIcon } from '../components/Spinner';
import {
  TTModifierPairingPairedWith,
  TTOrderItemCreate,
} from '../../interfaces/order_item';
import {
  attemptNewItem,
  attemptRemoveItem,
  calculateCartCheck,
  createOrderObj,
  replaceItem,
  setFilesRead,
} from '../../features/cart/cart';
import { TTModifierOptions } from '../../interfaces/modifier_options';
import DecreaseCounter from '../components/DecreaseCounter';
import CounterView from '../components/CounterView';
import IncreaseCounter from '../components/IncreaseCounter';
import NavBar from '../components/BackButton';
import DishComment from './components/DishComment';
import { getAnalytics, logEvent } from 'firebase/analytics';
import * as Sentry from '@sentry/react';
import { ZendeskAPI } from 'react-zendesk';
import { TTUserProfile } from '../../interfaces/user';
import { LoginPopUp } from '../LogInPopUp';
import { PairingCollection } from './components/PairingCollection';
import { TTModifierPairingOption } from '../../interfaces/modifier_pairing_options';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { TTMenu } from '../../interfaces/menus';
import { BookingSchdule } from './components/BookingSchedule';
import { BookingInfo, BookingSlots } from '../../interfaces/booking_schedule';
import moment from 'moment';
import { MenuFileView } from '../Menu/MenuComponents/components/MenuFileView';

type Props = {
  editableVersion?: boolean;
  menuItemId?: number;
  callback?: (item?: TTOrderItemCreate) => void;
  onRemoveCallback?: (item: TTOrderItemCreate) => void;
  i_ts?: number;
  addAsPairing?: boolean;
  pairedWith?: TTModifierPairingPairedWith;
  maxQuantity?: number;
  minQuantity?: number;
};

const MenuDish: React.FC<Props> = React.memo(
  ({
    editableVersion = false,
    menuItemId = null,
    callback,
    onRemoveCallback,
    i_ts,
    addAsPairing = false,
    pairedWith,
    maxQuantity,
    minQuantity,
  }) => {
    const [menuItem, setMenuItem] = useState<TTMenuItem | null>(null);
    // @ts-ignore
    const { r_id, mi_id } = useParams();
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState<string>('');
    const guest = useSelector<RootState, TTUserProfile>(
      state => state.auth.userProfile,
    );
    const selectedMenu = useSelector<RootState, TTMenu>(
      state => state.restaurant.menu_in_view,
    );
    const [showingPairingId, setShowingPairingId] = useState<number | null>(
      null,
    );
    const [showingModifierPairingOption, setShowingModifierPairingOption] =
      useState<TTModifierPairingOption>(null);

    const [showAuthPopUp, setShowAuthPopUp] = useState(false);

    const [existingItem, setExistingItem] = useState<TTOrderItemCreate | null>(
      null,
    );

    const [showFileRead, setShowFileRead] = useState(false);

    const [selectedModifiers, setSelectedModifiers] = useState<
      TTModifierWOptions[]
    >([]);

    const [modifierOptions, setModifierOptions] = useState<
      TTModifierWOptions[] | null
    >(null);

    const [modsChecked, setModsChecked] = useState(false);
    const [bookingSlot, setBookingSlot] = useState<BookingSlots | null>(null);
    const viewingMenu = useSelector<RootState, TTMenu | null>(
      state => state.restaurant.menu_in_view,
    );
    const files_read = useSelector<RootState, { [key: string]: boolean }>(
      state => state.cart.files_read,
    );
    useMemo(() => {
      if (modifierOptions !== null) {
        for (let mod of modifierOptions) {
          if (
            mod.is_required &&
            selectedModifiers.filter((sMod, i) => {
              return sMod.id == mod.id;
            }).length === 0
          ) {
            setModsChecked(false);
            return;
          }
        }
        setModsChecked(true);
      }
    }, [selectedModifiers, modifierOptions]);

    const addDishToCart = () => {
      console.log(selectedModifiers);
      for (let mod of modifierOptions) {
        if (mod?.is_pairing_type) {
          if (
            mod.is_required &&
            selectedModifiers
              ?.filter(sMod => {
                return sMod.id == mod.id && sMod.is_pairing_type;
              })
              ?.map(sMod =>
                sMod.modifier_pairing_options
                  ?.map(p => p.orderItem?.quantity)
                  ?.reduce((a, b) => a + b, 0),
              )
              ?.reduce((a, b) => a + b, 0) >
              (mod.is_single_selection
                ? 1
                : mod.max_selection !== 0 && mod.max_selection !== -1
                ? mod.max_selection
                : 1)
          ) {
            message.error({
              content: 'Please select the required options',
              onClose: () => {
                setLoading(false);
                return;
              },
            });
            return;
          } else if (minQuantity !== undefined && quantity < minQuantity) {
            message.error({
              content: `Please select at least ${minQuantity}`,
              onClose: () => {
                setLoading(false);
                return;
              },
            });
            return;
          } else if (
            mod.is_required &&
            selectedModifiers
              ?.filter(sMod => {
                return sMod.id == mod.id && sMod.is_pairing_type;
              })
              ?.map(sMod =>
                sMod.modifier_pairing_options
                  ?.map(p => p.orderItem?.quantity)
                  ?.reduce((a, b) => a + b, 0),
              )
              ?.reduce((a, b) => a + b, 0) <
              (mod.is_single_selection
                ? 1
                : mod.min_selection !== 0 && mod.min_selection !== -1
                ? mod.min_selection
                : 1)
          ) {
            message.error({
              content: `Please select the minimum required options for ${mod?.name}`,
              onClose: () => {
                setLoading(false);
                return;
              },
            });
            return;
          }
        } else {
          if (
            mod.is_required &&
            selectedModifiers.filter((sMod, i) => {
              return sMod.id == mod.id && !sMod.is_pairing_type;
            }).length >
              (mod.is_single_selection
                ? 1
                : mod.max_selection !== 0 && mod.max_selection !== -1
                ? mod.max_selection
                : 1)
          ) {
            message.error({
              content: 'Please select the required options',
              onClose: () => {
                setLoading(false);
                return;
              },
            });
            return;
          } else if (minQuantity !== undefined && quantity < minQuantity) {
            message.error({
              content: `Please select at least ${minQuantity}`,
              onClose: () => {
                setLoading(false);
                return;
              },
            });
            return;
          } else if (
            mod.is_required &&
            selectedModifiers
              .filter((sMod, i) => {
                return sMod.id == mod.id && !sMod.is_pairing_type;
              })
              ?.map(sM => sM.modifier_options)
              ?.reduce((a, b) => a.concat(b), []).length <
              (mod.is_single_selection
                ? 1
                : mod.min_selection !== 0 && mod.min_selection !== -1
                ? mod.min_selection
                : 1)
          ) {
            console.log('non pairing min not met');
            message.error({
              content: `Please select the minimum required options for ${mod?.name}`,
              onClose: () => {
                setLoading(false);
                return;
              },
            });
            return;
          }
        }
      }
      const insert_ts = Date.now();

      let bookingInfo: BookingInfo | null = null;

      if (menuItem.product_type === 'booking') {
        if (
          bookingSlot === null ||
          bookingSlot?.end_at === null ||
          bookingSlot?.start_at === null
        ) {
          message.error({
            content: 'Please select a booking slot',
          });
          return;
        }
        bookingInfo = {
          menu_item_id: menuItem?.id,
          guest_id: guest?.id,
          proposed_start_at: moment(
            bookingSlot.date + bookingSlot?.start_at,
            'YYYY-MM-DD HH:mm',
          ).toISOString(),
          proposed_end_at: moment(
            bookingSlot.date + bookingSlot?.end_at,
            'YYYY-MM-DD HH:mm',
          ).toISOString(),
          status: 'pending',
        };
      }

      let item: TTOrderItemCreate = {
        name: menuItem.name,
        base_price: menuItem.price,
        alcoholic: menuItem.is_alcoholic,
        quantity: quantity,
        served: false,
        menu_item_id: menuItem.id,
        category_id: menuItem.category.id,
        voided: false,
        void_pulled: false,
        modifier_groups: [...selectedModifiers],
        image_url: menuItem.image_url,
        guest_id: store.getState().auth.guest_id,
        pos_id: menuItem.square_default_variation_id ?? menuItem.pos_id,
        firebase_user_id: store.getState().auth.userProfile.firebase_id,
        short_name: menuItem.short_name,
        cart_insert_ts: !editableVersion
          ? pairedWith !== undefined &&
            pairedWith?.cart_insert_ts !== undefined &&
            pairedWith?.cart_insert_ts !== 0
            ? pairedWith.cart_insert_ts
            : insert_ts
          : existingItem?.cart_insert_ts,
        comment: comment,
        menu_id: menuItem.category.menu_id,
        as_pairing: addAsPairing,
        paired_with: {
          ...pairedWith,
          cart_insert_ts: !editableVersion
            ? pairedWith !== undefined &&
              pairedWith?.cart_insert_ts !== undefined &&
              pairedWith?.cart_insert_ts !== 0
              ? pairedWith?.cart_insert_ts
              : insert_ts
            : existingItem?.cart_insert_ts,
        },
        restaurant_id: menuItem.category.menu.restaurant_id,
        is_discount_excluded: menuItem.is_discount_excluded,
        is_service_fee_excluded: menuItem.is_service_fee_excluded,
        is_tip_excluded: menuItem.is_tip_excluded,
        is_tax_excluded: menuItem.is_tax_excluded,
        product_type: menuItem.product_type,
        booking_info: bookingInfo,
        is_scheduled: menuItem.is_always_scheduled,
        is_always_delivered: menuItem.is_always_delivered,
      };
      if (pairedWith !== undefined && pairedWith.is_pairing_option) {
        callback(item);
        return;
      }

      const pairedModifierOptions = selectedModifiers.filter(
        sM =>
          sM.is_pairing_type &&
          sM.modifier_pairing_options !== undefined &&
          sM.modifier_pairing_options.length !== 0,
      );
      if (pairedModifierOptions.length !== 0) {
        pairedModifierOptions.map(m => {
          m.modifier_pairing_options.map(async pM => {
            try {
              if (
                editableVersion ||
                addAsPairing ||
                store.getState().order.order !== null
              ) {
                if (
                  store
                    .getState()
                    .cart.cart_items?.filter(
                      cI =>
                        cI.cart_insert_ts === pM.orderItem.cart_insert_ts &&
                        cI.menu_item_id === pM.orderItem.menu_item_id,
                    ).length !== 0
                ) {
                  dispatch(
                    attemptNewItem(
                      {
                        ...pM.orderItem,
                        paired_with: {
                          ...pM.orderItem.paired_with,
                          cart_insert_ts: item.cart_insert_ts,
                        },
                      },
                      () => {},
                    ),
                  );
                } else {
                  dispatch(
                    attemptNewItem(
                      {
                        ...pM.orderItem,
                        paired_with: {
                          ...pM.orderItem.paired_with,
                          cart_insert_ts: item.cart_insert_ts,
                        },
                      },
                      () => {},
                    ),
                  );
                }
              } else if (store.getState().order.order === null) {
                dispatch(
                  createOrderObj(() => {
                    dispatch(
                      attemptNewItem(
                        {
                          ...pM.orderItem,
                          paired_with: {
                            ...pM.orderItem.paired_with,
                            cart_insert_ts: item.cart_insert_ts,
                          },
                        },
                        () => {},
                      ),
                    );
                  }),
                );
              }
            } catch {
              message.error({
                content: 'Unable to add the item',
                onClose: () => {
                  setLoading(false);
                  return;
                },
              });
            }
          });
        });
      }

      if (editableVersion || addAsPairing) {
        dispatch(
          attemptNewItem(item, (redirect: boolean) => {
            if (redirect) {
              setLoading(false);
              callback();
            } else {
              setLoading(false);
            }
          }),
        );
      } else {
        if (store.getState().order.order == null) {
          dispatch(
            createOrderObj(() => {
              dispatch(
                attemptNewItem(item, (redirect: boolean) => {
                  if (redirect) {
                    message.success({
                      content: 'Added to tab',
                      duration: 2,
                      className: 'text-md',
                    });
                    setLoading(false);
                    history.back();
                  } else {
                    setLoading(false);
                  }
                }),
              );
            }),
          );
        } else {
          dispatch(
            attemptNewItem(item, (redirect: boolean) => {
              if (redirect) {
                message.success({
                  content: 'Added to tab',
                  duration: 2,
                  className: 'text-md',
                });
                setLoading(false);
                history.back();
              } else {
                setLoading(false);
              }
            }),
          );
        }
      }
    };

    const removeDish = (dish: TTOrderItemCreate) => {
      setLoading(true);
      if (pairedWith !== undefined && pairedWith?.is_pairing_option) {
        let newMods = [...selectedModifiers];
        let modToRemove = {
          ...newMods.filter(
            val =>
              val.is_pairing_type &&
              val.modifier_pairing_options.filter(
                p => p.menu_item_id === dish.menu_item_id,
              ).length !== 0,
          )[0],
        };
        modToRemove.modifier_pairing_options = {
          ...modToRemove.modifier_pairing_options?.filter(
            p => p.menu_item_id !== dish.menu_item_id,
          ),
        };
        newMods = newMods.map(val => {
          return val.id == modToRemove.id ? modToRemove : val;
        });
        setSelectedModifiers(newMods);
        dispatch(
          attemptRemoveItem(dish, removed => {
            if (removed) {
              dispatch(calculateCartCheck());
              onRemoveCallback(dish);
            } else {
              setLoading(false);
              message.error('Unable to remove the item');
            }
          }),
        );
      } else {
        const pairedModifierOptions = selectedModifiers.filter(
          sM =>
            sM.is_pairing_type &&
            sM.modifier_pairing_options !== undefined &&
            sM.modifier_pairing_options.length !== 0,
        );
        if (pairedModifierOptions.length !== 0) {
          pairedModifierOptions.map(m => {
            m.modifier_pairing_options.map(async pM => {
              try {
                dispatch(
                  attemptRemoveItem(pM.orderItem, () => {
                    dispatch(calculateCartCheck());
                  }),
                );
              } catch {
                message.error({
                  content: 'Unable to remove the item',
                  onClose: () => {
                    setLoading(false);
                    return;
                  },
                });
              }
            });
          });
        }
        dispatch(
          attemptRemoveItem(dish, removed => {
            if (removed) {
              dispatch(calculateCartCheck());
              if (editableVersion || addAsPairing) {
                callback();
              } else {
                history.back();
              }
            }
          }),
        );
      }
    };

    useMemo(() => {
      if (files_read[viewingMenu?.file?.unique_id]) {
        setShowFileRead(false);
      }
    }, [files_read]);

    const updateModifiers = (
      newMod: TTModifierWOptions,
      options: TTModifierOptions[],
      pairing_options: TTModifierPairingOption[],
    ) => {
      if (options.length === 0 && pairing_options.length === 0) {
        let newMods = [...selectedModifiers];
        newMods = newMods.filter(mod => {
          return mod.id !== newMod.id;
        });
        setSelectedModifiers(newMods);
        return;
      }
      if (selectedModifiers.length == 0) {
        setSelectedModifiers([
          {
            ...newMod,
            modifier_options: [...options],
            modifier_pairing_options: [...pairing_options],
          },
        ]);
      } else if (
        selectedModifiers.filter(mod => mod.id == newMod.id).length != 0
      ) {
        let newMods = [...selectedModifiers];
        let mod = { ...newMods.filter(val => val.id == newMod.id)[0] };

        mod.modifier_options = [...options];
        mod.modifier_pairing_options = [...pairing_options];
        newMods = newMods.map(val => {
          return val.id == mod.id ? mod : val;
        });
        setSelectedModifiers(newMods);
      } else {
        newMod.modifier_options = options;
        newMod.modifier_pairing_options = pairing_options;
        setSelectedModifiers(existing => {
          return [...existing, newMod];
        });
      }
    };

    useEffect(() => {
      async function fetch(id?: number) {
        try {
          const response = await menuItemsApi.getMenuItem(
            id == null ? parseInt(mi_id) : id,
          );
          const modifiersResponse = await menuItemsApi.getModifiers(
            response.data.id,
          );
          setMenuItem(response.data);
          setModifierOptions(modifiersResponse.data);
          if (!editableVersion) {
            if (response.data.min_quantity) {
              setQuantity(response.data.min_quantity);
            }
          }
          setLoading(false);
        } catch (err) {
          Sentry.captureException(err);
          setLoading(false);
        }
      }

      if (menuItem === null) {
        setLoading(true);
        if (store.getState().restaurant.restaurant_id == null) {
          dispatch(
            getRestaurant(parseInt(r_id), async () => {
              await fetch(menuItemId);
            }),
          );
          if (editableVersion) {
            const cart_item: TTOrderItemCreate = store
              .getState()
              .cart.cart_items.filter(item => {
                return item.cart_insert_ts === i_ts;
              })[0];
            setExistingItem(cart_item);
            setComment(cart_item.comment);
            setSelectedModifiers([...cart_item.modifier_groups]);
            setQuantity(cart_item.quantity);
          }
        } else {
          fetch(menuItemId);
          if (editableVersion) {
            const cart_item: TTOrderItemCreate = store
              .getState()
              .cart.cart_items.filter(item => {
                return item.cart_insert_ts === i_ts;
              })[0];
            if (cart_item) {
              setExistingItem(cart_item);
              setComment(cart_item?.comment);
              setSelectedModifiers([...cart_item?.modifier_groups]);
              setQuantity(cart_item?.quantity);
            } else if (pairedWith?.order_item) {
              setExistingItem(pairedWith?.order_item);
              setComment(pairedWith?.order_item?.comment);
              setSelectedModifiers([
                ...pairedWith?.order_item?.modifier_groups,
              ]);
              setQuantity(pairedWith?.order_item?.quantity);
            }
          }
        }
      }

      return () => {
        window.scrollTo(0, 0);
      };
    }, [modifierOptions, existingItem]);

    async function checkAuth() {
      if (guest === null || guest === undefined) {
        logEvent(getAnalytics(), 'sign_in_popup_shown');
        setShowAuthPopUp(true);
      } else {
        logEvent(getAnalytics(), 'menu_item_added_cart', {
          menu_item_id: menuItem.id,
          menu_item_name: menuItem.name,
        });
        // @ts-ignore
        logEvent(getAnalytics(), 'add_to_cart', {
          items: [
            {
              item_name: menuItem?.name,
              item_id: menuItem?.id,
              location_id: menuItem?.category?.menu?.restaurant_id,
            },
          ],
        });
        if (quantity == 0) {
          const cart_item = store.getState().cart.cart_items.filter(item => {
            return item.cart_insert_ts == i_ts;
          })[0];
          removeDish(cart_item);
        } else {
          addDishToCart();
        }
      }
    }

    async function postAuthAddToDish() {
      setShowAuthPopUp(false);
      logEvent(getAnalytics(), 'menu_item_added_cart', {
        menu_item_id: menuItem.id,
        menu_item_name: menuItem.name,
      });
      // @ts-ignore
      logEvent(getAnalytics(), 'add_to_cart', {
        items: [
          {
            item_name: menuItem?.name,
            item_id: menuItem?.id,
            location_id: menuItem?.category?.menu?.restaurant_id,
          },
        ],
      });
      addDishToCart();
    }

    useEffect(() => {
      logEvent(getAnalytics(), 'menu_dish_view', {
        menu_item_id: mi_id,
        source_merchant_id: r_id,
      });
      ZendeskAPI('webWidget', 'hide');
    }, []);

    return (
      <>
        {!editableVersion && !addAsPairing && (
          <NavBar show_help={true} hide={true} />
        )}
        <div className={` bg-gray-100 h-full md:px-8 lg:px-16 xl:px-32`}>
          <div
            className={`transition duration-500 ease-in-out overflow-y-scroll scrolling-touch ${' bg-gray-100 '} pb-20`}
          >
            <Spin spinning={loading} indicator={LoadingIcon}>
              {menuItem !== null && (
                <div className="flex-col lg:flex-row">
                  <DishImage
                    imageUrl={menuItem.image_url}
                    menu={selectedMenu}
                  />
                  <DishInfoCard
                    menuItem={menuItem}
                    editable={editableVersion}
                    modsChecked={
                      modsChecked &&
                      (menuItem?.product_type === 'booking'
                        ? bookingSlot?.date !== null &&
                          bookingSlot?.end_at !== null &&
                          bookingSlot?.start_at !== null
                        : true)
                    }
                    title={editableVersion ? 'Remove Item' : 'Add to tab'}
                    onClick={async () => {
                      if (editableVersion) {
                        const cart_item = store
                          .getState()
                          .cart.cart_items.filter(item => {
                            return item.cart_insert_ts == i_ts;
                          })[0];
                        if (cart_item) {
                          removeDish(cart_item);
                        } else if (pairedWith?.is_pairing_option) {
                          removeDish(pairedWith?.order_item);
                        }
                      } else {
                        if (
                          viewingMenu?.file_required_to_view &&
                          (!store.getState().cart.files_read[
                            viewingMenu?.file?.unique_id
                          ] ??
                            true)
                        ) {
                          setShowFileRead(true);
                        } else {
                          await checkAuth();
                        }
                      }
                    }}
                    selectedModifiers={selectedModifiers}
                  />
                </div>
              )}

              {menuItem !== null &&
                menuItem.pairings !== null &&
                menuItem.pairings.length !== 0 && (
                  <PairingCollection
                    menuItem={menuItem}
                    callback={p => {
                      setShowingPairingId(p);
                    }}
                  />
                )}
              {menuItem !== null && menuItem.product_type === 'booking' && (
                <BookingSchdule
                  menuItem={menuItem}
                  onSelect={slot => setBookingSlot(slot)}
                />
              )}

              {modifierOptions !== null &&
                !editableVersion &&
                [...modifierOptions]
                  .filter(m => !m.out_of_stock)
                  .filter(m => {
                    return m?.is_pairing_type
                      ? m?.modifier_pairing_options?.filter(
                          p => !p.out_of_stock,
                        ).length == 0
                        ? false
                        : true
                      : m?.modifier_options?.filter(o => !o.out_of_stock)
                          .length == 0
                      ? false
                      : true;
                  })
                  ?.sort(
                    (a, b) =>
                      menuItem?.modifier_ranking[a?.id] -
                      menuItem?.modifier_ranking[b?.id],
                  )
                  ?.map((mods, i) => {
                    return (
                      <ModifierTable
                        options={mods.modifier_options}
                        optionHeading={mods.name}
                        menu_item={menuItem}
                        key={i}
                        modifier={mods}
                        onPairingOptionView={pairing => {
                          setShowingModifierPairingOption(pairing);
                        }}
                        onSelect={(selected, options, pairing_options) => {
                          if (selected) {
                            updateModifiers(
                              { ...mods },
                              options,
                              pairing_options,
                            );
                          } else {
                            updateModifiers(
                              { ...mods },
                              options,
                              pairing_options,
                            );
                          }
                        }}
                      />
                    );
                  })}

              {modifierOptions !== null &&
                editableVersion &&
                [...modifierOptions]
                  .filter(m => !m.out_of_stock)
                  ?.sort(
                    (a, b) =>
                      menuItem?.modifier_ranking[a?.id] -
                      menuItem?.modifier_ranking[b?.id],
                  )
                  ?.map((mods, i) => {
                    return (
                      <ModifierTable
                        menu_item={menuItem}
                        options={[...mods.modifier_options]}
                        optionHeading={mods.name}
                        key={i}
                        onPairingOptionView={pairingId => {
                          setShowingModifierPairingOption(pairingId);
                        }}
                        selected_pairing_options={
                          selectedModifiers.filter(mod => {
                            return mod.id === mods.id;
                          }).length !== 0
                            ? [
                                ...selectedModifiers.filter(mod => {
                                  return mod.id == mods.id;
                                })[0].modifier_pairing_options,
                              ]
                            : []
                        }
                        selected_options={
                          selectedModifiers.filter(mod => {
                            return mod.id === mods.id;
                          }).length !== 0
                            ? [
                                ...selectedModifiers.filter(mod => {
                                  return mod.id == mods.id;
                                })[0].modifier_options,
                              ]
                            : []
                        }
                        modifier={mods}
                        onSelect={(selected, options, pairing_options) => {
                          if (selected) {
                            updateModifiers(
                              { ...mods },
                              options,
                              pairing_options,
                            );
                          } else {
                            updateModifiers(
                              { ...mods },
                              options,
                              pairing_options,
                            );
                          }
                        }}
                      />
                    );
                  })}
              {store.getState().restaurant.profile !== null &&
                store.getState().restaurant.profile.is_payment_live && (
                  <div>
                    <p className="text-md font-semibold text-gray-700 md:px-4 px-4 mb-0">
                      Add a comment
                    </p>
                    <div className={`flex flex-row justify-center mb-4`}>
                      <DishComment
                        setComment={comment => {
                          setComment(comment);
                        }}
                        comment={comment}
                      />
                    </div>
                  </div>
                )}
              {store.getState().restaurant.profile !== null &&
                store.getState().restaurant.profile.is_payment_live && (
                  <div>
                    <p className="text-md font-semibold text-gray-700 md:px-4 px-4 mb-0">
                      Set quantity
                    </p>
                    <div
                      className={`flex flex-row justify-center ${
                        editableVersion || addAsPairing ? 'mb-20' : 'mb-20'
                      }`}
                    >
                      <DecreaseCounter
                        onClick={() => {
                          if (quantity > menuItem?.min_quantity) {
                            setQuantity(quantity - 1);
                          } else {
                            message.info(
                              `Minimum quantity required is ${menuItem?.min_quantity}`,
                            );
                          }
                        }}
                      />
                      <CounterView count={quantity} />
                      <IncreaseCounter
                        onClick={() => {
                          console.log(maxQuantity);
                          console.log(menuItem?.max_quantity);
                          let max = maxQuantity ?? menuItem?.max_quantity;
                          if (max === undefined || quantity < max) {
                            setQuantity(quantity + 1);
                          } else {
                            if (max) {
                              if (quantity === max) {
                                message.error(
                                  `Maximum quantity allowed is ${max}.`,
                                );
                              }
                            } else {
                              message.error(
                                `Maximum quantity allowed is ${max}.`,
                              );
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                )}

              {/* {store.getState().restaurant.profile !== null &&
                store.getState().restaurant.profile.is_payment_live &&
                menuItem !== null &&
                store
                  .getState()
                  .cart.cart_items.filter(
                    item => item.menu_item_id == menuItem.id,
                  ).length !== 0 &&
                editableVersion && (
                  <RemoveDish
                    onClick={() => {
                      firebase.analytics().logEvent('menu_item_removed_cart', {
                        menu_item_id: menuItem.id,
                        menu_item_name: menuItem.name,
                      });
                      const cart_item = store
                        .getState()
                        .cart.cart_items.filter(item => {
                          return item.cart_insert_ts == i_ts;
                        })[0];
                      removeDish(cart_item);
                    }}
                  />
                )} */}

              <div className={` `}>
                {store.getState().restaurant.profile !== null &&
                  store.getState().restaurant.profile.is_payment_live && (
                    <AddToCartBanner
                      modsChecked={
                        modsChecked &&
                        (menuItem?.product_type === 'booking'
                          ? bookingSlot !== null
                          : true)
                      }
                      onClick={async () => {
                        if (
                          viewingMenu?.file_required_to_view &&
                          (!store.getState().cart.files_read[
                            viewingMenu?.file?.unique_id
                          ] ??
                            true)
                        ) {
                          setShowFileRead(true);
                        } else {
                          await checkAuth();
                        }
                      }}
                      editable={editableVersion}
                      title={
                        editableVersion ? 'Save item changes' : 'Add to tab'
                      }
                      price={
                        menuItem != null
                          ? (menuItem.price +
                              selectedModifiers
                                .map(mod => {
                                  return mod.modifier_options.reduce(
                                    (prev, curr) => prev + curr.price || 0,
                                    0,
                                  );
                                })
                                .reduce((prev, curr) => prev + curr, 0)) *
                            quantity
                          : 0
                      }
                    />
                  )}

                {store.getState().restaurant.profile !== null &&
                  !store.getState().restaurant.profile.is_payment_live && (
                    <div className="mb-20"></div>
                  )}
              </div>
              {showAuthPopUp && (
                <LoginPopUp
                  onSuccess={async () => {
                    await postAuthAddToDish();
                  }}
                  onCancel={() => {
                    setShowAuthPopUp(false);
                  }}
                />
              )}
            </Spin>
          </div>
        </div>
        <Transition.Root
          show={showingPairingId !== null && menuItem !== null}
          as={Fragment}
        >
          <Dialog
            as="div"
            static
            className="fixed inset-0 overflow-hidden"
            open={showingPairingId !== null && menuItem !== null}
            onClose={() => setShowingPairingId(null)}
          >
            <div className="absolute inset-0 overflow-hidden">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={callback}
              >
                <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-y-0 bottom-0 pt-10 max-w-full max-h-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-y-full"
                  enterTo="translate-y-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-y-0"
                  leaveTo="translate-y-full"
                >
                  <div className="w-screen max-w-md">
                    <div className="h-full flex flex-col py-6 bg-gray-100 shadow-xl overflow-y-scroll rounded-md">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-end">
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                              onClick={() => setShowingPairingId(null)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 relative flex-1">
                        {showingPairingId !== null && menuItem !== null && (
                          <MenuDish
                            addAsPairing={true}
                            pairedWith={{
                              menu_item_id: menuItem?.id,
                              is_pairing_option: false,
                            }}
                            editableVersion={
                              store.getState().cart.cart_items === null
                                ? false
                                : store.getState().cart.cart_items.length === 0
                                ? false
                                : store
                                    .getState()
                                    .cart.cart_items.filter(
                                      item =>
                                        item?.menu_item_id ==
                                          showingPairingId && item?.as_pairing,
                                    ).length !== 0
                            }
                            i_ts={
                              store
                                .getState()
                                .cart.cart_items.filter(
                                  item =>
                                    item.menu_item_id == showingPairingId &&
                                    item.as_pairing,
                                ).length !== 0
                                ? store
                                    .getState()
                                    .cart.cart_items.filter(
                                      item =>
                                        item.menu_item_id == showingPairingId &&
                                        item.as_pairing,
                                    )[0].cart_insert_ts
                                : 0
                            }
                            menuItemId={showingPairingId}
                            callback={() => {
                              // setModalSheet(false);
                              setShowingPairingId(null);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={showFileRead} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setShowFileRead}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-2 py-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:mx-32 sm:p-6">
                    <MenuFileView
                      menu={viewingMenu}
                      loading_menu={false}
                      from_menu_item={true}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  },
);

export default MenuDish;
