import React from 'react';
import moment from 'moment-timezone';
import { RestaurantLocation } from '../../../interfaces/restaurant';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { TTOrderV2 } from '../../../interfaces/order';
import { TTUserProfile } from '../../../interfaces/user';
import PillLabel from '../../../components/PillLabel';

type Props = {
  classNameName?: string;
  pickup_time: string;
  location?: RestaurantLocation;
};

export const PickUpOrderInfo: React.FC<Props> = ({ pickup_time, location }) => {
  function getTimeToLocal(time): string {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(tz);
    // @ts-ignore
    const utc_moment = moment.utc(time);
    const local_moment = utc_moment.tz(tz);
    return local_moment.format('h:mm a');
  }
  const order = useSelector<RootState, TTOrderV2 | null>(
    state => state.order.order,
  );

  const guest = useSelector<RootState, TTUserProfile | null>(
    state => state.auth.userProfile,
  );

  return (
    <>
      {/* <div className="flex items-center justify-between space-x-4 p-6">
        <div>
          <div className={'text-lg  text-gray-900'}>Pick-up Order</div>
        </div>
        {location !== null && location !== undefined && (
          <div className="flex space-x-3">
            <button
              type="button"
              className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${location.lat},${location.lng}`,
                );
              }}
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <span>Get Directions</span>
            </button>
          </div>
        )}
      </div> */}
      {order?.order_tabs
        ?.map(tab => tab.order_fulfillments)
        ?.reduce((a, b) => a.concat(b), [])
        ?.filter(fulfillment => fulfillment.guest_id === guest?.id)
        ?.map(fulfillment => {
          return (
            <div
              className="flex flex-col rounded-md bg-white shadow-md mx-6 my-4 px-2 py-1 space-y-2"
              key={fulfillment?.id?.toString()}
            >
              <div className="flex flex-col   ">
                <div className="flex space-x-1 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>

                  <div className="text-sm text-gray-600">Status</div>
                </div>

                <div className="text-md text-gray-900 font-semibold">
                  {fulfillment?.state === 'pending' ? (
                    <PillLabel
                      label="Pending"
                      color="indigo"
                      label_size="text-md"
                    />
                  ) : fulfillment?.state === 'accepted' ? (
                    <PillLabel
                      label="Accepted"
                      color="green"
                      label_size="text-md"
                    />
                  ) : fulfillment?.state === 'ready' ? (
                    <PillLabel
                      label="Ready"
                      color="green"
                      label_size="text-md"
                    />
                  ) : fulfillment?.state === 'completed' ? (
                    <PillLabel
                      label="Completed"
                      color="green"
                      label_size="text-md"
                    />
                  ) : (
                    <PillLabel
                      label="Canceled"
                      color="gray"
                      label_size="text-md"
                    />
                  )}
                </div>
              </div>
              <div className="h-0.5 w-full bg-gray-300 mx-auto" />

              <div className="flex flex-col  ">
                <div className="flex space-x-1 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                    />
                  </svg>

                  <div className="text-sm text-gray-600">Name</div>
                </div>
                <div className="text-md text-gray-900 font-semibold">
                  {guest?.first_name}
                </div>
              </div>
              <div className="h-0.5 w-full bg-gray-300 mx-auto" />

              <div className="flex flex-col  ">
                <div className="flex space-x-1 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                    />
                  </svg>

                  <div className="text-sm text-gray-600">Order Placed</div>
                </div>
                <time className="text-md text-gray-900 font-semibold">
                  {moment
                    .utc(fulfillment?.created_at)
                    .local()
                    .format('MM/DD/YYYY, h:mm a')}
                </time>
              </div>
              <div className="h-0.5 w-full bg-gray-300 mx-auto" />
              <div className="flex flex-col  ">
                <div className="flex space-x-1 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                    />
                  </svg>

                  <div className="text-sm text-gray-600">Comment</div>
                </div>
                <p className="text-md text-gray-900 mb-0 font-semibold">
                  {fulfillment?.pickup_details?.note ?? ''}
                </p>
              </div>
            </div>
          );
        })}
    </>
  );
};
