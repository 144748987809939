import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

type Props = {
  onClick: () => void;
};

const DecreaseCounter: React.FC<Props> = ({ onClick }) => {
  return (
    <>
      <div
        onClick={() => onClick()}
        className="px-2 pb-2 pt-2 min-w-1/4 max-w-1/3"
      >
        <button
          className={` bg-white  "text-white" text-center align-center text-sm font-bold py-2 px-4 rounded-full w-full h-12 shadow-md outline-none focus:outline-none`}
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>
      </div>
    </>
  );
};

export default DecreaseCounter;
