import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripeKey } from '../../../env';
import store from '../../../store';

const usePaymentRequest = ({ options, onPaymentMethod, onCancel, ts }) => {
  let stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  useEffect(() => {
    async function load() {
      stripe = await loadStripe(stripeKey, {
        stripeAccount: store.getState().restaurant.profile.stripe_account_id,
      });
      if (stripe && paymentRequest === null) {
        let modified_options = { ...options };
        modified_options['total'][
          'label'
        ] = store.getState().restaurant.profile.name;
        const pr = stripe.paymentRequest(modified_options);
        setPaymentRequest(pr);
      }
    }
    if (
      store.getState().restaurant.profile !== null &&
      store.getState().restaurant.profile?.stripe_account_id !== null
    ) {
      load();
    }
  }, [stripe, options, paymentRequest]);

  useEffect(() => {
    let subscribed = true;
    if (paymentRequest) {
      paymentRequest.canMakePayment().then(res => {
        if (res && subscribed) {
          setCanMakePayment(true);
        }
      });
    }

    return () => {
      subscribed = false;
    };
  }, [paymentRequest]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('paymentmethod', onPaymentMethod);
      paymentRequest.on('cancel', onCancel);
    }
    return () => {
      if (paymentRequest) {
        paymentRequest.off('paymentmethod', onPaymentMethod);
        paymentRequest.off('cancel', onCancel);
      }
    };
  }, [paymentRequest, onPaymentMethod, onCancel]);

  return canMakePayment ? paymentRequest : null;
};

export default usePaymentRequest;
