import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  RestaurantAddress,
  TTRestaurantProfile,
} from '../../../interfaces/restaurant';
import { GuestAddress, TTUserProfile } from '../../../interfaces/user';
import { RootState } from '../../../rootReducer';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Input from '../../components/Input';
import PillLabel from '../../../components/PillLabel';
import {
  setDeliveryDetails,
  setFulfillmentDetails,
  setSelectedAddress,
  setSTSDetails,
  setTableNumber,
} from '../../../features/cart/cart';
import {
  addNewAddress,
  setFirstName,
  setUserProfile,
} from '../../../features/auth/auth';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { guestApi } from '../../../api/guest';
import { message } from 'antd';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { SingleDatePicker } from 'react-dates';
import isDayVisible, {
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from '../../../utils';
import moment, { Moment } from 'moment';
import {
  OrderFulfillmentDeliveryDetailsCreate,
  OrderFulfillmentHotelDetailsCreate,
  OrderFulfillmentSTSDetailsCreate,
  TTOrderFulfillmentCreate,
} from '../../../interfaces/order';
import { TTOrderItemCreate } from '../../../interfaces/order_item';

type Props = {
  restaurant_id: string;
  show_overlap_attributes: boolean;
};

export function STSOrderDetails({
  restaurant_id,
  show_overlap_attributes = true,
}: Props) {
  const restaurant_profile = useSelector<RootState, TTRestaurantProfile | null>(
    state => state.restaurant.profile,
  );

  const guest = useSelector<RootState, TTUserProfile | null>(
    state => state.auth.userProfile,
  );

  const cart_merchants = useSelector<RootState, number[]>(
    state => state.cart.cart_merchants,
  );
  const order_fulfillment = useSelector<
    RootState,
    { [key: string]: TTOrderFulfillmentCreate }
  >(state => state.cart.fulfillment_details);

  const sts_details = useSelector<RootState, OrderFulfillmentSTSDetailsCreate>(
    state => state.cart.sts_details[restaurant_id] ?? {},
  );

  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [scheduleType, setScheduleType] = useState('asap');
  const cart_items = useSelector<RootState, TTOrderItemCreate[]>(
    state => state.cart.cart_items,
  );
  const [addressValue, setAddressValue] = useState(null);
  const [addingNewAddress, setAddingNewAddress] = useState(true);
  const [comment, setComment] = useState('');
  const selectedAddress = useSelector<RootState, GuestAddress | null>(
    state => state.cart.selected_address,
  );

  const qr_address = useSelector<RootState, RestaurantAddress | null>(
    state => state.restaurant.qr_address,
  );
  const save = async (guest: TTUserProfile, address: RestaurantAddress) => {
    try {
      let orderAddress;
      if (
        guest?.addresses?.length !== 0 &&
        guest?.addresses?.filter(a => a.place_id === address?.place_id)
          ?.length !== 0
      ) {
        orderAddress = guest?.addresses?.filter(
          a => a.place_id === address?.place_id,
        )[0];
      } else {
        const response = await guestApi.saveAddress(guest?.id, {
          address_line_1: address?.address_line_1,
          city: address?.city,
          address_line_2: address?.address_line_2,
          administrative_area_level_1: address?.administrative_area_level_1,
          place_id: address?.place_id,
          zip_postal: address?.zip_postal,
          guest_id: guest?.id,
          country: address?.country,
        });
        orderAddress = response.data;
        dispatch(addNewAddress(orderAddress));
      }

      if (order_fulfillment[restaurant_id]) {
        dispatch(
          setFulfillmentDetails({
            [restaurant_id]: {
              ...order_fulfillment[restaurant_id],
              guest_address_id: orderAddress?.id,
            },
          }),
        );
      } else {
        dispatch(
          setFulfillmentDetails({
            [restaurant_id]: {
              guest_id: guest?.id,
              type: 'sts',
              order_item_application: 'all_items',
              guest_address_id: orderAddress?.id,
            },
          }),
        );
      }

      dispatch(setSelectedAddress(orderAddress));
      setAddingNewAddress(false);
    } catch (e) {
      setAddingNewAddress(true);
    }
  };

  useEffect(() => {
    if (guest !== null) {
      if (qr_address !== null) {
        save(guest, qr_address);
      } else if (guest?.addresses?.length != 0) {
        setAddingNewAddress(false);
      }

      if (guest?.first_name !== null) {
        setName(guest.first_name);
        dispatch(setTableNumber(`H_${guest?.first_name?.split(' ')[0]}`));
      }
      if (order_fulfillment[restaurant_id] === undefined) {
        dispatch(
          setFulfillmentDetails({
            [restaurant_id]: {
              guest_id: guest?.id,
              type: 'sts',
              order_item_application: 'all_items',
            },
          }),
        );
      } else {
        dispatch(
          setFulfillmentDetails({
            [restaurant_id]: {
              ...order_fulfillment[restaurant_id],
              type: 'sts',
              pickup_details: null,
              catering_details: null,
              delivery_details: null,
              hotel_details: null,
              guest_id: guest?.id,
            },
          }),
        );
      }
      if (sts_details === undefined) {
        dispatch(
          setSTSDetails({
            [restaurant_id]: {
              guest_id: guest?.id,
              schedule_type: 'asap',
            },
          }),
        );
      } else {
        if (sts_details !== undefined) {
          dispatch(
            setFulfillmentDetails({
              [restaurant_id]: {
                ...order_fulfillment[restaurant_id],
                type: 'sts',

                sts_details: {
                  ...sts_details,
                  schedule_type:
                    cart_items?.filter(
                      t =>
                        t.restaurant_id?.toString() === restaurant_id &&
                        t?.is_scheduled,
                    )?.length !== 0
                      ? 'scheduled'
                      : 'asap',
                  scheduled_for:
                    cart_items?.filter(
                      t =>
                        t.restaurant_id?.toString() === restaurant_id &&
                        t?.is_scheduled,
                    )?.length !== 0
                      ? sts_details?.scheduled_for
                      : null,
                },
                hotel_details: null,
                pickup_details: null,
                catering_details: null,
                delivery_details: null,
                guest_id: guest?.id,
              },
            }),
          );
        }
      }
    }
  }, [guest?.id]);

  return (
    <>
      {restaurant_profile !== null && (
        <div className="mx-4 my-2">
          <fieldset>
            <>
              {show_overlap_attributes && (
                <>
                  <div className={'flex flex-col'}>
                    <div className="flex flex-row justify-left content-center items-start mb-2 mt-4">
                      <label className=" block uppercase tracking-wide text-gray-700 text-xs font-bold">
                        Name
                      </label>
                      <div className="text-gray-900 items-start text-sm text-right -mt-1 ml-2">
                        <PillLabel color="red" label="Required" />
                      </div>
                    </div>
                  </div>
                  <div className="py-2   sm:h-1/5 max-h-1/5 sm:flex rounded">
                    <div className="p-4 flex felx-row w-full rounded-lg shadow-lg justify-between bg-white">
                      <Input
                        // @ts-ignore
                        handleChange={e => {
                          setName(e.target.value);
                        }}
                        handleBlur={async e => {
                          // dispatch(setPickUpName(e.target.value));
                          dispatch(setFirstName(e.target.value?.split(' ')[0]));
                          dispatch(
                            setTableNumber(
                              `STS_${e.target.value?.split(' ')[0]}`,
                            ),
                          );

                          try {
                            const guestNameResponse =
                              await guestApi.updateGuest(
                                { first_name: e.target.value?.split(' ')[0] },
                                guest?.id,
                              );
                            dispatch(setUserProfile(guestNameResponse.data));
                          } catch (e) {}
                        }}
                        name="name"
                        value={name}
                        className={
                          'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                        }
                        autoComplete="off"
                        placeholder=""
                      />
                    </div>
                  </div>
                </>
              )}
              {cart_items?.filter(
                t =>
                  t.restaurant_id?.toString() === restaurant_id &&
                  t?.is_scheduled &&
                  t?.product_type !== 'booking',
              )?.length !== 0 && (
                <>
                  <div className="flex flex-row justify-left content-center items-start mb-2 mt-4">
                    <legend className=" block uppercase tracking-wide text-gray-700 text-xs font-bold">
                      Schedule Date & Time for{' '}
                      <span className="text-blue-600 italic">
                        {cart_items
                          ?.filter(
                            t =>
                              t.restaurant_id?.toString() === restaurant_id &&
                              t?.is_scheduled,
                          )
                          ?.map(t => t.name)
                          ?.join(', ')}
                      </span>
                    </legend>
                    <div className="text-gray-900 items-start text-sm text-right -mt-1 ml-2">
                      <PillLabel color="red" label="Required" />
                    </div>
                  </div>
                  <STSSchedulingPicker
                    show_overlap_attributes={false}
                    restaurant_id={restaurant_id}
                  />
                </>
              )}

              {cart_items?.filter(
                t =>
                  (t.product_type !== 'booking' || t?.is_always_delivered) &&
                  t.restaurant_id?.toString() === restaurant_id,
              )?.length !== 0 && (
                <>
                  <div className="flex flex-row justify-left content-center items-start mb-2 mt-4">
                    <legend className=" block uppercase tracking-wide text-gray-700 text-xs font-bold">
                      Stay Address
                    </legend>
                    <div className="text-gray-900 items-start text-sm text-right -mt-1 ml-2">
                      <PillLabel color="red" label="Required" />
                    </div>
                  </div>
                  <div className="bg-white rounded-md my-2 shadow-md px-4 py-2">
                    {guest !== null && (
                      <div className="mt-1 -space-y-px rounded-md bg-white shadow-sm">
                        {addingNewAddress ? (
                          <div className="flex flex-col space-y-4">
                            <label htmlFor="card-number" className="sr-only">
                              Street Address
                            </label>
                            <GooglePlacesAutocomplete
                              apiKey="AIzaSyBCZTG_ZIce0-q8JgRU_EOSpv3vbcFVvwQ"
                              selectProps={{
                                addressValue,
                                onChange: async value => {
                                  const response = await geocodeByPlaceId(
                                    value['value']['place_id'],
                                  );
                                  try {
                                    const addressRequest: GuestAddress = {
                                      guest_id: guest?.id,
                                      address_line_1:
                                        response[0]?.address_components[0]
                                          .long_name +
                                        ' ' +
                                        response[0]?.address_components[1]
                                          .long_name,
                                      city: response[0]?.address_components?.filter(
                                        a => a.types.includes('locality'),
                                      )[0].long_name,
                                      administrative_area_level_1:
                                        response[0]?.address_components.filter(
                                          a =>
                                            a.types.includes(
                                              'administrative_area_level_1',
                                            ),
                                        )[0].long_name,
                                      country:
                                        response[0]?.address_components.filter(
                                          a => a.types.includes('country'),
                                        )[0].short_name,
                                      zip_postal:
                                        response[0]?.address_components.filter(
                                          a => a.types.includes('postal_code'),
                                        )[0].short_name,
                                      place_id: response[0].place_id,
                                    };
                                    const addressSavedResponse =
                                      await guestApi.saveAddress(
                                        guest?.id,
                                        addressRequest,
                                      );
                                    dispatch(
                                      setFulfillmentDetails({
                                        [restaurant_id]: {
                                          ...order_fulfillment[restaurant_id],
                                          guest_address_id:
                                            addressSavedResponse.data?.id,
                                        },
                                      }),
                                    );
                                    dispatch(
                                      addNewAddress(addressSavedResponse.data),
                                    );
                                    dispatch(
                                      setSelectedAddress(
                                        addressSavedResponse.data,
                                      ),
                                    );

                                    setAddressValue(value);
                                    setAddingNewAddress(false);
                                  } catch (e) {
                                    message.error('Unable to save address');
                                  }
                                },
                              }}
                              onLoadFailed={error =>
                                console.error(
                                  'Could not inject Google Maps script',
                                  error,
                                )
                              }
                              minLengthAutocomplete={2}
                              autocompletionRequest={{
                                componentRestrictions: { country: 'us' },
                              }}
                            />
                            {guest?.addresses?.length !== 0 && (
                              <div
                                className="flex flex-row bg-gray-500 text-white font-semibold rounded-md text-center justify-center py-2"
                                onClick={() => {
                                  setAddingNewAddress(false);
                                }}
                              >
                                Cancel
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="flex flex-col space-y-4">
                            {guest?.addresses?.map(a => {
                              return (
                                <div
                                  key={a?.id?.toString()}
                                  className={`flex flex-row rounded-md bg-white px-2 py-2 ${
                                    selectedAddress?.id === a?.id
                                      ? 'border-green-500 border-2'
                                      : 'border-gray-500 border-2'
                                  }`}
                                  onClick={() => {
                                    dispatch(setSelectedAddress(a));
                                    dispatch(
                                      setFulfillmentDetails({
                                        [restaurant_id]: {
                                          ...order_fulfillment[restaurant_id],
                                          guest_address_id: a?.id,
                                        },
                                      }),
                                    );
                                  }}
                                >
                                  {selectedAddress?.id === a?.id && (
                                    <div className="text-green-600 w-6 mt-1 mr-2">
                                      <CheckCircleIcon color="green-600" />
                                    </div>
                                  )}
                                  <div className="line-clamp-2 text-base font-medium text-gray-900">
                                    {a?.address_line_1?.concat(
                                      ', ',
                                      a?.city,
                                      ', ',
                                      a?.administrative_area_level_1,
                                      ', ',
                                      a?.country,
                                      ', ',
                                      a?.zip_postal,
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                            <div
                              className="flex flex-row bg-blue-500 text-white font-semibold rounded-md text-center justify-center py-2"
                              onClick={() => {
                                setAddingNewAddress(true);
                              }}
                            >
                              Add a new address
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* <div className={'h-0.5 w-8/10 bg-gray-900 my-4 mx-auto'} /> */}
              {show_overlap_attributes && (
                <>
                  <div className="flex flex-row justify-left content-center items-start mb-2 mt-4">
                    <legend className=" block uppercase tracking-wide text-gray-700 text-xs font-bold">
                      Comments
                    </legend>
                  </div>
                  <div className="mt-1">
                    <textarea
                      rows={2}
                      name="comment"
                      id="comment"
                      onChange={e => {
                        setComment(e?.target?.value);
                        dispatch(
                          setSTSDetails({
                            [restaurant_id]: {
                              ...sts_details,
                              note: e.target.value,
                            },
                          }),
                        );
                        dispatch(
                          setFulfillmentDetails({
                            [restaurant_id]: {
                              ...order_fulfillment[restaurant_id],
                              sts_details: {
                                ...sts_details,
                                note: e.target.value,
                              },
                            },
                          }),
                        );
                        cart_merchants
                          ?.filter(c => c.toString() !== restaurant_id)
                          ?.map(i => {
                            dispatch(
                              setSTSDetails({
                                [i]: {
                                  ...sts_details,
                                  note: e.target.value,
                                },
                              }),
                            );
                            dispatch(
                              setFulfillmentDetails({
                                [i]: {
                                  ...order_fulfillment[i],
                                  sts_details: {
                                    ...sts_details,
                                    note: e.target.value,
                                  },
                                },
                              }),
                            );
                          });
                      }}
                      value={
                        comment?.length !== 0
                          ? comment
                          : sts_details?.note ?? ''
                      }
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 ring-none sm:text-sm"
                    />
                  </div>
                </>
              )}
            </>
          </fieldset>
        </div>
      )}
    </>
  );
}

export function STSSchedulingPicker({ restaurant_id }: Props) {
  const [deliveryDate, setDeliveryDate] = useState<Moment>();
  const [focused, setFocused] = useState(false);
  const onFocusChange = ({ focused }) => {
    setFocused(focused);
  };
  const sts_details = useSelector<RootState, OrderFulfillmentSTSDetailsCreate>(
    state => state.cart.sts_details[restaurant_id],
  );

  const [deliveryTime, setDeliveryTime] = useState('');
  const dispatch = useDispatch();
  useMemo(() => {
    if (deliveryDate && deliveryTime?.length !== 0) {
      const time_moment = moment(deliveryTime, 'HH:mm');
      const combined_moment = deliveryDate.set({
        hour: time_moment.hour(),
        minute: time_moment.minute(),
        second: time_moment.second(),
      });
      dispatch(
        setSTSDetails({
          [restaurant_id]: {
            ...sts_details,
            schedule_type: 'scheduled',
            scheduled_for: combined_moment.toISOString(),
          },
        }),
      );
    }
  }, [deliveryDate, deliveryTime]);

  useEffect(() => {
    // if (catering_details && catering_details.scheduled_for) {
    //   const combined_moment = moment(catering_details.scheduled_for);
    //   setDeliveryDate(combined_moment);
    //   console.log(combined_moment.format('HH:mm'));
    //   setDeliveryTime(combined_moment.format('HH:mm'));
    // }
  }, []);
  return (
    <div className="bg-white rounded-md my-2 shadow-md px-4 py-2 flex flex-row items-center justify-between">
      <div>
        <div className="flex flex-row justify-left content-center items-start mb-2">
          <label
            className=" block uppercase tracking-wide text-gray-700 text-xs font-bold"
            htmlFor="delivery_date_input"
          >
            Date
          </label>
        </div>
        <SingleDatePicker
          date={deliveryDate}
          enableOutsideDays={false}
          keepFocusOnInput={false}
          onDateChange={date => {
            setDeliveryDate(date);
            //   setFocused(false);
          }}
          keepOpenOnDateSelect={false}
          id={'delivery_date_input'}
          isOutsideRange={day => {
            const minDate = moment();
            const maxDate = moment().add(1, 'months').endOf('month');
            return (
              isInclusivelyBeforeDay(day, minDate) ||
              isInclusivelyAfterDay(day, maxDate)
            );
          }}
          focused={focused}
          numberOfMonths={1}
          withPortal={false}
          onFocusChange={onFocusChange}
        ></SingleDatePicker>
      </div>
      <div className="mb-2">
        <div className="flex flex-row justify-left content-center items-start mb-2">
          <label
            className=" block uppercase tracking-wide text-gray-700 text-xs font-bold"
            htmlFor="delivery_time_input"
          >
            Time
          </label>
        </div>
        <input
          type="time"
          id="delivery_time_input"
          name="delivery_time_input"
          min="09:00"
          max="21:00"
          step="900"
          required
          onChange={e => {
            e.preventDefault();
            setDeliveryTime(e.target.value);
          }}
          value={deliveryTime}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-600 focus:ring-0 sm:text-base"
        />
      </div>
    </div>
  );
}
