import React, { memo } from 'react';

function Input({
  handleChange,
  handleBlur,
  name,
  value,
  className,
  autoComplete,
  placeholder,
  type,
}) {
  return (
    <input
      onChange={handleChange}
      onBlur={handleBlur}
      name={name}
      value={value}
      className={className}
      autoComplete={autoComplete}
      placeholder={placeholder}
      type={type}
    />
  );
}

function areEqual(prevProps, nextProps) {
  //   console.log(`
  //     prevProps: ${JSON.stringify(prevProps.value)}
  //     nextProps: ${JSON.stringify(nextProps.value)}
  //   `);
  return (
    prevProps.value === nextProps.value &&
    prevProps.handleChange === nextProps.handleChange
  );
}
const useMemo = (component, propsAreEqual) => {
  return memo(component, propsAreEqual);
};
export default useMemo(Input, areEqual);
