import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import store, { AppThunk } from '../../store';
import { ordersApi } from '../../api/order';
import { TTOrder, TTOrderV2 } from '../../interfaces/order';
import { TTCheck } from '../../interfaces/check';
import { orderItemsApi } from '../../api/order_items';
import * as Sentry from '@sentry/react';
import {
  clearCart,
  removePromotion,
  setExistingOrder,
  updateTableNumber,
} from '../cart/cart';
import {
  RestaurantAddress,
  TTRestaurantProfile,
} from '../../interfaces/restaurant';
import { restaurantApi } from '../../api/restaurant';

/* eslint-disable */
interface OrderState {
  order_id: number | null;
  firebase_order_id: string | null;
  pending_updates: boolean | null;
  order: TTOrderV2 | null;
  table_number: string | null;
  payment_intent: { [x: string]: number } | null;
  guest_check: TTCheck | null;
  order_tip_percentage: number | null;
  active: boolean;
  requestTableNumVerification: boolean;
  requestBookingConfirmation: boolean;
  order_restaurants: TTRestaurantProfile[];
  order_primary_restaurant: TTRestaurantProfile | null;
  order_restaurant_addresses: RestaurantAddress[];
}

const initialState: OrderState = {
  order_id: parseInt(localStorage.getItem('__tt_order_id')) ?? null,
  firebase_order_id: localStorage.getItem('__tt_fs_order_id'),
  pending_updates: null,
  order: null,
  table_number: localStorage.getItem('__tt_t_n') ?? '',
  payment_intent: JSON.parse(localStorage.getItem('__tt_pi')) ?? null,
  guest_check: null,
  order_tip_percentage: parseInt(localStorage.getItem('__tt_tip')) ?? 22,
  active: false,
  requestTableNumVerification: false,
  requestBookingConfirmation: false,
  order_restaurants: [],
  order_primary_restaurant: null,
  order_restaurant_addresses: [],
};

const order = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderId(state, action: PayloadAction<number>) {
      state.order_id = action.payload;
      localStorage.setItem('__tt_order_id', action.payload.toString());
    },
    setFirebaseOrderId(state, action: PayloadAction<string>) {
      state.firebase_order_id = action.payload;
      localStorage.setItem('__tt_fs_order_id', action.payload);
    },
    setOrder(state, action: PayloadAction<TTOrderV2>) {
      state.order = action.payload;
      state.order_id = action.payload.id;
      localStorage.setItem('__tt_order_id', state.order_id.toString());
      localStorage.setItem('__tt_fs_order_id', state.firebase_order_id);
      localStorage.setItem('__tt_order', JSON.stringify(action.payload));
    },
    setTableNumber(state, action: PayloadAction<string>) {
      state.table_number = action.payload;
      localStorage.setItem('__tt_t_n', action.payload);
    },
    setOrderPaymentIntent(
      state,
      action: PayloadAction<{ [x: string]: number }>,
    ) {
      state.payment_intent = action.payload;
      localStorage.setItem('__tt_pi', JSON.stringify(action.payload));
    },
    closeOrder(state, action?: PayloadAction<boolean>) {
      state.order_id = null;
      state.order = null;
      state.firebase_order_id = null;
      state.payment_intent = null;
      state.guest_check = null;
      state.active = false;
      localStorage.removeItem('__tt_pi');
      localStorage.removeItem('__tt_fs_order_id');
      localStorage.removeItem('__tt_order_id');
      localStorage.removeItem('__tt_guest_check');
      localStorage.removeItem('__tt_order');
      localStorage.removeItem('__tt_t_n');
      localStorage.removeItem('__tt_promotion');
      localStorage.removeItem('__tt_promotion_event');
      localStorage.removeItem('__tt_dining_mode');
      localStorage.removeItem('__tt_qr_menu_ids');
      localStorage.removeItem('__tt_qr');
    },
    setPendingUpdate(state, action: PayloadAction<boolean>) {
      state.pending_updates = action.payload;
    },
    setGuestCheck(state, action: PayloadAction<TTCheck>) {
      state.guest_check = action.payload;
      console.log(action.payload);
      localStorage.setItem('__tt_guest_check', JSON.stringify(action.payload));
    },
    setOrderTipPercentage(state, action: PayloadAction<number>) {
      state.order_tip_percentage = action.payload;
      localStorage.setItem('__tt_tip', JSON.stringify(action.payload));
    },
    setActive(state, action: PayloadAction<boolean>) {
      state.active = action.payload;
    },
    resetUpdatedCheck(state, action: PayloadAction) {
      localStorage.removeItem('__tt_updated_check');
    },
    setRequestTableNumberVerification(state, action: PayloadAction<boolean>) {
      state.requestTableNumVerification = action.payload;
    },
    setRequestBookingConfirmation(state, action: PayloadAction<boolean>) {
      state.requestBookingConfirmation = action.payload;
    },
    setOrderRestaurants(state, action: PayloadAction<TTRestaurantProfile[]>) {
      state.order_restaurants = action.payload;
    },
    setOrderRestaurantAddresses(
      state,
      action: PayloadAction<RestaurantAddress[]>,
    ) {
      state.order_restaurant_addresses = action.payload;
    },
    setOrderPrimaryRestaurant(
      state,
      action: PayloadAction<TTRestaurantProfile>,
    ) {
      state.order_primary_restaurant = action.payload;
    },
  },
});

export const {
  setOrderId,
  setFirebaseOrderId,
  setOrder,
  setOrderPaymentIntent,
  closeOrder,
  setPendingUpdate,
  setGuestCheck,
  setOrderTipPercentage,
  setActive,
  resetUpdatedCheck,
  setRequestTableNumberVerification,
  setRequestBookingConfirmation,
  setOrderRestaurants,
  setOrderRestaurantAddresses,
  setOrderPrimaryRestaurant,
} = order.actions;

export default order.reducer;

export const fetchOrder =
  (order_id: number, redirectTrigger: Function): AppThunk =>
  async dispatch => {
    try {
      if (isNaN(order_id)) {
        redirectTrigger && redirectTrigger();
        return;
      }
      const response = await ordersApi.getOrder(order_id);
      // dispatch(fetchOrderRestaurants(response.data));
      if (!response.data.is_open) {
        dispatch(closeOrder());
        redirectTrigger(false);
      } else {
        if (
          !response.data?.is_single_session &&
          response.data.is_open &&
          response.data.qr_code == store.getState().cart.qr_code
        ) {
          dispatch(setExistingOrder(response.data));
        } else {
          dispatch(setExistingOrder(null));
        }
        if (
          !store
            .getState()
            .restaurant.profile?.table_numbers?.includes(
              response.data?.table_number,
            ) &&
          response.data?.order_type === 'dine_in'
        ) {
          dispatch(setRequestTableNumberVerification(true));
        }
        if (
          response.data?.order_tabs
            ?.map(t => t.order_items)
            ?.reduce((a, b) => a.concat(b), [])
            ?.filter(
              i =>
                i.product_type === 'booking' &&
                i?.booking_info?.status === 'pending_guest_accept',
            )?.length !== 0
        ) {
          dispatch(setRequestBookingConfirmation(true));
        }
        dispatch(
          createGuestCheck(response.data, () => {
            dispatch(setOrder(response.data));
            dispatch(setActive(true));
            dispatch(updateTableNumber(response.data.table_number));
            redirectTrigger(true);
          }),
        );
      }
    } catch (err) {
      console.log(err.response);
      Sentry.captureException(err);
      redirectTrigger(false);
    }
  };

export const fetchOrderRestaurants =
  (order: TTOrderV2): AppThunk =>
  async dispatch => {
    try {
      const responses = await Promise.all(
        order?.restaurant_ids?.map(i => {
          return restaurantApi.getProfile(i);
        }),
      );
      const addresses = await Promise.all(
        order?.restaurant_ids?.map(i => {
          return restaurantApi.getAddresses(i);
        }),
      );
      dispatch(setOrderRestaurants(responses?.map(i => i.data)));
      dispatch(
        setOrderRestaurantAddresses(
          addresses.map(i => i.data).reduce((a, b) => a.concat(b), []),
        ),
      );
    } catch (e) {}
  };

export const fetchCurrentOpenOrder = (): AppThunk => async dispatch => {
  try {
    const response = await ordersApi.getCurrentlyOpenedOrder();
    if (response.data !== null) {
      if (!response.data?.is_open) {
        dispatch(closeOrder());
        dispatch(removePromotion());
      } else {
        dispatch(
          createGuestCheck(response.data, () => {
            dispatch(setOrder(response.data));
            dispatch(updateTableNumber(response.data.table_number));
            dispatch(setActive(true));
          }),
        );
      }
    } else {
      dispatch(closeOrder());
      dispatch(removePromotion());
    }
  } catch (err) {
    console.log(err.response);
    Sentry.captureException(err);
  }
};

export const createGuestCheck =
  (order: TTOrderV2, redirect: Function): AppThunk =>
  async dispatch => {
    try {
      const tip_percent = isNaN(store.getState().order.order_tip_percentage)
        ? parseInt(store.getState().auth.userProfile.tip_default)
        : store.getState().order.order_tip_percentage;

      const tax_rate = store.getState().restaurant.profile.tax_rate ?? 7500;

      const promotion = store.getState().cart.promotion;
      let restaurant_with_items = new Set<number>();
      order?.order_tabs
        ?.map(t => t.order_items)
        ?.reduce((a, b) => a.concat(b), [])
        ?.map(i => {
          restaurant_with_items.add(i.restaurant_id);
        });
      let discount = 0;
      const order_checks = order.order_tabs
        .map(t => t.checks)
        .reduce((a, b) => a.concat(b), []);
      const guest_checks = order_checks.filter(
        c => c.guest_id == store.getState().auth.guest_id,
      );
      const guest_tabs = order.order_tabs.filter(t =>
        t.guest_ids.includes(store.getState().auth.guest_id),
      );

      if (promotion != null) {
        discount =
          promotion.promotion_value.type == 'PERCENT'
            ? Math.round(
                (promotion.promotion_value.value / 100) *
                  (guest_checks
                    .map(a => a.subtotal)
                    .reduce((a, b) => a + b, 0) /
                    100) *
                  100,
              )
            : promotion.promotion_value.value;
      }

      const restaurant_service_charges = await Promise.all(
        Array.from(restaurant_with_items.values())?.map(i => {
          return ordersApi.getRestaurantServiceFees(
            guest_checks
              .filter(c => c.restaurant_id === i)
              .map(a => a.subtotal)
              .reduce((a, b) => a + b, 0) - discount,
            i,
            order?.order_type,
          );
        }),
      );

      const guest_check: TTCheck = {
        sub_total: guest_checks.map(a => a.subtotal).reduce((a, b) => a + b, 0),
        tip:
          guest_checks.map(a => a.tip).reduce((a, b) => a + b, 0) +
          guest_checks
            .map(a => a.primary_vendor_tip_take)
            .reduce((a, b) => a + b),
        tax: guest_checks.map(a => a.tax).reduce((a, b) => a + b, 0),
        total:
          guest_checks.map(a => a.total).reduce((a, b) => a + b, 0) +
          guest_checks
            .map(a => a.primary_vendor_tip_take)
            .reduce((a, b) => a + b, 0),
        num_items: guest_tabs
          .map(a => a.order_items)
          .reduce((a, b) => a.concat(b), [])
          .filter(item => {
            return item.guest_id == store.getState().auth.guest_id;
          }).length,
        tabletab_discount: 0,
        restaurant_discount: guest_checks
          .map(a => a.discount)
          .reduce((a, b) => a + b),
        service_fee:
          guest_checks
            .map(a => a.guest_service_fee)
            .reduce((a, b) => a + b, 0) ?? 100,
        restaurant_service_charges: restaurant_service_charges
          ?.map(c => c.data)
          ?.reduce((a, b) => a.concat(b), []),
      };

      dispatch(setGuestCheck(guest_check));
      redirect();
    } catch (err) {
      Sentry.captureException(err);
    }
  };

export const attemptCloseOrder =
  (redirect: Function): AppThunk =>
  async dispatch => {
    try {
      dispatch(closeOrder());
      dispatch(removePromotion());
      redirect();
    } catch (err) {}
  };
