import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GuestAddress, TTUserProfile } from '../../interfaces/user';
import store, { AppThunk } from '../../store';
import { guestApi } from '../../api/guest';
import { osName, osVersion } from 'react-device-detect';
import { getAuth, User } from 'firebase/auth';
import { setStripeId } from '../payment/payment';
import { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import { ZendeskAPI } from 'react-zendesk';
import { fetchCurrentOpenOrder } from '../order/order';
import { TTOrderV2 } from '../../interfaces/order';
/* eslint-disable */
interface AuthState {
  token: string | null;
  isLoggedIn: boolean | null;
  logInError: string | null;
  userProfile: TTUserProfile | null;
  guest_id: number | null;
  during_login: boolean;
  guest_orders: TTOrderV2[];
  viewing_order: TTOrderV2 | null;
}

const initialState: AuthState = {
  token: localStorage.getItem('tabletabToken'),
  isLoggedIn:
    localStorage.getItem('tabletabIsLoggedIn') === 'true' &&
    parseInt(localStorage.getItem('__tt_guest_id')) !== undefined,
  logInError: null,
  userProfile: null,
  guest_id: parseInt(localStorage.getItem('__tt_guest_id')) ?? null,
  during_login: false,
  guest_orders: [],
  viewing_order: null,
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<string>) {
      state.token = action.payload;
      state.isLoggedIn = true;
    },
    loginFailure(state, action: PayloadAction<string>) {
      state.isLoggedIn = false;
      state.logInError = action.payload;
    },
    setUserProfile(state, action: PayloadAction<TTUserProfile>) {
      if (action.payload.id !== null) {
        state.userProfile = action.payload;
        state.guest_id = action.payload.id;
        state.isLoggedIn = true;
        localStorage.setItem('__tt_guest_id', action.payload.id.toString());
      }
    },
    logout(state) {
      state.isLoggedIn = false;
      state.logInError = null;
      state.userProfile = null;
      state.token = null;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.userProfile.email = action.payload;
    },
    setDuringLogin(state, action: PayloadAction<boolean>) {
      state.during_login = action.payload;
    },
    setFirstName(state, action: PayloadAction<string>) {
      state.userProfile.first_name = action.payload;
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
      state.isLoggedIn = true;
    },
    addNewAddress(state, action: PayloadAction<GuestAddress>) {
      if (state.userProfile?.addresses !== null) {
        state.userProfile.addresses = state.userProfile.addresses?.concat([
          action.payload,
        ]);
      } else {
        state.userProfile.addresses = [action.payload];
      }
    },
    setGuestOrders(state, action: PayloadAction<TTOrderV2[]>) {
      state.guest_orders = action.payload;
    },
    addGuestOrders(state, action: PayloadAction<TTOrderV2[]>) {
      state.guest_orders = state.guest_orders.concat(action.payload);
    },
    setViewingOrder(state, action: PayloadAction<TTOrderV2>) {
      state.viewing_order = action.payload;
    },
  },
});

export const {
  loginSuccess,
  loginFailure,
  logout,
  setUserProfile,
  setEmail,
  setDuringLogin,
  setFirstName,
  setToken,
  addNewAddress,
  setGuestOrders,
  setViewingOrder,
  addGuestOrders,
} = auth.actions;

export default auth.reducer;

export const attemptLogin =
  (
    firebase_token: string,
    phone_number: string,
    user: User,
    redirectTrigger: Function,
  ): AppThunk =>
  async dispatch => {
    try {
      const response = await guestApi.getGuestByFirebaseIdToken(
        user.uid,
        firebase_token,
      );
      localStorage.setItem('tabletabToken', firebase_token);
      localStorage.setItem('tabletabIsLoggedIn', 'true');
      dispatch(loginSuccess(firebase_token));
      dispatch(setUserProfile(response.data));
      dispatch(setStripeId(response.data.stripe_id));
      redirectTrigger(true);
    } catch (err) {
      try {
        const [guestResponse] = await Promise.all([
          guestApi.createGuest({
            phone_number: phone_number,
            os_type: osName + '_' + osVersion,
            firebase_id: user.uid,
            tip_default: '22',
          }),
        ]);
        localStorage.setItem('tabletabToken', firebase_token);
        localStorage.setItem('tabletabIsLoggedIn', 'true');
        dispatch(loginSuccess(firebase_token));
        dispatch(setUserProfile(guestResponse.data));
        dispatch(setStripeId(guestResponse.data.stripe_id));
        redirectTrigger(true);
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
        dispatch(loginFailure(err.toString()));
        redirectTrigger(false);
      }
    }
  };

export const attemptLogout =
  (redirectTrigger: Function): AppThunk =>
  async dispatch => {
    try {
      localStorage.clear();
      dispatch(logout());
      redirectTrigger();
    } catch (err) {
      console.log(':(');
    }
  };

export const getCurrentGuestProfile =
  (redirectTrigger?: Function, fetchOpenOrder: boolean = false): AppThunk =>
  async dispatch => {
    try {
      let response: AxiosResponse<TTUserProfile>;
      const token = localStorage.getItem('tabletabToken');
      const isLoggedIn = localStorage.getItem('tabletabIsLoggedIn');
      if (token && isLoggedIn === 'true') {
        response = await guestApi.getMe();
        dispatch(setUserProfile(response.data));
        ZendeskAPI('webWidget', 'identify', {
          name: response.data.phone_number,
        });
        dispatch(setStripeId(response.data.stripe_id));
        if (fetchOpenOrder) {
          dispatch(fetchCurrentOpenOrder());
        }
        if (redirectTrigger !== undefined) {
          redirectTrigger(true);
        }
      } else {
        if (redirectTrigger !== undefined) {
          redirectTrigger(true);
        }
        return;
      }
    } catch (err) {
      Sentry.captureException(err?.response?.data?.detail ?? err);
      dispatch(logout());
      if (redirectTrigger !== undefined) {
        redirectTrigger(false);
      }
      console.log('no guest found');
    }
  };

export async function getCurrentPhone() {
  return getAuth().currentUser.phoneNumber;
}
