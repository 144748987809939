import React, { useEffect } from 'react';
import PillLabel from '../components/PillLabel';

type Props = {
  onCancel: () => void;
  restaurant_id: number;
  restaurant_name: string;
  message: string;
};

export const PopUpServiceMessage: React.FC<Props> = ({
  onCancel,
  restaurant_id,
  restaurant_name,
  message,
}) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 text-center  mt-10">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => {
            localStorage.setItem(
              `__${restaurant_id}_service_message_shown`,
              'true',
            );
            onCancel();
          }}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="bottom-0 fixed inline-block align-bottom bg-white rounded-lg max-h-full px-4 pt-5 pb-4 -mb-20 pb-32 text-left overflow-y-scroll  transform transition-all sm:my-8 sm:align-middle w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <svg
            className={'text-gray-500 w-8 h-8 top-0 right-0 m-1 fixed'}
            onClick={() => {
              localStorage.setItem(
                `__${restaurant_id}_service_message_shown`,
                'true',
              );
              onCancel();
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div className="align-bottom mb-10 bg-white rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle mx-4 mt-4">
            <div className="bg-white p-4">
              <div className="sm:flex sm:items-start sm:flex-row ">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center">
                  <span
                    className={`px-3 py-1 inline-flex text-lg leading-5  rounded-full bg-red-100 text-red-700`}
                  >
                    {`Message from ${restaurant_name}`}
                  </span>
                </div>
                <div className="mt-5 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="mt-2">
                    <p className="text-lg leading-5 text-gray-800">{message}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <button
              onClick={onCancel}
              id={'dismiss-walkthrough-btn'}
              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 text-base sm:text-lg font-medium text-white hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
