import React, { useState, useRef, useEffect, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { TTMenu } from '../../../interfaces/menus';
import { menuApi } from '../../../api/menu';
import { Spin } from 'antd';

import MenuCard from './components/MenuCard';
import store from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRestaurant,
  setMenu,
  setMenus,
} from '../../../features/restaurant/restaurant';
import { LoadingIcon } from '../../components/Spinner';
import CartBanner from '../../Cart/components/CartBanner';
import OrderBanner from '../../Order/components/OrderBanner';
import { isMenuAvailable } from '../../utils';
import { getAnalytics, logEvent } from 'firebase/analytics';
import ServiceMessageCard from './components/ServiceMessage';
import RestaurantNameCard from './components/RestaurantNameCard';
import { ZendeskAPI } from 'react-zendesk';
import { WalkthroughCTACard } from '../../WalkthroughCTACard';
import { WalkthroughPopUp } from '../../WalkthroughPopUp';
import { RootState } from '../../../rootReducer';
import { TTOrderV2 } from '../../../interfaces/order';
import { PopUpServiceMessage } from '../../PopUpServiceMessage';
import TableTabBanner from '../../components/TableTabBanner';
import { TTRestaurantProfile } from '../../../interfaces/restaurant';
import { CategorizedMenuView } from './components/CategorizedView';
import { FeaturedProducts } from './components/FeaturedProducts';

const MenusList: React.FC = () => {
  // @ts-ignore
  const { m_id, r_id } = useParams();
  const menus = useSelector<RootState, TTMenu[]>(
    state => state.restaurant.current_menus,
  );
  const [showMenu, setShowMenu] = useState(false);
  const qr_code_menu_ids = useSelector<RootState, { [key: string]: number[] }>(
    state => state.cart.qr_code_menu_ids,
  );
  const qr = useSelector<RootState, string>(state => state.cart.qr_code);
  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const [loading, setLoading] = useState(false);
  const order = useSelector<RootState, TTOrderV2>(state => state.order.order);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [walkthroughShown, setWalkthroughShown] = useState(
    localStorage.getItem('_tt_walkthrough') ?? 'false',
  );
  const [serviceMessageShown, setserviceMessageShown] = useState(
    localStorage.getItem(`__${r_id}_service_message_shown`) ?? 'false',
  );

  const [hybridClaimed, setHybridClaimed] = useState(
    localStorage.getItem(`__${r_id}_hybrid_claimed`) ?? 'false',
  );

  const categorizedView = useSelector<RootState, boolean>(
    state => state.restaurant.categorized_view,
  );

  const restaurantProfile = useSelector<RootState, TTRestaurantProfile | null>(
    state => state.restaurant.profile,
  );

  useEffect(() => {
    if (restaurantProfile !== null) {
      if (restaurantProfile.using_hybrid && hybridClaimed === 'false') {
        navigate(`/feed/restaurants/${r_id}/hybrid/claim-form`);
      }
    }
  }, [restaurantProfile]);

  useEffect(() => {
    let isMounted = true;
    async function fetchMenus() {
      try {
        // @ts-ignore
        const response = await menuApi.getMenusByRestaurant(r_id);
        const newMenus = response.data;
        if (isMounted) {
          dispatch(setMenus(newMenus.sort((a, b) => a.rank - b.rank)));
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    if (menus == null || store.getState().restaurant.restaurant_id === null) {
      if (isMounted) {
        setLoading(true);
      }
      if (store.getState().restaurant.restaurant_id === null) {
        dispatch(
          // @ts-ignore
          getRestaurant(r_id, () => {
            fetchMenus();
          }),
        );
      } else {
        fetchMenus();
      }
    } else {
      setLoading(true);
      fetchMenus();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    logEvent(getAnalytics(), 'menu_list_view');
    logEvent(getAnalytics(), 'merchant_landing_page_view', {
      merchant_id: r_id,
    });
    ZendeskAPI('webWidget', 'show');
  }, []);

  return (
    <>
      <div className="h-screen bg-gray-100 md:mx-8 lg:mx-16 xl:mx-32">
        <Spin spinning={loading} indicator={LoadingIcon}>
          <div className={' bg-gray-100 mb-10'}>
            {store.getState().restaurant.profile !== null && (
              <RestaurantNameCard
                restaurant_name={store.getState().restaurant.profile.name}
                profile_image_url={
                  store.getState().restaurant?.profile?.main_profile_image
                }
                bio={store.getState().restaurant?.profile?.bio}
              />
            )}
            <FeaturedProducts merchant_id={parseInt(r_id)} />
            {menus != null && !showMenu && !categorizedView && (
              <>
                {/* <div className="mx-4 flex flex-row bg-gray-900 text-white font-semibold px-2  overflow-x-auto items-center rounded-md py-2">
                  Catalogs
                </div> */}
                <div className="grid grid-cols-2 mt-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 items-center rounded-lg gap-6 scrolling-touch p-4">
                  {menus.map((menu, i) => {
                    if (
                      isMenuAvailable(menu.hours ?? []) &&
                      (qr !== null
                        ? qr_code_menu_ids[qr]?.length === 0 ||
                          qr_code_menu_ids[qr]?.includes(menu?.id)
                        : true)
                    ) {
                      return (
                        <MenuCard
                          menu={menu}
                          key={i}
                          onClick={() => {
                            logEvent(getAnalytics(), 'menu_clicked', {
                              menu_id: menu.id,
                              menu_name: menu.name,
                              rank: menu.rank,
                            });
                            dispatch(setMenu(menu));
                            // setSelectedMenu(menu);
                            navigate(
                              `/feed/restaurants/${r_id}/menus/${menu.id}/`,
                            );
                          }}
                        />
                      );
                    }
                  })}
                </div>
              </>
            )}
            {menus !== null && !showMenu && categorizedView && (
              <CategorizedMenuView />
            )}
            {!loading &&
              menus != null &&
              store.getState().restaurant.profile.service_message && (
                <ServiceMessageCard
                  message={store.getState().restaurant.profile.service_message}
                  restaurant_name={store.getState().restaurant.profile.name}
                />
              )}
            {!loading &&
              menus !== null &&
              store.getState().restaurant.profile.is_payment_live && (
                <WalkthroughCTACard
                  mb={'mb-10'}
                  onClick={() => {
                    setShowWalkthrough(true);
                    localStorage.setItem('_tt_walkthrough', 'true');
                  }}
                />
              )}
            <div className="w-full">
              <TableTabBanner />
            </div>
            {!loading && order === null && <CartBanner />}
            {!loading &&
              order !== null &&
              store.getState().cart.cart_items?.length !== 0 && (
                <div className="relative w-full bottom-16 fixed ">
                  <div
                    className="sticky bottom-0"
                    onClick={() => {
                      logEvent(getAnalytics(), 'view_cart_clicked');
                      navigate(`/feed/restaurants/${r_id}/plate`);
                    }}
                  >
                    <div className="flex flex-row bg-gray-900 p-4 rounded-t-md items-center justify-center shadow-sm">
                      <div className="flex flex-col justify-center leading-normal">
                        <div className="flex items-center">
                          <div className="text-white font-bold text-lg text-center mx-auto">
                            {'Continue ordering more'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {!loading && order !== null && (
              <div className="w-full">
                <OrderBanner />
              </div>
            )}
            {!loading &&
              !eval(serviceMessageShown) &&
              store.getState().restaurant.profile?.service_message_config
                ?.as_pop_up && (
                <PopUpServiceMessage
                  // @ts-ignore
                  restaurant_id={r_id}
                  restaurant_name={store.getState().restaurant.profile.name}
                  message={store.getState().restaurant.profile.service_message}
                  onCancel={() => {
                    localStorage.setItem(
                      `__${r_id}_service_message_shown`,
                      'true',
                    );
                    setserviceMessageShown('true');
                  }}
                />
              )}
            {!loading && showWalkthrough && (
              <WalkthroughPopUp
                onCancel={() => {
                  localStorage.setItem('_tt_walkthrough', 'true');
                  setWalkthroughShown('true');
                  setShowWalkthrough(false);
                }}
              />
            )}
          </div>
        </Spin>
      </div>
    </>
  );
};

export default MenusList;
