import { message } from 'antd';
import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { paymentsApi } from '../../../api/payments';
import { squareKey, squareLocation } from '../../../env';
import { TTCheck } from '../../../interfaces/check';
import { RootState } from '../../../rootReducer';
import { centsToDollars } from '../../utils';

type Props = {
  onClick: (token: string) => void;
};

export const SquareApplePay: React.FC<Props> = ({ onClick }) => {
  const check = useSelector<RootState, TTCheck>(state => state.cart.check);
  const [applePay, setApplePay] = useState(null);
  const [req, setReq] = useState<PaymentRequest | null>(null);
  const [squarePayments, setPayments] = useState(null);

  function buildPaymentRequest(payments) {
    return payments.paymentRequest({
      countryCode: 'US',
      currencyCode: 'USD',
      total: {
        amount: centsToDollars(check.total + check.service_fee).replace(
          '$',
          '',
        ),
        label: 'Total - Authorization Hold',
      },
    });
  }

  async function initializeApplePay(payments) {
    const paymentRequest = buildPaymentRequest(payments);
    setReq(paymentRequest);
    // @ts-ignore
    if (!window.ApplePaySession) {
      const req = await payments.googlePay(paymentRequest);
      await req.attach('#google-pay-button', {
        buttonColor: 'default',
        buttonType: 'long',
        buttonSizeMode: 'fill',
      });
      return req;
    } else {
      const req = await payments.applePay(paymentRequest);
      return req;
    }
  }

  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }
      message.error(errorMessage);
      return;
    }
  }

  async function handlePaymentMethodSubmission(event, paymentMethod) {
    event.preventDefault();

    try {
      // disable the submit button as we await tokenization and make a payment request.
      const token = await tokenize(paymentMethod);
      if (token !== undefined) {
        onClick(token);
      }
      // const paymentResults = await paymentsApi.attachSquarePaymentMethod({
      //   payment_method_token: token,
      //   platform: 'SQUARE',
      //   restaurant_id: 1,
      //   company_id: 1,
      //   cloning: false,
      // });
      // message.success('Card saved successfully');
      // console.debug('Payment Success', paymentResults);
    } catch (e) {
      console.log(e);
      console.log(e.response);
      message.error('Unable to authorize');
    }
  }

  useEffect(() => {
    async function setup() {
      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }

      let payments;
      try {
        payments = window.Square.payments(squareKey, squareLocation);
        setPayments(payments);
      } catch {
        return;
      }

      try {
        const respone = await initializeApplePay(payments);
        setApplePay(respone);
      } catch (e) {
        console.error('Initializing failed', e);
      }
    }
    setup();
  }, []);

  useMemo(async () => {
    if (req !== null) {
      // @ts-ignore
      const res = await req.update({
        total: {
          amount: centsToDollars(check.total + check.service_fee).replace(
            '$',
            '',
          ),
          label: 'Total - Authorization Hold',
        },
      });

      if (!res) {
        message.error('Unable to update the payment request');
      }
    }
  }, [check]);

  return (
    <form id="payment-form " className="m-2">
      {
        // @ts-ignore
        window.ApplePaySession && (
          <div
            id="apple-pay-button"
            onClick={async event => {
              event.preventDefault();
              await handlePaymentMethodSubmission(event, applePay);
            }}
          ></div>
        )
      }
      {
        // @ts-ignore
        !window.ApplePaySession && (
          <div
            id="google-pay-button"
            onClick={async event => {
              event.preventDefault();
              await handlePaymentMethodSubmission(event, applePay);
            }}
          ></div>
        )
      }
    </form>
  );
};
