import React from 'react';
import { getFullImageUrl } from '../../utils';
import store from '../../../store';
import { useTransition, animated, config } from 'react-spring';
import { TTMenu } from '../../../interfaces/menus';

type Props = {
  imageUrl: string;
  menu: TTMenu;
};

const DishImage: React.FC<Props> = ({ imageUrl, menu }) => {
  const transitions = useTransition(imageUrl, _ => imageUrl, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: config.molasses,
  });
  return (
    <>
      {imageUrl !== undefined &&
        imageUrl !== null &&
        !RegExp(
          `^(https:\/\/d2b8t2l9x5xm7h.cloudfront.net\/public\/[0-9]*\/100.jpg).*`,
        ).test(imageUrl) &&
        transitions.map(({ item, props, key }) => {
          return (
            <animated.div
              key={key}
              style={props}
              className="flex justify-center items-center lg:hidden mx-4 md:mx-0"
            >
              <img
                className=" rounded shadow-xl"
                src={getFullImageUrl(
                  menu.is_shared
                    ? menu.restaurant_id
                    : store.getState().restaurant.profile.restaurant_id,
                  imageUrl,
                )}
                alt=""
              />
            </animated.div>
          );
        })}
    </>
  );
};

export default DishImage;
