import { message, Spin } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { guestApi } from '../../api/guest';
import { ordersApi } from '../../api/order';
import {
  setClaimingGuest,
  setClaimingGuestId,
  setclaimingOrder,
  setLast4,
} from '../../features/hybrid/hybrid';
import { LoadingIcon } from '../components/Spinner';

export function ClaimHybridOrder() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { r_id } = useParams();

  const handleFormSubmit = async event => {
    const last_4 = event.target.elements.last_4.value;
    const tableNumber = event.target.elements.table_number.value;

    try {
      setLoading(true);
      const response = await ordersApi.getHybridDraftOrder(last_4, tableNumber);
      const guestToClaim = await guestApi.claimGuest(response.data?.id);
      dispatch(setclaimingOrder(response.data));
      dispatch(setClaimingGuestId(guestToClaim?.data.id));
      dispatch(setClaimingGuest(guestToClaim?.data));
      dispatch(setLast4(last_4));
      setLoading(false);
      navigate(`/feed/restaurants/${r_id}/hybrid/claim-guest-form`);
    } catch (e) {
      setLoading(false);
      message.error(e?.response?.data?.detail ?? 'Unable to claim an order');
    }
  };

  return (
    <div className="px-6 pb-4 pt-8">
      <div>
        <h3 className="text-xl leading-6 font-medium text-gray-900">
          Claim an open tab
        </h3>
        <p className="mt-1 text-base text-gray-500">
          If you started a tab at the bar, you can claim and continue ordering.
          Please enter either your unique code or the last 4 digits of the card
          used to open the tab.
        </p>
      </div>

      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <Spin spinning={loading} indicator={LoadingIcon}>
          <form
            className=""
            action="POST"
            onSubmit={async e => {
              e.preventDefault();
              handleFormSubmit(e);
            }}
          >
            <div className="shadow rounded-md sm:overflow-hidden">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6 rounded-md">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="last_4"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last 4 digits
                    </label>
                    <div className="mt-1 rounded-md shadow-sm flex">
                      <input
                        type="text"
                        name="last_4"
                        id="last_4"
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0  rounded-md sm:text-sm border-gray-300"
                      />
                    </div>
                  </div>
                  <div className="relative col-span-3 sm:col-span-2">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="px-2 bg-white text-sm text-gray-500">
                        Or
                      </span>
                    </div>
                  </div>
                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="table_number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Unique Code
                    </label>
                    <div className="mt-1 rounded-md shadow-sm flex">
                      <input
                        type="text"
                        name="table_number"
                        id="table_number"
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0  rounded-md sm:text-sm border-gray-300"
                      />
                    </div>
                  </div>
                </div>
                <div className="relative col-span-3 sm:col-span-2">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-2 pt-6">
                  <button
                    type="submit"
                    className="inline-flex  w-full justify-center items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="relative  pt-10">
            <div
              className="absolute inset-0 flex items-center pt-10"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="px-2 bg-gray-100 text-sm text-gray-500">Or</span>
            </div>
          </div>
          <div className="col-span-3 sm:col-span-2 pt-10">
            <button
              type="button"
              onClick={() => {
                localStorage.setItem(`__${r_id}_hybrid_claimed`, 'true');
                navigate(`/feed/restaurants/${r_id}/menus`);
              }}
              className="inline-flex  w-full justify-center items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Skip & start a new tab
            </button>
          </div>
        </Spin>
      </div>
    </div>
  );
}
