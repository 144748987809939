import React from 'react';
import { TCategory } from '../../../../interfaces/category';

type Props = {
  menuCategory: TCategory;
  selected: boolean;
  onClick: (cat: TCategory) => void;
};

const MenuCategoryPill: React.FC<Props> = ({
  menuCategory,
  onClick,
  selected,
}) => {
  return (
    <div
      key={menuCategory.id}
      onClick={() => onClick(menuCategory)}
      className="cursor-pointer"
    >
      <div
        className={` ${
          selected
            ? 'bg-white ring-2 ring-red-400 ring-offset-0'
            : 'bg-white hover:bg-red-100'
        }  ${
          selected ? 'text-red-400' : 'text-gray-900'
        } text-center align-center text-sm px-4 content-center items-center py-1 rounded-full w-full h-8 shadow-md outline-none focus:outline-none`}
      >
        <span className="align-middle truncate">{menuCategory.name}</span>
      </div>
    </div>
  );
};

export default MenuCategoryPill;
