import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { logEvent, getAnalytics } from 'firebase/analytics';

type Props = {
  cta?: string;
  applyFilter?: boolean;
};

const TableTabBanner: React.FC<Props> = ({
  cta = 'TableTab',
  applyFilter = true,
}) => {
  const analytics = getAnalytics();

  const navigate = useNavigate();

  return (
    <>
      {
        <div className="w-full mb-10 pb-16">
          <a
            className=""
            href="https://tabletab.io?ref=guest_app_bottom_banner_cta"
            target="_blank"
            onClick={() => {
              logEvent(analytics, 'powered_by_clicked');
            }}
          >
            <div
              className={`${
                applyFilter ? 'filter grayscale' : 'text-primary-600'
              } flex flex-row p-1 items-center justify-center`}
            >
              <div className=" font-bold text-sm text-center">Powered by</div>
              {/* <div className="text-white font-bold text-sm text-center ml-1">
              {cta}
            </div> */}
              <div className={'w-1/4 md:w-32 ml-2'}>
                <img
                  className={''}
                  src={
                    'https://dyneios5e1104b7b11b45eab3a4895c2b805a5b-948157201798-us-east-1.s3.amazonaws.com/website/TableTabCoral2_v2.webp'
                  }
                ></img>
              </div>
            </div>
          </a>
        </div>
      }
    </>
  );
};

export default TableTabBanner;
