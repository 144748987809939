import axios from 'axios';
import { apiUrl } from '../env';
import { getAuthHeaders } from './utils';
import {
  TTOrder,
  TTOrderCreate,
  TTOrderPlatformFee,
  TTOrderUpdate,
  TTOrderV2,
  TTOrderV2Create,
  TTRestaurantServiceFees,
} from '../interfaces/order';
/* eslint-disable */
export const ordersApi = {
  async getOrder(id: number) {
    return axios.get<TTOrderV2>(`${apiUrl}/api/v1/orders/new-model/${id}`, {
      headers: await getAuthHeaders(),
    });
  },
  async getCurrentlyOpenedOrder() {
    return axios.get<TTOrderV2>(`${apiUrl}/api/v1/orders/new-model/open/`, {
      headers: await getAuthHeaders(),
    });
  },
  async getGuestSideFee(
    subTotal: number,
    orderType: string,
    restaurantId: number,
  ) {
    return axios.get<TTOrderPlatformFee>(
      `${apiUrl}/api/v1/orders/new-model/pricing-model/?subtotal=${subTotal}&order_type=${orderType}&restaurant_id=${restaurantId}`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async getRestaurantServiceFees(
    subTotal: number,
    restaurantId: number,
    order_type: string,
  ) {
    return axios.get<TTRestaurantServiceFees[]>(
      `${apiUrl}/api/v1/orders/new-model/service-charges/?subtotal=${subTotal}&restaurant_id=${restaurantId}&order_type=${order_type}`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async createOrder(order_obj: TTOrderV2Create) {
    return axios.post<TTOrderV2>(
      `${apiUrl}/api/v1/orders/new-model/`,
      order_obj,
      {
        headers: await getAuthHeaders(),
      },
    );
  },

  async updateOrder(id: number, order_obj: TTOrderUpdate) {
    return axios.post<TTOrderV2>(
      `${apiUrl}/api/v1/orders/new-model/${id}/`,
      order_obj,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async getHybridDraftOrder(last4: string, table_number: string) {
    return axios.get<TTOrderV2>(
      `${apiUrl}/api/v1/orders/new-model/claim?table_number=${table_number}&last_4=${last4}`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },

  async updateTip(orderId: number, payload) {
    return axios.post<TTOrderV2>(
      `${apiUrl}/api/v1/orders/new-model/${orderId}/tip`,
      payload,
      {
        headers: await getAuthHeaders(),
      },
    );
  },

  async updateOrderTableNumber(orderId: number, payload) {
    return axios.put<TTOrderV2>(
      `${apiUrl}/api/v1/orders/new-model/${orderId}/table-number`,
      payload,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
};
