import axios, { AxiosResponse } from 'axios';
import { apiUrl } from '../env';
import { getAuthHeaders } from './utils';
import { BookingInfo, BookingSlots } from '../interfaces/booking_schedule';
/* eslint-disable */
export const bookingApi = {
  async getBookingSlots(menu_item_id: number, booking_date: string) {
    return axios.get<BookingSlots[]>(
      `${apiUrl}/api/v1/bookings/available-slots?menu_item_id=${menu_item_id}&date=${booking_date}`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async submitNewBookingPropsal(booking_id: number, booking_prospoal) {
    return axios.post<BookingSlots[]>(
      `${apiUrl}/api/v1/bookings/${booking_id}/propose-to-vendor`,
      booking_prospoal,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async acceptBookingProposal(booking_id: number) {
    return axios.post<BookingInfo>(
      `${apiUrl}/api/v1/bookings/${booking_id}/accept`,
      {},
      {
        headers: await getAuthHeaders(),
      },
    );
  },
};
