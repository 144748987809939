import { message } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTableNumber } from '../../../features/cart/cart';
import store from '../../../store';
import Input from '../../components/Input';

type Props = {
  callback: () => void;
};

export function TableNumberConfirmationPopUp({ callback }: Props) {
  const dispatch = useDispatch();
  const [local, setLocal] = useState(
    store.getState().cart.order_create.table_number ?? '',
  );
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
        &#8203;
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 text-gray-900"
                  id="modal-headline"
                >
                  The previous tab has been closed. Please re-confirm your
                  current table number.
                </h3>
                <div className="py-2  sm:flex rounded">
                  <div className="p-4 flex felx-row w-full rounded-lg  justify-between bg-white">
                    <Input
                      // @ts-ignore
                      handleChange={e => {
                        setLocal(e.target.value);
                      }}
                      handleBlur={e => {
                        const re = /^[A-Za-z0-9.-]+$/;
                        if (local === '' || re.test(local)) {
                          dispatch(setTableNumber(local));
                          if (
                            store
                              .getState()
                              .restaurant.profile.table_numbers.includes(local)
                          ) {
                            message.success('Table number saved');
                          }
                        } else {
                          setLocal('');
                          dispatch(setTableNumber(''));
                        }
                      }}
                      name="tableNumber"
                      value={local}
                      className={
                        'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                      }
                      autoComplete="off"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                onClick={callback}
              >
                Confirm
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
