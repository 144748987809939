import { message, Spin } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { osName, osVersion } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { authApi } from '../../api/auth';
import { guestApi } from '../../api/guest';
import { ordersApi } from '../../api/order';
import { restaurantApi } from '../../api/restaurant';
import PillLabel from '../../components/PillLabel';
import { setUserProfile } from '../../features/auth/auth';
import { setclaimingOrder } from '../../features/hybrid/hybrid';
import { setStripeId } from '../../features/payment/payment';
import { setProfile } from '../../features/restaurant/restaurant';
import { TTOrderV2 } from '../../interfaces/order';
import { TTOrderItem } from '../../interfaces/order_item';
import { TTRestaurantProfile } from '../../interfaces/restaurant';
import { RootState } from '../../rootReducer';
import DishCard from '../Cart/components/DishCard';
import { LoadingIcon } from '../components/Spinner';
import ViewHeader from '../components/ViewHeader';
import PrivacyInfoCard from '../Login/components/PrivacyInfoCard';
import { InfoAlert } from '../Order/components/InfoAlert';

export function StartGuestClaim() {
  const claimingOrder = useSelector<RootState, TTOrderV2 | null>(
    state => state.hybrid.claiming_order,
  );
  const claimingGuestId = useSelector<RootState, number | null>(
    state => state.hybrid.claiming_guest_id,
  );
  const { r_id } = useParams();
  const last4 = useSelector<RootState, string>(state => state.hybrid.last_4);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const restaurantProfile = useSelector<RootState, TTRestaurantProfile | null>(
    state => state.restaurant.profile,
  );
  const [showCodeField, setShowCodeField] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [code, setCode] = useState('');
  const [showPrivacyPopUp, setShowPrivacyPopUp] = useState(false);
  const navigate = useNavigate();
  const fetchOrder = async () => {
    try {
      setLoading(true);
      console.log(last4);
      const response = await ordersApi.getHybridDraftOrder(last4, '');
      dispatch(setclaimingOrder(response.data));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      message.error(
        e?.response?.data?.detail ?? 'Unable to fetch the order on record',
      );
    }
  };

  const requestPhoneVerificationCode = async e => {
    try {
      setPhoneNumber(e.target.elements.tel.value);
      const response = await authApi.createPhoneVerificationCode({
        phone_number: '+1' + e.target.elements.tel.value,
        channel: 'sms',
      });
      if (response.data.status == 'pending' || response.data.status == 'sent') {
        setError('');
        setLoading(false);
        setShowCodeField(true);
      }
    } catch (e) {
      setLoading(false);
      message.error(
        e?.response?.data?.detail ?? 'Unable to request phone verification.',
      );
    }
  };

  async function confirmCode() {
    try {
      const response = await guestApi.verifyClaim(claimingGuestId, {
        phone_number: '+1' + phoneNumber,
        code: code,
        os_type: osName + '_' + osVersion,
        order_id: claimingOrder?.id,
      });
      localStorage.setItem('tabletabToken', response.data.token?.access_token);
      localStorage.setItem('tabletabIsLoggedIn', 'true');
      dispatch(setStripeId(response.data.guest?.stripe_id));
      dispatch(setUserProfile(response.data?.guest));
      message.success('Verified!', 2, () => {
        localStorage.setItem(`__${r_id}_hybrid_claimed`, 'true');
        setLoading(false);
        navigate(`/feed/restaurants/${r_id}/order/${claimingOrder?.id}`);
      });
    } catch (e) {
      console.log(e?.response);
      setLoading(false);
      setError('Error: \n' + e.response?.data?.detail ?? e.message);
    }
  }

  const fetchProfile = async () => {
    try {
      if (!loading) {
        setLoading(true);
      }
      const response = await restaurantApi.getProfile(parseInt(r_id));
      dispatch(setProfile(response.data));
      setLoading(false);
    } catch (e) {}
  };

  useEffect(() => {
    if (claimingOrder === null) {
      fetchOrder();
    }
    if (restaurantProfile === null) {
      fetchProfile();
    }
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 ">
      <div className={' overflow-y-scroll scrolling-touch bg-gray-100'}>
        <Spin spinning={loading} indicator={LoadingIcon}>
          <div className="pt-8 px-6">
            <h3 className="text-xl leading-6 font-medium text-gray-900">
              {claimingOrder !== null
                ? 'Order found'
                : loading
                ? 'Fetching...'
                : 'Order not found.'}
            </h3>
            <p className="mt-1 text-base text-gray-500">
              {claimingOrder !== null &&
                'We found the following order that you started earlier. Please confirm by entering your phone number.'}
            </p>
          </div>
          {claimingOrder !== null && restaurantProfile !== null && (
            <>
              <table className="min-w-full mb-6 pt-10 bg-gray-100">
                <th className="px-4 pt-4 text-lg text-gray-900 font-normal">
                  <InfoAlert text={'These are all of your ordered items'} />
                </th>
                <tbody>
                  {claimingOrder.order_tabs
                    .map(a => a.order_items)
                    .reduce((a, b) => a.concat(b))
                    ?.filter(
                      oI =>
                        oI.paired_with === undefined ||
                        (!oI.paired_with?.is_pairing_option ?? false),
                    )
                    .map((item: TTOrderItem, i) => {
                      if (!item.voided) {
                        return (
                          <DishCard
                            menuItem={item}
                            onClick={() => {}}
                            key={item.id}
                          />
                        );
                      }
                    })}
                </tbody>
              </table>
            </>
          )}
          {claimingOrder !== null && restaurantProfile !== null && (
            <div className=" px-4">
              <div className="bg-white rounded-md shadow py-6 px-4">
                {!showCodeField && (
                  <form
                    className={'w-full'}
                    onSubmit={async e => {
                      e.preventDefault();
                      setLoading(true);
                      await requestPhoneVerificationCode(e);
                    }}
                  >
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <label
                          htmlFor="tel"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Enter Your Phone Number
                        </label>
                        {!showCodeField && (
                          <div className="justify-center content-center items-center">
                            <div
                              className=" rounded"
                              onClick={event => setShowPrivacyPopUp(true)}
                            >
                              <div className="flex flex-col  bg-white h-full items-center justify-center align-middle">
                                <div className="flex flex-row w-full items-center justify-center">
                                  <div className="text-gray-900 flex flex-row items-center text-sm text-left justify-left content-center">
                                    <PillLabel
                                      color="gray"
                                      label={'Why do we ask this?'}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="mt-1 rounded-md shadow-sm">
                        <input
                          id="tel"
                          type="phone"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-primary focus:border-red-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                    <div className="mt-6 justify-center content-center items-center">
                      <div className="pr-4 min-w-4/5 sm:h-10 max-h-10 sm:flex rounded">
                        <div className="flex flex-col bg-white h-full items-center justify-center align-middle">
                          <div className="flex flex-row w-full items-center justify-center">
                            <div className="text-gray-900 flex flex-row items-center text-xs text-left justify-left content-center">
                              <p>
                                {`You will receive an SMS for verification. Please make sure you have cellular service or WiFi connectivity.`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        id={'phone-number-submit'}
                        type="submit"
                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-400 text-base font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                      >
                        Continue
                      </button>
                    </div>
                  </form>
                )}
                {showCodeField && (
                  <form
                    className={'w-full'}
                    onSubmit={async e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setLoading(true);
                      await confirmCode();
                    }}
                  >
                    <div>
                      <label
                        htmlFor="code"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Enter Verification Code
                      </label>
                      <div className="mt-1 rounded-md shadow-sm">
                        <input
                          id="code"
                          type="ont-time-code"
                          autoComplete="one-time-code"
                          required
                          value={code}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            e.preventDefault();
                            const value = e.target.value.replace(/\s/g, '');
                            setCode(value);
                          }}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-primary focus:border-red-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                    <div className="mt-6 justify-center content-center items-center">
                      <div className="pr-4 min-w-4/5 sm:h-10 max-h-10 sm:flex rounded">
                        <div className="flex flex-col bg-white h-full items-center justify-center align-middle">
                          <div className="flex flex-row w-full items-center justify-center">
                            <div className="text-gray-900 flex flex-row items-center text-xs text-left justify-left content-center">
                              <p>
                                {`We have sent a code to +1${phoneNumber}. Press retry if you haven't received it in 60 secs.`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*<div id={'recaptcha'} ref={ref => (recaptcha = ref)} />*/}
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="submit"
                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-400 text-base font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                      >
                        Confirm
                      </button>
                    </div>
                    <div className="justify-center content-center items-center mt-6">
                      <div
                        className="py-2 pr-4 pl-4 w-full sm:h-10 max-h-10 rounded"
                        onClick={async () => {
                          setLoading(false);
                          setShowCodeField(false);
                        }}
                      >
                        <div className="flex flex-col p-4 bg-white h-full items-center justify-center align-middle">
                          <div className="flex flex-row w-full items-center justify-center">
                            <div className="text-gray-900 flex flex-row items-center text-sm text-left justify-left content-center">
                              <PillLabel color="gray" label={'Retry'} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
              <div className="justify-center content-center items-center mt-6">
                <div className="  w-full  rounded">
                  <div className="flex flex-col  bg-white h-full items-center justify-center align-middle rounded">
                    <p className="py-4 text-gray-900  text-xs text-left">
                      By continuing, you agree to our
                      <a
                        href="https://tabletab.io/terms"
                        target={'_blank'}
                        className="px-1"
                      >
                        Terms of Service
                      </a>
                      &
                      <a
                        href={'https://tabletab.io/privacy'}
                        target={'_blank'}
                        className="pl-1"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showPrivacyPopUp && (
            <PrivacyInfoCard callback={() => setShowPrivacyPopUp(false)} />
          )}
        </Spin>
      </div>
    </div>
  );
}
