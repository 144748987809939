import React from 'react';

type Props = {
  count: number;
};

const CounterView: React.FC<Props> = ({ count }) => {
  return (
    <>
      <div className="px-2 pb-2 pt-2 min-w-1/4 max-w-1/3">
        <button
          className={` bg-white  "text-white" text-center align-center text-sm font-bold py-2 px-4 rounded-full w-full h-12 shadow-md outline-none focus:outline-none`}
        >
          {count}
        </button>
      </div>
    </>
  );
};

export default CounterView;
