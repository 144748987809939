import { DocumentTextIcon } from '@heroicons/react/outline';
import { DocumentIcon } from '@heroicons/react/solid';
import { message, Spin } from 'antd';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { menuApi } from '../../../../api/menu';
import { setFilesRead } from '../../../../features/cart/cart';
import { TTMenu } from '../../../../interfaces/menus';
import { RootState } from '../../../../rootReducer';
import { LoadingIcon } from '../../../components/Spinner';
import FileRequiredToView from './FileRequiredToView';

type Props = {
  menu: TTMenu;
  loading_menu: boolean;
  from_menu_item?: boolean;
};

export function MenuFileView({
  menu,
  loading_menu,
  from_menu_item = false,
}: Props) {
  const [showFile, setShowFile] = useState(from_menu_item);
  const [signedUrl, setSignedUrl] = useState<string | ''>(null);
  const [fetchingFile, setFetchingFile] = useState(false);
  const dispatch = useDispatch();
  const fetchSignedUrl = async () => {
    try {
      setFetchingFile(true);
      const response = await menuApi.getSignedUrlForFile(menu?.file_id);
      setSignedUrl(response.data);
      const preview = document.getElementById('file-view');
      if (preview !== null && response.data !== null) {
        // @ts-ignore
        preview.src = response.data;
      }
      setFetchingFile(false);
    } catch (e) {
      console.log(e);
      setFetchingFile(false);
      message.error(e?.response?.data?.detail ?? 'Unable to fetch the file');
    }
  };

  const files_read = useSelector<RootState, { [key: string]: boolean }>(
    state => state.cart.files_read,
  );

  // const handleIFramLoad = async (
  //   e: SyntheticEvent<HTMLIFrameElement, Event>,
  // ) => {
  //   try {
  //     console.log(e.isDefaultPrevented());
  //     e.preventDefault();
  //     e.stopPropagation();
  //     console.log(e.target);
  //   } catch (e) {}
  // };
  useEffect(() => {
    if (showFile || from_menu_item) {
      fetchSignedUrl();
    }
  }, [showFile, from_menu_item]);

  return (
    <>
      {/* {menu?.file_required_to_view &&
        !files_read[menu?.file?.unique_id] &&
        !loading_menu &&
        !from_menu_item && (
          <div className="relative">
            <FileRequiredToView
              menu={menu}
              onClick={() => {
                setShowFile(true);
              }}
            />
          </div>
        )} */}
      <div className="flex-col bg-white px-2 py-1 mb-2 md:px-4 md:py-2 rounded-md shadow-md mx-4 md:mx-8 lg:mx-16 xl:mx-32">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-1">
            <DocumentTextIcon className="w-4 h-4 text-blue-600" />
            <h4 className="text-md md:text-md font-semibold mb-0">
              {menu?.file?.name}
            </h4>
          </div>
          <div>
            {!from_menu_item && (
              <button
                className="flex rounded-full px-2 py-1 bg-gray-100 text-sm text-gray-900"
                onClick={() => setShowFile(!showFile)}
              >
                {showFile ? 'Hide File' : 'View File'}
              </button>
            )}
          </div>
        </div>
        {(showFile || from_menu_item) && (
          <Spin spinning={fetchingFile} indicator={LoadingIcon}>
            <div id="file-view-container mt-4">
              <base target="_blank" />
              <iframe
                // src={`https://docs.google.com/gview?embedded=true${
                //   'url=' + signedUrl
                // }`}
                src=":my-file"
                id="file-view"
                scrolling="auto"
                width="100%"
                height="569"
                allowFullScreen
                className="my-2"
                onLoad={async e => {
                  // await handleIFramLoad(e);
                  //can try and hook the PDF.js event for rendering completed and call it then instead.
                  //https://stackoverflow.com/questions/12693207/how-to-know-if-pdf-js-has-finished-rendering
                  // setTimeout(function () {
                  // console.log(document.querySelector('#file-view'));
                  // document
                  //   .querySelector('#file-view')
                  //   // @ts-ignore
                  //   ?.ownerDocument.querySelectorAll('a:not(.bookmark)')
                  //   .forEach(function (a, i) {
                  //     a.setAttribute('target', '_blank');
                  //   });
                  // }, 1000);
                }}
                // sandbox="allow-same-origin allow-forms allow-scripts  allow-popups"
                // type="application/pdf"
              />
            </div>
            {menu?.file_required_to_view && (
              <fieldset className="space-y-5">
                <legend className="sr-only">Read</legend>
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id="read"
                      aria-describedby="read-description"
                      name="read"
                      disabled={files_read[menu?.file?.unique_id]}
                      type="checkbox"
                      onChange={v => {
                        console.log(v.target.value);
                        if (v.target.value === 'on') {
                          console.log(v.target.value);
                          dispatch(setFilesRead(menu?.file?.unique_id));
                          setShowFile(false);
                        }
                      }}
                      checked={files_read[menu?.file?.unique_id] ?? false}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 disabled:text-indigo-600/50 disabled:cursor-not-allowed focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="read" className="font-medium text-gray-700">
                      I confirm
                    </label>
                    <span id="read-description" className="text-gray-500">
                      <span className="sr-only">I Confirm </span> that I have
                      viewed & read the file.
                    </span>
                  </div>
                </div>
              </fieldset>
            )}
          </Spin>
        )}
      </div>
    </>
  );
}
