import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { TTMenu } from '../../../../interfaces/menus';
import { setMenu } from '../../../../features/restaurant/restaurant';
import { isMenuAvailable } from '../../../utils';
import { useNavigate, useParams } from 'react-router';

export default function MenuListSelection() {
  const menus = useSelector<RootState, TTMenu[]>(
    state => state.restaurant.current_menus,
  );
  const selectedMenu = useSelector<RootState, TTMenu>(
    state => state.restaurant.menu_in_view,
  );
  const qr_code_menu_ids = useSelector<RootState, { [key: string]: number[] }>(
    state => state.cart.qr_code_menu_ids,
  );
  const qr = useSelector<RootState, string>(state => state.cart.qr_code);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // @ts-ignore
  const { r_id } = useParams();

  return (
    <div className="sm:flex sm:w-full w-52">
      {selectedMenu !== null && menus !== null && (
        <Listbox
          value={selectedMenu}
          onChange={value => {
            if (value.id !== selectedMenu.id) {
              dispatch(setMenu(value));
              navigate(`/feed/restaurants/${r_id}/menus/${value.id}/`);
            }
          }}
        >
          {({ open }) => (
            <>
              <div className="relative mt-0 sm:w-full">
                <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-xl shadow-sm cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-gray-300 focus-visible:ring-offset-2 focus-visible:border-gray-500 sm:text-sm">
                  <span className="block truncate">{selectedMenu.name}</span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    as="div"
                    className="z-10 absolute w-full py-1 mt-1 overflow-y-scroll text-sm bg-white rounded-md shadow-lg max-h-60 sm:max-h-72 focus:outline-none sm:text-sm list-none"
                  >
                    {menus.map((menu, personIdx) => {
                      if (
                        isMenuAvailable(menu.hours ?? []) &&
                        (qr !== null
                          ? qr_code_menu_ids[qr]?.length === 0 ||
                            qr_code_menu_ids[qr]?.includes(menu?.id)
                          : true)
                      ) {
                        return (
                          <Listbox.Option
                            as="div"
                            key={personIdx}
                            className={({ active }) =>
                              `${
                                active
                                  ? 'text-green-900 bg-green-100'
                                  : 'text-gray-900'
                              }
                            cursor-default select-none relative py-2 pl-10 pr-4 `
                            }
                            value={menu}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`${
                                    selected ? 'font-medium' : 'font-normal'
                                  } block truncate`}
                                >
                                  {menu.name}
                                </span>
                                {selected ? (
                                  <span
                                    className={`${
                                      active
                                        ? 'text-green-600'
                                        : 'text-green-600'
                                    }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                  >
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        );
                      }
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      )}
    </div>
  );
}
