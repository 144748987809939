import React from 'react';
import { centsToDollars } from '../../utils';

type Props = {
  title: string;
  price: number;
  onClick: () => void;
  editable?: boolean;
  modsChecked: boolean;
};

const AddToCartBanner: React.FC<Props> = ({
  onClick,
  title,
  price,
  editable = false,
  modsChecked,
}) => {
  return (
    <>
      {
        <div
          className="w-full fixed bottom-0 md:-mx-8 lg:-mx-16 xl:-mx-32"
          onClick={onClick}
        >
          <div
            className={`flex flex-row p-4 md:mx-8 lg:mx-16 xl:mx-32 ${
              modsChecked
                ? !editable
                  ? 'bg-gray-900'
                  : 'bg-gray-900'
                : 'bg-gray-300'
            } items-center justify-center shadow-sm rounded-t-md`}
          >
            <div className="flex flex-col leading-normal">
              <div className="flex items-center">
                <span
                  className={`${
                    !editable
                      ? 'text-white text-center'
                      : 'text-white text-left'
                  } font-bold text-lg  mx-auto`}
                >
                  {title}
                </span>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default AddToCartBanner;
