import React, {
  Component,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ModifierRow from './ModifierRow';
import { TTModifierOptions } from '../../../interfaces/modifier_options';
import ModifiersTableHeader from './ModifiersTableHeader';
import HeaderCell, {
  RequiredMetPill,
  RequiredPill,
} from './ModifiersTableHeaderCell';
import { TTModifierWOptions } from '../../../interfaces/modifier';
import { ModifierPairingOptionRow } from './ModifierPairingOptionRow';
import { TTModifierPairingOption } from '../../../interfaces/modifier_pairing_options';
import { useSelector } from 'react-redux';
import { TTOrderItemCreate } from '../../../interfaces/order_item';
import { RootState } from '../../../rootReducer';
import { TTMenuItem } from '../../../interfaces/menuItem';
import MenuDish from '../../MenuDish/MenuDish';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

type Props = {
  menu_item: TTMenuItem;
  modifier: TTModifierWOptions;
  options?: TTModifierOptions[];
  onSelect: (
    selected: boolean,
    options: TTModifierOptions[],
    pairing_options: TTModifierPairingOption[],
  ) => void;
  optionHeading?: string;
  selected_options?: TTModifierOptions[];
  selected_pairing_options?: TTModifierPairingOption[];
  onPairingOptionView?: (pairing: TTModifierPairingOption) => void;
  itemModifierRankings?: { [key: string]: number };
};
/* eslint-disable */

const ModifierTable: React.FC<Props> = ({
  menu_item,
  options,
  optionHeading,
  modifier,
  onSelect,
  selected_options = [],
  selected_pairing_options = [],
  onPairingOptionView,
}) => {
  const [showMods, setShowMods] = useState(false);
  const [selectedOptions, setSelectedOptions] =
    useState<TTModifierOptions[]>(selected_options);
  const [modifierPairingOptions, setModifierPairingOptions] = useState<
    TTModifierPairingOption[]
  >([...selected_pairing_options]);
  const [showingModifierPairingOption, setShowingModifierPairingOption] =
    useState<TTModifierPairingOption>(null);
  const cartItems = useSelector<RootState, TTOrderItemCreate[]>(
    state => state.cart.cart_items,
  );

  useMemo(() => {
    if (modifier?.is_required) {
      setShowMods(true);
      if (modifier?.modifier_options?.length === 1) {
        setSelectedOptions([...modifier?.modifier_options]);
        onSelect(true, [...modifier?.modifier_options], []);
      }
    }
  }, [modifier]);

  return (
    <>
      <div className="mr-4 ml-4 my-4">
        <table className="table-fixed w-full">
          <ModifiersTableHeader onClick={() => setShowMods(!showMods)}>
            <HeaderCell
              title={optionHeading}
              isOrdering={showMods}
              required={modifier.is_required}
              subHeader={
                modifier?.max_selection === modifier?.min_selection &&
                modifier?.max_selection !== 0 &&
                modifier?.max_selection !== null
                  ? `Choose ${modifier?.max_selection}`
                  : modifier?.max_selection !== null &&
                    modifier.min_selection !== null &&
                    modifier.min_selection !== 0
                  ? `Choose minimum of ${modifier.min_selection}`
                  : undefined
              }
              requiredPill={
                modifier.is_required ? (
                  modifier.is_pairing_type ? (
                    modifierPairingOptions.length <
                      (modifier.min_selection ?? 0) ||
                    modifierPairingOptions.length === 0 ? (
                      <RequiredPill />
                    ) : (
                      <RequiredMetPill />
                    )
                  ) : selectedOptions.length < (modifier.min_selection ?? 0) ||
                    selectedOptions.length === 0 ? (
                    <RequiredPill />
                  ) : (
                    <RequiredMetPill />
                  )
                ) : undefined
              }
            />
          </ModifiersTableHeader>

          <Transition
            className="w-full space-y-2"
            as="tbody"
            show={showMods}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {!modifier.is_pairing_type &&
              options
                ?.filter(o => !o.out_of_stock)
                ?.sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0))
                ?.map((item: TTModifierOptions, i) => {
                  return (
                    <ModifierRow
                      key={`${item.id}-${i}`}
                      modifierOption={item}
                      onClick={() => {
                        if (
                          selectedOptions.filter(e_i => {
                            return e_i.id == item.id;
                          }).length !== 0
                        ) {
                          if (selectedOptions.length == 1) {
                            onSelect(false, [], modifierPairingOptions);
                          } else {
                            onSelect(
                              false,
                              selectedOptions.filter(i => {
                                return i.id != item.id;
                              }),
                              modifierPairingOptions,
                            );
                          }
                          setSelectedOptions(exsiting =>
                            exsiting.filter(e => e.id !== item.id),
                          );
                        } else if (
                          modifier.is_single_selection ||
                          modifier.max_selection === 1
                        ) {
                          setSelectedOptions([item]);
                          onSelect(true, [item], modifierPairingOptions);
                        } else {
                          onSelect(
                            true,
                            [...selectedOptions, item],
                            modifierPairingOptions,
                          );
                          setSelectedOptions(existing => [...existing, item]);
                        }
                      }}
                      show={true}
                      selected={
                        selectedOptions.filter(op => op.id === item.id)
                          .length !== 0
                      }
                      disabled={
                        (modifier?.is_required &&
                          modifier.modifier_options?.length === 1) ||
                        (selectedOptions.length === modifier.max_selection &&
                          modifier.max_selection !== 0 &&
                          selectedOptions.filter(o => o.id === item.id)
                            .length === 0)
                      }
                    />
                  );
                })}
          </Transition>
          <Transition
            className="space-y-4"
            as="tbody"
            show={showMods}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {modifier.is_pairing_type &&
              modifier.modifier_pairing_options
                ?.filter(p => !p.out_of_stock)
                .map(p => {
                  return (
                    <ModifierPairingOptionRow
                      modifier_pairing_option={
                        modifierPairingOptions.filter(sP => sP.id === p.id)
                          .length === 0
                          ? p
                          : modifierPairingOptions.filter(
                              sP => sP.id === p.id,
                            )[0]
                      }
                      onClick={() => {
                        setShowingModifierPairingOption(
                          modifierPairingOptions.filter(
                            sP => sP.id === p.id,
                          )[0] ?? p,
                        );
                      }}
                      key={p.id.toString()}
                      isSelected={
                        modifierPairingOptions.filter(sP => sP.id === p.id)
                          .length !== 0
                      }
                      disabled={
                        modifierPairingOptions.reduce(
                          (prev, curr) => prev + curr.orderItem?.quantity ?? 0,
                          0,
                        ) === modifier.max_selection &&
                        modifier.max_selection !== 0 &&
                        modifierPairingOptions.filter(o => o.id === p.id)
                          .length === 0
                      }
                    />
                  );
                })}
          </Transition>
        </table>
      </div>
      <Transition.Root
        show={showingModifierPairingOption !== null && menu_item !== null}
        as={Fragment}
      >
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden"
          open={showingModifierPairingOption !== null && menu_item !== null}
          onClose={() => setShowingModifierPairingOption(null)}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setShowingModifierPairingOption(null)}
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 bottom-0 pt-10 max-w-full max-h-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll rounded-md">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-end">
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            onClick={() =>
                              setShowingModifierPairingOption(null)
                            }
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1">
                      {showingModifierPairingOption !== null &&
                        menu_item !== null && (
                          <MenuDish
                            minQuantity={modifier.min_selection ?? 0}
                            maxQuantity={
                              modifier.max_selection -
                                modifierPairingOptions
                                  ?.map(p =>
                                    p?.id !== showingModifierPairingOption?.id
                                      ? p.orderItem?.quantity
                                      : 0,
                                  )
                                  ?.reduce((a, b) => a + b, 0) ?? 99
                            }
                            addAsPairing={true}
                            pairedWith={{
                              menu_item_id:
                                showingModifierPairingOption.menu_item_id,
                              is_pairing_option: true,
                              pairing_option_id:
                                showingModifierPairingOption.id,
                              modifier_group_id:
                                showingModifierPairingOption.modifier_id,
                              paired_menu_item_id: menu_item.id,
                              cart_insert_ts:
                                cartItems?.filter(
                                  c =>
                                    c.paired_with !== undefined &&
                                    c.as_pairing &&
                                    c.paired_with.pairing_option_id ===
                                      showingModifierPairingOption.id &&
                                    c.paired_with.menu_item_id ===
                                      showingModifierPairingOption.menu_item_id &&
                                    showingModifierPairingOption.modifier_id ===
                                      c.paired_with.modifier_group_id &&
                                    c.cart_insert_ts ===
                                      showingModifierPairingOption.orderItem
                                        ?.cart_insert_ts,
                                )[0]?.cart_insert_ts ?? 0,
                              order_item: modifierPairingOptions.filter(
                                item =>
                                  item.menu_item_id ===
                                    showingModifierPairingOption.menu_item_id &&
                                  item.id === showingModifierPairingOption.id,
                              )[0]?.orderItem,
                            }}
                            editableVersion={
                              modifierPairingOptions === null
                                ? false
                                : modifierPairingOptions.length === 0
                                ? false
                                : modifierPairingOptions.filter(
                                    item =>
                                      item.menu_item_id ===
                                        showingModifierPairingOption.menu_item_id &&
                                      item.id ===
                                        showingModifierPairingOption.id,
                                  ).length !== 0
                            }
                            i_ts={
                              cartItems?.filter(
                                c =>
                                  c.paired_with !== undefined &&
                                  c.as_pairing &&
                                  c.paired_with.pairing_option_id ===
                                    showingModifierPairingOption.id &&
                                  c.paired_with.menu_item_id ===
                                    showingModifierPairingOption.menu_item_id &&
                                  showingModifierPairingOption.modifier_id ===
                                    c.paired_with.modifier_group_id &&
                                  c.cart_insert_ts ===
                                    showingModifierPairingOption.orderItem
                                      ?.cart_insert_ts,
                              )[0]?.cart_insert_ts ?? 0
                            }
                            onRemoveCallback={item => {
                              setModifierPairingOptions(prev => [
                                ...prev.filter(
                                  p => p.id !== showingModifierPairingOption.id,
                                ),
                              ]);
                              onSelect(false, selectedOptions, [
                                ...modifierPairingOptions.filter(
                                  p => p.id !== showingModifierPairingOption.id,
                                ),
                              ]);
                              setShowingModifierPairingOption(null);
                            }}
                            menuItemId={
                              showingModifierPairingOption.menu_item_id
                            }
                            callback={item => {
                              if (
                                modifierPairingOptions.filter(
                                  p => p.id === showingModifierPairingOption.id,
                                ).length === 0
                              ) {
                                setModifierPairingOptions(prev => [
                                  ...prev,
                                  {
                                    ...showingModifierPairingOption,
                                    orderItem: {
                                      ...item,
                                      paired_modifier_group_name: modifier.name,
                                    },
                                    pairing_option_id:
                                      showingModifierPairingOption.id,
                                  },
                                ]);
                                onSelect(true, selectedOptions, [
                                  ...modifierPairingOptions,
                                  {
                                    ...showingModifierPairingOption,
                                    orderItem: {
                                      ...item,
                                      paired_modifier_group_name: modifier.name,
                                    },
                                    pairing_option_id:
                                      showingModifierPairingOption.id,
                                  },
                                ]);
                              } else {
                                setModifierPairingOptions(prev => [
                                  ...prev.map(p =>
                                    p.id !== showingModifierPairingOption.id
                                      ? { ...p }
                                      : {
                                          ...p,
                                          orderItem: {
                                            ...item,
                                            paired_modifier_group_name:
                                              modifier.name,
                                          },
                                        },
                                  ),
                                ]);
                                onSelect(true, selectedOptions, [
                                  ...modifierPairingOptions.map(p =>
                                    p.id !== showingModifierPairingOption.id
                                      ? { ...p }
                                      : {
                                          ...p,
                                          orderItem: {
                                            ...item,
                                            paired_modifier_group_name:
                                              modifier.name,
                                          },
                                        },
                                  ),
                                ]);
                              }
                              setShowingModifierPairingOption(null);
                            }}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ModifierTable;
