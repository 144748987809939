import { message, Spin } from 'antd';
import useSelection from 'antd/lib/table/hooks/useSelection';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { guestApi } from '../../api/guest';
import {
  addGuestOrders,
  setGuestOrders,
  setViewingOrder,
} from '../../features/auth/auth';
import { TTOrderV2 } from '../../interfaces/order';
import { TTUserProfile } from '../../interfaces/user';
import { RootState } from '../../rootReducer';
import Hashids from 'hashids';
import moment from 'moment';
import { restaurantApi } from '../../api/restaurant';
import { LoadingIcon } from '../components/Spinner';
import { useNavigate } from 'react-router';
type Props = {};

export function OrderList() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);

  const guestOrders = useSelector<RootState, TTOrderV2[]>(
    state => state.auth.guest_orders,
  );
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const guestProfile = useSelector<RootState, TTUserProfile | null>(
    state => state.auth.userProfile,
  );
  const [restaurantProfiles, setRestaurantProfiles] = useState({});
  const hashids = new Hashids();
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetch = async () => {
    try {
      setFetching(true);
      const response = await guestApi.getOrders(
        guestProfile?.id,
        page * limit,
        limit,
      );
      dispatch(setGuestOrders(response.data));
      setFetching(false);
    } catch (e) {
      setFetching(false);
      message.error(e?.response?.data?.detail ?? 'Unable to fetch your orders');
    }
  };

  const fetchProfile = async (id: number) => {
    try {
      const response = await restaurantApi.getProfile(id);
      let profiles = { ...restaurantProfiles };
      profiles[id?.toString()] = response.data;
      setRestaurantProfiles({ ...profiles });
    } catch (e) {}
  };

  useMemo(() => {
    if (guestProfile !== null) {
      fetch();
    }
  }, [page]);

  useMemo(() => {
    if (guestProfile !== null) {
      fetch();
    }
  }, [guestProfile?.id]);
  return (
    <>
      <div className="mt-8 flex flex-col">
        <div className="overflow-x-auto ">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden ">
              <div className="sm:flex-auto">
                <h3 className="text-lg font-semibold text-gray-900">Orders</h3>
              </div>
              <Spin spinning={fetching} indicator={LoadingIcon}>
                <div className="min-w-full ">
                  <div className="flex flex-col space-y-2 ">
                    {guestOrders?.map(order => (
                      <div
                        key={order?.id?.toString()}
                        className="rounded-md bg-white px-2 py-2"
                        onClick={() => {
                          dispatch(setViewingOrder(order));
                          navigate(`orders/${order?.id}`);
                        }}
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex flex-col">
                            <label className="text-xs font-semibold">
                              Order Id
                            </label>
                            <td className="whitespace-nowrap  text-sm font-medium text-gray-900 ">
                              {hashids.encode(order?.id)}
                            </td>
                          </div>
                          <div className="flex flex-col">
                            <label className="text-xs font-semibold">
                              Order Date
                            </label>

                            <td className="whitespace-nowrap  text-sm text-gray-500 sm:pr-6">
                              {moment
                                .utc(order?.created_at)
                                .tz(tz)
                                .format('lll')}
                            </td>
                          </div>
                        </div>

                        <td className="whitespace-nowrap text-sm text-gray-500 pt-2">
                          <div
                            className={`rounded-md  ${
                              order?.order_type === 'dine_in'
                                ? 'bg-gray-900'
                                : order?.order_type === 'catering'
                                ? 'bg-indigo-800'
                                : order?.order_type === 'delivery'
                                ? 'bg-orange-600'
                                : order?.order_type === 'hotel'
                                ? 'bg-emerald-600'
                                : order?.order_type === 'sts'
                                ? 'bg-cyan-600'
                                : 'bg-yellow-600'
                            }`}
                          >
                            <div className="px-2 py-1 text-center">
                              <p className="font-medium mb-0 text-white">
                                {order?.order_type === 'dine_in'
                                  ? 'Dine-in'
                                  : order?.order_type === 'pick_up' ||
                                    order?.order_type === 'take_out'
                                  ? 'Pickup/Takeout'
                                  : order?.order_type === 'catering'
                                  ? 'Catering'
                                  : order?.order_type === 'hotel'
                                  ? 'Hotel'
                                  : order?.order_type === 'sts'
                                  ? 'Short Term Stays'
                                  : 'Delivery'}
                              </p>
                            </div>
                          </div>
                        </td>
                      </div>
                    ))}
                  </div>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </div>

      <nav
        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-md my-2 shadow"
        aria-label="Pagination"
      >
        <div className="hidden sm:block"></div>
        <div className="flex-1 flex justify-between sm:justify-end">
          <div
            onClick={() => {
              if (page !== 0) {
                setPage(page - 1);
              }
            }}
            className="cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </div>
          <div
            onClick={() => {
              if (guestOrders?.length === limit) {
                setPage(page + 1);
              }
            }}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </div>
        </div>
      </nav>
    </>
  );
}
