import React, { Fragment, useState } from 'react';
import { getFullImageUrl, centsToDollars } from '../../../utils';
import store from '../../../../store';
import { TTMenuItem } from '../../../../interfaces/menuItem';
import PillLabel from '../../../../components/PillLabel';
import LazyLoad from 'react-lazyload';
import { Transition } from '@headlessui/react';
import { TTMenu } from '../../../../interfaces/menus';

type Props = {
  menuItem: TTMenuItem;
  onClick: () => void;
  menu?: TTMenu;
};

const MenuItemCard: React.FC<Props> = ({ menuItem, onClick, menu }) => {
  const [loading, setLoading] = useState(true);
  return (
    <li key={menuItem.id} onClick={onClick} className={'w-full h-full'}>
      <Transition
        appear={true}
        show={true}
        as={'div'}
        key={menuItem.id.toString()}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <LazyLoad
          offset={100}
          className="w-full h-full rounded transition ease-in-out delay-50  hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer"
        >
          <div className="flex-grow flex flex-row bg-white p-4 items-between rounded-lg shadow-lg justify-between">
            <div className="flex-grow flex flex-col justify-between leading-normal">
              <div className="mb-2">
                <div className="text-gray-900 font-bold text-sm mb-2 mr-1">
                  {menuItem.name}
                </div>
                <div className="max-h-20">
                  <p className="text-gray-700 text-xs truncate-4-lines mr-1 whitespace-pre-line">
                    {menuItem.description}
                  </p>
                </div>
              </div>
              <div className="flex flex-row justify-left items-center  space-x-1 space-y-0">
                <div>
                  <PillLabel
                    color="green"
                    label={`${
                      store.getState().restaurant.profile.is_payment_live
                        ? 'Click to Add'
                        : 'Click to View'
                    }`}
                  />
                </div>
                {menuItem.price !== 0 && (
                  <div className="">
                    <PillLabel
                      color="gray"
                      label={centsToDollars(menuItem.price)}
                    />
                  </div>
                )}
              </div>
            </div>
            {menuItem.image_url !== null &&
              !RegExp(
                `^(https:\/\/d2b8t2l9x5xm7h.cloudfront.net\/public\/[0-9]*\/100.jpg).*`,
              ).test(menuItem.image_url) && (
                <img
                  className={`  flex w-32 h-32 shrink-0 rounded-lg object-cover `}
                  src={getFullImageUrl(
                    menu?.is_shared
                      ? menu?.restaurant_id
                      : store.getState().restaurant.profile.restaurant_id,
                    menuItem.image_url,
                  )}
                  alt={menuItem.name + "'s photo"}
                />
              )}
          </div>
        </LazyLoad>
      </Transition>
    </li>
  );
};

export default MenuItemCard;
