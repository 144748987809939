import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { useNavigate, useParams } from 'react-router-dom';
import { logEvent, getAnalytics } from 'firebase/analytics';

type Props = {
  cta?: string;
};

const CartBanner: React.FC<Props> = ({ cta = 'View Cart' }) => {
  const cart = useSelector<RootState, boolean>(state => {
    return state.cart.cart_items.length != 0;
  });
  const analytics = getAnalytics();

  const navigate = useNavigate();
  // @ts-ignore
  const { r_id } = useParams();

  return (
    <>
      {cart && (
        <div
          className="w-full fixed bottom-0 md:-mx-8 lg:-mx-16 xl:-mx-32"
          onClick={() => {
            logEvent(analytics, 'view_cart_clicked');
            logEvent(analytics, 'view_cart', {
              merchant_id: r_id,
            });
            navigate(`/feed/restaurants/${r_id}/plate`);
          }}
        >
          <div className="flex flex-row bg-primary-600 md:rounded-t-md  p-4 md:mx-8 lg:mx-16 xl:mx-32 items-center justify-center shadow-sm">
            <div className="flex flex-col justify-center leading-normal">
              <div className="flex items-center">
                <div className="text-white font-bold text-lg text-center mx-auto">
                  {cta}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CartBanner;
