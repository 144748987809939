import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { useNavigate, useParams } from 'react-router-dom';
import { TTOrderV2 } from '../../../interfaces/order';
import { getAnalytics, logEvent } from 'firebase/analytics';

type Props = {};

const OrderBanner: React.FC<Props> = () => {
  const navigate = useNavigate();
  // @ts-ignore
  const { r_id } = useParams();

  const order = useSelector<RootState, TTOrderV2>(state => {
    return state.order.order;
  });

  return (
    <>
      {
        <div
          className="w-full sticky bottom-0"
          onClick={() => {
            logEvent(getAnalytics(), 'view_order_clicked');
            navigate(`/feed/restaurants/${r_id}/order/${order.id}`);
          }}
        >
          <div className="flex flex-row bg-primary-600 rounded-t-md p-4 justify-center items-center shadow-sm">
            <div className="flex flex-col justify-center leading-normal ">
              <div className="flex items-center">
                <span className="text-white font-bold text-lg">
                  Current Tab
                </span>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default OrderBanner;
