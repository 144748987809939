import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import store, { AppThunk } from '../../store';
import { TTOrderItemCreate } from '../../interfaces/order_item';
import { TTCheck } from '../../interfaces/check';
import {
  OrderFulfillmentCateringDetailsCreate,
  OrderFulfillmentDeliveryDetailsCreate,
  OrderFulfillmentHotelDetailsCreate,
  OrderFulfillmentPickupDetailsCreate,
  OrderFulfillmentSTSDetailsCreate,
  TTOrder,
  TTOrderFulfillmentCreate,
  TTOrderV2,
  TTOrderV2Create,
} from '../../interfaces/order';
import { TTIDModMap } from '../../interfaces/modifier';
import { TTPromotion, TTPromotionEvent } from '../../interfaces/promotion';
import * as Sentry from '@sentry/react';
import { promotionsApi } from '../../api/promotions';
import {
  getModifierPairingTotal,
  getPrice,
} from '../../app/Cart/components/DishCard';
import { TTPaymentIntentCreate } from '../../interfaces/payment';
import { ordersApi } from '../../api/order';
import { GuestAddress } from '../../interfaces/user';
import { restaurantApi } from '../../api/restaurant';

/* eslint-disable */

export type OrderTypes =
  | 'dine_in'
  | 'take_out'
  | 'pick_up'
  | 'catering'
  | 'delivery'
  | 'hotel'
  | 'sts';
interface CartState {
  cart_items: TTOrderItemCreate[] | null;
  tip_percent: number | null;
  check: TTCheck;
  order_create: TTOrderV2Create | null;
  cart_mod: TTIDModMap | null;
  promotion: TTPromotion | null;
  promotion_event: TTPromotionEvent | null;
  order_type: OrderTypes;
  cart_merchants: number[];
  selected_address: GuestAddress | null;
  fulfillment_details: { [key: string]: TTOrderFulfillmentCreate } | null;
  catering_details: {
    [key: string]: OrderFulfillmentCateringDetailsCreate;
  } | null;
  pickup_details: { [key: string]: OrderFulfillmentPickupDetailsCreate } | null;
  delivery_details: {
    [key: string]: OrderFulfillmentDeliveryDetailsCreate;
  } | null;
  hotel_details: { [key: string]: OrderFulfillmentHotelDetailsCreate } | null;
  sts_details: { [key: string]: OrderFulfillmentSTSDetailsCreate } | null;
  qr_code_menu_ids: { [key: string]: number[] };
  qr_code: string;
  existing_order: TTOrderV2 | null;
  files_read: { [key: string]: boolean };
}

const initialState: CartState = {
  cart_items: JSON.parse(localStorage.getItem('cart_items')) ?? [],
  check: {
    num_items: 0,
    tabletab_discount: 0,
    restaurant_discount: 0,
    tip: 0,
    tax: 0,
    sub_total: 0,
    total: 0,
    service_fee: 0,
    restaurant_service_charges: [],
  },
  cart_merchants: [],
  tip_percent: 0.22,
  order_create: JSON.parse(localStorage.getItem('__tt_cart_order')) ?? null,
  cart_mod: JSON.parse(localStorage.getItem('__tt_cart_mod')) ?? null,
  promotion: JSON.parse(localStorage.getItem('__tt_promotion')) ?? null,
  promotion_event:
    JSON.parse(localStorage.getItem('__tt_promotion_event')) ?? null,
  // @ts-ignore
  order_type: localStorage.getItem('__tt_dining_mode') ?? 'dine_in',
  selected_address:
    JSON.parse(localStorage.getItem('__tt_selected_address')) ?? null,
  fulfillment_details:
    JSON.parse(localStorage.getItem('__tt_fulfillment_details')) ?? {},
  catering_details:
    JSON.parse(localStorage.getItem('__tt_catering_details')) ?? {},
  pickup_details: JSON.parse(localStorage.getItem('__tt_pickup_details')) ?? {},
  delivery_details:
    JSON.parse(localStorage.getItem('__tt_delivery_details')) ?? {},
  hotel_details: JSON.parse(localStorage.getItem('__tt_hotel_details')) ?? {},
  sts_details: JSON.parse(localStorage.getItem('__tt_sts_details')) ?? {},
  qr_code_menu_ids: JSON.parse(localStorage.getItem('__tt_qr_menu_ids')) ?? {},
  qr_code: JSON.parse(localStorage.getItem('__tt_qr')) ?? null,
  files_read: JSON.parse(localStorage.getItem('__tt_files_read')) ?? {},
  existing_order: null,
};

const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addNewItem(state, action: PayloadAction<TTOrderItemCreate>) {
      state.cart_items.push(action.payload);
      localStorage.setItem('cart_items', JSON.stringify(state.cart_items));
      state.order_create.order_items.push(action.payload);
      localStorage.setItem(
        '__tt_cart_order',
        JSON.stringify(state.order_create),
      );
    },
    clearCart(state, action: PayloadAction<number>) {
      state.cart_items = [];
      localStorage.setItem('cart_items', JSON.stringify(state.cart_items));
      state.order_create = null;
      localStorage.removeItem('__tt_cart_order');
      state.check = {
        num_items: 0,
        tabletab_discount: 0,
        restaurant_discount: 0,
        tip: 0,
        tax: 0,
        sub_total: 0,
        total: 0,
        service_fee: 0,
        restaurant_service_charges: [],
      };
      localStorage.removeItem('__tt_cart_check');
      localStorage.removeItem('__tt_table_number');
      localStorage.removeItem('__tt_cart_check');
      localStorage.removeItem('cart_items');
      localStorage.removeItem('__tt_selected_address');
      localStorage.removeItem('__tt_fulfillment_details');
      localStorage.removeItem('__tt_catering_details');
      localStorage.removeItem('__tt_pickup_details');
      localStorage.removeItem('__tt_delivery_details');
      localStorage.removeItem('__tt_sts_details');
      localStorage.removeItem('__tt_files_read');
      state.selected_address = null;
      state.fulfillment_details = null;
      state.catering_details = null;
      state.delivery_details = null;
      state.pickup_details = null;
      state.sts_details = null;
      state.hotel_details = null;
      state.files_read = {};
      // state.qr_code_menu_ids = [];
    },
    removePromotion(state, action: PayloadAction<number>) {
      if (
        state?.promotion !== null &&
        state?.promotion?.restaurant_id !== action.payload
      ) {
        state.promotion = null;
        state.promotion_event = null;
        localStorage.removeItem('__tt_promotion');
        localStorage.removeItem('__tt_promotion_event');
      }
    },
    updateTipPercent(state, action: PayloadAction<number>) {
      state.tip_percent = action.payload / 100;
      state.order_create.tip_percentage = action.payload / 100;
      localStorage.setItem(
        '__tt_cart_order',
        JSON.stringify(state.order_create),
      );
    },
    setCheck(state, action: PayloadAction<TTCheck>) {
      state.check = action.payload;
      localStorage.setItem('__tt_cart_check', JSON.stringify(state.check));

      localStorage.setItem(
        '__tt_cart_order',
        JSON.stringify(state.order_create),
      );
    },
    removeItem(state, action: PayloadAction<TTOrderItemCreate>) {
      state.cart_items = state.cart_items.filter(
        item => item.cart_insert_ts !== action.payload.cart_insert_ts,
      );

      if (state.order_create) {
        state.order_create.order_items = state.cart_items;
        localStorage.setItem(
          '__tt_cart_order',
          JSON.stringify(state.order_create),
        );
        localStorage.setItem(
          'cart_items',
          JSON.stringify(state.order_create.order_items),
        );
      }
    },
    replaceItem(state, action: PayloadAction<TTOrderItemCreate>) {
      console.log(action.payload);
      state.cart_items = [
        ...state.cart_items.map(item =>
          item.cart_insert_ts === action.payload.cart_insert_ts &&
          item.menu_item_id === action.payload.menu_item_id
            ? { ...action.payload }
            : item,
        ),
      ];
      console.log('udpated items');
      console.log(state.cart_items);
      state.order_create.order_items = [...state.cart_items];
      localStorage.setItem(
        '__tt_cart_order',
        JSON.stringify(state.order_create),
      );
      localStorage.setItem(
        'cart_items',
        JSON.stringify(state.order_create.order_items),
      );
    },
    emptyCart(state, action: PayloadAction) {
      state.cart_items = [];
      state.order_create.order_items = [];
      localStorage.setItem(
        'cart_items',
        JSON.stringify(state.order_create.order_items),
      );
      localStorage.setItem(
        '__tt_cart_order',
        JSON.stringify(state.order_create),
      );
    },
    setOrderCreate(state, action: PayloadAction<TTOrderV2Create>) {
      state.order_create = action.payload;
      localStorage.setItem('__tt_cart_order', JSON.stringify(action.payload));
    },
    setTableNumber(state, action: PayloadAction<string>) {
      state.order_create.table_number = action.payload;
      localStorage.setItem(
        '__tt_cart_order',
        JSON.stringify(state.order_create),
      );
    },
    addPromotion(state, action: PayloadAction<TTPromotion>) {
      state.promotion = action.payload;
      localStorage.setItem('__tt_promotion', JSON.stringify(action.payload));
    },
    setPromotionEvent(state, action: PayloadAction<TTPromotionEvent>) {
      state.promotion_event = action.payload;
      localStorage.setItem(
        '__tt_promotion_event',
        JSON.stringify(action.payload),
      );
    },
    changeOrderType(state, action: PayloadAction<OrderTypes>) {
      if (state.order_create !== null) {
        state.order_create.order_type = action.payload;
        state.order_type = action.payload;
        localStorage.setItem(
          '__tt_cart_order',
          JSON.stringify(state.order_create),
        );
      }
      state.order_type = action.payload;
    },
    setPaymentCreate(state, action: PayloadAction<TTPaymentIntentCreate>) {
      state.order_create.payment_create = action.payload;
      localStorage.setItem(
        '__tt_cart_order',
        JSON.stringify(state.order_create),
      );
    },
    setSelectedAddress(state, action: PayloadAction<GuestAddress>) {
      state.selected_address = action.payload;
      localStorage.setItem(
        '__tt_selected_address',
        JSON.stringify(action.payload),
      );
    },
    setFulfillmentDetails(
      state,
      action: PayloadAction<{ [key: string]: TTOrderFulfillmentCreate }>,
    ) {
      if (action.payload !== null) {
        let new_values: { [key: string]: TTOrderFulfillmentCreate } = {};
        Object.keys(action.payload)?.forEach(k => {
          new_values[k] = action.payload[k];
        });
        state.fulfillment_details = {
          ...state.fulfillment_details,
          ...new_values,
        };
        localStorage.setItem(
          '__tt_fulfillment_details',
          JSON.stringify(state.fulfillment_details),
        );
      }
    },
    setCateringDetails(
      state,
      action: PayloadAction<{
        [key: string]: OrderFulfillmentCateringDetailsCreate;
      }>,
    ) {
      if (action.payload) {
        let new_values: {
          [key: string]: OrderFulfillmentCateringDetailsCreate;
        } = {};
        Object.keys(action.payload).forEach(k => {
          new_values[k] = action.payload[k];
        });
        state.catering_details = { ...state.catering_details, ...new_values };
        localStorage.setItem(
          '__tt_catering_details',
          JSON.stringify(state.catering_details),
        );
      }
    },
    setPickupDetails(
      state,
      action: PayloadAction<{
        [key: string]: OrderFulfillmentPickupDetailsCreate;
      }>,
    ) {
      if (action.payload) {
        let new_values: {
          [key: string]: OrderFulfillmentPickupDetailsCreate;
        } = {};
        Object.keys(action.payload).forEach(k => {
          new_values[k] = action.payload[k];
        });
        state.pickup_details = { ...state.pickup_details, ...new_values };
        localStorage.setItem(
          '__tt_pickup_details',
          JSON.stringify(state.pickup_details),
        );
      }
    },
    setDeliveryDetails(
      state,
      action: PayloadAction<{
        [key: string]: OrderFulfillmentDeliveryDetailsCreate;
      }>,
    ) {
      if (action.payload) {
        let new_values: {
          [key: string]: OrderFulfillmentDeliveryDetailsCreate;
        } = {};
        Object.keys(action.payload).forEach(k => {
          new_values[k] = action.payload[k];
        });
        state.delivery_details = { ...state.delivery_details, ...new_values };
        localStorage.setItem(
          '__tt_delivery_details',
          JSON.stringify(state.delivery_details),
        );
      }
    },
    setHotelDetails(
      state,
      action: PayloadAction<{
        [key: string]: OrderFulfillmentHotelDetailsCreate;
      }>,
    ) {
      if (action.payload) {
        let new_values: {
          [key: string]: OrderFulfillmentHotelDetailsCreate;
        } = {};
        Object.keys(action.payload).forEach(k => {
          new_values[k] = action.payload[k];
        });
        state.hotel_details = { ...state.hotel_details, ...new_values };
        localStorage.setItem(
          '__tt_hotel_details',
          JSON.stringify(state.hotel_details),
        );
      }
    },
    setSTSDetails(
      state,
      action: PayloadAction<{
        [key: string]: OrderFulfillmentSTSDetailsCreate;
      }>,
    ) {
      if (action.payload) {
        let new_values: {
          [key: string]: OrderFulfillmentSTSDetailsCreate;
        } = {};
        Object.keys(action.payload).forEach(k => {
          new_values[k] = action.payload[k];
        });
        state.sts_details = { ...state.sts_details, ...new_values };
        localStorage.setItem(
          '__tt_sts_details',
          JSON.stringify(state.sts_details),
        );
      }
    },
    setQRMenuIds(state, action: PayloadAction<{ [key: string]: number[] }>) {
      Object.keys(action.payload).forEach(k => {
        state.qr_code_menu_ids[k] = action.payload[k];
      });
      localStorage.setItem(
        '__tt_qr_menu_ids',
        JSON.stringify(state.qr_code_menu_ids),
      );
    },
    setQR(state, action: PayloadAction<string>) {
      state.qr_code = action.payload;
      localStorage.setItem('__tt_qr', JSON.stringify(action.payload));
    },
    setFilesRead(state, action: PayloadAction<string>) {
      let files = { ...state.files_read };
      files[action.payload] = true;
      state.files_read = files;
      localStorage.setItem('__tt_files_read', JSON.stringify(state.files_read));
    },
    setExistingOrder(state, action: PayloadAction<TTOrderV2>) {
      state.existing_order = action.payload;
    },
    setCartMerchants(state, action: PayloadAction<number[]>) {
      state.cart_merchants = action.payload;
    },
  },
});

export const {
  addNewItem,
  clearCart,
  setCheck,
  removeItem,
  updateTipPercent,
  setOrderCreate,
  setTableNumber,
  addPromotion,
  emptyCart,
  setPromotionEvent,
  changeOrderType,
  replaceItem,
  removePromotion,
  setPaymentCreate,
  setSelectedAddress,
  setFulfillmentDetails,
  setCateringDetails,
  setPickupDetails,
  setDeliveryDetails,
  setHotelDetails,
  setSTSDetails,
  setQRMenuIds,
  setQR,
  setFilesRead,
  setExistingOrder,
  setCartMerchants,
} = cart.actions;

export default cart.reducer;

export const createOrderObj =
  (redirectTrigger?: Function): AppThunk =>
  async dispatch => {
    try {
      const order_obj: TTOrderV2Create = {
        table_number:
          store.getState().order.order !== null &&
          store.getState().order.order.is_open
            ? store.getState().order.order.table_number
            : localStorage.getItem('__tt_table_number') !== undefined
            ? localStorage.getItem('__tt_table_number')
            : store.getState().order.table_number,
        restaurant_ids: store
          .getState()
          .restaurant.profile.connected_vendor_ids?.concat([
            store.getState().restaurant.profile.restaurant_id,
          ]) ?? [store.getState().restaurant.profile.restaurant_id],
        primary_restaurant_id:
          store.getState().restaurant.profile.restaurant_id,
        order_items: store.getState().cart?.cart_items,
        platform: {
          [`${store.getState().auth.userProfile.firebase_id}`]: 'PWA',
        },
        order_type:
          localStorage.getItem('__tt_dining_mode') ??
          store.getState().cart.order_type ??
          'dine_in',
        tip_percentage: store.getState().cart.tip_percent ?? 0.22,
        promo_code: store.getState().cart?.promotion?.promo_id,
      };
      dispatch(setOrderCreate(order_obj));
      if (redirectTrigger) {
        redirectTrigger();
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

export const calculateCartCheck = (): AppThunk => async dispatch => {
  try {
    const existing_cart = store.getState().cart;
    const restaurant_profile = store.getState().restaurant.profile;
    let restaurant_with_items = new Set<number>();
    let sub_total = 0;
    let tip_subtotal = 0;
    let tax_subtotal = 0;
    let fee_subtotal = 0;
    let fee_subtotal_by_restaurant = {};
    let subtotal_by_restaurant = {};
    let discount_subtotal = 0;

    existing_cart.cart_items.forEach(item => {
      restaurant_with_items.add(item.restaurant_id);
      if (!item.as_pairing || !item.paired_with.is_pairing_option) {
        const mods_price = getPrice(item, getModifierPairingTotal(item));
        sub_total += mods_price * item.quantity;
        if (
          Object.keys(fee_subtotal_by_restaurant).includes(
            item.restaurant_id?.toString(),
          )
        ) {
          subtotal_by_restaurant[item.restaurant_id?.toString()] +=
            mods_price * item.quantity;
        } else {
          subtotal_by_restaurant[item.restaurant_id?.toString()] =
            mods_price * item.quantity;
        }
        if (!item.is_tax_excluded) {
          tax_subtotal += mods_price * item.quantity;
        }
        if (!item.is_tip_excluded) {
          tip_subtotal += mods_price * item.quantity;
        }
        if (!item.is_discount_excluded) {
          discount_subtotal += mods_price * item.quantity;
        }
        if (!item.is_service_fee_excluded) {
          fee_subtotal += mods_price * item.quantity;
          if (
            Object.keys(fee_subtotal_by_restaurant).includes(
              item.restaurant_id?.toString(),
            )
          ) {
            fee_subtotal_by_restaurant[item.restaurant_id?.toString()] +=
              mods_price * item.quantity;
          } else {
            fee_subtotal_by_restaurant[item.restaurant_id?.toString()] =
              mods_price * item.quantity;
          }
        }
      }
    });
    dispatch(setCartMerchants(Array.from(restaurant_with_items)));

    let discount = 0;
    if (store.getState().cart.promotion != null) {
      discount = store.getState().cart.promotion.is_guest_based
        ? store.getState().cart.promotion.promotion_value.type == 'PERCENT'
          ? Math.round(
              (store.getState().cart.promotion.promotion_value.value / 100) *
                (discount_subtotal / 100) *
                100,
            )
          : store.getState().cart.promotion.promotion_value.value
        : 0;
    }

    const response = await Promise.all(
      Array.from(restaurant_with_items.values())?.map(i => {
        return ordersApi.getGuestSideFee(
          fee_subtotal_by_restaurant[i?.toString()] - discount,
          store.getState().cart.order_create?.order_type ?? 'dine_in',
          i,
        );
      }),
    );

    const guest_side_service_fee = response
      .map(r => r.data.guest_service_fee)
      ?.reduce((a, b) => a + b, 0);

    const restaurant_service_charges = await Promise.all(
      Array.from(restaurant_with_items.values())?.map(i => {
        return ordersApi.getRestaurantServiceFees(
          subtotal_by_restaurant[i?.toString()] - discount,
          i,
          store.getState().cart?.order_type ?? 'dine_in',
        );
      }),
    );

    const taxed_service_fees = restaurant_service_charges
      ?.map(t => t.data)
      ?.reduce((a, b) => a.concat(b), [])
      .filter(a => a.taxable);

    const tax = Math.max(
      Math.round(
        ((tax_subtotal - discount) / 100 +
          guest_side_service_fee / 100 +
          (taxed_service_fees.map(a => a.amount).length != 0
            ? taxed_service_fees.map(a => a.amount).reduce((a, b) => a + b, 0)
            : 0) /
            100) *
          ((restaurant_profile.tax_rate ?? 7500) / 100000) *
          100,
      ),
      0,
    );
    const tip = Math.max(
      Math.round(
        existing_cart.tip_percent * ((tip_subtotal - discount) / 100) * 100,
      ),
      0,
    );

    const updatedCheck: TTCheck = {
      num_items: store.getState().cart.cart_items.length,
      tip: tip,
      sub_total: sub_total,
      tax: tax,
      restaurant_discount: discount > sub_total ? sub_total : discount,
      tabletab_discount: 0,
      total: Math.max(
        tip +
          tax +
          sub_total -
          discount +
          restaurant_service_charges
            ?.map(t => t.data)
            ?.reduce((a, b) => a.concat(b), [])
            ?.map(c => c.amount)
            .reduce((a, b) => a + b, 0),
        0,
      ),
      service_fee: guest_side_service_fee ?? 100,
      restaurant_service_charges: restaurant_service_charges
        ?.map(t => t.data)
        ?.reduce((a, b) => a.concat(b), []),
    };

    dispatch(setCheck(updatedCheck));
    return updatedCheck;
  } catch (e) {
    throw e;
  }
};

export const attemptNewItem =
  (newItem: TTOrderItemCreate, redirectTrigger: Function): AppThunk =>
  async dispatch => {
    try {
      if (newItem === undefined) {
        redirectTrigger(false);
        return;
      }

      if (store.getState().cart.order_create == null) {
        dispatch(
          createOrderObj(() => {
            dispatch(addNewItem(newItem));
          }),
        );
      } else {
        if (
          store
            .getState()
            .cart.cart_items.filter(
              c =>
                c.cart_insert_ts === newItem.cart_insert_ts &&
                c.menu_item_id === newItem.menu_item_id,
            ).length !== 0
        ) {
          dispatch(replaceItem(newItem));
        } else {
          dispatch(addNewItem(newItem));
        }
      }

      if (
        newItem.as_pairing &&
        newItem.paired_with !== undefined &&
        newItem.paired_with.is_pairing_option
      ) {
        redirectTrigger && redirectTrigger(true);
        return;
      }

      dispatch(calculateCartCheck());
      redirectTrigger(true);
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
      redirectTrigger(false);
    }
  };

export const setPickUpName =
  (name: string): AppThunk =>
  async dispatch => {
    try {
      const tableNumber = `P_${name.toUpperCase().substring(0, 2)}`;
      dispatch(setTableNumber(tableNumber));
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  };

export const attemptRemoveItem =
  (item: TTOrderItemCreate, redirectTrigger: Function): AppThunk =>
  async dispatch => {
    try {
      console.log('removing item');
      console.log(item);
      if (
        item.as_pairing &&
        item.paired_with !== undefined &&
        item.paired_with.is_pairing_option
      ) {
        dispatch(removeItem(item));
        redirectTrigger(true);
        return;
      }

      dispatch(removeItem(item));
      redirectTrigger(true);
    } catch (err) {
      Sentry.captureException(err);
      redirectTrigger(false);
    }
  };

export const attemptAddPromotion =
  (promotion: TTPromotion, redirect: Function): AppThunk =>
  async dispatch => {
    try {
      const cart = store.getState().cart;

      let order_create = { ...cart.order_create };
      order_create.promo_code = promotion.promo_id;
      dispatch(addPromotion(promotion));
      dispatch(setOrderCreate(order_create));
      redirect(true);
    } catch (err) {
      redirect(false);
      Sentry.captureException(err);
    }
  };

export const updateTableNumber =
  (tableNumber: string): AppThunk =>
  async dispatch => {
    try {
      if (store.getState().cart.order_create.table_number === null) {
        dispatch(setTableNumber(tableNumber));
      }
    } catch (e) {}
  };

export const createPromotionEvent = (): AppThunk => async dispatch => {
  try {
    const promotion = store.getState().cart.promotion;
    const promotionEvent = store.getState().cart.promotion_event;
    if (promotionEvent == null) {
      const promotionEvent: TTPromotionEvent = {
        promotion_id: promotion.id,
        restaurant_id: promotion.restaurant_id,
        guest_id: store.getState().auth.userProfile.phone_number,
        order_id: store.getState().order.firebase_order_id,
      };
      const event = await promotionsApi.createEvent(promotionEvent);
      dispatch(setPromotionEvent(event.data));
    }
  } catch (err) {
    Sentry.captureException(err);
  }
};
