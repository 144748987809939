import { StripePaymentRequestButtonElementOptions } from '@stripe/stripe-js';
import React, { useMemo } from 'react';

const useOptions = (paymentRequest, ts) => {
  const options: StripePaymentRequestButtonElementOptions = useMemo(
    () => ({
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: 'dark',
          height: '48px',
        },
      },
    }),
    [paymentRequest],
  );

  return options;
};

export default useOptions;
