import React from 'react';

type Props = {
  onClick: () => void;
};

const PaymentMethodsButton: React.FC<Props> = ({ onClick }) => {
  return (
    <>
      <div
        className="px-4 pb-2 pt-4 max-w-full justify-center items-center mb-4"
        onClick={onClick}
      >
        <button
          className={` bg-red-500 text-white  align-middle justify-center shadow-lg rounded-full w-full h-10 outline-none focus:outline-none`}
          onClick={() => {}}
        >
          Select Payment Method
        </button>
      </div>
    </>
  );
};

export default PaymentMethodsButton;
