import axios from 'axios';
import { apiUrl } from '../env';
import {
  PhoneVerificationCodeRequest,
  PhoneVerificationCodeRequestResponse,
} from '../interfaces/login';
import { TTOrderV2 } from '../interfaces/order';
import {
  TTUserProfileCreate,
  TTUserProfile,
  TTUserProfileUpdate,
  GuestAddress,
} from '../interfaces/user';
import { getAuthHeaders } from './utils';
/* eslint-disable */
export const guestApi = {
  async createGuest(user: TTUserProfileCreate) {
    return axios.post<TTUserProfile>(`${apiUrl}/api/v1/guests/`, user, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('tabletabToken')}`,
      },
    });
  },
  async updateGuest(user: TTUserProfileUpdate, id: number) {
    return axios.put<TTUserProfile>(`${apiUrl}/api/v1/guests/${id}`, user, {
      headers: await getAuthHeaders(),
    });
  },
  async getMe() {
    return axios.get<TTUserProfile>(`${apiUrl}/api/v1/guests/me`, {
      headers: await getAuthHeaders(),
    });
  },

  async getGuest(id: number) {
    return axios.get<TTUserProfile>(`${apiUrl}/api/v1/guests/${id}`, {
      headers: await getAuthHeaders(),
    });
  },

  async getGuestByFirebaseId(firebase_id: string) {
    return axios.get<TTUserProfile>(
      `${apiUrl}/api/v1/guests/by?f_id=${firebase_id}`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async claimGuest(orderId: number) {
    return axios.get<TTUserProfile>(
      `${apiUrl}/api/v1/guests/claim?order_id=${orderId}`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async verifyClaim(id: number, body: PhoneVerificationCodeRequest) {
    return axios.post<PhoneVerificationCodeRequestResponse>(
      `${apiUrl}/api/v1/guests/claim/${id}/verify`,
      body,
    );
  },
  async getGuestByFirebaseIdToken(firebase_id: string, token: string) {
    return axios.get<TTUserProfile>(
      `${apiUrl}/api/v1/guests/by?f_id=${firebase_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  },
  async saveAddress(guest_id: number, addressBody: GuestAddress) {
    return axios.post<GuestAddress>(
      `${apiUrl}/api/v1/guests/${guest_id}/address`,
      addressBody,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async getOrders(guest_id: number, skip: number, limit: number) {
    return axios.get<TTOrderV2[]>(
      `${apiUrl}/api/v1/guests/${guest_id}/orders?skip=${skip}&limit=${limit}`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
};
