import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
/* eslint-disable */
const ErrorIcon: React.FC = () => {
  return (
    <div className="mx-auto flex items-center justify-center h-8 w-8 rounded-full bg-red-100">
      <FontAwesomeIcon icon="times" />
    </div>
  );
};

export default ErrorIcon;
