import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react';
import { Spin, Drawer } from 'antd';
import { RootState } from '../../rootReducer';
import { LoadingIcon } from '../components/Spinner';
import { TTOrderItemCreate } from '../../interfaces/order_item';
import DishCard from './components/DishCard';
import store from '../../store';
import CheckInfoCard from './components/Check';
import NavBar from '../components/BackButton';
import PaymentMethodsButton from '../Payment/StripeElements/PaymentMethodsButton';
import {
  SquareCard,
  SquareGiftCard,
  StripeCard,
} from '../../interfaces/stripe_payment_method';
import { paymentsApi } from '../../api/payments';
import AddPaymentMethodButton from '../Payment/StripeElements/AddPaymentMethodButton';
import SelectedPMCard from '../Payment/StripeElements/SelectedPMCard';
import PaymentMethodList from '../Payment/StripeElements/PaymentMethodList';
import { useSpring, animated } from 'react-spring';
import {
  setPaymentMethods,
  setSelectedPM,
  setSquareGiftCards,
  toggleCardsFetched,
} from '../../features/payment/payment';
import ViewHeader from '../components/ViewHeader';
import TipCollection from './components/TipCollection';
import PlaceOrderButton from '../Order/components/PlaceOrderButton';
import MenuDish from '../MenuDish/MenuDish';
import {
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';
import { TTPaymentIntentCreate } from '../../interfaces/payment';
import {
  attemptAddPromotion,
  createOrderObj,
  updateTipPercent,
  createPromotionEvent,
  changeOrderType,
  calculateCartCheck,
  clearCart,
  setPaymentCreate,
  setOrderCreate,
} from '../../features/cart/cart';
import TableNumberField from './components/TableNumberField';
import { ordersApi } from '../../api/order';
import {
  fetchOrder,
  setOrder,
  setOrderPaymentIntent,
  setOrderTipPercentage,
} from '../../features/order/order';
import { message } from 'antd';
import ErrorIcon from '../components/ErrorIcon';
import PromotionField from './components/PromotionField';
import { TTPromotionRequest } from '../../interfaces/promotion';
import { promotionsApi } from '../../api/promotions';
import SuccessIcon from '../components/SuccessIcon';
import EmailField from './components/EmailField';
import { setUserProfile } from '../../features/auth/auth';
import { guestApi } from '../../api/guest';
import { logEvent, getAnalytics } from 'firebase/analytics';
import * as Sentry from '@sentry/react';
import EmailRequired from './components/EmailRequired';
import PaymentInfoCard from '../../components/AboutPayment';
import { TTUserProfile } from '../../interfaces/user';
import {
  TTOrder,
  TTOrderCreate,
  TTOrderV2,
  TTOrderV2Create,
} from '../../interfaces/order';
import usePaymentRequest from '../Payment/Wallet/usePaymentRequest';
import useOptions from '../Payment/Wallet/useOptions';
import { loadStripe } from '@stripe/stripe-js';
import { stripeKey } from '../../env';
import SwitchPaymentStyle from '../Payment/Wallet/SwitchPaymentStyle';
import { isIOS } from 'react-device-detect';
import { TTRestaurantProfile } from '../../interfaces/restaurant';
import useWindowDimensions from '../../hooks/windowDimension';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { getRestaurant } from '../../features/restaurant/restaurant';
import { WalkthroughPopUp } from '../WalkthroughPopUp';
import TableNumberRequired from './components/TableNumberRequired';
import OrderErrorPopUp from './components/OrderErrorPopUp';
import { LoadingOutlined } from '@ant-design/icons';
import PillLabel from '../../components/PillLabel';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import SquareCardView from '../Payment/SquareElements/SquareCardView';
import { AxiosResponse } from 'axios';
import { SquareApplePay } from './components/SquareApplePay';
import { SelectPaymentMethodHeader } from './components/SelectPaymentMethodHeader';
import { TableNumberConfirmationPopUp } from './components/TableNumberConfirmationPopUp';
import TableTabBanner from '../components/TableTabBanner';
import { CateringOrderDetails } from './components/CateringOrderDetails';
import { PickupOrderDetails } from './components/PickupOrderDetails';
import { DeliveryOrderDetails } from './components/DeliveryOrderDetails';
import {
  HotelOrderDetails,
  HotelSchedulingPicker,
} from './components/HotelOrderDetails';
import {
  STSOrderDetails,
  STSSchedulingPicker,
} from './components/STSOrderDetails';

type Props = {};

const Cart: React.FC<Props> = ({}) => {
  let stripe = useStripe();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [pmLoading, setPmsLoading] = useState(true);
  const [placingOrder, setPlacingOrder] = useState(false);
  const [showEmailNeeded, setShowEmailNeeded] = useState(false);
  const [showTableNumberNeeded, setShowTableNumberNeeded] = useState(false);

  const analytics = getAnalytics();

  const guest = useSelector<RootState, TTUserProfile>(
    state => state.auth.userProfile,
  );
  const order = useSelector<RootState, TTOrderV2>(
    state => state.cart.existing_order,
  );
  const cardsFetched = useSelector<RootState, boolean>(
    state => state.payment.cards_fetched,
  );

  const [confirmTableNumber, setConfirmTableNumber] = useState(false);

  const restaurant_profile = useSelector<RootState, TTRestaurantProfile>(
    state => state.restaurant.profile,
  );

  const cart = useSelector<RootState, TTOrderCreate>(
    state => state.cart.order_create,
  );

  // @ts-ignore
  const { r_id } = useParams();

  const paymentMethods = useSelector<RootState, any | null>(
    state => state.payment.payment_methods,
  );
  const [showWalkthrough, setShowWalkthrough] = useState(false);

  const selected_pm = useSelector<RootState, string>(state => {
    return state.payment.selected_pm;
  });

  const [showPaymentMethodsDrawer, setShowPaymentMethodsDrawer] =
    useState(false);

  const [preSetTip, setPreSetTip] = useState<number | null>(null);

  const [editDishId, setEditDishId] = useState<TTOrderItemCreate | null>(null);
  const sq_gfcs = useSelector<RootState, SquareGiftCard[]>(
    state => state.payment.square_gift_cards,
  );
  const payment_btn_ref = useRef();

  const email = useSelector<RootState, string | null>(
    state => state.auth.userProfile?.email ?? null,
  );

  const cart_merchants = useSelector<RootState, number[]>(
    state => state.cart.cart_merchants,
  );

  const total = useSelector<RootState, number>(state => {
    return (
      state.cart.check.total +
      (state.order.guest_check != null ? state.order.guest_check.total : 0) +
      (state.cart.check.service_fee ?? 100)
    );
  });

  const paymentRequest = usePaymentRequest({
    options: {
      country: 'US',
      currency: 'usd',
      total: {
        label:
          store.getState().restaurant.profile !== null
            ? store.getState().restaurant.profile.name
            : '',
        amount: total,
      },
    },
    onPaymentMethod: async ev => {
      setPlacingOrder(true);
      scrollToTop();
      await placeOrder(true, ev);
    },
    onCancel: ev => {
      setPlacingOrder(false);
    },
    ts: Date.now(),
  });

  const [canMakePayment, setCanMakePayment] = useState(false);

  const options = useOptions(paymentRequest, Date.now());
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const drawerProps = useSpring({
    opacity: showPaymentMethodsDrawer ? 1 : 0,
  });

  // check promo
  const checkPromo = async (promoId: string) => {
    try {
      const request: TTPromotionRequest = {
        promo_id: promoId,
        restaurant_id: store
          .getState()
          .restaurant.profile.restaurant_id.toString(),
        guest_id: store.getState().auth.userProfile.phone_number,
      };
      const response = await promotionsApi.validatePromo(request);
      dispatch(
        attemptAddPromotion(response.data, (status: boolean) => {
          if (status) {
            dispatch(calculateCartCheck());
          }
        }),
      );
      message.success({ content: 'Promotion applied', icon: <SuccessIcon /> });
      if (paymentRequest !== null) {
        paymentRequest.update({
          total: {
            label: `Pay - Previous charges will be canceled`,
            amount: total,
          },
        });
      }
      setLoading(false);
    } catch (err) {
      console.log(err.response);
      message.error({ content: 'Invalid promotion', icon: <ErrorIcon /> });
      setLoading(false);
    }
  };

  // handle placing order
  const placeOrder = async (is_wallet: boolean = false, ev?) => {
    if (!stripe) {
      setPlacingOrder(false);
      message.error({
        content: "Stripe didn't load correctly. Please reload the page.",
        icon: <ErrorIcon />,
      });
      return;
    }
    if (is_wallet) {
      localStorage.setItem(
        '__tt_wallet_token',
        restaurant_profile.processing_platform === 'STRIPE'
          ? ev.paymentMethod.id
          : ev,
      );
    }
    let payment_intent_payload: TTPaymentIntentCreate = {
      processing_platform: restaurant_profile.processing_platform,
      restaurant_ids: [restaurant_profile.restaurant_id],
      guest_id: store.getState().auth.userProfile.id,
      total_amount:
        store.getState().cart.check.total +
        (store.getState().order.guest_check != null
          ? store.getState().order.guest_check.total
          : 0),
      currency: 'USD',
      restaurant_id: store.getState().restaurant.profile.restaurant_id,
      receipt_address: email ?? 'none',
      is_wallet: is_wallet,
      payment_method_id: is_wallet
        ? restaurant_profile.processing_platform === 'STRIPE'
          ? ev.paymentMethod.id
          : ev
        : selected_pm,
      order_id: order?.id,
      table_number: store.getState().cart.order_create.table_number,
      tip_amount: store.getState().cart.check.tip,
    };
    dispatch(setPaymentCreate(payment_intent_payload));
    if (store.getState().cart.order_create?.order_type === null) {
      dispatch(changeOrderType('dine_in'));
    }
    try {
      let order: AxiosResponse<TTOrderV2>;

      if (
        store.getState().order?.order !== null &&
        !store.getState().order.order?.is_single_session
      ) {
        order = await ordersApi.updateOrder(
          store.getState().order.order_id,
          store.getState().cart.order_create,
        );
      } else {
        if (
          Object.keys(store.getState().cart.fulfillment_details ?? {})
            .length !== 0 &&
          store.getState().cart.qr_code !== null
        ) {
          dispatch(
            setOrderCreate({
              ...store.getState().cart.order_create,
              order_fulfillment_create:
                store.getState().cart.fulfillment_details,
              qr_code: store.getState().cart.qr_code,
            }),
          );
        } else if (
          Object.keys(store.getState().cart.fulfillment_details).length !== 0
        ) {
          dispatch(
            setOrderCreate({
              ...store.getState().cart.order_create,
              order_fulfillment_create:
                store.getState().cart.fulfillment_details,
            }),
          );
        } else if (store.getState().cart.qr_code !== null) {
          dispatch(
            setOrderCreate({
              ...store.getState().cart.order_create,
              qr_code: store.getState().cart.qr_code,
            }),
          );
        }
        order = await ordersApi.createOrder(store.getState().cart.order_create);
      }

      if (is_wallet && restaurant_profile.processing_platform === 'STRIPE') {
        ev.complete('success');
      }

      dispatch(setOrder(order.data));

      if (store.getState().cart.promotion !== null) {
        dispatch(createPromotionEvent());
      }
      dispatch(setOrderTipPercentage(store.getState().cart.tip_percent));
      logEvent(analytics, 'order_placed', {
        merchant_id: r_id,
        order_id: order?.data?.id,
      });
      dispatch(clearCart(parseInt(r_id)));
      localStorage.removeItem('__tt_wallet_token');
      navigate(`/feed/restaurants/${r_id}/order/${order.data.id}`);
    } catch (err) {
      console.log(err.response);
      if (is_wallet && restaurant_profile.processing_platform === 'STRIPE') {
        ev.complete('fail');
      }
      setPlacingOrder(false);
      Sentry.captureException(err);
      if (err.response !== undefined && err.response.data !== undefined) {
        if (
          err.response.data.detail ===
          'Order has been closed. Please enter your current table number, and try again.'
        ) {
          setConfirmTableNumber(true);
          return;
        }
        setError(
          err?.response?.data?.detail[0]?.msg ??
            err?.response?.data?.detail ??
            "Unable to process the order. Please don't refresh the page or cancel during processing.",
        );
        return;
      } else {
        setError(
          "Unable to process the order. Please don't refresh the page or cancel during processing.",
        );
      }
    }
  };

  // update the stripe payment request total on total change
  useMemo(() => {
    if (restaurant_profile !== null && paymentRequest && !placingOrder) {
      paymentRequest.update({
        total: {
          label: `${restaurant_profile.name} - All previous charges will be canceled`,
          amount: total,
        },
      });
    }
  }, [restaurant_profile, total]);

  // fetch guest
  useEffect(() => {
    let isMounted = true;
    async function fetch() {
      try {
        const response = await guestApi.getMe();
        setLoading(false);
        dispatch(setUserProfile(response.data));
      } catch (e) {
        setLoading(false);
        Sentry.captureException(e);
      }
    }

    async function getPaymentMethods() {
      try {
        const response = await paymentsApi.listPaymentMethods(
          restaurant_profile.processing_platform,
          restaurant_profile.company_id,
        );
        if (response.data.data.length == 0) {
          if (isMounted) {
            dispatch(toggleCardsFetched());
            setPmsLoading(false);
            dispatch(setPaymentMethods([]));
            dispatch(setSelectedPM(null));
          }
        } else {
          if (selected_pm !== null) {
            if (restaurant_profile.processing_platform == 'STRIPE') {
              let data: StripeCard[] = response.data.data;
              if (
                data.filter(card => {
                  return card.id == selected_pm;
                }).length == 0
              ) {
                if (isMounted) {
                  dispatch(toggleCardsFetched());
                  dispatch(setSelectedPM(null));
                  dispatch(setPaymentMethods([]));
                }
              }
            } else {
              // @ts-ignore
              let data: SquareCard[] = response.data.data;
              if (
                data.filter(card => {
                  return card.id == selected_pm;
                }).length == 0
              ) {
                if (isMounted) {
                  dispatch(toggleCardsFetched());
                  dispatch(setSelectedPM(null));
                  dispatch(setPaymentMethods([]));
                }
              }
            }
          }
          if (isMounted) {
            if (location.hash === '#payment-btn') {
              scrollPaymentBtnInView();
            }
            dispatch(toggleCardsFetched());
            setPmsLoading(false);
            dispatch(setPaymentMethods(response.data.data));
          }
        }
      } catch (err) {
        setPmsLoading(false);
        Sentry.captureException(err);
        dispatch(setSelectedPM(null));
      }
    }
    if (guest === null) {
      setLoading(true);
      fetch();
    } else {
      setLoading(false);
      getPaymentMethods();
    }
    return function cleanup() {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (
      guest !== null &&
      !isNaN(parseInt(localStorage.getItem('__tt_order_id'))) &&
      order === null
    ) {
      dispatch(
        fetchOrder(parseInt(localStorage.getItem('__tt_order_id')), () => {}),
      );
    }
  }, [guest?.id]);

  // fetching cards
  useEffect(() => {
    let isMounted = true;
    async function getPaymentMethods() {
      try {
        const response = await paymentsApi.listPaymentMethods(
          restaurant_profile.processing_platform,
          restaurant_profile.company_id,
        );
        if (response.data.data.length == 0) {
          if (isMounted) {
            dispatch(toggleCardsFetched());
            setPmsLoading(false);
            dispatch(setPaymentMethods([]));
            dispatch(setSelectedPM(null));
          }
        } else {
          if (selected_pm !== null) {
            if (restaurant_profile.processing_platform == 'STRIPE') {
              let data: StripeCard[] = response.data.data;
              if (
                data.filter(card => {
                  return card.id == selected_pm;
                }).length == 0
              ) {
                if (isMounted) {
                  dispatch(toggleCardsFetched());
                  dispatch(setSelectedPM(null));
                  dispatch(setPaymentMethods([]));
                }
              }
            } else {
              // @ts-ignore
              let data: SquareCard[] = response.data.data;
              if (
                data.filter(card => {
                  return card.id == selected_pm;
                }).length == 0
              ) {
                if (isMounted) {
                  dispatch(toggleCardsFetched());
                  dispatch(setSelectedPM(null));
                  dispatch(setPaymentMethods([]));
                }
              }
            }
          }
          if (isMounted) {
            if (location.hash === '#payment-btn') {
              scrollPaymentBtnInView();
            }
            dispatch(toggleCardsFetched());
            setPmsLoading(false);
            dispatch(setPaymentMethods(response.data.data));
          }
        }
      } catch (err) {
        setPmsLoading(false);
        Sentry.captureException(err);
        dispatch(setSelectedPM(null));
      }
    }

    if (
      guest !== null &&
      paymentMethods.length === 0 &&
      restaurant_profile !== null &&
      !cardsFetched
    ) {
      setPmsLoading(true);
      getPaymentMethods();
    } else if (guest !== null && paymentMethods !== null) {
      if (selected_pm !== null) {
        dispatch(
          setSelectedPM(
            paymentMethods.filter(p => p.id == selected_pm)?.length !== 0
              ? paymentMethods?.filter(p => p.id == selected_pm)[0]?.id
              : paymentMethods?.length !== 0
              ? paymentMethods[0]?.id
              : null,
          ),
        );
      }
    }
    return function cleanup() {
      isMounted = false;
    };
  }, [guest]);

  // fetch restaurant profile
  useEffect(() => {
    let isMounted = true;
    async function setRestaurantInfo() {
      dispatch(
        getRestaurant(parseInt(r_id), async () => {
          if (store.getState().restaurant.profile.is_fixed_grat ?? false) {
            if (isMounted) {
              setPreSetTip(store.getState().restaurant.profile.fixed_grat);
            }
            dispatch(
              updateTipPercent(store.getState().restaurant.profile.fixed_grat),
            );
            dispatch(
              setOrderTipPercentage(
                store.getState().restaurant.profile.fixed_grat,
              ),
            );
          }
        }),
      );
    }
    if (restaurant_profile === null) {
      setRestaurantInfo();
    } else {
      if (restaurant_profile.is_fixed_grat ?? false) {
        setPreSetTip(restaurant_profile.fixed_grat);
        dispatch(updateTipPercent(restaurant_profile.fixed_grat));
        dispatch(setOrderTipPercentage(restaurant_profile.fixed_grat));
      }
    }
    return function cleanup() {
      isMounted = false;
    };
  }, [restaurant_profile]);

  // loading payment request
  useEffect(() => {
    async function loadPaymentRequest() {
      dispatch(calculateCartCheck());
      if (paymentRequest && total) {
        paymentRequest.canMakePayment().then(async result => {
          if (result) {
            setCanMakePayment(true);
            paymentRequest.update({
              total: {
                label: `Pay ${store.getState().restaurant.profile.name}`,
                amount: total,
              },
            });
            stripe = await loadStripe(stripeKey, {
              stripeAccount:
                store.getState().restaurant.profile.stripe_account_id,
            });
          } else {
            setCanMakePayment(false);
          }
        });
      }
    }
    if (
      restaurant_profile !== null &&
      canMakePayment === false &&
      restaurant_profile.processing_platform === 'STRIPE'
    ) {
      loadPaymentRequest();
    }
    if (restaurant_profile !== null) {
      dispatch(calculateCartCheck());
    }
    if (
      restaurant_profile !== null &&
      restaurant_profile.processing_platform === 'SQUARE' &&
      restaurant_profile.sq_apple_pay_enabled
    ) {
      setCanMakePayment(true);
    }
  }, [restaurant_profile?.id]);

  // init empty order_state
  useEffect(() => {
    if (store.getState().cart.order_create === null) {
      dispatch(createOrderObj());
    }
    return () => {};
  }, []);

  async function switchOrderType() {
    try {
      dispatch(
        changeOrderType(cart.order_type === 'dine_in' ? 'pick_up' : 'dine_in'),
      );
      setLoading(false);
    } catch (e) {
      message.error(e.message);
    }
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  function scrollPaymentBtnInView() {
    // @ts-ignore
    payment_btn_ref.current?.scrollIntoView({ behavior: 'smooth' });
    navigate(location.pathname);
  }

  useEffect(() => {
    logEvent(analytics, 'cart_view');
    // ZendeskAPI('webWidget', 'show');
  }, []);

  async function tryPlacingOrder() {
    scrollToTop();
    setPlacingOrder(true);
    try {
      const resp = await guestApi.updateGuest(
        { email: email },
        store.getState().auth.guest_id,
      );
      dispatch(setUserProfile(resp.data));
      await placeOrder();
    } catch (err) {
      Sentry.captureException(err);
      setPlacingOrder(false);
      message.error({
        content:
          'Unable to process the order. If it persists, press the refresh button',
        icon: <ErrorIcon />,
      });
    }
  }

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 32, color: '#ff6f61' }} spin />
  );
  return (
    <>
      <div className="overflow-y-scroll scrolling-touch min-h-screen bg-gray-100">
        <Spin
          spinning={placingOrder}
          indicator={antIcon}
          size={'large'}
          tip="Processing..."
        >
          <NavBar show_refresh={false} hide={true} />
          <Spin
            spinning={loading}
            indicator={LoadingIcon}
            className="h-96 "
            wrapperClassName="md:mx-8 lg:mx-16 xl:mx-32"
          >
            <div className="flex justify-between items-center mt-4 ">
              <ViewHeader
                title={`${
                  order !== null && !order?.is_single_session
                    ? 'Add to your Tab'
                    : 'Start your Tab'
                }`}
                className={'text-left px-4'}
              />
              {order !== null && !order?.is_single_session && (
                <div className="px-4">
                  <button
                    onClick={() => {
                      navigate(`/feed/restaurants/${r_id}/order/${order.id}`);
                    }}
                    type="button"
                    className="inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    Table {order?.table_number}
                  </button>
                </div>
              )}
            </div>
            {loading && cart === null && (
              <main
                className={`flex-1 relative z-0 overflow-y-auto ${
                  loading ? 'pt-20' : 'pt-0'
                }`}
              />
            )}
            {!loading &&
              cart !== null &&
              restaurant_profile !== null &&
              guest !== null && (
                <div className="">
                  {isNaN(parseInt(localStorage.getItem('__tt_order_id'))) &&
                    store.getState().restaurant.profile !== null &&
                    store.getState().restaurant.profile.is_pickup &&
                    store.getState().cart.order_type === 'dine_in' && (
                      <div
                        className="inline-flex truncate text-sm px-4 pt-2 sm:px-4"
                        onClick={async () => {
                          setLoading(true);
                          await switchOrderType();
                        }}
                      >
                        <p className="text-gray-500 truncate group-hover:text-gray-900">
                          Switch to{' '}
                          {cart.order_type === 'dine_in'
                            ? 'Pick Up'
                            : 'Dine In'}
                        </p>
                      </div>
                    )}
                  {cart.order_items !== null && (
                    <>
                      <div
                        className={
                          'flex justify-between px-4 mt-4 items-center'
                        }
                      >
                        <label className=" block uppercase tracking-wide text-gray-700 text-xs font-bold">
                          Items
                        </label>
                        <div className="flex flex-row justify-end space-x-2 items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            className="text-center rounded-full h-6 w-6 text-xs text-primary-600"
                            fill={'#ff6f61'}
                            onClick={() =>
                              navigate(
                                `/feed/restaurants/${restaurant_profile.restaurant_id}/menus`,
                              )
                            }
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            onClick={() => {
                              dispatch(clearCart());
                              message.success('Cart cleared!');
                              navigate(
                                `/feed/restaurants/${restaurant_profile.restaurant_id}/menus`,
                              );
                            }}
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                      <table className="min-w-full mb-6 pt-10">
                        <tbody>
                          {[
                            ...cart.order_items
                              .filter(
                                cI =>
                                  cI.paired_with !== undefined &&
                                  !cI.paired_with?.is_pairing_option,
                              )
                              .map((item: TTOrderItemCreate, i) => {
                                return (
                                  <DishCard
                                    menuItem={item}
                                    onClick={() => {
                                      setEditDishId({ ...item });
                                    }}
                                    key={item.cart_insert_ts.toString()}
                                  />
                                );
                              }),
                          ]}
                        </tbody>
                      </table>
                    </>
                  )}

                  {order === null &&
                    cart_merchants?.map((c, i) => {
                      return store.getState().cart.order_type === 'catering' ? (
                        <>
                          <CateringOrderDetails restaurant_id={c?.toString()} />
                        </>
                      ) : store.getState().cart.order_type === 'delivery' ? (
                        <>
                          <DeliveryOrderDetails restaurant_id={c?.toString()} />
                        </>
                      ) : store.getState().cart.order_type === 'hotel' ? (
                        <>
                          <HotelOrderDetails restaurant_id={c?.toString()} />
                        </>
                      ) : store.getState().cart.order_type === 'sts' ? (
                        <>
                          <STSOrderDetails
                            restaurant_id={c?.toString()}
                            show_overlap_attributes={i === 0}
                          />
                        </>
                      ) : store.getState().cart.order_type === 'pick_up' ||
                        store.getState().cart.order_type === 'take_out' ? (
                        <>
                          <PickupOrderDetails restaurant_id={c?.toString()} />
                        </>
                      ) : (
                        <></>
                      );
                    })}

                  {
                    <TipCollection
                      preset={preSetTip !== null}
                      tip={preSetTip}
                    />
                  }
                  {
                    <PromotionField
                      onClick={async promo => {
                        logEvent(analytics, 'promo_check', { promo_id: promo });
                        setLoading(true);
                        await checkPromo(promo);
                      }}
                    />
                  }
                  {guest !== null && <CheckInfoCard />}
                  {(isNaN(parseInt(localStorage.getItem('__tt_order_id'))) ||
                    guest?.email === null) && (
                    <div className={'h-0.5 w-4/5 bg-gray-900 my-4 mx-auto'} />
                  )}
                  <div className={'flex flex-col'}>
                    {isNaN(parseInt(localStorage.getItem('__tt_order_id'))) &&
                      cart.order_type === 'dine_in' && (
                        <TableNumberField
                          onSwitch={async () => {
                            setLoading(true);
                            await switchOrderType();
                          }}
                        />
                      )}

                    {guest !== null && (
                      <>
                        <EmailField
                          onClick={async email => {
                            logEvent(analytics, 'set_email', { email: email });
                            setLoading(true);
                            try {
                              const resp = await guestApi.updateGuest(
                                { email: email },
                                guest.id,
                              );
                              dispatch(setUserProfile(resp.data));
                              setLoading(false);
                            } catch (err) {
                              console.log(email);
                              setLoading(false);
                              Sentry.captureException(err);
                            }
                          }}
                          callback={email => {
                            // setEmail(email);
                          }}
                        />
                      </>
                    )}
                  </div>

                  <div className={'h-0.5 w-4/5 bg-gray-900 my-4 mx-auto'} />
                  {!canMakePayment &&
                    paymentMethods.length !== 0 &&
                    selected_pm !== null &&
                    (restaurant_profile.processing_platform == 'STRIPE' ? (
                      <SelectedPMCard
                        card={
                          paymentMethods.filter(
                            (card, i) => card.id === selected_pm,
                          )[0]
                        }
                        onClick={() => {
                          setShowPaymentMethodsDrawer(true);
                        }}
                      />
                    ) : (
                      <SquareCardView
                        card={
                          paymentMethods.filter(
                            (card, i) => card.id === selected_pm,
                          )[0]
                        }
                        onClick={() => {
                          setShowPaymentMethodsDrawer(true);
                        }}
                      />
                    ))}
                  {(paymentRequest != null || selected_pm !== null) && (
                    <PaymentInfoCard
                      amount={
                        store.getState().cart.check.total +
                        store.getState().cart.check.service_fee
                      }
                    />
                  )}

                  {!canMakePayment &&
                    selected_pm === null &&
                    paymentMethods.length === 0 && (
                      <AddPaymentMethodButton
                        onClick={() => {
                          logEvent(analytics, 'add_payment_method');
                          navigate(
                            `/feed/restaurants/${r_id}/plate/payments/attach/${
                              restaurant_profile?.processing_platform ==
                              'STRIPE'
                                ? 'stripe'
                                : 'square'
                            }`,
                          );
                        }}
                      />
                    )}
                  {!canMakePayment &&
                    paymentMethods.length !== 0 &&
                    selected_pm === null && (
                      <PaymentMethodsButton
                        onClick={() => {
                          logEvent(analytics, 'view_payment_options');
                          setShowPaymentMethodsDrawer(true);
                        }}
                      />
                    )}
                  <div id="payment-btn" ref={payment_btn_ref}>
                    {selected_pm !== null && !canMakePayment && (
                      <PlaceOrderButton
                        bgColor={'bg-red-500'}
                        textColor={'text-white'}
                        onClick={async () => {
                          logEvent(analytics, 'place_order_clicked');
                          if (store.getState().cart.cart_items.length == 0) {
                            message.error({
                              content: 'Empty cart, please add items!',
                              icon: <ErrorIcon />,
                            });
                          } else {
                            if (
                              cart.table_number == '' ||
                              cart.table_number == null
                            ) {
                              setShowTableNumberNeeded(true);
                            } else {
                              if (
                                !restaurant_profile.table_numbers.includes(
                                  cart.table_number,
                                ) &&
                                cart.order_type === 'dine_in'
                              ) {
                                setShowTableNumberNeeded(true);
                              } else {
                                if (guest.email == null) {
                                  if (
                                    email == null ||
                                    !/\S+@\S+\.\S+/.test(email)
                                  ) {
                                    setShowEmailNeeded(true);
                                  } else {
                                    await tryPlacingOrder();
                                  }
                                } else {
                                  await tryPlacingOrder();
                                }
                              }
                            }
                          }
                        }}
                        className={`${
                          paymentRequest === null ? 'mb-10' : 'mb-4'
                        } `}
                        title={
                          order !== null && !order?.is_single_session
                            ? 'Add to your Tab'
                            : 'Start your Tab'
                        }
                      />
                    )}
                  </div>

                  {canMakePayment &&
                    (!/\S+@\S+\.\S+/.test(email) ||
                      (cart.order_type === 'dine_in'
                        ? !restaurant_profile.table_numbers.includes(
                            cart.table_number,
                          )
                        : cart.table_number === null ||
                          cart.table_number === '')) && (
                      <div
                        className="align-bottom bg-white rounded-lg text-left overflow-hidden shadow-lg sm:my-8 sm:align-middle sm:max-w-lg sm:w-full mx-4 mt-4 mb-4"
                        onClick={() => {
                          if (
                            cart.order_type === 'dine_in'
                              ? !restaurant_profile.table_numbers.includes(
                                  cart.table_number,
                                )
                              : cart.table_number === null ||
                                cart.table_number === ''
                          ) {
                            setShowTableNumberNeeded(true);
                          } else if (!/\S+@\S+\.\S+/.test(email)) {
                            setShowEmailNeeded(true);
                          }
                        }}
                      >
                        <div className="bg-white p-4">
                          <div className="sm:flex sm:items-start sm:flex-row ">
                            <div className="mx-auto flex-shrink-0 flex items-center justify-center">
                              <PillLabel
                                label={'Incomplete information'}
                                color={'red'}
                              />
                            </div>
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <div className="mt-2">
                                <p className="text-sm leading-5 text-gray-500 text-left">
                                  {`Please fill out the ${
                                    cart.order_type === 'dine_in'
                                      ? 'table number'
                                      : 'first name'
                                  } or email address to continue with Apple Pay`}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mx-auto flex-shrink-0 flex items-center justify-end">
                            <span
                              className={`px-2 inline-flex text-xs leading-5 font-medium rounded-full text-gray-900 `}
                            >
                              Click to learn more
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                  {restaurant_profile?.processing_platform == 'SQUARE' &&
                    restaurant_profile?.sq_apple_pay_enabled &&
                    canMakePayment &&
                    /\S+@\S+\.\S+/.test(email) &&
                    (cart.order_type === 'dine_in'
                      ? restaurant_profile.table_numbers.includes(
                          cart.table_number,
                        )
                      : cart.table_number !== null &&
                        cart.table_number !== '') && (
                      <div>
                        <SquareApplePay
                          onClick={async (token: string) => {
                            setPlacingOrder(true);
                            scrollToTop();
                            await placeOrder(true, token);
                          }}
                        />
                        <SwitchPaymentStyle
                          label="Pay with Card"
                          onClick={async () => {
                            setCanMakePayment(false);
                          }}
                        />
                      </div>
                    )}
                  {paymentRequest &&
                    canMakePayment &&
                    /\S+@\S+\.\S+/.test(email) &&
                    (cart.order_type === 'dine_in'
                      ? restaurant_profile.table_numbers.includes(
                          cart.table_number,
                        )
                      : cart.table_number !== null &&
                        cart.table_number !== '') &&
                    restaurant_profile?.processing_platform == 'STRIPE' && (
                      <>
                        <div className="bg-gray-100 min-w-4/5 h-14 rounded mb-4">
                          <PaymentRequestButtonElement
                            options={options}
                            className="PaymentRequestButton"
                            onClick={async () => {
                              try {
                                await guestApi.updateGuest(
                                  { email: email },
                                  guest.id,
                                );
                              } catch (err) {
                                console.log(err);
                                Sentry.captureException(err);
                              }
                            }}
                          />
                        </div>
                        <SwitchPaymentStyle
                          label="Pay with Card"
                          onClick={async () => {
                            stripe = await loadStripe(stripeKey);
                            setCanMakePayment(false);
                          }}
                        />
                      </>
                    )}
                  {!canMakePayment &&
                    paymentRequest !== null &&
                    stripe &&
                    restaurant_profile?.processing_platform == 'STRIPE' && (
                      <SwitchPaymentStyle
                        label={`Switch to ${
                          isIOS ? 'Apple Pay' : 'Google Pay'
                        }`}
                        onClick={async () => {
                          stripe = await loadStripe(stripeKey, {
                            stripeAccount:
                              store.getState().restaurant.profile
                                .stripe_account_id,
                          });
                          setCanMakePayment(true);
                        }}
                      />
                    )}

                  {!canMakePayment &&
                    restaurant_profile?.processing_platform == 'SQUARE' &&
                    restaurant_profile?.sq_apple_pay_enabled && (
                      <SwitchPaymentStyle
                        label={`Switch to ${
                          isIOS ? 'Apple Pay' : 'Google Pay'
                        }`}
                        onClick={async () => {
                          setCanMakePayment(true);
                        }}
                      />
                    )}
                  <div className="w-full">
                    <TableTabBanner />
                  </div>
                  {/* {paymentMethods !== null && showPaymentMethodsDrawer && ( */}
                  <animated.div
                    style={drawerProps}
                    className={
                      !showPaymentMethodsDrawer
                        ? 'transition-opacity duration-200'
                        : ''
                    }
                  >
                    <Drawer
                      title={<SelectPaymentMethodHeader />}
                      placement="bottom"
                      closable={false}
                      visible={
                        paymentMethods !== null && showPaymentMethodsDrawer
                      }
                      onClose={() => {
                        setShowPaymentMethodsDrawer(false);
                      }}
                      getContainer={false}
                      maskClosable={true}
                      bodyStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
                      height="600"
                      footer={
                        <AddPaymentMethodButton
                          onClick={() => {
                            navigate(
                              `/feed/restaurants/${r_id}/plate/payments/attach/${
                                restaurant_profile?.processing_platform ==
                                'STRIPE'
                                  ? 'stripe'
                                  : 'square'
                              }`,
                            );
                          }}
                        />
                      }
                    >
                      {paymentMethods !== null && showPaymentMethodsDrawer && (
                        <PaymentMethodList
                          platform={restaurant_profile.processing_platform}
                          callback={(id: string) => {
                            logEvent(analytics, 'payment_method_selected');
                            dispatch(setSelectedPM(id));
                            setShowPaymentMethodsDrawer(false);
                          }}
                        />
                      )}
                    </Drawer>
                  </animated.div>

                  <Transition.Root show={editDishId !== null} as={Fragment}>
                    <Dialog
                      as="div"
                      static
                      className="fixed inset-0 overflow-hidden"
                      open={editDishId !== null}
                      onClose={() => setEditDishId(null)}
                    >
                      <div className="absolute inset-0 overflow-hidden">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-in-out duration-500"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in-out duration-500"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          afterLeave={() => setEditDishId(null)}
                        >
                          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-y-0 bottom-0 pt-10 max-w-full max-h-full flex">
                          <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-y-full"
                            enterTo="translate-y-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-y-0"
                            leaveTo="translate-y-full"
                          >
                            <div className="w-screen max-w-md">
                              <div className="h-full flex flex-col py-6 bg-gray-100 shadow-xl overflow-y-scroll rounded-md">
                                <div className="px-4 sm:px-6">
                                  <div className="flex items-start justify-end">
                                    <div className="ml-3 h-4 flex items-center">
                                      <button
                                        className="bg-gray-100 rounded-md text-gray-900 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                        onClick={() => setEditDishId(null)}
                                      >
                                        <span className="sr-only">
                                          Close panel
                                        </span>
                                        <XIcon
                                          className="h-6 w-6"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-6 relative flex-1">
                                  {editDishId !== null && (
                                    <MenuDish
                                      editableVersion={true}
                                      menuItemId={editDishId.menu_item_id}
                                      i_ts={editDishId.cart_insert_ts}
                                      callback={() => {
                                        setEditDishId(null);
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition.Root>

                  {!loading && showEmailNeeded && (
                    <EmailRequired callback={() => setShowEmailNeeded(false)} />
                  )}
                  {!loading && showTableNumberNeeded && (
                    <TableNumberRequired
                      callback={() => setShowTableNumberNeeded(false)}
                    />
                  )}
                  {!loading && error !== null && (
                    <OrderErrorPopUp
                      callback={() => {
                        setError(null);
                        // history.go(0);
                        window.location.reload();
                      }}
                      error={error}
                    />
                  )}
                  {!loading && confirmTableNumber && (
                    <TableNumberConfirmationPopUp
                      callback={async () => {
                        setError(null);
                        window.location.reload();
                      }}
                    />
                  )}
                  {!loading && showWalkthrough && (
                    <WalkthroughPopUp
                      onCancel={() => {
                        setShowWalkthrough(false);
                      }}
                    />
                  )}
                </div>
              )}
          </Spin>
        </Spin>
      </div>
    </>
  );
};

export default Cart;
