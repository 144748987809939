import { getAnalytics, logEvent } from 'firebase/analytics';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { setMenu } from '../../../../features/restaurant/restaurant';
import { TTMenu } from '../../../../interfaces/menus';
import { RootState } from '../../../../rootReducer';
import { BUSINESS_TYPES, BUSINESS_TYPES_DICT } from '../../../../utils';
import { isMenuAvailable } from '../../../utils';
import MenuCard from './MenuCard';

type Props = {};

export function CategorizedMenuView() {
  const menus = useSelector<RootState, TTMenu[]>(
    state => state.restaurant.current_menus,
  );
  const [categorizedView, setCategorizedView] = useState<{
    [key: string]: TTMenu[];
  }>({});
  const qr_code_menu_ids = useSelector<RootState, { [key: string]: number[] }>(
    state => state.cart.qr_code_menu_ids,
  );
  const { r_id } = useParams();
  const qr = useSelector<RootState, string>(state => state.cart.qr_code);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useMemo(() => {
    let new_values: { [key: string]: TTMenu[] } = {};
    menus.forEach(m => {
      if (
        isMenuAvailable(m.hours ?? []) &&
        (qr !== null
          ? qr_code_menu_ids[qr]?.length === 0 ||
            qr_code_menu_ids[qr]?.includes(m?.id)
          : true)
      ) {
        new_values[m.business_type ?? 'food_beverage'] = new_values[
          m.business_type
        ]?.concat([m]) ?? [m];
      }
    });
    setCategorizedView(new_values);
  }, [menus]);

  return (
    <>
      <div className="my-4 md:mx-4 ">
        <div className="sticky top-0 z-10">
          <div
            className={`flex flex-no-wrap  overflow-x-scroll items-center pl-4 pr-4 scrolling-touch mb-2 mt-2   z-10 space-x-2 bg-gray-300 md:rounded-md`}
            onScroll={() => {}}
          >
            {Object.keys(categorizedView)?.map(category => {
              return (
                <a key={category} href={`#${category}`} className="py-4">
                  <div
                    className={` ${'bg-white'}  'text-gray-900'
                 text-center align-center text-sm px-4 content-center items-center py-1 rounded-full w-full h-8 shadow-md outline-none focus:outline-none`}
                  >
                    <span className="align-middle truncate font-semibold text-gray-900">
                      {BUSINESS_TYPES_DICT[category]}
                    </span>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
        {/* <div className="flex flex-nowrap bg-gray-300 overflow-x-scroll items-center px-4 py-2 space-x-2">
          
        </div> */}
        <div className="flex flex-col  px-4 my-2">
          {Object.keys(categorizedView)?.map(c => {
            return (
              <div key={c} className="overflow-x-scroll" id={c}>
                <div className="flex flex-row bg-gray-900 text-white font-semibold px-2  overflow-x-auto items-center rounded-md py-2">
                  {BUSINESS_TYPES_DICT[c]}
                </div>
                <div className="flex flex-row overflow-x-scroll space-x-2 my-2 pb-4">
                  {categorizedView[c]
                    ?.filter(
                      m =>
                        isMenuAvailable(m.hours ?? []) &&
                        (qr !== null
                          ? qr_code_menu_ids[qr]?.length === 0 ||
                            qr_code_menu_ids[qr]?.includes(m?.id)
                          : true),
                    )
                    ?.map(m => {
                      return (
                        <MenuCard
                          menu={m}
                          onClick={() => {
                            logEvent(getAnalytics(), 'menu_clicked', {
                              menu_id: m.id,
                              menu_name: m.name,
                              rank: m.rank,
                            });
                            dispatch(setMenu(m));
                            // setSelectedMenu(menu);
                            navigate(
                              `/feed/restaurants/${r_id}/menus/${m.id}/`,
                            );
                          }}
                          card_width="w-32"
                        />
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
