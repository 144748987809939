import React, { ReactNode } from 'react';
/* eslint-disable */
interface Props {
  children?: ReactNode;
  onClick: () => void;
}

const ModifiersTableHeader: React.FC<Props> = ({
  children,
  onClick,
}: Props) => {
  return (
    <thead>
      <tr
        className="flex items-center mb-4 shadow-md bg-white rounded-md"
        onClick={onClick}
      >
        {children}
      </tr>
    </thead>
  );
};

export default ModifiersTableHeader;
