import React from 'react';
import { getFullImageUrl } from '../../utils';
import { TTRestaurantProfile } from '../../../interfaces/restaurant';

type Props = {
  profile: TTRestaurantProfile;
  onClick: () => void;
};

const ResturantProfileCard: React.FC<Props> = ({ profile, onClick }) => {
  return (
    <div
      key={profile.id}
      onClick={onClick}
      className="bg-white p-4 rounded-lg shadow-md"
    >
      <div className="relative">
        <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden">
          <img
            src={getFullImageUrl(
              profile.restaurant_id,
              profile.main_profile_image,
            )}
            alt=""
            className="object-cover pointer-events-none group-hover:opacity-75"
          />
          <button type="button" className="absolute inset-0 focus:outline-none">
            <span className="sr-only">View details for </span>
          </button>
        </div>
        <p className="mt-2 block text-xl font-medium text-gray-900 truncate pointer-events-none">
          {profile.name}
        </p>
        <p className="block text-sm text-gray-500 pointer-events-none truncate-4-lines">
          {profile.bio}
        </p>
      </div>
    </div>
  );
};

export default ResturantProfileCard;
