import React from 'react';
import { centsToDollars } from '../../utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { TTCheck } from '../../../interfaces/check';
import { Tooltip } from 'antd';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { TTOrder, TTOrderV2 } from '../../../interfaces/order';

type Props = {
  from_order?: boolean;
  tip_callback?: () => void;
};

const CheckInfoCard: React.FC<Props> = ({
  from_order = false,
  tip_callback,
}) => {
  const check = useSelector<RootState, TTCheck>(state => {
    return from_order ? state.order.guest_check : state.cart.check;
  });

  const order = useSelector<RootState, TTOrderV2 | null>(
    state => state.order.order,
  );

  return (
    <div className="py-2 pr-4 pl-4 sm:h-1/5 max-h-1/5  rounded">
      <div className="flex flex-col bg-white p-4 items-center rounded-lg shadow-lg justify-between">
        <div className="flex flex-row mr-1 w-full mb-2 justify-between">
          <div className="text-gray-900  text-sm  text-left justify-left">
            Item Total
          </div>
          <div className="text-gray-900 text-sm text-right justify-right">
            {centsToDollars(check.sub_total)}
          </div>
        </div>
        {check.restaurant_discount != 0 && (
          <div className="flex flex-row mr-1 w-full mb-2  justify-between">
            <div className="text-green-500  text-sm  text-left justify-left">
              Discount
            </div>
            <div className="text-green-500 text-sm  text-right justify-right">
              -{centsToDollars(check.restaurant_discount)}
            </div>
          </div>
        )}
        <div className="flex flex-row mr-1 w-full mb-2 justify-between items-center">
          <div className="flex flex-row justify-start items-center">
            <div className="text-gray-900  text-sm  text-left justify-left">
              Tip
            </div>
            {from_order &&
              order?.order_type !== 'hotel' &&
              order?.order_type !== 'sts' && (
                <div
                  className="ml-1  px-2 rounded-full bg-blue-400 text-white"
                  onClick={tip_callback}
                >
                  Change
                </div>
              )}
          </div>

          <div className="text-gray-900  text-sm text-right justify-right">
            {centsToDollars(check.tip)}
          </div>
        </div>
        {check.restaurant_service_charges
          ?.filter(c => c.taxable && c?.amount !== 0)
          ?.map(c => {
            return (
              <div
                className="flex flex-row mr-1 w-full mb-2 justify-between"
                id={c.id?.toString()}
              >
                <div className="text-gray-900 text-sm  text-left justify-left items-center">
                  {c.description !== null && (
                    <Tooltip
                      title={c.description}
                      trigger={['click', 'hover', 'touch']}
                      className={'items-center'}
                    >
                      <div className="flex flex-row justify-start items-center space-x-1">
                        <div>{c.name ?? 'Service Charge'}</div>
                        <InformationCircleIcon
                          color={'gray-900'}
                          className={'w-4 h-4 text-gray-900 '}
                        />
                      </div>
                    </Tooltip>
                  )}
                  {c.description === null && (
                    <div>{c.name ?? 'Service Charge'}</div>
                  )}
                </div>
                <div className="text-gray-900  text-sm text-right justify-right">
                  {centsToDollars(c.amount)}
                </div>
              </div>
            );
          })}
        <div className="flex flex-row mr-1 w-full mb-2 justify-between">
          <div className="text-gray-900 text-sm  text-left justify-left">
            {'Taxes & Fees'}
          </div>
          <div className="text-gray-900  text-sm  text-right justify-right">
            {centsToDollars(check.tax + check.service_fee)}
          </div>
        </div>
        {check.restaurant_service_charges
          ?.filter(c => !c.taxable && c?.amount !== 0)
          ?.map(c => {
            return (
              <div
                className="flex flex-row mr-1  mb-2 w-full justify-between"
                id={c.id?.toString()}
              >
                <div className="text-gray-900 text-sm  text-left justify-left items-center">
                  {c.description !== null && (
                    <Tooltip
                      title={c.description}
                      trigger={['click', 'hover', 'touch']}
                      className={'items-center'}
                    >
                      <div className="flex flex-row justify-start items-center space-x-1">
                        <div>{c.name ?? 'Service Charge'}</div>
                        <InformationCircleIcon
                          color={'gray-900'}
                          className={'w-4 h-4 text-gray-900 '}
                        />
                      </div>
                    </Tooltip>
                  )}
                  {c.description === null && (
                    <div>{c.name ?? 'Service Charge'}</div>
                  )}
                </div>
                <div className="text-gray-900  text-sm  text-right justify-right">
                  {centsToDollars(c.amount)}
                </div>
              </div>
            );
          })}
        <div className="flex flex-row mr-1 w-full justify-between">
          <div className="text-gray-900 font-bold text-sm  text-left justify-left">
            Total
          </div>
          <div className="text-gray-900 font-bold text-sm  text-right justify-right">
            {centsToDollars(check.total + check.service_fee)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckInfoCard;
