import { message, Spin } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { paymentsApi } from '../../api/payments';
import { clearCart } from '../../features/cart/cart';
import {
  attemptCloseOrder,
  createGuestCheck,
  fetchOrder,
  setRequestTableNumberVerification,
} from '../../features/order/order';
import { getRestaurant } from '../../features/restaurant/restaurant';
import { TTOrderV2 } from '../../interfaces/order';
import { TTOrderItem } from '../../interfaces/order_item';
import { RootState } from '../../rootReducer';
import store from '../../store';
import CheckInfoCard from '../Cart/components/Check';
import DishCard from '../Cart/components/DishCard';
import ErrorIcon from '../components/ErrorIcon';
import { LoadingIcon } from '../components/Spinner';
import SuccessIcon from '../components/SuccessIcon';
import ViewHeader from '../components/ViewHeader';
import TabActionButton from './components/PlaceOrderButton';
import 'firebase/analytics';
import 'firebase/auth';
import * as Sentry from '@sentry/react';
import { PickUpOrderInfo } from './components/PickUpOrderInfo';
import { TTRestaurantProfile } from '../../interfaces/restaurant';
import { TTUserProfile } from '../../interfaces/user';
import { DineInInfo } from './components/DineInInfo';
import { TTCheck } from '../../interfaces/check';
import { getCurrentGuestProfile } from '../../features/auth/auth';
import { InfoAlert } from './components/InfoAlert';
import { centsToDollars } from '../utils';
import { getAnalytics, logEvent } from 'firebase/analytics';
import TableTabBanner from '../components/TableTabBanner';
import { Dialog, Transition } from '@headlessui/react';
import ChangeTipModal from './components/ChangeTipModal';
import { ChangeTableNumberModal } from './components/ChangeTableNumberModal';
import { OrderCateringDetails } from './components/OrderCateringDetails';
import { OrderDeliveryDetails } from './components/OrderDeliveryDetails';
import { OrderHotelDetails } from './components/OrderHotelDetails';
import { BookingConfirmationModal } from './components/BookingConfirmationModal';
import { OrderSTSDetails } from './components/OrderSTSDetails';

type Props = {};

const Order: React.FC<Props> = ({}) => {
  const guest = useSelector<RootState, TTUserProfile>(
    state => state.auth.userProfile,
  );
  // @ts-ignore
  const { r_id, o_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const order = useSelector<RootState, TTOrderV2>(state => state.order.order);
  const guestCheck = useSelector<RootState, TTCheck>(
    state => state.order.guest_check,
  );

  const [tip, setTip] = useState('Fetching...');

  const [loading, setLoading] = useState(true);

  const [showTipModal, setShowTipModal] = useState(false);

  const restaurant = useSelector<RootState, TTRestaurantProfile>(
    state => state.restaurant.profile,
  );

  const requestTableNumber = useSelector<RootState, boolean>(
    state => state.order.requestTableNumVerification,
  );

  const [closeOrderPostTip, setCloseOrderPostTip] = useState(false);
  const requestBookingConfirmation = useSelector<RootState, boolean>(
    state => state.order.requestBookingConfirmation,
  );

  // async function getOrder() {
  //   try {
  //     setLoading(true);
  //     dispatch(
  //       fetchOrder(parseInt(o_id), fetched => {
  //         if (fetched) {
  //           if (!store.getState().order.order.is_open) {
  //             setTip('Sending you away...');
  //             message.info({
  //               content: 'Old order',
  //               icon: <ErrorIcon />,
  //               onClose: () => {
  //                 dispatch(
  //                   attemptCloseOrder(() => {
  //                     navigate(`/feed/restaurants/${r_id}/menus`);
  //                   }),
  //                 );
  //               },
  //             });
  //           } else {
  //             setLoading(false);
  //           }
  //         } else {
  //           setTip('Sending you away...');
  //           message.info({
  //             content: 'Old order',
  //             icon: <ErrorIcon />,
  //             onClose: () => {
  //               dispatch(
  //                 attemptCloseOrder(() => {
  //                   navigate(`/feed/restaurants/${r_id}/menus`);
  //                 }),
  //               );
  //             },
  //           });
  //         }
  //       }),
  //     );
  //   } catch (err) {
  //     Sentry.captureException(err);
  //     setLoading(false);
  //   }
  // }

  useEffect(() => {
    async function fetch() {
      try {
        dispatch(getCurrentGuestProfile(() => {}, false));
      } catch (e) {
        Sentry.captureException(e);
      }
    }
    if (guest === null) {
      fetch();
    }
  }, [guest]);

  useEffect(() => {
    let isMounted = true;
    if (order === null && restaurant !== null && guest !== null) {
      dispatch(
        fetchOrder(parseInt(o_id), () => {
          setLoading(false);
        }),
      );
    } else if (order !== null && restaurant !== null && guest !== null) {
      dispatch(
        createGuestCheck(order, () => {
          if (isMounted) {
            setLoading(false);
          }
        }),
      );
    }
    return function cleanup() {
      isMounted = false;
    };
  }, [order, guest, restaurant]);

  useEffect(() => {
    let isMounted = true;
    async function fetchRestaurant() {
      try {
        dispatch(getRestaurant(parseInt(r_id)));
      } catch (e) {
        if (isMounted) {
          setLoading(false);
          message.error(e.message);
        }
      }
    }

    fetchRestaurant();

    return function cleanup() {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    logEvent(getAnalytics(), 'order_view');
    // ZendeskAPI('webWidget', 'show');
  }, []);

  const closeTab = async () => {
    try {
      await paymentsApi.capturePaymentIntent(order.id);
      message.success({
        content: 'Tab Closed',
        className: '',
        style: { borderRadius: '30px' },
        duration: 2,
        icon: <SuccessIcon />,
        onClose: () => {
          setLoading(false);
          logEvent(getAnalytics(), 'order_closed');
          localStorage.setItem(`__${r_id}_hybrid_claimed`, 'false');
          navigate(`/feed/restaurants/${r_id}/order/${order.id}/thankyou`);
          dispatch(clearCart());
          dispatch(attemptCloseOrder(() => {}));
        },
      });
    } catch (err) {
      console.log(err.response);
      Sentry.captureException(err);
      message.error({
        content: 'Unable to close your tab',
        className: '',
        style: { borderRadius: '30px' },
        duration: 2,
        icon: <ErrorIcon />,
        onClose: () => {
          setLoading(false);
        },
      });
    }
  };

  return (
    <>
      <div className="min-h-screen bg-gray-100 md:mx-8 lg:mx-16 xl:mx-32">
        <div className={'pt-4 overflow-y-scroll scrolling-touch bg-gray-100'}>
          <Spin spinning={loading} indicator={LoadingIcon} tip={tip}>
            <ViewHeader
              title={` ${
                order !== null
                  ? order?.order_type === 'catering'
                    ? 'Your Catering Tab'
                    : order?.order_type === 'dine_in'
                    ? 'Your Tab'
                    : order?.order_type === 'delivery'
                    ? 'Your Delivery Tab'
                    : order?.order_type === 'hotel'
                    ? 'Your Hotel Tab'
                    : order?.order_type === 'sts'
                    ? 'Your Order'
                    : 'Your Tab'
                  : 'Your Tab'
              }`}
              className={'text-left px-4'}
            />
            <div className="inline-flex space-x-2 truncate text-sm px-4 pt-2 ">
              <p className="text-gray-500 truncate group-hover:text-gray-900">
                Congrats 🎉 We have received your order!
              </p>
            </div>
            {loading && (
              <main
                className={`flex-1 relative z-0 overflow-y-auto ${
                  loading ? 'pt-20' : 'pt-0'
                }`}
              />
            )}
            {!loading &&
              order !== null &&
              (order.order_type === 'pick_up' ||
                order.order_type === 'take_out') &&
              restaurant !== null && (
                <PickUpOrderInfo
                  pickup_time={order.order_tabs[0].pick_up_time}
                  location={restaurant.coordinates}
                />
              )}
            {!loading &&
              order !== null &&
              order.order_type === 'catering' &&
              restaurant !== null && <OrderCateringDetails />}
            {!loading &&
              order !== null &&
              order.order_type === 'hotel' &&
              restaurant !== null && <OrderHotelDetails />}
            {!loading &&
              order !== null &&
              order.order_type === 'delivery' &&
              restaurant !== null && <OrderDeliveryDetails />}
            {!loading &&
              order !== null &&
              order.order_type === 'sts' &&
              restaurant !== null && <OrderSTSDetails />}
            {!loading && order !== null && order.order_type === 'dine_in' && (
              <DineInInfo
                order={order}
                orderMoreCallback={() => {
                  logEvent(getAnalytics(), 'order_again_clicked');
                  // dispatch(clearCart(r_id));
                  navigate(`/feed/restaurants/${r_id}/menus`);
                }}
              />
            )}
            {!loading && order !== null && order.order_tabs !== null && (
              <>
                <table className="min-w-full mb-6 pt-10 bg-gray-100">
                  <th className="px-4 pt-4 text-lg text-gray-900 font-normal">
                    {/*Items*/}
                    <InfoAlert
                      text={
                        'These are all of your ordered items, including any previously ordered ones.'
                      }
                    />
                  </th>
                  <tbody>
                    {order.order_tabs
                      .map(a => a.order_items)
                      .reduce((a, b) => a.concat(b))
                      ?.filter(
                        oI =>
                          oI.paired_with === undefined ||
                          (!oI.paired_with?.is_pairing_option ?? false),
                      )
                      .map((item: TTOrderItem, i) => {
                        if (
                          item.guest_id == store.getState().auth.guest_id &&
                          !item.voided
                        ) {
                          return (
                            <DishCard
                              menuItem={item}
                              onClick={() => {
                                logEvent(getAnalytics(), 'order_item_clicked');
                              }}
                              key={item.id}
                            />
                          );
                        }
                      })}
                  </tbody>
                </table>
              </>
            )}
            {!loading &&
              order !== null &&
              restaurant !== null &&
              guestCheck !== null && (
                <>
                  <CheckInfoCard
                    from_order={true}
                    tip_callback={() => setShowTipModal(true)}
                  />
                  <div className={'px-4'}>
                    <InfoAlert
                      text={`The updated charge on your payment method is ${centsToDollars(
                        guestCheck.total + guestCheck.service_fee,
                      )}. Any previous holds have been canceled.`}
                    />
                  </div>
                </>
              )}

            {!loading &&
              order !== null &&
              (order.order_type === 'dine_in' ||
                order.order_type === 'catering') && (
                <div className={'space-y-6 my-6'}>
                  <div className={'flex w-full'}>
                    <TabActionButton
                      onClick={async () => {
                        logEvent(getAnalytics(), 'order_again_clicked');
                        dispatch(clearCart(parseInt(r_id)));
                        navigate(`/feed/restaurants/${r_id}/menus`);
                      }}
                      title="Order More"
                      className=""
                      bgColor={'bg-primary-600'}
                      textColor={'text-white'}
                    />
                  </div>
                  {order.order_type === 'dine_in' && (
                    <div className={'flex w-full'}>
                      <TabActionButton
                        onClick={async () => {
                          if (
                            Math.round(
                              (store.getState().order.guest_check.tip /
                                Math.max(
                                  store.getState().order.guest_check.sub_total,
                                  0.01,
                                )) *
                                100,
                            ) < 15
                          ) {
                            setCloseOrderPostTip(true);
                            setShowTipModal(true);
                          } else {
                            logEvent(getAnalytics(), 'close_tab_clicked');
                            setTip('Closing...');
                            setLoading(true);
                            await closeTab();
                          }
                        }}
                        title="Close Tab"
                        className=""
                        bgColor={'bg-white'}
                        textColor={'text-gray-900'}
                      />
                    </div>
                  )}
                </div>
              )}

            {!loading &&
              order !== null &&
              order.order_type !== 'dine_in' &&
              order.order_type !== 'catering' &&
              order.order_type !== 'delivery' &&
              order.order_type !== 'sts' &&
              order.order_type !== 'hotel' && (
                <TabActionButton
                  onClick={async () => {
                    logEvent(getAnalytics(), 'order_picked_up_clicked');
                    setTip('Closing...');
                    setLoading(true);
                    await closeTab();
                  }}
                  title="Mark Picked Up"
                  className=""
                  bgColor={'bg-white'}
                  textColor={'text-gray-900'}
                />
              )}
            {!loading && order !== null && order.order_type === 'delivery' && (
              <TabActionButton
                onClick={async () => {
                  logEvent(getAnalytics(), 'order_delivered_clicked');
                  setTip('Closing...');
                  setLoading(true);
                  await closeTab();
                }}
                title="Mark Delivered"
                className=""
                bgColor={'bg-white'}
                textColor={'text-gray-900'}
              />
            )}
            <div className="w-full my-4">
              <TableTabBanner />
            </div>
            <Transition.Root show={showTipModal} as={Fragment}>
              <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                onClose={() => setShowTipModal(false)}
              >
                <div className="flex items-end justify-center h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0 md:mx-32">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    {/* <ChangeTipModal /> */}
                    <div className="relative inline-block align-bottom rounded-md w-full">
                      <ChangeTipModal
                        onClose={() => setShowTipModal(false)}
                        closeOrderPost={closeOrderPostTip}
                      />
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            <Transition.Root show={requestTableNumber} as={Fragment}>
              <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                onClose={
                  () => {}
                  // dispatch(setRequestTableNumberVerification(false))
                }
              >
                <div className="flex items-end justify-center h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0 md:mx-32">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    {/* <ChangeTipModal /> */}
                    <div className="relative inline-block align-bottom rounded-md w-full">
                      <ChangeTableNumberModal />
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
            <Transition.Root show={requestBookingConfirmation} as={Fragment}>
              <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                onClose={
                  () => {}
                  // dispatch(setRequestTableNumberVerification(false))
                }
              >
                <div className="flex items-end justify-center h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0 md:mx-32">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    {/* <ChangeTipModal /> */}
                    <div className="relative inline-block align-bottom rounded-md w-full">
                      <BookingConfirmationModal />
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default Order;
