import React from 'react';
import { centsToDollars } from '../app/utils';
import store from '../store';
import PillLabel from './PillLabel';

type Props = {
  amount: number;
};

const PaymentInfoCard: React.FC<Props> = ({ amount }) => {
  return (
    <div className="align-bottom bg-white rounded-lg text-left overflow-hidden shadow-lg sm:my-8 sm:align-middle  mx-4 mt-4 mb-4">
      <div className="bg-white p-4">
        <div className="">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center">
            <PillLabel label={`About Payment`} color="indigo" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <div className="mt-2">
              <p className="text-sm leading-5 text-gray-500 text-left">
                {`If the order is successfully placed, we set a hold totaling your current tab amount. Adding items will update your hold with the new total tab amount. Previous holds will be removed.`}
                {/*{`If the order is successfully placed, this will be a ${centsToDollars(*/}
                {/*  amount + (store.getState().order.guest_check?.total ?? 0),*/}
                {/*)} hold on your card. If you order again, this hold will cancel, and we will create an updated charge. The previous hold will be reversed in a few days.`}*/}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInfoCard;
