import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PillLabel from '../../../components/PillLabel';
import { fetchOrderRestaurants } from '../../../features/order/order';
import { TTOrderV2 } from '../../../interfaces/order';
import {
  RestaurantAddress,
  TTRestaurantProfile,
} from '../../../interfaces/restaurant';
import { TTUserProfile } from '../../../interfaces/user';
import { RootState } from '../../../rootReducer';

type Props = {};

export function OrderSTSDetails() {
  const order = useSelector<RootState, TTOrderV2 | null>(
    state => state.order.order,
  );

  const guest = useSelector<RootState, TTUserProfile | null>(
    state => state.auth.userProfile,
  );
  const restaurant_profiles = useSelector<RootState, TTRestaurantProfile[]>(
    state => state.order.order_restaurants,
  );

  const restaurant_addresses = useSelector<RootState, RestaurantAddress[]>(
    state => state.order.order_restaurant_addresses,
  );
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState({});

  useEffect(() => {
    if (order !== null) {
      dispatch(fetchOrderRestaurants(order));
    }
  }, [order?.id]);

  if (!order) return <></>;
  return (
    <>
      {order?.order_tabs
        ?.map(tab => tab.order_fulfillments)
        ?.reduce((a, b) => a.concat(b), [])
        ?.filter(fulfillment => fulfillment.guest_id === guest?.id)
        ?.map(fulfillment => {
          return (
            <div key={fulfillment?.id?.toString()}>
              <div
                className="flex flex-col rounded-md bg-white shadow-md mx-6 my-4 px-2 py-1 space-y-2"
                key={fulfillment?.id?.toString()}
              >
                <div className="flex flex-col  ">
                  <div className="flex space-x-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                      />
                    </svg>

                    <div className="text-sm text-gray-600">Vendor Name</div>
                  </div>
                  <div className="text-md text-gray-900 font-semibold">
                    {restaurant_profiles?.filter(
                      r =>
                        r.restaurant_id ===
                        order?.order_tabs?.filter(
                          t => t.id === fulfillment?.order_tab_id,
                        )[0]?.restaurant_id,
                    )[0]?.name ?? ''}
                  </div>
                </div>
                <div className="h-0.5 w-full bg-gray-300 mx-auto" />
                <div className="flex flex-col  ">
                  <div className="flex space-x-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                      />
                    </svg>

                    <div className="text-sm text-gray-600">Vendor Address</div>
                  </div>
                  <div className="text-md text-gray-900 font-semibold">
                    {restaurant_addresses?.filter(
                      r =>
                        r.restaurant_id ===
                        order?.order_tabs?.filter(
                          t => t.id === fulfillment?.order_tab_id,
                        )[0]?.restaurant_id,
                    )[0]?.address_line_1 +
                      ', ' +
                      restaurant_addresses?.filter(
                        r =>
                          r.restaurant_id ===
                          order?.order_tabs?.filter(
                            t => t.id === fulfillment?.order_tab_id,
                          )[0]?.restaurant_id,
                      )[0]?.city +
                      ', ' +
                      restaurant_addresses?.filter(
                        r =>
                          r.restaurant_id ===
                          order?.order_tabs?.filter(
                            t => t.id === fulfillment?.order_tab_id,
                          )[0]?.restaurant_id,
                      )[0]?.administrative_area_level_1 +
                      ', ' +
                      restaurant_addresses?.filter(
                        r =>
                          r.restaurant_id ===
                          order?.order_tabs?.filter(
                            t => t.id === fulfillment?.order_tab_id,
                          )[0]?.restaurant_id,
                      )[0]?.zip_postal ?? ''}
                  </div>
                </div>
                <div className="h-0.5 w-full bg-gray-300 mx-auto" />

                <div className="flex flex-col   ">
                  <div className="flex space-x-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>

                    <div className="text-sm text-gray-600">Status</div>
                  </div>

                  <div className="text-md text-gray-900 font-semibold">
                    {fulfillment?.state === 'pending' ? (
                      <PillLabel
                        label="Pending"
                        color="indigo"
                        label_size="text-md"
                      />
                    ) : fulfillment?.state === 'accepted' ? (
                      <PillLabel
                        label="Accepted"
                        color="green"
                        label_size="text-md"
                      />
                    ) : fulfillment?.state === 'ready' ? (
                      <PillLabel
                        label="Ready"
                        color="green"
                        label_size="text-md"
                      />
                    ) : fulfillment?.state === 'completed' ? (
                      <PillLabel
                        label="Completed"
                        color="green"
                        label_size="text-md"
                      />
                    ) : (
                      <PillLabel
                        label="Canceled"
                        color="gray"
                        label_size="text-md"
                      />
                    )}
                  </div>
                </div>
                <div className="h-0.5 w-full bg-gray-300 mx-auto" />
                {fulfillment?.sts_details?.schedule_type === 'scheduled' && (
                  <>
                    {' '}
                    <div className="flex flex-col   ">
                      <div className="flex space-x-1 items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                          />
                        </svg>

                        <div className="text-sm text-gray-600">
                          Scheduled for
                        </div>
                      </div>

                      <div className="text-md text-gray-900 font-semibold">
                        <time className="text-md text-gray-900 font-semibold">
                          {moment
                            .utc(fulfillment?.sts_details?.scheduled_for)
                            .local()
                            .format('MM/DD/YYYY, h:mm a')}
                        </time>
                      </div>
                    </div>
                    <div className="h-0.5 w-full bg-gray-300 mx-auto" />
                  </>
                )}
                {showDetails[fulfillment?.id] ? (
                  <>
                    <div className="flex flex-col  ">
                      <div className="flex space-x-1 items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                          />
                        </svg>

                        <div className="text-sm text-gray-600">Name</div>
                      </div>
                      <div className="text-md text-gray-900 font-semibold">
                        {guest?.first_name}
                      </div>
                    </div>

                    {fulfillment?.guest_address && (
                      <>
                        <div className="h-0.5 w-full bg-gray-300 mx-auto" />

                        <div className="flex flex-col justify-between ">
                          <div className="flex space-x-1 items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              className="w-4 h-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                              />
                            </svg>

                            <div className="text-sm text-gray-600">
                              Stay Address
                            </div>
                          </div>

                          <address className="text-md text-gray-900  mb-0 font-semibold">
                            {fulfillment?.guest_address?.address_line_1 +
                              ', ' +
                              fulfillment?.guest_address?.city +
                              ', ' +
                              fulfillment?.guest_address
                                ?.administrative_area_level_1 +
                              ', ' +
                              fulfillment?.guest_address?.country +
                              ' ' +
                              fulfillment?.guest_address?.zip_postal}
                          </address>
                        </div>
                      </>
                    )}
                    <div className="h-0.5 w-full bg-gray-300 mx-auto" />

                    <div className="flex flex-col  ">
                      <div className="flex space-x-1 items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                          />
                        </svg>

                        <div className="text-sm text-gray-600">
                          Order Placed at
                        </div>
                      </div>
                      <time className="text-md text-gray-900 font-semibold">
                        {moment
                          .utc(fulfillment?.created_at)
                          .local()
                          .format('MM/DD/YYYY, h:mm a')}
                      </time>
                    </div>
                    <div className="h-0.5 w-full bg-gray-300 mx-auto" />
                    <div className="flex flex-col  ">
                      <div className="flex space-x-1 items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                          />
                        </svg>

                        <div className="text-sm text-gray-600">Comment</div>
                      </div>
                      <p className="text-md text-gray-900 mb-0 font-semibold">
                        {fulfillment?.sts_details?.note ?? ''}
                      </p>
                    </div>
                    {fulfillment?.sts_details?.tracking_details && (
                      <div
                        className="flex flex-col rounded-md bg-white shadow-md mx-6 my-4 px-2 py-1 space-y-2"
                        key={fulfillment?.id?.toString() + '_tracking_details'}
                      >
                        <div className="flex flex-col  ">
                          <div className="flex space-x-1 items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              className="w-4 h-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                              />
                            </svg>

                            <div className="text-sm text-gray-600">
                              Delivery note from merchant
                            </div>
                          </div>
                          <p className="text-md text-gray-900 font-semibold mb-0">
                            {fulfillment?.sts_details?.tracking_details?.note ??
                              ''}
                          </p>
                        </div>
                        <div className="h-0.5 w-full bg-gray-300 mx-auto" />
                        <div className="flex flex-col  ">
                          <div className="flex space-x-1 items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              className="w-4 h-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                              />
                            </svg>

                            <div className="text-sm text-gray-600">Carrier</div>
                          </div>
                          <p className="text-md text-gray-900 font-semibold mb-0">
                            {fulfillment?.sts_details?.tracking_details?.delivery_provider?.toUpperCase() ??
                              'N/A'}
                          </p>
                        </div>
                        <div className="h-0.5 w-full bg-gray-300 mx-auto" />
                        <div className="flex flex-col  ">
                          <div className="flex space-x-1 items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              className="w-4 h-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 6h.008v.008H6V6z"
                              />
                            </svg>

                            <div className="text-sm text-gray-600">
                              Tacking Number
                            </div>
                          </div>
                          <p className="text-md text-gray-900 font-semibold mb-0">
                            {fulfillment?.sts_details?.tracking_details?.tracking_id?.toUpperCase() ??
                              'N/A'}
                          </p>
                        </div>
                      </div>
                    )}
                    <>
                      <div>
                        <div
                          className="flex flex-row justify-center items-center rounded-full px-2 py-1 bg-gray-600 text-center text-white"
                          onClick={() => {
                            let details = { ...showDetails };
                            details[fulfillment?.id] = false;
                            setShowDetails({ ...details });
                          }}
                        >
                          Hide Details
                        </div>
                      </div>
                    </>
                  </>
                ) : (
                  <>
                    <div>
                      <div
                        className="flex flex-row justify-center items-center rounded-full px-2 py-1 bg-gray-600 text-center text-white"
                        onClick={() => {
                          let details = { ...showDetails };
                          details[fulfillment?.id] = true;
                          setShowDetails({ ...details });
                        }}
                      >
                        View Details
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
}
