import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './features/auth/auth';
import restaurantReducer from './features/restaurant/restaurant';
import cartReducer from './features/cart/cart';
import paymentReducer from './features/payment/payment';
import orderReducer from './features/order/order';
import hybridReducer from './features/hybrid/hybrid';

const rootReducer = combineReducers({
  auth: authReducer,
  restaurant: restaurantReducer,
  cart: cartReducer,
  payment: paymentReducer,
  order: orderReducer,
  hybrid: hybridReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
