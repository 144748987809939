import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RestaurantAddress,
  TRestaurant,
  TTRestaurantProfile,
} from '../../interfaces/restaurant';
import { AppThunk } from '../../store';
import { restaurantApi } from '../../api/restaurant';
import { TTMenu } from '../../interfaces/menus';
import { TTMenuItem } from '../../interfaces/menuItem';
import { TCategory } from '../../interfaces/category';
import * as Sentry from '@sentry/react';

/* eslint-disable */
interface RestaurantState {
  restaurant_id: number | null;
  restaurant_name: string | null;
  restaurant_cuisine: string | null;
  menu_in_view: TTMenu | null;
  profile: TTRestaurantProfile | null;
  all_profiles: TTRestaurantProfile[] | null;
  current_menu_items: TTMenuItem[] | null;
  current_menu_categories: TCategory[] | null;
  current_menus: TTMenu[] | null;
  categorized_view: boolean;
  qr_address: RestaurantAddress | null;
  featured_products: TTMenuItem[];
}

const initialState: RestaurantState = {
  restaurant_id: null,
  restaurant_name: null,
  restaurant_cuisine: null,
  menu_in_view: JSON.parse(localStorage.getItem('__tt_selected_menu')) ?? null,
  profile: JSON.parse(localStorage.getItem('__tt_rest_profile')) ?? null,
  current_menu_items:
    JSON.parse(localStorage.getItem('__tt_menu_items')) ?? null,
  current_menu_categories:
    JSON.parse(localStorage.getItem('__tt_menu_cats')) ?? null,
  current_menus: null,
  all_profiles:
    JSON.parse(localStorage.getItem('__tt_restaurant_profiles')) ?? null,
  categorized_view: localStorage.getItem('__tt_categorized_view') === 'true',
  qr_address:
    JSON.parse(localStorage.getItem('__tt_restaurant_address')) ?? null,
  featured_products: [],
};

const restaurant = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    setRestaurant(state, action: PayloadAction<TRestaurant>) {
      state.restaurant_id = action.payload.id;
      state.restaurant_name = action.payload.name;
      state.restaurant_cuisine = action.payload.cuisine;
    },
    setMenus(state, action: PayloadAction<TTMenu[]>) {
      state.current_menus = action.payload;
      if (action.payload !== null) {
        localStorage.setItem('__tt_menus', JSON.stringify(action.payload));
      } else {
        localStorage.removeItem('__tt_menus');
      }
    },
    setMenu(state, action: PayloadAction<TTMenu>) {
      state.menu_in_view = action.payload;
      localStorage.setItem(
        '__tt_selected_menu',
        JSON.stringify(action.payload),
      );
    },
    setProfile(state, action: PayloadAction<TTRestaurantProfile>) {
      state.profile = action.payload;
      state.restaurant_name = action.payload.name;
      state.restaurant_id = action.payload.restaurant_id;
      state.restaurant_cuisine = action.payload.cuisine;
      if (state.profile?.restaurant_id !== state.qr_address?.restaurant_id) {
        state.qr_address = null;
        localStorage.removeItem('__tt_qr_address');
      }
      localStorage.setItem('__tt_rest_profile', JSON.stringify(action.payload));
    },
    setCurrentMenuItems(state, action: PayloadAction<TTMenuItem[]>) {
      state.current_menu_items = action.payload;
      localStorage.setItem('__tt_menu_items', JSON.stringify(action.payload));
    },
    setCurrentMenuCats(state, action: PayloadAction<TCategory[]>) {
      state.current_menu_categories = action.payload;
      localStorage.setItem('__tt_menu_cats', JSON.stringify(action.payload));
    },
    setAllProfiles(state, action: PayloadAction<TTRestaurantProfile[]>) {
      state.all_profiles = action.payload;
      localStorage.setItem(
        '__tt_restaurant_profiles',
        JSON.stringify(action.payload),
      );
    },
    setCategorizedView(state, action: PayloadAction<boolean>) {
      state.categorized_view = action.payload;

      localStorage.setItem('__tt_categorized_view', action.payload.toString());
    },
    setQRAddress(state, action: PayloadAction<RestaurantAddress>) {
      state.qr_address = action.payload;
      if (state.qr_address) {
        localStorage.setItem(
          '__tt_restaurant_address',
          JSON.stringify(state.qr_address),
        );
      } else {
        localStorage.removeItem('__tt_restaurant_address');
      }
    },
    setFeaturedProducts(state, action: PayloadAction<TTMenuItem[]>) {
      state.featured_products = action.payload;
    },
  },
});

export const {
  setRestaurant,
  setMenu,
  setMenus,
  setProfile,
  setCurrentMenuItems,
  setCurrentMenuCats,
  setAllProfiles,
  setCategorizedView,
  setQRAddress,
  setFeaturedProducts,
} = restaurant.actions;

export default restaurant.reducer;

export const getRestaurant =
  (id: number, redirectTrigger?: Function): AppThunk =>
  async dispatch => {
    try {
      const response = await restaurantApi.getRestaurant(id);
      const profile_response = await restaurantApi.getProfile(id);
      dispatch(setRestaurant(response.data));
      dispatch(setProfile(profile_response.data));
      if (profile_response?.data?.connected_vendor_ids?.length !== 0) {
        const all_profiles_response = await Promise.all(
          profile_response?.data?.connected_vendor_ids?.map(i => {
            return restaurantApi.getProfile(i);
          }),
        );
        dispatch(
          setAllProfiles(
            all_profiles_response
              .map(i => i.data)
              ?.concat([profile_response.data]),
          ),
        );
      }
      if (redirectTrigger !== undefined) {
        redirectTrigger();
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  };

export const fetchQRCodeAddress =
  (restaurant_id: number, address_id: number): AppThunk =>
  async dispatch => {
    try {
      const response = await restaurantApi.getAddress(
        restaurant_id,
        address_id,
      );
      dispatch(setQRAddress(response.data));
    } catch (e) {
      dispatch(setQRAddress(null));
    }
  };
