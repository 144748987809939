import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import './generated.css';
import './fontawesome';
import 'antd/dist/antd.css';
import './index.css';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { stripeKey } from './env';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './.well-known/apple-developer-merchantid-domain-association';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';
// import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
// import aphroditeInterface from 'react-with-styles-interface-aphrodite';
// import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';

const firebaseConfig = {
  apiKey: 'AIzaSyB2EetJdF3WwZwHxF7vrctdP4PxM8WaHJw',
  authDomain: 'tabletab-763b6.firebaseapp.com',
  projectId: 'tabletab-763b6',
  storageBucket: 'tabletab-763b6.appspot.com',
  messagingSenderId: '698286649033',
  appId: '1:698286649033:web:4764d385bc75e5d856e718',
  measurementId: 'G-H18KDR5FZ9',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

setPersistence(getAuth(app), browserLocalPersistence)
  .then(function () {})
  .catch(function (error) {});

export const stripePromise = loadStripe(stripeKey);

// ThemedStyleSheet.registerInterface(aphroditeInterface);
// ThemedStyleSheet.registerTheme({
//   reactDates: {
//     ...DefaultTheme.reactDates,
//     color: {
//       ...DefaultTheme.reactDates.color,
//       primary: '#ff6f61',
//       highlighted: {
//         backgroundColor: '#82E0AA',
//         backgroundColor_active: '#58D68D',
//         backgroundColor_hover: '#58D68D',
//         color: '#ff6f61',
//         color_active: '#ff6f61',
//         color_hover: '#ff6f61',
//       },
//       selected: {
//         ...DefaultTheme.reactDates.color.selected,
//         backgroundColor: '#ff6f61',
//       },
//     },
//   },
// });

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store}>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </Provider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
