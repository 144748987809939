import React from 'react';
import PillLabel from '../components/PillLabel';

type Props = {
  onClick: () => void;
  mb: string;
};

export const WalkthroughCTACard: React.FC<Props> = ({ onClick, mb }) => {
  return (
    <div
      className={`align-bottom ${mb} bg-white rounded-lg text-left overflow-hidden shadow-lg transform transition-all sm:my-8 sm:align-middle  mx-4 mt-4`}
      onClick={onClick}
    >
      <div className="bg-white p-4">
        <div className="">
          <div className="mx-auto flex-shrink-0 flex items-center justify-start">
            <span
              className={`px-2 inline-flex text-sm leading-5 font-semibold rounded-full text-gray-900 `}
            >
              How to order?
            </span>
          </div>
          <nav aria-label="Progress mt-10">
            <ol className="mt-4">
              <li className="relative flex flex-1">
                <span className="group flex items-center w-full">
                  <span className="px-2 py-2 flex items-center text-sm font-medium">
                    <span className="relative z-10 w-4 h-4 flex items-center justify-center bg-white border-2 border-primary-600 rounded-full">
                      <span className="h-2 w-2 bg-primary-600 rounded-full" />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">
                      👆 Add menu items to your tab
                    </span>
                  </span>
                </span>
              </li>

              <li className="relative flex flex-1">
                <span className="group flex items-center w-full">
                  <span className="px-2 py-2 flex items-center text-sm font-medium">
                    <span className="relative z-10 w-4 h-4 flex items-center justify-center bg-white border-2 border-primary-600 rounded-full">
                      <span className="h-2 w-2 bg-primary-600 rounded-full" />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">
                      💳 Add your payment info
                    </span>
                  </span>
                </span>
              </li>

              <li className="relative flex flex-1">
                <span className="group flex items-center w-full">
                  <span className="px-2 py-2 flex items-center text-sm font-medium">
                    <span className="relative z-10 w-4 h-4 flex items-center justify-center bg-white border-2 border-primary-600 rounded-full">
                      <span className="h-2 w-2 bg-primary-600 rounded-full" />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">
                      👏 Start your tab
                    </span>
                  </span>
                </span>
              </li>
            </ol>
          </nav>
          <div className="mx-auto flex-shrink-0 flex items-center justify-end">
            <span
              className={`px-2 inline-flex text-xs leading-5 font-medium rounded-full text-gray-900 `}
            >
              Click to learn more
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
