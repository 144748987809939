import { Transition } from '@headlessui/react';
import { message, Spin } from 'antd';
import useSelection from 'antd/lib/table/hooks/useSelection';
import { getAnalytics, logEvent } from 'firebase/analytics';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { menuItemsApi } from '../../../../api/menuItems';
import { setFeaturedProducts } from '../../../../features/restaurant/restaurant';
import { TTMenuItem } from '../../../../interfaces/menuItem';
import { RootState } from '../../../../rootReducer';
import { LoadingIcon } from '../../../components/Spinner';

type Props = {
  merchant_id: number;
};

export function FeaturedProducts({ merchant_id }: Props) {
  const [fetching, setFetching] = useState(false);
  const featuredProducts = useSelector<RootState, TTMenuItem[]>(
    state => state.restaurant.featured_products,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchProducts = async () => {
    try {
      setFetching(true);
      const response = await menuItemsApi.getFeaturedItems(merchant_id);
      dispatch(setFeaturedProducts(response.data));
      setFetching(false);
    } catch (e) {
      setFetching(false);
      message.error(e?.response?.data?.detail);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);
  return (
    <>
      <div className="my-4 ">
        <div className="flex flex-col  px-4 mt-4  ">
          <Spin spinning={fetching} indicator={LoadingIcon}>
            {featuredProducts?.length !== 0 && (
              <div
                className="overflow-x-scroll rounded-md border-4 border-dashed border-yellow-500 px-4 bg-white"
                id={'featued_product_'}
              >
                <div className="flex flex-row text-gray-900 font-semibold px-2 text-lg  overflow-x-auto items-center rounded-md py-2">
                  Featured Products
                </div>
                <div className="flex flex-row overflow-x-scroll space-x-2 my-2 pb-4">
                  {featuredProducts?.map(p => {
                    return (
                      <Transition
                        show={true}
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-y-full"
                        enterTo="translate-y-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-y-0"
                        leaveTo="translate-y-full"
                      >
                        <div
                          key={p?.id?.toString()}
                          onClick={() => {
                            logEvent(getAnalytics(), 'menu_item_clicked', {
                              menu_item_id: p.id,
                              menu_item_name: p.name,
                              is_featured: true,
                              click_location: 'featured_banner',
                            });
                            navigate(
                              `/feed/restaurants/${merchant_id}/menus/${p?.menu_id}/items/${p?.id}`,
                            );
                          }}
                          className="bg-gray-900 rounded-lg shadow-md cursor-pointer"
                        >
                          <div
                            className={`relative ${'w-40 md:w-40 lg:w-40 2xl:w-44'}`}
                          >
                            <div className="group block border-b border-white w-full  aspect-w-14 aspect-h-14 rounded-t-lg bg-gray-100 overflow-hidden">
                              <img
                                src={p?.image_url}
                                alt=""
                                className="object-cover pointer-events-none group-hover:opacity-75"
                              />
                            </div>
                            <p className="m-2 py-2 block text-md font-semibold text-white truncate-2-lines pointer-events-none">
                              {p?.name}
                            </p>
                            <p className="block mx-2 text-sm text-white pointer-events-none truncate-4-lines">
                              {p?.description ?? ''}
                            </p>
                          </div>
                        </div>
                      </Transition>
                    );
                  })}
                </div>
              </div>
            )}
          </Spin>
        </div>
      </div>
    </>
  );
}
