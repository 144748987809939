import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TTUserProfile, TTUserProfileUpdate } from '../../interfaces/user';
import { RootState } from '../../rootReducer';
import {
  getCurrentGuestProfile,
  setEmail,
  setUserProfile,
} from '../../features/auth/auth';
import NavBar from '../components/BackButton';
import { message, Spin } from 'antd';
import { LoadingIcon } from '../components/Spinner';
import ViewHeader from '../components/ViewHeader';
import { OrderList } from './OrderList';
import { PhoneIcon, UserIcon } from '@heroicons/react/outline';
import { guestApi } from '../../api/guest';
type Props = {};

export function GuestOverview() {
  const guestProfile = useSelector<RootState, TTUserProfile | null>(
    state => state.auth.userProfile,
  );
  const [update, setUpdate] = useState<TTUserProfileUpdate>({});
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    dispatch(getCurrentGuestProfile());
  }, []);

  const updateProfile = async () => {
    try {
      setFetching(true);
      const response = await guestApi.updateGuest(update, guestProfile?.id);
      dispatch(setUserProfile(response.data));
      setFetching(false);
    } catch (e) {
      setFetching(false);
      message.error(
        e?.response?.data?.detail[0]?.msg ??
          e?.response?.data?.detail ??
          'Unable to update',
      );
    }
  };

  return (
    <>
      <div className="overflow-y-scroll scrolling-touch min-h-screen bg-gray-100 pb-32">
        <NavBar show_refresh={false} hide={true} />
        <div className="flex justify-between items-center mt-4 md:px-32">
          <ViewHeader title={`Guest Profile`} className={'text-left px-4'} />
        </div>

        <div className=" mt-4 px-4 md:px-32">
          <div
            key={guestProfile?.id?.toString()}
            className="relative flex px-2 py-2 items-center space-x-3 rounded-lg border border-gray-300 bg-white  shadow-sm "
          >
            <Spin
              spinning={fetching}
              indicator={LoadingIcon}
              size={'large'}
              tip="Saving..."
              className="w-full"
              wrapperClassName="w-full"
            >
              <div className="w-full">
                <div className="focus:outline-none">
                  <div className="flex-col space-x-1 items-center">
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <UserIcon className="h-4 w-4" />
                      </div>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          defaultValue={guestProfile?.first_name}
                          value={update?.first_name ?? guestProfile?.first_name}
                          onChange={e => {
                            setUpdate({
                              ...update,
                              first_name: e.target.value,
                            });
                          }}
                          className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          placeholder={guestProfile?.first_name}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-col space-x-1 items-center">
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <PhoneIcon className="h-4 w-4" />
                      </div>
                      <div className="mt-1">
                        <input
                          type="tel"
                          name="phone"
                          id="phone"
                          defaultValue={guestProfile?.phone_number}
                          disabled
                          value={guestProfile?.phone_number}
                          className="block w-full pl-10 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 cursor-not-allowed border-gray-200 bg-gray-50 text-gray-500 sm:text-sm"
                          placeholder={guestProfile?.phone_number}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-col items-center space-x-1">
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                          />
                        </svg>
                      </div>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        onChange={e => {
                          const re = /\S+@\S+\.\S+/;
                          setUpdate({ ...update, email: e.target.value });
                        }}
                        className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="you@example.com"
                        value={update?.email ?? guestProfile?.email}
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    className="mt-2 bg-gray-900  rounded-md w-full px-2 py-2 text-white font-semibold"
                    // disabled={Object.keys(update)?.length === 0}
                    onClick={async () => {
                      console.log('clciking...');
                      await updateProfile();
                    }}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </Spin>
          </div>
          {guestProfile !== null && <OrderList />}
        </div>
      </div>
    </>
  );
}
