import axios from 'axios';
import { paymentsUrl } from '../env';
import { getAuthHeaders } from './utils';
import { TRestaurant, TTRestaurantProfile } from '../interfaces/restaurant';
import {
  TTSetupIntentResponse,
  TTPaymentIntentCreate,
  TTPaymentIntentMetaUpdate,
  PaymentTransaction,
  TTPaymentMethodCreate,
} from '../interfaces/payment';
import {
  SquareCard,
  SquareGiftCard,
  StripeCard,
  StripeCardsResponse,
} from '../interfaces/stripe_payment_method';
/* eslint-disable */

export const paymentsApi = {
  async listPaymentMethods(platform: string, company_id: number) {
    return axios.get<StripeCardsResponse>(
      `${paymentsUrl}/api/v1/payment-methods/?platform=${platform}&company_id=${1}`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async removeCardOnFile(platform: string, card_id: string) {
    return axios.delete<SquareCard | StripeCard>(
      `${paymentsUrl}/api/v1/payment-methods/${card_id}?platform=${platform}`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async getSquareGiftCards(platform: string, company_id: number) {
    return axios.get<SquareGiftCard[]>(
      `${paymentsUrl}/api/v1/payment-methods/gift-cards/?platform=${platform}&company_id=${company_id}`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async attachSquarePaymentMethod(obj: TTPaymentMethodCreate) {
    return axios.post<SquareCard>(
      `${paymentsUrl}/api/v1/payment-methods/`,
      obj,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async attachGiftCardOnFile(obj: TTPaymentMethodCreate) {
    return axios.post<SquareGiftCard>(
      `${paymentsUrl}/api/v1/payment-methods/`,
      obj,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async createSetupIntent() {
    return axios.post<TTSetupIntentResponse>(
      `${paymentsUrl}/api/v1/setup-intents/`,
      {},
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async confirmPaymentIntent(obj: TTPaymentIntentCreate) {
    return axios.post(
      `${paymentsUrl}/api/v1/payment/confirmPaymentIntent`,
      obj,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async createPaymentIntent(obj: TTPaymentIntentCreate) {
    return axios.post<PaymentTransaction>(
      `${paymentsUrl}/api/v1/payments/`,
      obj,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async capturePaymentIntent(id?: number) {
    return axios.post<PaymentTransaction>(
      id !== undefined && id !== null
        ? `${paymentsUrl}/api/v1/payments/capture?order_id=${id}`
        : `${paymentsUrl}/api/v1/payments/capture`,
      {},
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async cancelPaymentIntent(id: number) {
    return axios.post<PaymentTransaction>(
      `${paymentsUrl}/api/v1/payments/${id}/cancel`,
      {},
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async updateMetdaData(id: number, obj: TTPaymentIntentMetaUpdate) {
    return axios.put(`${paymentsUrl}/api/v1/payments/${id}/metadata`, obj, {
      headers: await getAuthHeaders(),
    });
  },
};
