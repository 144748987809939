import { cloudfront } from '../env';
import { TTHourFormat, TTHours } from '../interfaces/hours';
import moment from 'moment-timezone';
import store from '../store';

export function getFullImageUrl(restaurantId: number, imagePath: string) {
  return `${imagePath}?d=400x400`;
}

export function centsToDollars(price: number): string {
  const profile = store.getState().restaurant.profile;
  const currency = profile?.currency ?? 'usd';
  return `${(price / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: currency.toLocaleUpperCase(),
  })}`;
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function deepCopy(obj) {
  var copy;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || 'object' != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}

export function isMenuAvailable(hours: TTHours[]) {
  if (hours.length === 0) {
    return false;
  }
  const now = moment().local();
  const day = now.day();

  const today_hours = hours.filter(h => {
    return h.day == day;
  })[0];

  if (!today_hours.is_online) {
    return false;
  } else if (today_hours.is_all_day) {
    return true;
  } else {
    return isBetween(today_hours.start_time, today_hours.end_time, undefined);
  }
}

function isBetween(aStartTime, anEndTime, aCurrTime) {
  // you may pass in aCurrTime or use the *actual* current time
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  var currentTime = !aCurrTime
    ? moment.utc(moment().utc().format('HH:mm:ss'), 'HH:mm:ss')
    : moment(aCurrTime, 'HH:mm a').utc(true);

  var startTime = moment.utc(aStartTime, 'HH:mm:ss');
  var endTime = moment.utc(anEndTime, 'HH:mm:ss');

  if (
    (startTime.hour() >= 12 && endTime.hour() <= 12) ||
    endTime.isBefore(startTime)
  ) {
    endTime.add(1, 'days'); // handle spanning days endTime

    if (currentTime.hour() <= 12) {
      currentTime.add(1, 'days'); // handle spanning days currentTime
    }
  }

  return currentTime.isBetween(startTime, endTime);
}
