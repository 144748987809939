import { ChevronRightIcon, QrcodeIcon } from '@heroicons/react/outline';
import { CalendarIcon } from '@heroicons/react/solid';
import { message, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bookingApi } from '../../../api/booking';
import { menuItemsApi } from '../../../api/menuItems';
import { ordersApi } from '../../../api/order';
import {
  fetchOrder,
  setRequestBookingConfirmation,
} from '../../../features/order/order';
import { TTOrderV2 } from '../../../interfaces/order';
import { TTOrderItem } from '../../../interfaces/order_item';
import { TTRestaurantProfile } from '../../../interfaces/restaurant';
import { TTUserProfile } from '../../../interfaces/user';
import { RootState } from '../../../rootReducer';
import { LoadingIcon } from '../../components/Spinner';
import { BookingSchdule } from '../../MenuDish/components/BookingSchedule';

export function BookingConfirmationModal() {
  const restaurantProfile = useSelector<RootState, TTRestaurantProfile | null>(
    state => state.restaurant.profile,
  );
  const order = useSelector<RootState, TTOrderV2 | null>(
    state => state.order.order,
  );

  const guest = useSelector<RootState, TTUserProfile | null>(
    state => state.auth.userProfile,
  );

  const [menuItems, setMenuItems] = useState({});

  const [loading, setLoading] = useState(false);
  const [localNum, setLocalNum] = useState('');
  const dispatch = useDispatch();
  const [showNewProposal, setShowNewProposal] = useState(false);

  const [newTimeSlots, setNewTimeSlots] = useState({});

  const fetchMenuItem = async (item_id: number) => {
    try {
      const response = await menuItemsApi.getMenuItem(item_id);
      let values = { ...menuItems };
      values[item_id] = response.data;
      setMenuItems({ ...values });
      return response.data;
    } catch (e) {}
  };
  const tz =
    Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Los_Angeles';
  useEffect(() => {
    if (showNewProposal) {
      Promise.all(
        order?.order_tabs
          ?.map(t => t.order_items)
          ?.reduce((a, b) => a.concat(b), [])
          ?.filter(
            i =>
              i.product_type === 'booking' &&
              i?.booking_info?.status === 'pending_guest_accept',
          )
          ?.map(i => {
            return fetchMenuItem(i.menu_item_id);
          }),
      );
    }
  }, [showNewProposal]);

  const handleNewBookingProposal = async (orderItem: TTOrderItem) => {
    try {
      setLoading(true);
      const response = await bookingApi.submitNewBookingPropsal(
        orderItem?.booking_info?.id ?? 0,
        {
          proposed_start_at: moment(
            newTimeSlots[orderItem.id]?.date +
              newTimeSlots[orderItem.id]?.start_at,
            'YYYY-MM-DD HH:mm',
          ).toISOString(),
          proposed_end_at: moment(
            newTimeSlots[orderItem.id]?.date +
              newTimeSlots[orderItem.id]?.end_at,
            'YYYY-MM-DD HH:mm',
          ).toISOString(),
        },
      );
      message.success('New times submitted');
      setLoading(false);
      dispatch(fetchOrder(orderItem?.order_v2_id, () => {}));
      dispatch(setRequestBookingConfirmation(false));
    } catch (e) {
      setLoading(false);
      message.error(
        e?.response?.data?.detail[0]?.msg ??
          e?.response?.data?.detail ??
          'Unable to submit the new time propsal.',
      );
    }
  };
  const handleBookingFormSubmit = async (item: TTOrderItem) => {
    try {
      setLoading(true);
      const response = await bookingApi.acceptBookingProposal(
        item?.booking_info?.id ?? 0,
      );
      message.success('Booking confirmed!');
      setLoading(false);
      dispatch(fetchOrder(item?.order_v2_id, () => {}));
      dispatch(setRequestBookingConfirmation(false));
    } catch (e) {
      setLoading(false);
      message.error(
        e?.response?.data?.detail ?? 'Unable to update the table number.',
      );
    }
  };
  return (
    <>
      <div className="bg-white shadow rounded-md">
        <Spin spinning={loading} indicator={LoadingIcon}>
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Booking Time Confirmation
            </h3>

            {!showNewProposal && (
              <form
                className="mt-5 space-y-2"
                onSubmit={async e => {
                  e.preventDefault();
                }}
              >
                <div>
                  <label
                    htmlFor="table_number"
                    className="block text-sm font-medium text-gray-700 text-left"
                  >
                    Time proposed by {restaurantProfile?.name}
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div>
                      {order?.order_tabs
                        ?.map(t => t.order_items)
                        ?.reduce((a, b) => a.concat(b), [])
                        ?.filter(
                          i =>
                            i.product_type === 'booking' &&
                            i?.booking_info?.status === 'pending_guest_accept',
                        )
                        ?.map(item => {
                          return (
                            <>
                              <div
                                key={item?.id?.toString()}
                                className="rounded-md border border-gray-100 px-2 py-2"
                              >
                                <div className="flex flex-row">
                                  <div className="text-base font-semibold text-gray-900">
                                    {item.name}
                                  </div>
                                </div>
                                <div className="flex flex-row space-x-2">
                                  <div className=" flex items-center">
                                    <CalendarIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div>
                                    {moment
                                      .utc(item?.booking_info?.booked_for)
                                      .clone()
                                      .tz(tz)
                                      .format('MM/D/YY, h:mm a')}
                                  </div>
                                </div>
                              </div>
                              <button
                                type="button"
                                onClick={async () => {
                                  await handleBookingFormSubmit(item);
                                }}
                                className="mt-6 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500   text-base"
                              >
                                Confirm
                              </button>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setShowNewProposal(true);
                  }}
                  className="mt-6 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500  text-base"
                >
                  Propose a new time
                </button>
              </form>
            )}
            {showNewProposal && (
              <form
                className="mt-5 "
                onSubmit={async e => {
                  e.preventDefault();
                }}
              >
                <div className="">
                  <label
                    htmlFor="table_number"
                    className="block text-sm font-medium text-gray-700 text-left"
                  >
                    Select a new time slot
                  </label>
                  <div className="mt-1 relative">
                    <div>
                      {order?.order_tabs
                        ?.map(t => t.order_items)
                        ?.reduce((a, b) => a.concat(b), [])
                        ?.filter(
                          i =>
                            i.product_type === 'booking' &&
                            i?.booking_info?.status === 'pending_guest_accept',
                        )
                        ?.map(item => {
                          if (menuItems[item.menu_item_id])
                            return (
                              <div className="flex-col">
                                <BookingSchdule
                                  key={item?.id?.toString()}
                                  menuItem={menuItems[item.menu_item_id]}
                                  onSelect={selectedSlot => {
                                    let values = { ...newTimeSlots };
                                    values[item.id] = selectedSlot;
                                    setNewTimeSlots({ ...values });
                                  }}
                                />
                                <button
                                  type="button"
                                  onClick={async () => {
                                    await handleNewBookingProposal(item);
                                  }}
                                  className="mt-6 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500  text-base"
                                >
                                  Confirm
                                </button>
                              </div>
                            );
                          return <>Fetching data...</>;
                        })}
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setShowNewProposal(false);
                  }}
                  className="mt-6 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-base"
                >
                  Cancel
                </button>
              </form>
            )}
          </div>
        </Spin>
      </div>
    </>
  );
}
