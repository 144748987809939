import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TTRestaurantProfile } from '../../../interfaces/restaurant';
import { GuestAddress, TTUserProfile } from '../../../interfaces/user';
import { RootState } from '../../../rootReducer';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Input from '../../components/Input';
import PillLabel from '../../../components/PillLabel';
import {
  setCateringDetails,
  setFulfillmentDetails,
  setPickUpName,
  setSelectedAddress,
  setTableNumber,
} from '../../../features/cart/cart';
import {
  addNewAddress,
  setFirstName,
  setUserProfile,
} from '../../../features/auth/auth';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { guestApi } from '../../../api/guest';
import { message } from 'antd';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { SingleDatePicker } from 'react-dates';
import isDayVisible, {
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from '../../../utils';
import moment, { Moment } from 'moment';
import {
  OrderFulfillmentCateringDetailsCreate,
  TTOrderFulfillmentCreate,
} from '../../../interfaces/order';

type Props = {
  restaurant_id: string;
};

type DateProps = {
  restaurant_id: string;
};

export function CateringOrderDetails({ restaurant_id }: Props) {
  const restaurant_profile = useSelector<RootState, TTRestaurantProfile | null>(
    state => state.restaurant.profile,
  );

  const guest = useSelector<RootState, TTUserProfile | null>(
    state => state.auth.userProfile,
  );

  const order_fulfillment = useSelector<
    RootState,
    { [key: string]: TTOrderFulfillmentCreate }
  >(state => state.cart.fulfillment_details);

  const catering_details = useSelector<
    RootState,
    OrderFulfillmentCateringDetailsCreate | undefined
  >(state => state.cart.catering_details[restaurant_id]);

  const dispatch = useDispatch();
  const [name, setName] = useState('');

  const [presetMode, setPresetMode] = useState<string>('choose');

  const [selectedMode, setSelectedMode] = useState('');
  const [addressValue, setAddressValue] = useState(null);
  const [addingNewAddress, setAddingNewAddress] = useState(true);
  const [comment, setComment] = useState('');
  const selectedAddress = useSelector<RootState, GuestAddress | null>(
    state => state.cart.selected_address,
  );

  useEffect(() => {
    if (restaurant_profile !== null) {
      if (
        restaurant_profile.catering_settings?.offers_delivery &&
        !restaurant_profile.catering_settings?.offers_pickup
      ) {
        setPresetMode('delivery');
        setSelectedMode('delivery');
      } else if (
        !restaurant_profile.catering_settings?.offers_delivery &&
        restaurant_profile.catering_settings?.offers_pickup
      ) {
        setPresetMode('pickup');
        setSelectedMode('pickup');
      }
    }
  }, [restaurant_profile]);

  useEffect(() => {
    if (guest !== null) {
      if (guest?.addresses?.length != 0) {
        setAddingNewAddress(false);
      }
      if (guest?.first_name !== null) {
        setName(guest.first_name);
        dispatch(setTableNumber(`CT_${guest?.first_name?.split(' ')[0]}`));
      }
      if (order_fulfillment[restaurant_id] === undefined) {
        dispatch(
          setFulfillmentDetails({
            [restaurant_id]: {
              guest_id: guest?.id,
              type: 'catering',
              order_item_application: 'all_items',
            },
          }),
        );
      } else {
        dispatch(
          setFulfillmentDetails({
            [restaurant_id]: {
              ...order_fulfillment[restaurant_id],
              type: 'catering',
              pickup_details: null,
              delivery_details: null,
            },
          }),
        );
      }
      if (catering_details === undefined) {
        dispatch(
          setCateringDetails({
            [restaurant_id]: {
              guest_id: guest?.id,
              schedule_type: 'scheduled',
            },
          }),
        );
      } else {
        if (catering_details !== undefined) {
          dispatch(
            setFulfillmentDetails({
              [restaurant_id]: {
                ...order_fulfillment[restaurant_id],
                catering_details: { ...catering_details },
                pickup_details: null,
                delivery_details: null,
              },
            }),
          );
        }
      }
    }
  }, [guest]);

  return (
    <>
      {restaurant_profile !== null && (
        <div className="mx-4 my-2">
          <fieldset>
            {presetMode === 'choose' ? (
              <div className="flex flex-row justify-between items-center px-4 py-2 shadow-md bg-gray-400 space-x-2 rounded-md">
                <div
                  className={`flex px-2 py-2 w-1/2 ${
                    selectedMode === 'delivery' ? 'bg-white' : 'bg-gray-300 '
                  } rounded-md`}
                  onClick={() => {
                    setSelectedMode('delivery');
                    dispatch(
                      setCateringDetails({
                        [restaurant_id]: {
                          ...catering_details,
                          is_delivered: true,
                        },
                      }),
                    );
                    dispatch(
                      setFulfillmentDetails({
                        [restaurant_id]: {
                          ...order_fulfillment[restaurant_id],
                          catering_details: {
                            ...catering_details,
                            is_delivered: true,
                          },
                        },
                      }),
                    );
                  }}
                >
                  <div className="text-base text-gray-900 font-semibold">
                    Delivery
                  </div>
                </div>
                <div
                  className={`flex px-2 py-2 w-1/2  ${
                    selectedMode === 'pickup' ? 'bg-white' : 'bg-gray-300 '
                  } rounded-md`}
                  onClick={() => {
                    setSelectedMode('pickup');
                    dispatch(
                      setCateringDetails({
                        [restaurant_id]: {
                          ...catering_details,
                          is_delivered: false,
                        },
                      }),
                    );
                    dispatch(
                      setFulfillmentDetails({
                        [restaurant_id]: {
                          ...order_fulfillment[restaurant_id],
                          catering_details: {
                            ...catering_details,
                            is_delivered: false,
                          },
                        },
                      }),
                    );
                  }}
                >
                  <div className="text-base text-gray-900 font-semibold">
                    Pickup
                  </div>
                </div>
              </div>
            ) : presetMode === 'delivery' ? (
              <div
                className={`flex px-4 py-2 ${
                  selectedMode === 'delivery' ? 'bg-white' : 'bg-gray-100'
                } rounded-md shadow-md`}
                onClick={() => {
                  setSelectedMode('delivery');
                  dispatch(
                    setCateringDetails({
                      [restaurant_id]: {
                        ...catering_details,
                        is_delivered: true,
                      },
                    }),
                  );
                  dispatch(
                    setFulfillmentDetails({
                      [restaurant_id]: {
                        ...order_fulfillment[restaurant_id],
                        catering_details: {
                          ...catering_details,
                          is_delivered: true,
                        },
                      },
                    }),
                  );
                }}
              >
                <div className="text-base text-gray-900 font-semibold">
                  Delivery
                </div>
              </div>
            ) : (
              <div
                className={`flex px-4 py-2 ${
                  selectedMode === 'delivery' ? 'bg-white' : 'bg-gray-100'
                } rounded-md shadow-md`}
                onClick={() => {
                  setSelectedMode('pickup');
                  dispatch(
                    setCateringDetails({
                      [restaurant_id]: {
                        ...catering_details,
                        is_delivered: false,
                      },
                    }),
                  );
                  dispatch(
                    setFulfillmentDetails({
                      [restaurant_id]: {
                        ...order_fulfillment[restaurant_id],
                        catering_details: {
                          ...catering_details,
                          is_delivered: false,
                        },
                      },
                    }),
                  );
                }}
              >
                Pickup
              </div>
            )}
          </fieldset>
          <fieldset>
            {restaurant_profile?.catering_settings?.offers_delivery &&
            selectedMode === 'delivery' ? (
              <>
                <div className={'flex flex-col'}>
                  <div className="flex flex-row justify-left content-center items-start mb-2 mt-4">
                    <label className=" block uppercase tracking-wide text-gray-700 text-xs font-bold">
                      Name
                    </label>
                    <div className="text-gray-900 items-start text-sm text-right -mt-1 ml-2">
                      <PillLabel color="red" label="Required" />
                    </div>
                  </div>
                </div>
                <div className="py-2   sm:h-1/5 max-h-1/5 sm:flex rounded">
                  <div className="p-4 flex felx-row w-full rounded-lg shadow-lg justify-between bg-white">
                    <Input
                      // @ts-ignore
                      handleChange={e => {
                        setName(e.target.value);
                      }}
                      handleBlur={async e => {
                        // dispatch(setPickUpName(e.target.value));
                        dispatch(setFirstName(e.target.value?.split(' ')[0]));
                        dispatch(
                          setTableNumber(`CT_${e.target.value?.split(' ')[0]}`),
                        );

                        try {
                          const guestNameResponse = await guestApi.updateGuest(
                            { first_name: e.target.value?.split(' ')[0] },
                            guest?.id,
                          );
                          dispatch(setUserProfile(guestNameResponse.data));
                        } catch (e) {}
                      }}
                      name="name"
                      value={name}
                      className={
                        'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                      }
                      autoComplete="off"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-left content-center items-start mb-2 mt-4">
                  <legend className=" block uppercase tracking-wide text-gray-700 text-xs font-bold">
                    Delivery Address
                  </legend>
                  <div className="text-gray-900 items-start text-sm text-right -mt-1 ml-2">
                    <PillLabel color="red" label="Required" />
                  </div>
                </div>
                <div className="bg-white rounded-md my-2 shadow-md px-4 py-2">
                  {/* <legend className="block text-sm font-medium text-gray-700">
                    Delivery Address
                  </legend> */}
                  {guest !== null && (
                    <div className="mt-1 -space-y-px rounded-md bg-white shadow-sm">
                      {addingNewAddress ? (
                        <div className="flex flex-col space-y-4">
                          <label htmlFor="card-number" className="sr-only">
                            Street Address
                          </label>
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyBCZTG_ZIce0-q8JgRU_EOSpv3vbcFVvwQ"
                            selectProps={{
                              addressValue,
                              onChange: async value => {
                                const response = await geocodeByPlaceId(
                                  value['value']['place_id'],
                                );
                                try {
                                  const addressRequest: GuestAddress = {
                                    guest_id: guest?.id,
                                    address_line_1:
                                      response[0]?.address_components[0]
                                        .long_name +
                                      ' ' +
                                      response[0]?.address_components[1]
                                        .long_name,
                                    city: response[0]?.address_components?.filter(
                                      a => a.types.includes('locality'),
                                    )[0].long_name,
                                    administrative_area_level_1:
                                      response[0]?.address_components.filter(
                                        a =>
                                          a.types.includes(
                                            'administrative_area_level_1',
                                          ),
                                      )[0].long_name,
                                    country:
                                      response[0]?.address_components.filter(
                                        a => a.types.includes('country'),
                                      )[0].short_name,
                                    zip_postal:
                                      response[0]?.address_components.filter(
                                        a => a.types.includes('postal_code'),
                                      )[0].short_name,
                                    place_id: response[0].place_id,
                                  };
                                  const addressSavedResponse =
                                    await guestApi.saveAddress(
                                      guest?.id,
                                      addressRequest,
                                    );
                                  dispatch(
                                    setFulfillmentDetails({
                                      [restaurant_id]: {
                                        ...order_fulfillment[restaurant_id],
                                        guest_address_id:
                                          addressSavedResponse.data?.id,
                                      },
                                    }),
                                  );
                                  dispatch(
                                    addNewAddress(addressSavedResponse.data),
                                  );
                                  dispatch(
                                    setSelectedAddress(
                                      addressSavedResponse.data,
                                    ),
                                  );

                                  setAddressValue(value);
                                  setAddingNewAddress(false);
                                } catch (e) {
                                  message.error('Unable to save address');
                                }
                              },
                            }}
                            onLoadFailed={error =>
                              console.error(
                                'Could not inject Google Maps script',
                                error,
                              )
                            }
                            minLengthAutocomplete={2}
                            autocompletionRequest={{
                              componentRestrictions: { country: 'us' },
                            }}
                          />
                          {guest?.addresses?.length !== 0 && (
                            <div
                              className="flex flex-row bg-gray-500 text-white font-semibold rounded-md text-center justify-center py-2"
                              onClick={() => {
                                setAddingNewAddress(false);
                              }}
                            >
                              Cancel
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="flex flex-col space-y-4">
                          {guest?.addresses?.map(a => {
                            return (
                              <div
                                key={a?.id?.toString()}
                                className={`flex flex-row rounded-md bg-white px-2 py-2 ${
                                  selectedAddress?.id === a?.id
                                    ? 'border-green-500 border-2'
                                    : 'border-gray-500 border-2'
                                }`}
                                onClick={() => {
                                  dispatch(setSelectedAddress(a));
                                  dispatch(
                                    setFulfillmentDetails({
                                      [restaurant_id]: {
                                        ...order_fulfillment[restaurant_id],
                                        guest_address_id: a?.id,
                                      },
                                    }),
                                  );
                                }}
                              >
                                {selectedAddress?.id === a?.id && (
                                  <div className="text-green-600 w-6 mt-1 mr-2">
                                    <CheckCircleIcon color="green-600" />
                                  </div>
                                )}
                                <div className="line-clamp-2 text-base font-medium text-gray-900">
                                  {a?.address_line_1?.concat(
                                    ', ',
                                    a?.city,
                                    ', ',
                                    a?.administrative_area_level_1,
                                    ', ',
                                    a?.country,
                                    ', ',
                                    a?.zip_postal,
                                  )}
                                </div>
                              </div>
                            );
                          })}
                          <div
                            className="flex flex-row bg-blue-500 text-white font-semibold rounded-md text-center justify-center py-2"
                            onClick={() => {
                              setAddingNewAddress(true);
                            }}
                          >
                            Add a new address
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="flex flex-row justify-left content-center items-start mb-2 mt-4">
                  <legend className=" block uppercase tracking-wide text-gray-700 text-xs font-bold">
                    Delivery Date & Time
                  </legend>
                  <div className="text-gray-900 items-start text-sm text-right -mt-1 ml-2">
                    <PillLabel color="red" label="Required" />
                  </div>
                </div>
                <DatePickerWrapper restaurant_id={restaurant_id} />
                {/* <div className={'h-0.5 w-8/10 bg-gray-900 my-4 mx-auto'} /> */}

                <div className="flex flex-row justify-left content-center items-start mb-2 mt-4">
                  <legend className=" block uppercase tracking-wide text-gray-700 text-xs font-bold">
                    Comments
                  </legend>
                </div>
                <div className="mt-1">
                  <textarea
                    rows={2}
                    name="comment"
                    id="comment"
                    onChange={e => {
                      setComment(e?.target?.value);
                      dispatch(
                        setCateringDetails({
                          [restaurant_id]: {
                            ...catering_details,
                            note: e.target.value,
                          },
                        }),
                      );
                      dispatch(
                        setFulfillmentDetails({
                          [restaurant_id]: {
                            ...order_fulfillment[restaurant_id],
                            catering_details: {
                              ...catering_details,
                              note: e.target.value,
                            },
                          },
                        }),
                      );
                    }}
                    value={
                      comment?.length !== 0
                        ? comment
                        : catering_details?.note ?? ''
                    }
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 ring-none sm:text-sm"
                  />
                </div>
              </>
            ) : restaurant_profile?.catering_settings?.offers_pickup &&
              selectedMode === 'pickup' ? (
              <>
                <div className={'flex flex-col'}>
                  <div className="flex flex-row justify-left content-center items-start mb-2 mt-4">
                    <label className=" block uppercase tracking-wide text-gray-700 text-xs font-bold">
                      Name
                    </label>
                    <div className="text-gray-900 items-start text-sm text-right -mt-1 ml-2">
                      <PillLabel color="red" label="Required" />
                    </div>
                  </div>
                </div>
                <div className="py-2   sm:h-1/5 max-h-1/5 sm:flex rounded">
                  <div className="p-4 flex felx-row w-full rounded-lg shadow-lg justify-between bg-white">
                    <Input
                      // @ts-ignore
                      handleChange={e => {
                        setName(e.target.value);
                      }}
                      handleBlur={async e => {
                        dispatch(setFirstName(e.target.value?.split(' ')[0]));
                        dispatch(
                          setTableNumber(`CT_${e.target.value?.split(' ')[0]}`),
                        );

                        try {
                          const guestNameResponse = await guestApi.updateGuest(
                            { first_name: e.target.value?.split(' ')[0] },
                            guest?.id,
                          );
                          dispatch(setUserProfile(guestNameResponse.data));
                        } catch (e) {}
                      }}
                      name="name"
                      value={name}
                      className={
                        'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                      }
                      autoComplete="off"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-left content-center items-start mb-2 mt-4">
                  <legend className=" block uppercase tracking-wide text-gray-700 text-xs font-bold">
                    Pickup Date & Time
                  </legend>
                  <div className="text-gray-900 items-start text-sm text-right -mt-1 ml-2">
                    <PillLabel color="red" label="Required" />
                  </div>
                </div>
                <DatePickerWrapper restaurant_id={restaurant_id} />

                <div className="flex flex-row justify-left content-center items-start mb-2 mt-4">
                  <legend className=" block uppercase tracking-wide text-gray-700 text-xs font-bold">
                    Comments
                  </legend>
                </div>
                <div className="mt-1">
                  <textarea
                    rows={2}
                    name="comment"
                    id="comment"
                    onChange={e => {
                      setComment(e?.target?.value);
                      dispatch(
                        setCateringDetails({
                          [restaurant_id]: {
                            ...catering_details,
                            note: e.target.value,
                          },
                        }),
                      );
                      dispatch(
                        setFulfillmentDetails({
                          [restaurant_id]: {
                            ...order_fulfillment[restaurant_id],
                            catering_details: {
                              ...catering_details,
                              note: e.target.value,
                            },
                          },
                        }),
                      );
                    }}
                    value={
                      comment?.length !== 0
                        ? comment
                        : catering_details?.note ?? ''
                    }
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 ring-none sm:text-sm"
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </fieldset>
        </div>
      )}
    </>
  );
}

function DatePickerWrapper({ restaurant_id }: DateProps) {
  const [deliveryDate, setDeliveryDate] = useState<Moment>();
  const [focused, setFocused] = useState(false);
  const onFocusChange = ({ focused }) => {
    setFocused(focused);
  };

  const catering_details = useSelector<
    RootState,
    OrderFulfillmentCateringDetailsCreate | null
  >(state => state.cart.catering_details);

  const [deliveryTime, setDeliveryTime] = useState('');
  const dispatch = useDispatch();
  useMemo(() => {
    if (deliveryDate && deliveryTime?.length !== 0) {
      const time_moment = moment(deliveryTime, 'HH:mm');
      const combined_moment = deliveryDate.set({
        hour: time_moment.hour(),
        minute: time_moment.minute(),
        second: time_moment.second(),
      });
      dispatch(
        setCateringDetails({
          [restaurant_id]: {
            ...catering_details,
            scheduled_for: combined_moment.toISOString(),
          },
        }),
      );
    }
  }, [deliveryDate, deliveryTime]);

  useEffect(() => {
    // if (catering_details && catering_details.scheduled_for) {
    //   const combined_moment = moment(catering_details.scheduled_for);
    //   setDeliveryDate(combined_moment);
    //   console.log(combined_moment.format('HH:mm'));
    //   setDeliveryTime(combined_moment.format('HH:mm'));
    // }
  }, []);
  return (
    <div className="bg-white rounded-md my-2 shadow-md px-4 py-2 flex flex-row items-center justify-between">
      <div>
        <div className="flex flex-row justify-left content-center items-start mb-2">
          <label
            className=" block uppercase tracking-wide text-gray-700 text-xs font-bold"
            htmlFor="delivery_date_input"
          >
            Date
          </label>
        </div>
        <SingleDatePicker
          date={deliveryDate}
          enableOutsideDays={false}
          keepFocusOnInput={false}
          onDateChange={date => {
            setDeliveryDate(date);
            //   setFocused(false);
          }}
          keepOpenOnDateSelect={false}
          id={'delivery_date_input'}
          isOutsideRange={day => {
            const minDate = moment();
            const maxDate = moment().add(1, 'months').endOf('month');
            return (
              isInclusivelyBeforeDay(day, minDate) ||
              isInclusivelyAfterDay(day, maxDate)
            );
          }}
          focused={focused}
          numberOfMonths={1}
          withPortal={false}
          onFocusChange={onFocusChange}
        ></SingleDatePicker>
      </div>
      <div className="mb-2">
        <div className="flex flex-row justify-left content-center items-start mb-2">
          <label
            className=" block uppercase tracking-wide text-gray-700 text-xs font-bold"
            htmlFor="delivery_time_input"
          >
            Time
          </label>
        </div>
        <input
          type="time"
          id="delivery_time_input"
          name="delivery_time_input"
          min="09:00"
          max="21:00"
          step="900"
          required
          onChange={e => {
            e.preventDefault();
            setDeliveryTime(e.target.value);
          }}
          value={deliveryTime}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-600 focus:ring-0 sm:text-base"
        />
      </div>
    </div>
  );
}
