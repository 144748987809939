import React, { useEffect } from 'react';
import '../style.css';
import { Route, Routes } from 'react-router-dom';
import { SetupSession } from './SetupSession';
import { SuccessSession } from './SuccessSession';

/* eslint-disable */
const Checkouts: React.FC = () => {
  useEffect(() => {}, []);

  // @ts-ignore
  return (
    <div className="min-h-screen flex bg-gray-100">
      <div className="flex flex-col w-0 flex-1  bg-gray-100">
        <Routes>
          <Route
            path={`/checkout/setup/success`}
            element={<SuccessSession />}
          />
          <Route path={`/checkout/setup`} element={<SetupSession />} />
        </Routes>
      </div>
    </div>
  );
};

export default Checkouts;
