import { useStripe } from '@stripe/react-stripe-js';
import { message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { LoadingIcon } from '../components/Spinner';

type Props = {};

export const SuccessSession: React.FC<Props> = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  let session_id;
  const [spinning, setSpinning] = useState(false);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    console.log('hello');
    setSpinning(true);
    const search = location.search;
    console.log(location);
    const params = new URLSearchParams(search);
    session_id = params.get('session_id');
    console.log(session_id);
    if (session_id !== undefined && session_id !== null) {
      setSpinning(false);
      setValid(true);
    } else {
      setSpinning(false);
      setValid(false);
    }
  }, []);

  return (
    <Spin spinning={spinning} indicator={LoadingIcon}>
      {valid && (
        <div className="bg-white w-screen h-screen items-center justify-center flex flex-col">
          <div className="px-10 align-middle">
            <div className="relative bg-gray-800 rounded-md shadow-md">
              <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                <div className="md:ml-auto md:w-full">
                  <div className="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
                    Payment Method
                  </div>
                  <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
                    Success
                  </h2>
                  <p className="mt-3 text-lg leading-7 text-gray-300">
                    Your card has been saved on file! Thank you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!valid && (
        <div className="bg-white w-screen h-screen items-center justify-center flex flex-col">
          <div className="px-10 align-middle">
            <div className="relative bg-gray-800 rounded-md shadow-md">
              <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                <div className="w-64 ">
                  <div className="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
                    Payment Method
                  </div>
                  <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
                    Failure
                  </h2>
                  <p className="mt-3 text-lg leading-7 text-gray-300">
                    Unable to save card on file.
                  </p>
                  <div className="mt-8">
                    <div className="inline-flex rounded-md shadow">
                      <button
                        onClick={() => {
                          navigate('/checkout/setup');
                        }}
                        className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                      >
                        Retry
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Spin>
  );
};
