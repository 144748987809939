import 'firebase/auth';
/* eslint-disable */
export async function getAuthHeaders() {
  return {
    Authorization: `Bearer ${localStorage.getItem('tabletabToken')}`,
  };
}

export function cleanParams(obj: Record<string, any>) {
  for (const propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      delete obj[propName];
    }
  }
  return obj;
}
