import React, { Fragment } from 'react';
import { TTMenu } from '../../../../interfaces/menus';
import { getFullImageUrl, isMenuAvailable } from '../../../utils';
import store from '../../../../store';
import { Transition } from '@headlessui/react';

type Props = {
  menu: TTMenu;
  onClick: () => void;
  card_width?: string;
};

const MenuCard: React.FC<Props> = ({ menu, onClick, card_width }) => {
  return (
    <Transition
      show={true}
      as={Fragment}
      enter="transform transition ease-in-out duration-500 sm:duration-700"
      enterFrom="translate-y-full"
      enterTo="translate-y-0"
      leave="transform transition ease-in-out duration-500 sm:duration-700"
      leaveFrom="translate-y-0"
      leaveTo="translate-y-full"
    >
      <div
        key={menu.id}
        onClick={onClick}
        className="bg-white rounded-lg shadow-md cursor-pointer"
      >
        <div
          className={`relative ${
            card_width && 'w-32 md:w-32 lg:w-40 2xl:w-44'
          }`}
        >
          <div className="group block w-full  aspect-w-14 aspect-h-14 rounded-t-lg bg-gray-100 overflow-hidden">
            <img
              src={
                menu.image_url !== null
                  ? getFullImageUrl(
                      menu.is_shared
                        ? menu.restaurant_id
                        : store.getState().restaurant.profile.restaurant_id,
                      `${menu.image_url}`,
                    )
                  : getFullImageUrl(
                      store.getState().restaurant.profile.restaurant_id,
                      `${
                        store.getState().restaurant.profile.main_profile_image
                      }`,
                    )
              }
              alt=""
              className="object-cover pointer-events-none group-hover:opacity-75"
            />
          </div>
          <p className="m-2 py-2 block text-md font-semibold text-gray-900 truncate-2-lines pointer-events-none">
            {menu.name}
          </p>
          <p className="block mx-2 text-sm text-gray-500 pointer-events-none truncate-4-lines">
            {menu.description ?? ''}
          </p>
        </div>
      </div>
    </Transition>
  );
};

export default MenuCard;
