import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, ChangeEvent } from 'react';
import store from '../../../store';

type Props = {
  onClick: (promoId: string) => void;
};

const PromotionField: React.FC<Props> = ({ onClick }) => {
  const [promoId, setPromoId] = useState<string | null>(
    store.getState().cart.promotion != null
      ? store.getState().cart.promotion.promo_id
      : '',
  );
  return (
    <>
      <label className="mx-4 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4">
        Promo Code
      </label>
      <div className="py-2 pr-4 pl-4  sm:h-1/5 max-h-1/5 sm:flex rounded">
        <div className="p-4 flex felx-row w-full rounded-lg shadow-lg justify-between bg-white">
          <input
            id="promo"
            value={promoId}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPromoId(e.target.value)
            }
            disabled={store.getState().cart.promotion != null}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
          <button
            className={`${
              promoId == '' ? 'bg-gray-200' : 'bg-green-200'
            } text-center align-center text-sm font-bold ml-2 rounded-md w-1/5 h-12 outline-none focus:outline-none`}
            onClick={() => {
              onClick(promoId);
            }}
          >
            {<FontAwesomeIcon icon="check" />}
          </button>
        </div>
      </div>
    </>
  );
};

export default PromotionField;
