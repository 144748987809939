import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TTRestaurantProfile } from '../../../interfaces/restaurant';
import { GuestAddress, TTUserProfile } from '../../../interfaces/user';
import { RootState } from '../../../rootReducer';
import Input from '../../components/Input';
import PillLabel from '../../../components/PillLabel';
import {
  setFulfillmentDetails,
  setPickupDetails,
  setTableNumber,
} from '../../../features/cart/cart';
import { setFirstName, setUserProfile } from '../../../features/auth/auth';
import { guestApi } from '../../../api/guest';
import {
  OrderFulfillmentPickupDetailsCreate,
  TTOrderFulfillmentCreate,
} from '../../../interfaces/order';

type Props = {
  restaurant_id: string;
};

export function PickupOrderDetails({ restaurant_id }: Props) {
  const restaurant_profile = useSelector<RootState, TTRestaurantProfile | null>(
    state => state.restaurant.profile,
  );

  const guest = useSelector<RootState, TTUserProfile | null>(
    state => state.auth.userProfile,
  );

  const order_fulfillment = useSelector<
    RootState,
    { [key: string]: TTOrderFulfillmentCreate }
  >(state => state.cart.fulfillment_details);

  const pickup_details = useSelector<
    RootState,
    OrderFulfillmentPickupDetailsCreate
  >(state => state.cart.pickup_details[restaurant_id]);

  const dispatch = useDispatch();
  const [name, setName] = useState('');

  const [comment, setComment] = useState('');

  useEffect(() => {}, [restaurant_profile]);

  useEffect(() => {
    if (guest !== null) {
      if (guest?.first_name !== null) {
        setName(guest.first_name);
        dispatch(setTableNumber(`P_${guest?.first_name?.split(' ')[0]}`));
      }
      if (order_fulfillment[restaurant_id] === undefined) {
        dispatch(
          setFulfillmentDetails({
            [restaurant_id]: {
              guest_id: guest?.id,
              type: 'pick_up',
              order_item_application: 'all_items',
            },
          }),
        );
      } else {
        dispatch(
          setFulfillmentDetails({
            [restaurant_id]: {
              ...order_fulfillment[restaurant_id],
              type: 'pick_up',
              catering_details: null,
              delivery_details: null,
            },
          }),
        );
      }
      if (pickup_details === undefined) {
        dispatch(
          setPickupDetails({
            [restaurant_id]: {
              guest_id: guest?.id,
              schedule_type: 'asap',
            },
          }),
        );
      } else {
        if (order_fulfillment[restaurant_id] !== undefined) {
          dispatch(
            setFulfillmentDetails({
              [restaurant_id]: {
                ...order_fulfillment,
                pickup_details: { ...pickup_details },
                catering_details: null,
                delivery_details: null,
              },
            }),
          );
        }
      }
    }
  }, [guest]);

  return (
    <>
      {restaurant_profile !== null && (
        <div className="mx-4 my-2">
          <fieldset>
            {
              <>
                <div className={'flex flex-col'}>
                  <div className="flex flex-row justify-left content-center items-start mb-2 mt-4">
                    <label className=" block uppercase tracking-wide text-gray-700 text-xs font-bold">
                      Name
                    </label>
                    <div className="text-gray-900 items-start text-sm text-right -mt-1 ml-2">
                      <PillLabel color="red" label="Required" />
                    </div>
                  </div>
                </div>
                <div className="py-2   sm:h-1/5 max-h-1/5 sm:flex rounded">
                  <div className="p-4 flex felx-row w-full rounded-lg shadow-lg justify-between bg-white">
                    <Input
                      // @ts-ignore
                      handleChange={e => {
                        setName(e.target.value);
                      }}
                      handleBlur={async e => {
                        // dispatch(setPickUpName(e.target.value));
                        dispatch(setFirstName(e.target.value?.split(' ')[0]));
                        dispatch(
                          setTableNumber(`CT_${e.target.value?.split(' ')[0]}`),
                        );

                        try {
                          const guestNameResponse = await guestApi.updateGuest(
                            { first_name: e.target.value?.split(' ')[0] },
                            guest?.id,
                          );
                          dispatch(setUserProfile(guestNameResponse.data));
                        } catch (e) {}
                      }}
                      name="name"
                      value={name}
                      className={
                        'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                      }
                      autoComplete="off"
                      placeholder=""
                    />
                  </div>
                </div>

                {/* <div className={'h-0.5 w-8/10 bg-gray-900 my-4 mx-auto'} /> */}

                <div className="flex flex-row justify-left content-center items-start mb-2 mt-4">
                  <legend className=" block uppercase tracking-wide text-gray-700 text-xs font-bold">
                    Comments
                  </legend>
                </div>
                <div className="mt-1">
                  <textarea
                    rows={2}
                    name="comment"
                    id="comment"
                    onChange={e => {
                      setComment(e?.target?.value);
                      dispatch(
                        setPickupDetails({
                          [restaurant_id]: {
                            ...pickup_details,
                            note: e.target.value,
                          },
                        }),
                      );
                      dispatch(
                        setFulfillmentDetails({
                          [restaurant_id]: {
                            ...order_fulfillment[restaurant_id],
                            pickup_details: {
                              ...pickup_details,
                              note: e.target.value,
                            },
                          },
                        }),
                      );
                    }}
                    value={
                      comment?.length !== 0
                        ? comment
                        : pickup_details?.note ?? ''
                    }
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 ring-none sm:text-sm"
                  />
                </div>
              </>
            }
          </fieldset>
        </div>
      )}
    </>
  );
}
