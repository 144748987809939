import React, { useEffect, useState } from 'react';
import { TTModifierPairingOption } from '../../../interfaces/modifier_pairing_options';
import RowCell from '../../components/RowCell';
import { TTMenuItem } from '../../../interfaces/menuItem';
import { Spin } from 'antd';
import { LoadingIcon } from '../../components/Spinner';
import { menuItemsApi } from '../../../api/menuItems';
import { getFullImageUrl } from '../../utils';
import store from '../../../store';
import PillLabel from '../../../components/PillLabel';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { ShoppingCartIcon } from '@heroicons/react/outline';

type Props = {
  modifier_pairing_option: TTModifierPairingOption;
  onClick: () => void;
  isSelected: boolean;
  disabled: boolean;
};

export const ModifierPairingOptionRow: React.FC<Props> = ({
  modifier_pairing_option,
  onClick,
  isSelected,
  disabled,
}) => {
  const [menuItem, setMenuItem] = useState<TTMenuItem | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        const response = await menuItemsApi.getMenuItem(
          modifier_pairing_option.menu_item_id,
        );
        setMenuItem(response.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }

    if (menuItem === null) {
      setLoading(true);
      fetch();
    }
  }, []);

  console.log(isSelected);

  return (
    <Spin spinning={loading} indicator={LoadingIcon}>
      <div
        className={`flex-grow flex flex-row bg-white p-4 items-between rounded-lg shadow-lg justify-between ${
          isSelected && 'border-2 border-green-400 border-md'
        }`}
        onClick={onClick}
        aria-disabled={disabled}
      >
        <div className="flex-grow flex flex-col justify-between leading-normal">
          <div className="mb-2">
            <div className="flex items-center mb-2 space-x-1">
              {isSelected && (
                <div className="text-green-600 w-4 h-4 flex ">
                  <CheckCircleIcon color="green-600" />
                </div>
              )}
              <div className="text-gray-900 font-semibold text-sm  mr-1">
                {menuItem?.name}
              </div>
            </div>
            <div className="max-h-20">
              <p className="text-gray-700 text-xs md:text-sm truncate-4-lines mr-1 whitespace-pre-wrap">
                {menuItem?.description}
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-left items-center  space-x-1 space-y-0">
            {isSelected && (
              <div className="flex flex-row items-center space-x-1 bg-gray-900 px-2 py-0.5 rounded-full">
                <ShoppingCartIcon className="w-4 h-4 text-white" />
                <div className="text-white text-xs font-semibold">
                  {modifier_pairing_option?.orderItem?.quantity}
                </div>
              </div>
            )}

            <div>
              <PillLabel
                color="green"
                label={`${
                  store.getState().restaurant?.profile?.is_payment_live
                    ? isSelected
                      ? 'Click to Update'
                      : 'Click to Add'
                    : 'Click to View'
                }`}
              />
            </div>
          </div>
        </div>
        {menuItem?.image_url !== null &&
          !RegExp(
            `^(https:\/\/d2b8t2l9x5xm7h.cloudfront.net\/public\/[0-9]*\/100.jpg).*`,
          ).test(menuItem?.image_url) && (
            <img
              className={'flex w-32 h-32 shrink-0 rounded-lg object-cover mr-2'}
              src={getFullImageUrl(
                store.getState().restaurant?.restaurant_id,
                menuItem?.image_url,
              )}
              alt={menuItem?.name + ' Image'}
            />
          )}
      </div>
      {/* <tr
        className={`disabled:bg-gray-100 flex flex-row rounded-md justify-between items-center shadow-md bg-white w-full ${
          isSelected && 'border-2 border-green-400 border-md'
        }`}
        onClick={onClick}
        aria-disabled={disabled}
      > */}
      {
        <>
          {/* <td className={'flex-col  pl-4 pr-2 py-2 text-sm items-center '}>
              <p
                className={
                  'text-gray-900 font-medium hover:text-gray-600 truncate'
                }
              >
                {menuItem !== null && menuItem.name}
              </p>
              <p className={'text-gray-700 truncate whitespace-pre-wrap'}>
                {menuItem !== null && menuItem.description}
              </p>
              <PillLabel
                color="green"
                label={`${
                  store.getState().restaurant.profile.is_payment_live
                    ? 'Click to Add'
                    : 'Click to View'
                }`}
              />
            </td> */}
          {/* {menuItem !== null &&
              menuItem.image_url !== null &&
              menuItem.image_url !== undefined && (
                // <td className={''}>
                <img
                  className={
                    'flex w-32 h-32 shrink-0 rounded-lg object-cover mr-2'
                  }
                  src={getFullImageUrl(
                    store.getState().restaurant.restaurant_id,
                    menuItem.image_url,
                  )}
                  alt={menuItem.name + ' Image'}
                />
                // </td>
              )} */}
        </>
      }
      {/* </tr> */}
    </Spin>
  );
};
