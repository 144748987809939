import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router';
import { GuestOverview } from './GuestOverview';
import OrderHistoryDetailView from './OrderHistoryDetailView';
type Props = {};

export function GuestHome() {
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location.pathname);
  return (
    <>
      <Routes>
        <Route path="/orders/:id" element={<OrderHistoryDetailView />} />
        <Route path="/" element={<GuestOverview />} />
      </Routes>
    </>
  );
}
