import React from 'react';
import { TTMenuItem } from '../../../interfaces/menuItem';
import store from '../../../store';
import { PairingCard } from './PairingCard';

type Props = {
  menuItem: TTMenuItem;
  callback: (id: number) => void;
};

export const PairingCollection: React.FC<Props> = ({ menuItem, callback }) => {
  return (
    <div className={'p-4'}>
      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
        Merchant Recommended
      </h2>
      <div className="flex flex-row flex-no-wrap p-4 overflow-x-scroll items-center rounded-lg scrolling-touch">
        {menuItem.pairings.map(p => {
          return (
            <div className={''} key={p}>
              <PairingCard
                key={p}
                menu_item_id={p}
                onClick={() => callback(p)}
                inCart={
                  store.getState().cart.cart_items === null
                    ? false
                    : store.getState().cart.cart_items.length === 0
                    ? false
                    : store
                        .getState()
                        .cart.cart_items.filter(
                          item => item.menu_item_id == p && item.as_pairing,
                        ).length !== 0
                }
              />{' '}
            </div>
          );
        })}
      </div>
    </div>
  );
};
