import React from 'react';

type Props = {
  onClick: () => void;
  title?: string;
  className: string;
  bgColor: string;
  textColor: string;
};

const PlaceOrderButton: React.FC<Props> = ({
  onClick,
  title = 'Start your Tab',
  className,
  bgColor,
  textColor,
}) => {
  return (
    <>
      <div className={`p-4 w-full justify-center items-center ${className}`}>
        <button
          className={`${bgColor} ${textColor} align-middle justify-center font-bold text-lg shadow-lg rounded-full w-full h-10 outline-none focus:outline-none`}
          onClick={onClick}
        >
          {title}
        </button>
      </div>
    </>
  );
};

export default PlaceOrderButton;
