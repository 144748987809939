import React from 'react';
import { StripeCard } from '../../../interfaces/stripe_payment_method';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import PillLabel from '../../../components/PillLabel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';

type Props = {
  card: StripeCard;
  onClick: () => void;
};

const StripeCardView: React.FC<Props> = ({ card, onClick }) => {
  const isEditMode = useSelector<RootState, boolean>(
    state => state.payment.edit_mode,
  );
  return (
    <div className="py-2 px-4   rounded" onClick={onClick}>
      <div className="flex flex-col p-4 bg-white h-full rounded-lg shadow-lg items-center justify-center align-middle">
        <div className="flex flex-row w-full items-center justify-between">
          <div className="text-gray-900 flex flex-row items-center text-sm text-left justify-left content-center">
            <FontAwesomeIcon icon={faCreditCard} color="green" className="" />
            <div className="pl-2 items-center">
              {card?.card?.brand?.toUpperCase()}
            </div>
            <div className="pl-2">{card?.card?.last4}</div>
          </div>
          <div className="text-gray-900 flex flex-row items-center text-sm text-right justify-right content-center">
            <PillLabel
              color={isEditMode ? 'red' : 'green'}
              label={isEditMode ? 'Delete' : 'Select'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeCardView;
