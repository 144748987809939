import { message, Spin } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { paymentsApi } from '../../../api/payments';
import {
  removePaymentMethod,
  setSelectedPM,
  toggleEditMode,
} from '../../../features/payment/payment';
import {
  SquareCard,
  StripeCard,
} from '../../../interfaces/stripe_payment_method';
import { RootState } from '../../../rootReducer';
import { LoadingIcon } from '../../components/Spinner';
import SquareCardView from '../SquareElements/SquareCardView';
import StripeCardView from './SelectedPMCard';

type Props = {
  callback: (id: string) => void;
  platform: string;
};

const PaymentMethodList: React.FC<Props> = ({ callback, platform }) => {
  const cards = useSelector<RootState, any[]>(
    state => state.payment.payment_methods,
  );
  const isEditMode = useSelector<RootState, boolean>(
    state => state.payment.edit_mode,
  );
  const [removingCard, setRemovingCard] = useState(false);
  const dispatch = useDispatch();

  async function removeCard(id: string) {
    try {
      setRemovingCard(true);
      dispatch(setSelectedPM(null));
      const response = await paymentsApi.removeCardOnFile(platform, id);
      dispatch(removePaymentMethod(id));
      message.success('Card deleted');
      dispatch(toggleEditMode());
      setRemovingCard(false);
    } catch (e) {
      setRemovingCard(false);
      message.error(
        e.response?.data?.detail ?? 'Unable to delete card on file',
      );
    }
  }

  return (
    <Spin spinning={removingCard} indicator={LoadingIcon}>
      <table className="min-w-full">
        <tbody>
          {platform == 'STRIPE' &&
            cards.map((card: StripeCard, i) => {
              return (
                <tr key={card.id}>
                  <td>
                    <StripeCardView
                      card={card}
                      onClick={async () => {
                        if (isEditMode) {
                          await removeCard(card.id);
                        } else {
                          callback(card.id);
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}

          {platform == 'SQUARE' &&
            cards.map((card: SquareCard, i) => {
              return (
                <tr key={card.id}>
                  <td>
                    <SquareCardView
                      card={card}
                      onClick={async () => {
                        if (isEditMode) {
                          await removeCard(card.id);
                        } else {
                          callback(card.id);
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Spin>
  );
};

export default PaymentMethodList;
