import { UserIcon } from '@heroicons/react/solid';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { TTUserProfile } from '../../interfaces/user';
import { RootState } from '../../rootReducer';

type Props = {};

export function GuestAccessPoint() {
  const guestProfile = useSelector<RootState, TTUserProfile | null>(
    state => state.auth.userProfile,
  );
  const location = useLocation();
  const navigate = useNavigate();
  if (guestProfile === null || guestProfile === undefined) {
    return <></>;
  }
  if (location.pathname.match(/(guests)\//gm)) {
    return <></>;
  }
  return (
    <>
      <div className="relative">
        <div
          className="fixed right-5 bottom-16"
          style={{
            width: '48px',
            height: '50px',
            padding: '0px',
            margin: '10px 20px',
            position: 'fixed',
            bottom: '54px',
            overflow: 'visible',
            opacity: '1',
            border: '0px',
            zIndex: '999998',
            transitionDuration: '250ms',
            transitionTimingFunction: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
            transitionProperty: 'opacity top bottom',
            right: '0px',
          }}
        >
          <div
            className="bg-blue-700 rounded-full px-3 py-3"
            onClick={() => {
              navigate('guests/');
            }}
          >
            <UserIcon className="h-6 w-6 text-white" />
          </div>
        </div>
      </div>
    </>
  );
}
