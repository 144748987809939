import { QrcodeIcon } from '@heroicons/react/outline';
import { message, Spin } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ordersApi } from '../../../api/order';
import {
  setOrder,
  setRequestTableNumberVerification,
} from '../../../features/order/order';
import { TTOrderV2 } from '../../../interfaces/order';
import { TTRestaurantProfile } from '../../../interfaces/restaurant';
import { TTUserProfile } from '../../../interfaces/user';
import { RootState } from '../../../rootReducer';
import { LoadingIcon } from '../../components/Spinner';

export function ChangeTableNumberModal() {
  const restaurantProfile = useSelector<RootState, TTRestaurantProfile | null>(
    state => state.restaurant.profile,
  );
  const order = useSelector<RootState, TTOrderV2 | null>(
    state => state.order.order,
  );
  const lastScannedTable = useSelector<RootState, string | null>(
    state => state.hybrid.lastQRTableNumber,
  );

  const guest = useSelector<RootState, TTUserProfile | null>(
    state => state.auth.userProfile,
  );

  const [loading, setLoading] = useState(false);
  const [localNum, setLocalNum] = useState('');
  const dispatch = useDispatch();
  const handleTableFormSubmit = async e => {
    const value = e.target.elements.table_number.value;
    if (
      !restaurantProfile?.table_numbers?.includes(value) ||
      value == undefined
    ) {
      message.error('Invalid table number. Please enter a valid table number');
      return;
    }
    try {
      setLoading(true);
      const response = await ordersApi.updateOrderTableNumber(order?.id, {
        table_number: value,
      });
      dispatch(setOrder(response.data));
      message.success('Table number successfully updated!');
      setLoading(false);
      dispatch(setRequestTableNumberVerification(false));
    } catch (e) {
      setLoading(false);
      message.error(
        e?.response?.data?.detail ?? 'Unable to update the table number.',
      );
    }
  };
  return (
    <>
      <div className="bg-white shadow rounded-md">
        <Spin spinning={loading} indicator={LoadingIcon}>
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Confirm your table number
            </h3>

            <form
              className="mt-5 sm:flex sm:items-center"
              onSubmit={async e => {
                e.preventDefault();
                await handleTableFormSubmit(e);
              }}
            >
              <div>
                <label
                  htmlFor="table_number"
                  className="block text-sm font-medium text-gray-700 text-left"
                >
                  Table number
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <QrcodeIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="table_number"
                    id="table_number"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    // placeholder='1'
                    value={
                      localNum != ''
                        ? localNum
                        : restaurantProfile?.table_numbers?.includes(
                            order?.table_number,
                          )
                        ? order?.table_number
                        : lastScannedTable !== null &&
                          lastScannedTable !== undefined
                        ? lastScannedTable
                        : ''
                    }
                    onChange={e => {
                      setLocalNum(e.target.value);
                    }}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="mt-6 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto text-base"
              >
                Confirm
              </button>
            </form>
          </div>
        </Spin>
      </div>
    </>
  );
}
