import React from 'react';
/* eslint-disable */
type PillLabelProps = {
  color: 'green' | 'blue' | 'gray' | 'red' | 'indigo' | 'orange';
  label: string;
  label_size?: 'text-xs' | 'text-md' | 'text-lg';
};

const PillLabel: React.FC<PillLabelProps> = props => {
  return (
    <span
      className={`px-2 inline-flex ${
        props.label_size ? props.label_size + ' py-0.5' : 'text-xs'
      } leading-5 font-semibold rounded-full bg-${props.color}-100 text-${
        props.color
      }-800`}
    >
      {props.label}
    </span>
  );
};

export default PillLabel;
