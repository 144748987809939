import { useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { message } from 'antd';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { checkoutsApi } from '../../api/checkouts';

type Props = {};

export const SetupSession: React.FC<Props> = ({}) => {
  const stripe = useStripe();
  const location = useLocation();
  let customer_id;

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    customer_id = params.get('customer_id');
  });

  const handleClick = async event => {
    // Call your backend to create the Checkout session.
    try {
      const response = await checkoutsApi.createSetupSession({
        customer_id: customer_id,
      });
      // When the customer clicks on the button, redirect them to Checkout.
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.checkoutSession.id,
      });
    } catch (e) {
      message.error(e.message);
    }

    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };
  return (
    <div className="bg-white w-screen h-screen items-center justify-center flex flex-col">
      <div className="px-10 align-middle">
        <div className="relative bg-gray-800 rounded-md shadow-md">
          <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2 rounded-md">
            <img
              className="w-full h-full object-cover rounded-t-md md:rounded-l-md"
              src="https://dyneios5e1104b7b11b45eab3a4895c2b805a5b-948157201798-us-east-1.s3.amazonaws.com/website/Shadow.jpg"
              alt="Support team"
            />
          </div>
          <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
            <div className="md:ml-auto md:w-1/2 md:pl-10">
              <div className="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
                Payment Method
              </div>
              <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
                Save Card on File
              </h2>
              <p className="mt-3 text-lg leading-7 text-gray-300">
                Please save card on file by clicking the continue button to
                retry a charge and future usage. Your payment information is
                securely saved. Powered by Stripe.
              </p>
              <div className="mt-8">
                <div className="inline-flex rounded-md shadow">
                  <button
                    onClick={handleClick}
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  >
                    Continue
                    <svg
                      className="-mr-1 ml-3 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
