import React from 'react';
import { TTOrder, TTOrderV2 } from '../../../interfaces/order';

type Props = {
  className?: string;
  order: TTOrderV2;
  orderMoreCallback: () => void;
};

export const DineInInfo: React.FC<Props> = ({ order, orderMoreCallback }) => {
  return (
    <>
      <div className="flex items-center flex-row justify-between space-x-4 px-4 pt-4">
        <div>
          <div className={'text-lg  text-gray-900'}>
            Table Number: {order.table_number}
          </div>
        </div>
        <button
          onClick={orderMoreCallback}
          type="button"
          className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Order More
        </button>
      </div>
    </>
  );
};
