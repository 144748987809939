import React from 'react';
import { useSelector } from 'react-redux';
import { TTRestaurantProfile } from '../../interfaces/restaurant';
import { RootState } from '../../rootReducer';
import TableTabBanner from '../components/TableTabBanner';
import ViewHeader from '../components/ViewHeader';
import { InfoAlert } from './components/InfoAlert';

type Props = {};

export function OrderClosedThankYou(props: Props) {
  const profile = useSelector<RootState, TTRestaurantProfile | null>(
    state => state.restaurant.profile,
  );
  return (
    <>
      <div className="min-h-screen bg-gray-100 ">
        <div
          className={
            'flex flex-col pt-4 p-4 overflow-y-scroll scrolling-touch bg-gray-100'
          }
        >
          <ViewHeader
            title="Thanks for visiting!"
            className={'text-left px-2'}
          />
          <div className={'bg-white rounded-md shadow-md mt-16'}>
            <div className={'flex flex-col p-4'}>
              <div className={''}>
                <img
                  className={'w-40 h-40 flex-shrink-0 mx-auto rounded-full'}
                  src={profile?.main_profile_image || ''}
                ></img>
                <h3 className="mt-6 text-center text-gray-900 text-lg font-medium">
                  {profile?.name}
                </h3>
                {profile?.bio && (
                  <p className="mt-2 text-center text-gray-600 text-sm font-medium">
                    {profile?.bio}
                  </p>
                )}
              </div>
            </div>
          </div>
          {/* <div className="mt-4">
            <InfoAlert text="Do you know other breweries or restaurants that could be a great fit for TableTab?" />
          </div> */}
          <div className={'mt-8 w-full'}>
            <TableTabBanner applyFilter={false} />
          </div>
        </div>
      </div>
    </>
  );
}
