import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlus,
  faHome,
  faThLarge,
  faCube,
  faStore,
  faNewspaper,
  faChevronLeft,
  faBars,
  faDolly,
  faWarehouse,
  faWallet,
  faCalculator,
  faCreditCard,
  faHistory,
  faUsers,
  faUser,
  faPhone,
  faEnvelopeOpen,
  faMoneyBillWave,
  faMinus,
  faCheck,
  faCompress,
  faExpand,
  faPencilAlt,
  faEdit,
  faSave,
  faTimes,
  faRedo,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

export {
  faPlus,
  faHome,
  faThLarge,
  faCube,
  faStore,
  faNewspaper,
  faChevronLeft,
  faBars,
  faDolly,
  faWarehouse,
  faWallet,
  faCalculator,
  faCreditCard,
  faHistory,
  faUsers,
  faUser,
  faPhone,
  faEnvelopeOpen,
  faMoneyBillWave,
  faMinus,
  faCheck,
  faCompress,
  faExpand,
  faPencilAlt,
  faEdit,
  faSave,
  faTimes,
  faRedo,
  faQuestionCircle,
};

library.add(
  faPlus,
  faHome,
  faThLarge,
  faCube,
  faStore,
  faNewspaper,
  faChevronLeft,
  faBars,
  faDolly,
  faWarehouse,
  faWallet,
  faCalculator,
  faCreditCard,
  faHistory,
  faMoneyBillWave,
  faMinus,
  faCheck,
  faCompress,
  faExpand,
  faPencilAlt,
  faEdit,
  faSave,
  faTimes,
  faRedo,
  faQuestionCircle,
);
