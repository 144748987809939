import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../rootReducer';
import Login from '../Login/index';
import { getCurrentGuestProfile } from '../../features/auth/auth';
import 'firebase/auth';
import 'firebase/analytics';
import { message, Spin } from 'antd';
import { LoadingIcon } from '../components/Spinner';
import * as Sentry from '@sentry/react';
import { isMobile } from 'react-device-detect';
import useQuery from '../../hooks/useQuery';
import RestaurantFeed from '../Feed/RestaurantFeed/FeedList';
import MenuRouter from '../Menu';
import Zendesk from 'react-zendesk';
import { zendeskKey } from '../../env';
import Checkouts from '../Checkouts';
import { setLastTableNumber } from '../../features/hybrid/hybrid';
import {
  changeOrderType,
  createOrderObj,
  setQR,
  setQRMenuIds,
} from '../../features/cart/cart';
import store from '../../store';
import {
  fetchQRCodeAddress,
  setCategorizedView,
  setQRAddress,
} from '../../features/restaurant/restaurant';
import { GuestHome } from '../Guest';
import { GuestAccessPoint } from '../Guest/GuestAccessPoint';
Sentry.init({
  dsn: 'https://7acf435d176b46d8b448ce0fa529f468@o440089.ingest.sentry.io/5467875',
  release: 'pwa@' + process.env.REACT_APP_VERSION,
  tracesSampleRate: 0.1,
  environment: 'production',
});

declare global {
  interface Window {
    heap: any;
  }
}

const setting = {
  color: {
    theme: '#000',
  },
  launcher: {
    chatLabel: {
      'en-US': 'Help Desk',
    },
  },
};

function AppRouteComponent(props: {
  spinning: boolean;
  onLoad: () => void;
  render: (props) => JSX.Element;
  render1: (props) => JSX.Element;
  render2: () => JSX.Element;
  render3: () => JSX.Element;
}) {
  const desktopComingSoon = (
    <div className="flex justify-center w-full bg-gray-900 h-screen ">
      <div className="flex flex-col justify-center pt-10 object-center items-center w-3/5 bg-white shadow-lg">
        <img
          className="w-1/2  object-center"
          src="https://dyneios5e1104b7b11b45eab3a4895c2b805a5b-948157201798-us-east-1.s3.amazonaws.com/website/coolwcode.png"
          onLoad={props.onLoad}
          alt="Sunset in the mountains"
        />
        <p className="text-center pt-5 font-bold text-xl">
          Coming Soon to Desktop
        </p>
        <p className="text-center font-bold text-l">
          Scan the QR Code on your mobile device
        </p>
      </div>
    </div>
  );
  return (
    <>
      {/* {!isMobile && (
        <Routes>
          <Route
            path="/.well-known/apple-developer-merchantid-domain-association"
            children={() => (
              <link
                rel="apple-developer-merchantid-domain-association file"
                href="%PUBLIC_URL%/.well-known/apple-developer-merchantid-domain-association"
              />
            )}
          />
          <Route path="/checkout" element={<Checkouts />} />
          <Route path={'/'} element={desktopComingSoon} />
        </Routes>
      )} */}
      <Zendesk zendeskKey={zendeskKey} {...setting} />
      <Spin indicator={LoadingIcon} spinning={props.spinning}>
        {props.spinning && (
          <div className="justify-center h-full w-full py-1/2" />
        )}
        {!props.spinning && (
          <>
            <Routes>
              <Route path="/checkout" element={<Checkouts />} />
              <Route path="/login" element={<Login />} />
              <Route path="/guests/*" element={<GuestHome />} />
              <Route
                path={'/feed/restaurants/:r_id/*'}
                element={<MenuRouter />}
              />
              <Route
                path="/feed/restaurants/all"
                element={<RestaurantFeed />}
              />
              <Route path="/feed/*" children={props.render1} />
              <Route path="/*" element={<RestaurantFeed />} />
            </Routes>
            <GuestAccessPoint />
          </>
        )}
      </Spin>
    </>
  );
}

function AppRoute(props) {
  const [loading, setLoading] = useState(false);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  const query = useQuery();
  const [rUrl, setRUrl] = useState<string | null>(null);

  useEffect(() => {
    if (query.get('r_id') != null) {
      if (query.get('table_number') != null) {
        localStorage.setItem('__tt_table_number', query.get('table_number'));
        dispatch(setLastTableNumber(query.get('table_number')));
      }
      if (query.get('order_type') != null) {
        localStorage.setItem('__tt_dining_mode', query.get('order_type'));

        // @ts-ignore
        dispatch(changeOrderType(query.get('order_type') ?? 'dine_in'));
      } else {
        localStorage.setItem('__tt_dining_mode', 'dine_in');
        dispatch(changeOrderType('dine_in'));
      }
      if (query.get('menu_ids') !== null && query.get('qr') !== null) {
        const menu_ids = query.getAll('menu_ids');
        const qr = query.get('qr');
        let values = {};
        values[qr] = menu_ids.map(v => parseInt(v));
        dispatch(setQRMenuIds(values));
        dispatch(setQR(qr));
      } else if (query.get('qr') !== null && query.get('menu_ids') === null) {
        const qr = query.get('qr');
        let values = {};
        values[qr] = [];
        dispatch(setQRMenuIds(values));
        dispatch(setQR(qr));
      }
      if (query.get('categorized_view') !== null) {
        dispatch(
          setCategorizedView(
            query.get('categorized_view') === 'True' ||
              query.get('categorized_view') === 'true',
          ),
        );
      } else {
        dispatch(setCategorizedView(false));
      }
      if (query.get('address_id')) {
        if (!isNaN(parseInt(query.get('address_id')))) {
          const address_id = parseInt(query.get('address_id'));
          dispatch(fetchQRCodeAddress(parseInt(query.get('r_id')), address_id));
        } else {
          dispatch(setQRAddress(null));
        }
      }
      setRUrl(
        encodeURIComponent(
          `https://order.tabletab.io/feed/restaurants/${query.get(
            'r_id',
          )}/menus`,
        ),
      );
    } else {
      setRUrl('https://order.tabletab.io/feed');
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (
      localStorage.getItem('tabletabToken') !== undefined &&
      localStorage.getItem('tabletabToken') !== null
    ) {
      dispatch(
        getCurrentGuestProfile(() => {
          // fetchCurrentOpenOrder();
          setLoading(false);
        }),
      );
    }

    return function cleanup() {
      isMounted = false;
    };
  }, [isLoggedIn]);

  return (
    <div>
      <AppRouteComponent
        spinning={loading}
        onLoad={() => {}}
        render={() => (isLoggedIn ? <Navigate to={rUrl} /> : <Login />)}
        render1={() => <Navigate to={rUrl} />}
        render2={() => <Navigate to={rUrl} />}
        render3={() => {
          return <Navigate to={rUrl} />;
        }}
      />
    </div>
  );
}

const App = props => {
  return <AppRoute props={props} />;
};

export default App;
