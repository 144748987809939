import React, { ReactNode } from 'react';
/* eslint-disable */
type Props = {
  bold?: Boolean;
  children?: ReactNode;
  num_headers?: number;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
};

const RowCell: React.FC<Props> = ({
  bold = false,
  children,
  onClick,
  num_headers = 1,
  className,
}) => {
  return (
    <td
      className={`px-4 py-4 whitespace-no-wrap max-w-1/${num_headers.toString()} align-middle content-center ${className}`}
      onClick={onClick}
    >
      <div
        className={`text-sm break-words leading-5 text-gray-900 ${
          bold && 'font-medium'
        }`}
      >
        {children}
      </div>
    </td>
  );
};

export default RowCell;
