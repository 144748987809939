import axios from 'axios';
import { apiUrl, menuUrl } from '../env';
import { getAuthHeaders } from './utils';
import {
  RestaurantAddress,
  TRestaurant,
  TTRestaurantProfile,
} from '../interfaces/restaurant';
/* eslint-disable */

export const restaurantApi = {
  async getRestaurant(id: number) {
    return axios.get<TRestaurant>(`${menuUrl}/api/v1/restaurants/${id}`, {
      headers: await getAuthHeaders(),
    });
  },
  async getProfile(restaurant_id: number) {
    return axios.get<TTRestaurantProfile>(
      `${menuUrl}/api/v1/restaurant-profile/?restaurant_id=${restaurant_id}`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async getAddresses(restaurant_id: number) {
    return axios.get<RestaurantAddress[]>(
      `${menuUrl}/api/v1/restaurants/${restaurant_id}/address`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
  async getAddress(restaurant_id: number, address_id: number) {
    return axios.get<RestaurantAddress>(
      `${menuUrl}/api/v1/restaurants/${restaurant_id}/address/${address_id}`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },

  async getProfiles() {
    return axios.get<TTRestaurantProfile[]>(
      `${menuUrl}/api/v1/restaurant-profile/?limit=100`,
      {
        headers: await getAuthHeaders(),
      },
    );
  },
};
