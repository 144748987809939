import React, { useState, ChangeEvent } from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { setTableNumber } from '../../../features/cart/cart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercentage } from '@fortawesome/free-solid-svg-icons';

type Props = {
  onClick: (customTip: number) => void;
};

const CustomTipField: React.FC<Props> = ({ onClick }) => {
  const [customTip, setCustomTip] = useState<string | null>('');
  return (
    <>
      {
        <div className="flex flex-row w-full">
          <input
            id="number"
            type="number"
            required
            placeholder="As a percentage"
            value={customTip}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              console.log(e.target.value);
              const re = /^[0-9]\d*(\.\d+)?$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                logEvent(getAnalytics(), 'tip_changed', {
                  new_tip: e.target.value,
                });
                setCustomTip(e.target.value);
              } else {
                setCustomTip('22');
              }
            }}
            className="appearance-none block w-4/5 px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
          {customTip !== '' && (
            <button
              className={`${
                customTip !== '' ? 'bg-green-600' : 'bg-gray-200'
              } ${
                customTip === '' ? 'disabled' : ''
              } text-center align-center text-sm font-bold ml-2 rounded-md w-1/5 h-12 shadow-md outline-none focus:outline-none items-center justify-center`}
              onClick={() => {
                onClick(parseInt(customTip));
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={'text-white h-8 w-8 mx-auto'}
              >
                <FontAwesomeIcon icon={faPercentage} />
              </svg>
            </button>
          )}
        </div>
      }
    </>
  );
};

export default CustomTipField;
