import { SortAscendingIcon, XIcon } from '@heroicons/react/solid';
import { message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { ordersApi } from '../../../api/order';
import { paymentsApi } from '../../../api/payments';
import { clearCart } from '../../../features/cart/cart';
import {
  attemptCloseOrder,
  createGuestCheck,
  setOrder,
  setOrderTipPercentage,
} from '../../../features/order/order';
import { TTOrderV2 } from '../../../interfaces/order';
import { TTRestaurantProfile } from '../../../interfaces/restaurant';
import { RootState } from '../../../rootReducer';
import store from '../../../store';
import ErrorIcon from '../../components/ErrorIcon';
import { LoadingIcon } from '../../components/Spinner';
import SuccessIcon from '../../components/SuccessIcon';
import { PaymentMethods } from '../../Payment/PaymentMethods';

export default function ChangeTipModal({ onClose, closeOrderPost }) {
  const restaurantProfile = useSelector<RootState, TTRestaurantProfile | null>(
    state => state.restaurant.profile,
  );
  const currentTipPercentage = useSelector<RootState, number>(
    state => state.order.order_tip_percentage ?? 22,
  );
  const { r_id } = useParams();
  useEffect(() => {
    if (restaurantProfile !== null) {
      let tips = restaurantProfile.pre_set_tip_defaults ?? [
        '18',
        '20',
        '22',
        '25',
      ];
      setTipOptions(
        // @ts-ignore
        tips?.map(t => parseInt(t)).concat('Custom') ?? [],
      );
    }
  }, [restaurantProfile]);

  const [tipOptions, setTipOptions] = useState([]);
  const order = useSelector<RootState, TTOrderV2>(state => state.order.order);
  const [selectedTip, setSelectedTip] = useState();
  const [showCustomField, setShowCustomField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOnce, setSelectedOnce] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleUpdateTipForm = async e => {
    try {
      setLoading(true);
      let tip;
      if (selectedTip !== null && selectedTip !== undefined) {
        tip = selectedTip;
      }
      if (
        e.target.elements.custom_tip !== undefined &&
        e.target.elements.custom_tip !== null
      ) {
        tip = e.target.elements.custom_tip.value;
      }
      const response = await ordersApi.updateTip(order?.id, {
        tip_percentage: parseInt(tip) / 100,
        guest_id: store.getState().auth.userProfile?.id,
        pm_id: store.getState().payment.selected_pm,
      });
      dispatch(createGuestCheck(response.data, () => {}));
      dispatch(setOrder(response.data));
      dispatch(setOrderTipPercentage(parseInt(tip) ?? 22));
      message.success(
        `Tip amount updated! ${parseInt(tip) > 0.2 ? 'Thank you.' : ''}`,
      );
      if (closeOrderPost) {
        try {
          await paymentsApi.capturePaymentIntent(order.id);
          message.success({
            content: 'Tab Closed',
            className: '',
            style: { borderRadius: '30px' },
            duration: 2,
            icon: <SuccessIcon />,
            onClose: () => {
              setLoading(false);
              navigate(`/feed/restaurants/${r_id}/order/${order.id}/thankyou`);
              dispatch(clearCart());
              dispatch(attemptCloseOrder(() => {}));
            },
          });
        } catch (err) {
          console.log(err.response);
          message.error({
            content: 'Unable to close your tab',
            className: '',
            style: { borderRadius: '30px' },
            duration: 2,
            icon: <ErrorIcon />,
            onClose: () => {
              setLoading(false);
            },
          });
        }
      } else {
        setLoading(false);
        onClose();
      }
    } catch (e) {
      setLoading(false);
      message.error(
        e?.response?.data?.detail ?? 'Unable to update the tip amount',
      );
    }
  };

  return (
    <div className="bg-white shadow rounded-md">
      <Spin spinning={loading} indicator={LoadingIcon}>
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Update your tip
          </h3>

          <form
            className="mt-5 sm:flex sm:items-center"
            onSubmit={async e => {
              e.preventDefault();
              if (selectedTip === undefined && !showCustomField) {
                message.error('Please select a tip amount');
                return;
              }
              await handleUpdateTipForm(e);
            }}
          >
            <div className="w-full sm:max-w-xs">
              <label htmlFor="email" className="sr-only">
                Tip %
              </label>
              {!showCustomField && (
                <div className="flex flex-row items-center overflow-x-scroll space-x-2 py-4">
                  {tipOptions?.map((i, k) => {
                    return (
                      <div
                        key={i.toString()}
                        className={`  px-4 py-1 rounded-full ${
                          selectedTip === i
                            ? 'bg-blue-400 text-white font-bold'
                            : 'bg-gray-200 text-gray-900'
                        }`}
                        onClick={() => {
                          if (k === 4) {
                            setShowCustomField(true);
                          } else {
                            setSelectedTip(i);
                          }
                        }}
                      >
                        {k !== 4 ? `${i}%` : `${i}`}
                      </div>
                    );
                  })}
                </div>
              )}
              {showCustomField && (
                <div className="pb-4">
                  <label
                    htmlFor="custom_tip"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    Custom % amount
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm flex flex-row">
                    {/* <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"> */}
                    {/* <span className="text-gray-500 sm:text-sm">%</span> */}
                    {/* </div> */}
                    <input
                      type="text"
                      name="custom_tip"
                      id="custom_tip"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-2 pr-12 sm:text-sm border-gray-300 rounded-l-md"
                      placeholder="22"
                      required
                      aria-describedby="price-currency"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setShowCustomField(false);
                      }}
                      className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <XIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Cancel</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-4 border rounded-md px-2 py-2">
              <h3 className="text-base leading-6 font-medium text-gray-900 text-left">
                Current Payment Method
              </h3>
              <PaymentMethods />
            </div>
            <button
              type="submit"
              className="mt-6 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto text-base"
            >
              Update
            </button>
          </form>
        </div>
      </Spin>
    </div>
  );
}
