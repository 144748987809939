import moment from 'moment';
import { Moment } from 'moment';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TTMenuItem } from '../../../interfaces/menuItem';
import {
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
  isBeforeDay,
  isAfterDay,
} from '../../../utils';
import { SingleDatePicker } from 'react-dates';
import { bookingApi } from '../../../api/booking';
import { BookingSlots } from '../../../interfaces/booking_schedule';
import { RadioGroup } from '@headlessui/react';
import { message, Spin } from 'antd';
import { LoadingIcon } from '../../components/Spinner';

type Props = {
  menuItem: TTMenuItem;
  onSelect: (selectedSlot: BookingSlots) => void;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function BookingSchdule({ menuItem, onSelect }: Props) {
  const [bookingDate, setBookingDate] = useState<Moment>();
  const [bookingSlots, setBookingSlots] = useState<BookingSlots[]>([]);
  const [selectedSlot, setSelectSlot] = useState<BookingSlots | null>(null);
  const [fetchSlots, setFetchingSlots] = useState(false);
  const [focused, setFocused] = useState(false);
  const onFocusChange = ({ focused }) => {
    setFocused(focused);
  };
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const fetchAvailableSlots = async () => {
    try {
      setFetchingSlots(true);
      const response = await bookingApi.getBookingSlots(
        menuItem?.id,
        bookingDate.format('YYYY-MM-DD'),
      );
      setBookingSlots(response.data);
      setFetchingSlots(false);
    } catch (e) {
      setFetchingSlots(false);
      message.error(
        'Unable to fetch the booking slots. Please try again later.',
      );
    }
  };

  console.log(menuItem?.booking_settings);

  useMemo(() => {
    if (bookingDate) {
      fetchAvailableSlots();
    }
  }, [bookingDate]);

  return (
    <div className="pr-4 pl-4 py-4">
      <div className="bg-white rounded-md px-2 py-2 shadow-md">
        {/* <h4 className="text-lg font-medium leading-6 text-gray-900">
          Booking Information
        </h4> */}
        <div className="mb-4 flex flex-col items-start">
          <div className="flex flex-row justify-left content-center items-start mb-2">
            <label
              className=" block uppercase tracking-wide text-gray-700 text-xs font-bold"
              htmlFor="booking_date_input"
            >
              Booking Date
            </label>
          </div>
          <SingleDatePicker
            date={bookingDate}
            enableOutsideDays={false}
            keepFocusOnInput={false}
            onDateChange={date => {
              setBookingDate(date);
              //   setFocused(false);
            }}
            keepOpenOnDateSelect={false}
            id={'booking_date_input'}
            isOutsideRange={day => {
              const minDate = moment().add(
                menuItem?.booking_settings?.minimum_days_from_booking ?? 0,
                'days',
              );
              const maxDate = moment()
                .add(
                  menuItem?.booking_settings?.maximum_days_from_booking ?? 60,
                  'days',
                )
                .endOf('day');
              let is_blackout = false;
              menuItem?.booking_settings?.blackout_dates?.forEach(i => {
                if (
                  moment.utc(i).tz(tz).format('YYYY-MM-DD') ==
                  day.format('YYYY-MM-DD')
                ) {
                  console.log(moment.utc(i).tz(tz).format('YYYY-MM-DD'));
                  console.log(day.format('YYYY-MM-DD'));
                  is_blackout = true;
                }
              });
              return (
                isBeforeDay(day, minDate) ||
                isAfterDay(day, maxDate) ||
                is_blackout ||
                menuItem?.booking_settings?.blackout_days?.includes(day.day())
              );
            }}
            focused={focused}
            numberOfMonths={1}
            withPortal={false}
            onFocusChange={onFocusChange}
          ></SingleDatePicker>
        </div>
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            {/* <span className="bg-white px-2 text-sm text-gray-500">Continue</span> */}
          </div>
        </div>
        <div className="mt-4">
          <div className="flex flex-row justify-left content-center items-start mb-2">
            <label
              className=" block uppercase tracking-wide text-gray-700 text-xs font-bold"
              htmlFor="booking_time_slots"
            >
              Booking Time Slots
            </label>
          </div>
          <Spin
            spinning={fetchSlots}
            tip={'Fetching available slots'}
            indicator={LoadingIcon}
          >
            <RadioGroup
              value={selectedSlot}
              onChange={slot => {
                setSelectSlot(slot);
                onSelect(slot);
              }}
            >
              <RadioGroup.Label className="sr-only">
                {' '}
                Booking Slot{' '}
              </RadioGroup.Label>
              <div className="space-y-4">
                {bookingSlots.map(plan => (
                  <RadioGroup.Option
                    key={plan.start_at}
                    disabled={plan.bookings_remaining <= 0}
                    value={plan}
                    className={({ checked, active }) =>
                      classNames(
                        checked ? 'border-transparent' : 'border-gray-300',
                        active ? 'border-green-500 ring-2 ring-green-500' : '',
                        'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between',
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <span className="flex items-center">
                          <span className="flex flex-col text-sm">
                            <RadioGroup.Label
                              as="span"
                              className="font-medium text-gray-900"
                            >
                              {moment(plan.start_at, 'HH:mm:ss').format(
                                'hh:mm a',
                              )}{' '}
                              -{' '}
                              {moment(plan.end_at, 'HH:mm:ss').format(
                                'hh:mm a',
                              )}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className="text-gray-500"
                            >
                              <span className="block sm:inline">
                                {plan.bookings_remaining !== 0
                                  ? 'spots available'
                                  : 'fully booked'}
                              </span>
                            </RadioGroup.Description>
                          </span>
                        </span>
                        <span
                          className={classNames(
                            active ? 'border' : 'border-2',
                            checked ? 'border-green-500' : 'border-transparent',
                            'pointer-events-none absolute -inset-px rounded-lg',
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </Spin>
        </div>
      </div>
    </div>
  );
}
