import React, { useEffect } from 'react';
import store from '../store';

type Props = {
  onCancel: () => void;
};

export const WalkthroughPopUp: React.FC<Props> = ({ onCancel }) => {
  // useEffect(() => {
  //   localStorage.setItem('_tt_walkthrough', 'true')
  // }, [])
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 text-center  mt-10">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="bottom-0 fixed inline-block align-bottom bg-white rounded-lg max-h-full px-4 pt-5 pb-4 -mb-20 pb-32 text-left overflow-y-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <svg
            className={'text-gray-500 w-8 h-8 top-0 right-0 m-1 fixed'}
            onClick={() => {
              localStorage.setItem('_tt_walkthrough', 'true');
              onCancel();
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div>
            <h2 className="text-3xl leading-9 font-extrabold text-gray-900 mb-10">
              Ordering using TableTab
            </h2>
            <nav aria-label="Progress mt-8">
              <ol className="overflow-hidden">
                <li className="relative pb-10">
                  {/*<div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full "*/}
                  {/*     aria-hidden="true"/>*/}
                  <span className="relative flex items-start group">
                    <span className="h-9 flex items-center">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-primary-600 rounded-full">
                        <span className="h-2.5 w-2.5 bg-primary-600 rounded-full" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase">
                        🍽 Browse the menu
                      </span>
                      <span className="text-sm text-gray-500">
                        Check out the menu by clicking the ones available to
                        you.
                      </span>
                    </span>
                  </span>
                </li>

                <li className="relative pb-10">
                  {/*<div className={`-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full 'bg-primary-600'}`}*/}
                  {/*     aria-hidden="true"/>*/}
                  <span
                    className="relative flex items-start group"
                    aria-current="step"
                  >
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span
                        className="h-9 flex items-center"
                        aria-hidden="true"
                      >
                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-primary-600 rounded-full">
                          <span className="h-2.5 w-2.5 bg-primary-600 rounded-full" />
                        </span>
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                        👆 Add menu items to your tab
                      </span>
                      <span className="text-sm text-gray-500">
                        Click an item that looks good to you, customize it, and
                        then add it to your tab.
                      </span>
                    </span>
                  </span>
                </li>

                <li className="relative pb-10">
                  {/*<div className={`-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-primary-600}`}*/}
                  {/*     aria-hidden="true"/>*/}
                  <span className="relative flex items-start group">
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-primary-600 rounded-full">
                        <span className="h-2.5 w-2.5 bg-primary-600 rounded-full" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                        👀 View tab when done adding
                      </span>
                      <span className="text-sm text-gray-500">
                        When done adding items, click "View Tab" at the bottom
                        of your screen to view your tab.
                      </span>
                    </span>
                  </span>
                </li>

                <li className="relative pb-10">
                  {/*<div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"*/}
                  {/*     aria-hidden="true"/>*/}
                  <span className="relative flex items-start group">
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-primary-600 rounded-full">
                        <span className="h-2.5 w-2.5 bg-primary-600 rounded-full" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                        💳 Add your payment info
                      </span>
                      <span className="text-sm text-gray-500">
                        Securely add a valid card to authorize your payment
                        method.
                      </span>
                    </span>
                  </span>
                </li>

                <li className="relative pb-10">
                  <span className="relative flex items-start group">
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-primary-600 rounded-full">
                        <span className="h-2.5 w-2.5 bg-primary-600 rounded-full" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                        👏 Start your tab
                      </span>
                      <span className="text-sm text-gray-500">
                        With payment authorized, click “Start your Tab” to send
                        your order to the kitchen!
                      </span>
                    </span>
                  </span>
                </li>
                <li className="relative  pb-10">
                  <span className="relative flex items-start group">
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-primary-600 rounded-full">
                        <span className="h-2.5 w-2.5 bg-primary-600 rounded-full" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                        🔁 Order more or close tab
                      </span>
                      <span className="text-sm text-gray-500">
                        Order more by adding to your tab during your experience.
                        Close the tab when you’re done.
                      </span>
                    </span>
                  </span>
                </li>
              </ol>
            </nav>
            <div className="mb-6">
              <span className={'font-extrabold mr-1 text-gray-900 text-sm'}>
                Note on Privacy:
              </span>
              <span className={'text-sm text-gray-500'}>
                We collect your email to send you receipts after your meal, and
                we collect your phone number to keep your payment information
                secure. Your email and phone number are not shared with
                third-parties, sold, or used for any other reason than your
                protection.
              </span>
            </div>
            <div className="mb-6">
              <span className={'font-extrabold mr-1 text-gray-900 text-sm'}>
                Need Help?
              </span>
              <span className={'text-sm text-gray-500'}>
                Click the question mark at the top right corner of your screen
                to message us directly so we can help you.
              </span>
            </div>
            <p className={'text-sm text-gray-500'}>
              Thank you for supporting restaurants. All TableTab orders are sent
              with ♥
            </p>
          </div>
          <div className="mt-5 sm:mt-6">
            <button
              onClick={onCancel}
              id={'dismiss-walkthrough-btn'}
              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-400 text-base sm:text-lg font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
